//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const VERIFY_SESSION: string = typeCreatorV2(
  'VerifySessionReducer',
  'VerifySession'
);

export const RESEND_EMAIL: string = typeCreatorV2(
  'ForgotPasswordReducer',
  'ResendEmail'
);
