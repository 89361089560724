import { Roles } from 'constants/authRolesConst';
import React from 'react';
import { useIsLogin } from 'redux/reducers/gl_auth_reducer/selectors';

const withAuth = (Component: any): React.FC => {
  const ComposedComponent = (props: any) => {
    const isLogin = useIsLogin();
    console.log('isLogin:::', isLogin);
    return isLogin ? <Component {...props} /> : null;
  };

  return ComposedComponent;
};

export default withAuth;
