// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request, { nativeAxios } from 'utils/request';
import * as PathUtils from 'utils/path';
import { ItemTypeEnum, KnowledgeLevelEnum } from 'constants/typesConst';
import { LanguagesEnum } from 'constants/languagesConst';
import { ItemStatusEnum } from 'constants/statusConst';
import { AppTypes } from 'constants/namespaceConst';

/** fetch trick categories list */
interface IFetchTrickCategoriesOutput extends Types.ResponseOutput {
  data: FetchTrickCategoriesData;
}

export type FetchTrickCategoriesData = Array<{
  id: string;
  path: string;
  name: string;
}>;

export interface IFetchTrickCategoriesInput {}

export const fetchTrickCategoriesList = async (
  payload: IFetchTrickCategoriesInput
): Promise<IFetchTrickCategoriesOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/item/categories`
  );

  return data;
};

/** fetch Tricks list */
interface IFetchTrickListOutput extends Types.ResponseOutput {
  data: FetchTrickListData;
}

export type FetchTrickListData = {
  items: Array<Types.ITrickData>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface IFetchTrickListInput {
  public?: boolean;
  page?: number;
  pageSize?: number;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  tag?: string;
  globalSearch?: string;
  createdAt_Start?: Date;
  createdAt_End?: Date;
  updatedAt_Start?: Date;
  updatedAt_End?: Date;
  type?: ItemTypeEnum;
  status?: ItemStatusEnum;
  createdBy?: string;
}

export const fetchTrickList = async (
  payload: IFetchTrickListInput
): Promise<IFetchTrickListOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'public',
        value:
          payload.public === true
            ? 'true'
            : payload.public === false
            ? 'false'
            : undefined,
      },
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },
      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'tag',
        value: payload.tag,
      },
      {
        key: 'globalSearch',
        value: payload.globalSearch,
      },
      {
        key: 'createdAt_Start',
        value: payload.createdAt_Start
          ? new Date(payload.createdAt_Start).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'createdAt_End',
        value: payload.createdAt_End
          ? new Date(payload.createdAt_End).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'updatedAt_Start',
        value: payload.updatedAt_Start
          ? new Date(payload.updatedAt_Start).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'updatedAt_End',
        value: payload.updatedAt_End
          ? new Date(payload.updatedAt_End).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'types',
        value: payload.type,
      },
      {
        key: 'status',
        value: payload.status,
      },
      {
        key: 'createdBy',
        value: payload.createdBy,
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/item/list${query}`
  );

  return data;
};

/** fetch Trick Details */
interface IFetchTrickDetailsOutput extends Types.ResponseOutput {
  data: FetchTrickDetailsData;
}

export type FetchTrickDetailsData = Types.ITrickData & {
  translations?: {
    [key in LanguagesEnum]: Partial<{
      description: string;
      question: string;
      bookSection_ContentTranslation: string;
    }>;
  };
};

export const fetchTrickDetails = async (
  trickId: string
): Promise<IFetchTrickDetailsOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/item/details/${trickId}`
  );

  return data;
};

/** fetch trick tags list */
interface IFetchTrickTagsOutput extends Types.ResponseOutput {
  data: FetchTrickTagsData;
}

export type FetchTrickTagsData = Array<{
  id: string;
  label: string;
  group?: string[];
}>;

export const fetchItemTagsList = async (): Promise<IFetchTrickTagsOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'renameId',
        value: 'id',
      },
      {
        key: 'renameLabel',
        value: 'label',
      },
    ],
  });

  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/item/tags${query}`
  );

  return data;
};

// /** fetch trick places list */
// interface IFetchTrickPlacesOutput extends Types.ResponseOutput {
//   data: FetchTrickPlacesData;
// }

// export type FetchTrickPlacesData = Array<{
//   id: string;
//   label: string;
// }>;

// export interface IFetchTrickPlacesInput {}

// export const fetchTrickPlacesList =
//   async (): Promise<IFetchTrickPlacesOutput> => {
//     const { data } = await request(
//       'get',
//       `${Environment.API_BASE_URL}/api/item/places`
//     );

//     return data;
//   };

/** Add Trick */
interface IAddTrickOutput extends Types.ResponseOutput {
  data: null;
}

export interface IAddTrickInput {
  title: string;
  description: string;
  type: string;
  link: string;
  tags: string[];
  public: boolean;
  difficulty?: number | null;
  examples: string[];
  additionalInfo?: string;
  namespaces: string[];
  question?: string;
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string;
        question: string;
        bookSection_ContentTranslation: string;
      }>;
    }
  >;
  status: ItemStatusEnum;
  imageId?: string;
  etymologyEvolution?: Types.ITrickData['etymologyEvolution'];
  hiddenInfo?: string;
  bookSection_Content?: string;
  bookSection_ContentTranslation?: string;
}

export const addTrick = async (
  payload: IAddTrickInput
): Promise<IAddTrickOutput> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/item/add`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Edit Trick */
interface IEditTrickOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditTrickInput extends Omit<IAddTrickInput, 'imageId'> {
  imageId: string | null;
}

export const editTrick = async (
  trickId: string,
  payload: Partial<IEditTrickInput>
): Promise<IEditTrickOutput> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/item/details/${trickId}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Remove Trick */
interface IRemoveTrickOutput extends Types.ResponseOutput {
  data: null;
}

export interface IRemoveItemPayload {
  itemId: string;
  removeImage?: boolean;
}

export const removeTrick = async (
  payload: IRemoveItemPayload
): Promise<IRemoveTrickOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'removeImage',
        value: payload.removeImage ? 'true' : 'false',
      },
    ],
  });

  const { data } = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/item/details/${payload.itemId}${query}`,
    {
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Download Trick */
export const downloadTrick = async (trickId: string) => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/item/document/${trickId}`,
    {
      config: {
        responseType: 'blob',
      },
    }
  );

  const url = window.URL.createObjectURL(new Blob([data]));
  var a = document.createElement('a');
  a.href = url;
  a.download = `${trickId}.docx`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

/** updateBookItems */
export interface IUpdateBookItemsPayload {
  fileId: string;
  collectionId: string;
  senctions: Array<{
    pageTo: number;
    pageFrom: number;
    title: string;
  }>;
}

export const updateBookItems = async (
  payload: IUpdateBookItemsPayload
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'put',
    `${Environment.API_BASE_URL}/api/item/add/collection/book`,
    {
      toastMessageVisibility: {
        success: true,
        error: true,
      },
      body: payload,
    }
  );

  return data;
};

/** View collection items per student */

export interface IViewCollectionItemsPerStudentData {
  items: Array<
    Types.ITrickData & {
      library?: {
        _id: string;
        practiceMode: {
          weight: number | null;
          practice: boolean;
          _id: string;
        };
        favorite: false;
        item: string;
        knowledgeLevel: {
          wrongTimes: number;
          correctTimes: number;
          dontKnowTimes: number;
          level: KnowledgeLevelEnum;
          _id: string;
        };
        latestQuizAnswer?: {
          value: string;
          isCorrect: boolean;
          itemType: ItemTypeEnum;
        };
      };
    }
  >;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
}

export interface IViewCollectionItemsPerStudentPayload {
  collectionId: string;
  studentId: string;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  page?: number;
  pageSize?: number;
}

export const viewCollectionItemsPerStudent = async (
  payload: IViewCollectionItemsPerStudentPayload
): Promise<Types.ResponseOutputV2<null>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },

      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'collectionId',
        value: payload.collectionId,
      },
      {
        key: 'studentId',
        value: payload.studentId,
      },
    ],
  });

  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/item/view-collection-items/student${query}`
  );

  return data;
};
