import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import {
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Divider,
} from '@material-ui/core';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import { useForm } from 'react-hook-form';
import { AppCommon, AppTextField } from 'components/molecules/FormControls';
import UPloadedFilesActions from '../../store/actions';
import * as UPloadedFilesSelectors from '../../store/selectors';
import { DefaultPaging } from '../../configuration';
import { StateStatus } from 'redux/utils/common';
import { useContextProps } from '../../provider';

interface IEditFileDataParams {
  name: string | null;
  fileId: string;
}

const ModalEditFileData = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_EDIT_FILE_DATA}
      renderContent={(props: IEditFileDataParams) => <Content {...props} />}
      modalProps={{
        fullWidth: true,
      }}
      contentProps={{
        style: {
          padding: 0,
          margin: 0,
        },
      }}
      renderHeader={(props: IEditFileDataParams) => (
        <TranslatedText defaultText={'Edit'} />
      )}
    />
  );
};

export const openUpdateFileDataModal = (params: IEditFileDataParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_EDIT_FILE_DATA,
      params,
    })
  );
};

export const closeUpdateFileDataModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_EDIT_FILE_DATA,
    })
  );
};

interface IFormData {
  name: string | null;
}

const defaultValues: IFormData = {
  name: null,
};

export const Content = (props: IEditFileDataParams) => {
  const dispatch = useDispatch();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const editStatus = UPloadedFilesSelectors.useEditFileStatus();
  const { refetch } = useContextProps();

  React.useEffect(() => {
    reset({
      ...defaultValues,
      name: props.name,
    });
  }, [reset]);

  const submit = handleSubmit((data: IFormData) => {
    dispatch(
      UPloadedFilesActions.editFileAction({
        payload: {
          name: data.name as string,
        },
        onSuccess: () => {
          refetch && refetch();
          // dispatch(
          //   UPloadedFilesActions.fetchFilesAction({
          //     payload: contextParams,
          //     onSuccess: () => {},
          //   })
          // );
          closeUpdateFileDataModal();
        },
        fileId: props.fileId,
      })
    );
  });

  return (
    <>
      <Box style={{ padding: '1rem' }}>
        <AppTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label={<TranslatedText defaultText={'Name'} />}
          name="name"
          autoComplete="name"
          control={control}
          error={'name' in errors}
          helperText={errors.name && errors.name.message}
          rules={{
            required: <AppCommon.RequiredField />,
          }}
        />
      </Box>

      <Divider style={{ marginTop: '1rem' }} />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0.5rem',
        }}
      >
        <Button
          disabled={editStatus === StateStatus.Pending}
          variant="contained"
          color="primary"
          onClick={submit}
        >
          <TranslatedText defaultText="Update" />
        </Button>
      </Box>
    </>
  );
};

export default ModalEditFileData;
