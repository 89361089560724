import { NavbarTypeEnum } from 'constants/navigationConst';
import { getState } from 'redux/store';
import { IAction, IActionMethods } from '../../utils/common';
import * as NavigationSelectors from './selectors';
import * as Types from './types';

interface ISetSideMenuVisiblityInput {
  isOpen: boolean;
}

class SetSideMenuVisiblity implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: ISetSideMenuVisiblityInput): IAction {
    return {
      type: Types.SET_SIDE_MENU_VISIBILITY,
      data: {
        sideMenuIsOpen: data.isOpen,
      },
    };
  }
  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: ISetSideMenuVisiblityInput): IAction {
    return this.onSuccess(data);
  }
}

interface INavigateToScreenInput {
  history: any;
  path: string;
  selectedItemID: number | string;
}

interface INavigateToScreenSuccessInput {
  close: boolean;
  selectedItemID: number | string;
}

class NavigateToScreen implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(data: INavigateToScreenSuccessInput): IAction {
    return {
      type: Types.SET_NAVIGATE_TO_SCREEN,
      data: {
        visibility: !data.close,
        selectedItemID: data.selectedItemID,
      },
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: INavigateToScreenInput): IAction {
    let close = NavigationSelectors.isMobileScreen()
      ? true
      : !getState().NavigationReducer.sideMenuIsOpen;

    data.history.push(data.path);
    return this.onSuccess({ close, selectedItemID: data.selectedItemID });
  }
}

class SetNavbarType implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(data: NavbarTypeEnum): IAction {
    return {
      type: Types.SET_NAVBAR_TYPE,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: NavbarTypeEnum): IAction {
    return this.onSuccess(data);
  }
}

export default {
  setSideMenuVisiblityAction: (isOpen: boolean) =>
    new SetSideMenuVisiblity().action({ isOpen }),
  navigateToScreenAction: (
    history: any,
    path: string,
    selectedItemID: number | string
  ) => new NavigateToScreen().action({ history, path, selectedItemID }),
  setNavbarTypeAction: (payload: NavbarTypeEnum) =>
    new SetNavbarType().action(payload),
};
