import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppTextField,
  AppSelect,
  IMultipleSelectProps,
  AppCommon,
  IAdvancedSelectProps,
  AppAdvancedselect,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useWatch,
  useForm,
  useFormContext,
} from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'constants/index';
import GridLayout, { IElement } from 'components/molecules/GridLayout';
import { colors, IconButton, Paper, useTheme } from '@material-ui/core';
import RoutineDetailsActions from '../../store/actions';
import * as RoutineDetailsSelectors from '../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields, OverrideValues } from 'utils/generics';
import NavigationConfig from 'config/navigationConfig';
import { IFormData, VectorIconsList, defaultValues } from '../../configuration';
import TrickList from '../ItemsList';
import TrickListModal, { TricksListModalParams } from '../ItemsListModal';
import { withVerification } from 'components/molecules/ModalVerifyAction';
import {
  exportCollectionExercises as downloadRoutine,
  downloadCsvCollection,
} from 'services/api/collections';
import RichTextEditor, {
  convertDraftToHtml,
  convertHtmlToDraft,
} from 'components/atoms/RichTextEditor';
import {
  CollectionTypeEnum,
  CollectionTypeNeedsDescription,
  CollectionTypeNeedsQuizType,
  ItemGroups,
  ItemTypeEnum,
  QuizTypeEnum,
} from 'constants/typesConst';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import TagDetailsModal, {
  openTagDetailsModal,
  closeTagDetailsModal,
} from 'components/compounds/TagDetailsModal';
import { TagTypes } from 'constants/tagsConst';
import { EditorState } from 'draft-js';
import {
  useSelectedPortalNamespace,
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import {
  openTranslationModalFormModal,
  ITranslationsModalFormParams,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import TranslateIcon from '@material-ui/icons/Translate';
import { DefaultLanguage, LanguagesEnum } from 'constants/languagesConst';
import {
  fixTranslations,
  getFieldTranslations,
  translation,
} from 'utils/translation';
import { useNamespacesList } from 'redux/reducers/gl_common_reducer/selectors';
import { PaymentTypeEnum, PaymentTypeOptions } from 'constants/paymentsConst';
import {
  CollectionStatusEnum,
  CollectionStatusOptions,
} from 'constants/statusConst';
import { CollectionTypeOptions } from 'constants/dropdownOptions';
import {
  closeUploadedFilesModal,
  openUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  getFileDetails,
  openFileToNewTab,
  openFileToNewTabAsync,
} from 'services/api/file';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { FileType } from 'constants/filesConst';
import SelectButton from 'components/molecules/SelectButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import LanguageIcon from '@material-ui/icons/Language';
import { getQuizzesByChapterType } from '../../common';
import {
  closePromptsModal,
  openPromptsModal,
} from 'components/app_common/Prompts';
import { formatCamelOrUnderscoreCaseToSentenceCase } from 'utils/strings';
import { Roles } from 'constants/authRolesConst';
import { openPromptInputModalModal } from 'components/app_common/PromptInputModal';
import {
  addItemsDataByPrompt,
  ICollectionPromptData,
} from 'services/api/openai';
import * as OpenAIService from 'services/api/openai';
import QuizTypesButton from './QuizTypesButton';
import OptionsMenu from 'components/atoms/OptionsMenu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import TranscriptTextModal from './TranscriptTextModal';
import VideoActionsMenu from './VideoActionsMenu';
import ImageUrlSection from './ImageUrlSection';
import { useContextProps } from '../../provider';

interface IAddEditTrickFormProps {
  routineId?: string;
  type?: CollectionTypeEnum;
  // collIsPublic: boolean;
}

export default function AddEditTrickForm({
  routineId,
}: // type,
// collIsPublic,
IAddEditTrickFormProps) {
  const classes = useStyles({});
  const trickDetails = RoutineDetailsSelectors.useRoutineDetails();
  const {
    reset,
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
    watch,
  } = useFormContext<IFormData>();
  // const params = useParams<ITrickDetailsPageParams>();
  const { ...params } = useContextProps();
  const dispatch = useDispatch();
  const history = useHistory();
  const tricksRef = React.useRef<IFormData['items']>();
  const descriptionEditorStateRef = React.useRef<EditorState | null>(null);
  const namespaces = useNamespacesList();
  const namespacesOptions = React.useMemo(
    () => namespaces.data?.map((el) => ({ id: el._id, label: el.name })) ?? [],
    [namespaces]
  );
  const selectedNamespace = useSelectedPortalNamespace();
  const collectionType = watch('type');
  const roles = useSelectUserRoles();

  const initialRichText = React.useMemo(
    () => convertHtmlToDraft(trickDetails.data?.description ?? ''),
    [trickDetails]
  );

  console.log('render form:', trickDetails.status);

  React.useEffect(() => {
    if (trickDetails.data) {
      reset({
        ...defaultValues,
        title: trickDetails.data.title,
        description: trickDetails.data.description,
        type: trickDetails.data.type as CollectionTypeEnum,
        link: trickDetails.data.link,
        quizTypes: trickDetails.data.quizTypes as QuizTypeEnum[],
        tags: trickDetails.data.tags ?? [],
        public: trickDetails.data.public ? 'true' : 'false',
        items: trickDetails.data.items?.map(
          (item) =>
            ({
              id: item?._id,
              title: item?.title,
              tags: item?.tags,
              status: item.status,
              type: item.type as ItemTypeEnum,
              difficulty: item.difficulty,
              public: item.public,
            } ?? [])
        ),
        namespaces: trickDetails.data.namespaces ?? null,
        imageId: trickDetails.data.imageId?._id ?? null,
        translations: trickDetails.data.translations,
        status: trickDetails.data.status,
        paymentType: trickDetails.data.paymentType,
        difficulty: trickDetails.data.difficulty,
      });
      register('translations');
    } else {
      setValue('namespaces', [selectedNamespace]);
    }
  }, [trickDetails, reset, selectedNamespace]);

  const handleTranslation = React.useCallback(
    (
      name: string,
      title: string,
      options?: Partial<ITranslationsModalFormParams>
    ) => {
      openTranslationModalFormModal({
        onSubmit: (data) => {
          Object.keys(data).forEach((lan) => {
            const key = `translations.${lan}.${name}`;
            register(key);
            setValue(key, data[lan as LanguagesEnum]);
          });
        },
        type: 'text',
        title,
        initialData: getFieldTranslations(name, getValues() as any) as any,
        modalSize: 'xlarge',
        autoTranslation: {
          textToTranslate: _.get(getValues(), name),
          execute: async (text, lanTo) => {
            return await translation(text, {
              lanTo,
              lanFrom: 'defaultLan',
            });
          },
        },
        ...options,
      });
    },
    []
  );

  const onSubmit = handleSubmit((_data: NoNullFields<IFormData>) => {
    const description = descriptionEditorStateRef.current
      ? convertDraftToHtml(descriptionEditorStateRef.current)
      : '';

    if (params.mode === 'add') {
      dispatch(
        RoutineDetailsActions.addRoutineAction({
          payload: {
            ..._data,
            tags: _data.tags ?? null,
            description,
            type: _data.type,
            link: _data.link ?? undefined,
            public: _data.public === 'false' ? false : true, // collIsPublic,
            items: tricksRef.current?.map((el) => el.id) ?? [],
            namespaces: _data.namespaces ?? [],
            imageId: _data.imageId ?? undefined,
            translations: fixTranslations(_data.translations),
            difficulty: _data.difficulty
              ? parseFloat(`${_data.difficulty}`)
              : undefined,
            quizTypes:
              (_data.quizTypes?.length ?? 0) === 0
                ? getQuizzesByChapterType(_data.type) ?? undefined
                : undefined,
          },
          onSuccess: () => {
            history.push(NavigationConfig.routinesPage().path);
          },
        })
      );
    } else if (params.mode === 'edit') {
      // console.log('Payload::', _data);
      dispatch(
        RoutineDetailsActions.editRoutineAction({
          payload: {
            ..._data,
            description,
            type: _data.type,
            link: _data.link ?? undefined,
            public: _data.public === 'false' ? false : true,
            // collIsPublic,
            items: tricksRef.current?.map((el) => el.id) ?? [],
            namespaces: _data.namespaces ?? [],
            imageId: _data.imageId,
            translations: fixTranslations(_data.translations),
            difficulty: _data.difficulty
              ? parseFloat(`${_data.difficulty}`)
              : null,
          },
          onSuccess: () => {
            dispatch(
              RoutineDetailsActions.fetchRoutineDetailsAction(
                routineId as string
              )
            );
          },
          routineId: routineId as string,
        })
      );
    }
  });

  return (
    <>
      <TranscriptTextModal />
      <TrickListModal setValue={setValue} />
      <TagDetailsModal />
      <div className={classes.form}>
        <AppCommon.FormContainer>
          <AppCommon.Section
            iconLeft={
              <IconButton
                onClick={() => {
                  handleTranslation('title', 'Title');
                }}
                size="small"
                style={{ marginRight: '0.3rem' }}
              >
                <LanguageIcon />
              </IconButton>
            }
            title="Title"
            footer={<SelectedLanguages fieldName="title" control={control} />}
          >
            <GridLayout
              justify="flex-start"
              elements={[
                {
                  id: 'title',
                  element: (
                    <AppTextField
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      error={'title' in errors}
                      helperText={errors.title && errors.title.message}
                      rules={{ required: <AppCommon.RequiredField /> }}
                      required
                      fullWidth
                      id="title"
                      control={control}
                      placeholder="Enter Title.."
                      autoComplete="title"
                      name="title"
                    />
                  ),
                  size: 12,
                },
              ]}
            />
          </AppCommon.Section>

          {collectionType !== CollectionTypeEnum.PlacementTest && (
            <AppCommon.Section title="Chapters">
              <TagsSelectorWrapper
                id="tags"
                control={control}
                placeholder="Select Chapters.."
                name="tags"
                helperText={errors.tags && errors.tags.message}
                rules={{ required: <AppCommon.RequiredField /> }}
                margin="normal"
                error={'tags' in errors}
              />
            </AppCommon.Section>
          )}

          {/* <AppCommon.Section
            // contentIsVisible={params.mode === 'edit'}
            title="Image URL"
          >
            <AppTextField
              variant="outlined"
              size="medium"
              margin="normal"
              error={'imageId' in errors}
              helperText={errors.imageId && errors.imageId.message}
              rules={{}}
              fullWidth
              id="imageId"
              disabled
              label={<TranslatedText defaultText={'Image Url'} />}
              control={control}
              autoComplete="imageId"
              icon={{
                ComponentRight: (
                  <>
                    <IconButton
                      onClick={async () => {
                        let initialValue;
                        if (getValues().imageId) {
                          try {
                            const fileDetails = await getFileDetails(
                              getValues().imageId as string
                            );
                            initialValue = fileDetails.data.name;
                          } catch (error) {}
                        }

                        openUploadedFilesModal({
                          handleSelectedFile: (filePath) => {
                            setValue('imageId', filePath);
                            closeUploadedFilesModal();
                          },
                          selectItem: true,
                          initialSearch: initialValue,
                          type: FileType.Image,
                        });
                      }}
                    >
                      <CloudUploadOutlinedIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setValue('imageId', null);
                      }}
                    >
                      <HighlightOffOutlinedIcon />
                    </IconButton>
                  </>
                ),
                ComponentLeft: (
                  <IconButton
                    onClick={async () => {
                      getValues().imageId &&
                        (await openFileToNewTabAsync(
                          getValues().imageId ?? ''
                        ));
                    }}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                ),
              }}
              name="imageId"
            />
          </AppCommon.Section> */}
          <ImageUrlSection />

          <AppCommon.Section
            contentIsVisible={params.mode === 'edit'}
            title="Basic Info"
          >
            <GridLayout
              justify="flex-start"
              elements={[
                {
                  id: 'status',
                  element: (
                    <AppSelect
                      variant="outlined"
                      size="medium"
                      margin="normal"
                      error={'status' in errors}
                      helperText={errors.status && errors.status.message}
                      rules={{ required: <AppCommon.RequiredField /> }}
                      required
                      fullWidth
                      id="status"
                      label={<TranslatedText defaultText={'Status'} />}
                      control={control}
                      autoComplete="status"
                      name="status"
                      options={CollectionStatusOptions}
                    />
                  ),
                  // collectionType === null ? 4 : 6,
                  size: 12,
                  md: 12,
                },
                {
                  id: 'public',
                  element: (
                    <AppSelect
                      variant="outlined"
                      size="medium"
                      margin="normal"
                      error={'public' in errors}
                      helperText={errors.public && errors.public.message}
                      rules={{ required: <AppCommon.RequiredField /> }}
                      required
                      fullWidth
                      id="public"
                      label={<TranslatedText defaultText={'Access'} />}
                      control={control}
                      autoComplete="public"
                      name="public"
                      options={[
                        {
                          id: 'true',
                          label: 'Public',
                        },
                        {
                          id: 'false',
                          label: 'Private',
                        },
                      ]}
                    />
                  ),
                  size: 12,
                },
                // {
                //   id: 'imageId',
                //   element: (
                //     <AppTextField
                //       variant="outlined"
                //       size="medium"
                //       margin="normal"
                //       error={'imageId' in errors}
                //       helperText={errors.imageId && errors.imageId.message}
                //       rules={{}}
                //       fullWidth
                //       id="imageId"
                //       disabled
                //       label={<TranslatedText defaultText={'Image Url'} />}
                //       control={control}
                //       autoComplete="imageId"
                //       icon={{
                //         ComponentRight: (
                //           <>
                //             <IconButton
                //               onClick={() => {
                //                 openUploadedFilesModal({
                //                   handleSelectedFile: (filePath) => {
                //                     setValue('imageId', filePath);
                //                     closeUploadedFilesModal();
                //                   },
                //                   selectItem: true,
                //                 });
                //               }}
                //             >
                //               <CloudUploadOutlinedIcon />
                //             </IconButton>
                //             <IconButton
                //               onClick={() => {
                //                 setValue('imageId', null);
                //               }}
                //             >
                //               <HighlightOffOutlinedIcon />
                //             </IconButton>
                //           </>
                //         ),
                //         ComponentLeft: (
                //           <IconButton
                //             onClick={async () => {
                //               getValues().imageId &&
                //                 (await openFileToNewTabAsync(
                //                   getValues().imageId ?? ''
                //                 ));
                //             }}
                //           >
                //             <VisibilityOutlinedIcon />
                //           </IconButton>
                //         ),
                //       }}
                //       name="imageId"
                //     />
                //     // <AppSelect
                //     //   variant="outlined"
                //     //   size="medium"
                //     //   margin="normal"
                //     //   error={'imageId' in errors}
                //     //   helperText={errors.imageId && errors.imageId.message}
                //     //   rules={{ required: <AppCommon.RequiredField /> }}
                //     //   required
                //     //   fullWidth
                //     //   id="imageId"
                //     //   label={<TranslatedText defaultText={'Collection Icon'} />}
                //     //   control={control}
                //     //   autoComplete="imageId"
                //     //   name="imageId"
                //     //   options={VectorIconsList}
                //     // />

                //     // <AppAdvancedselect
                //     //   control={control}
                //     //   name="imageId"
                //     //   placeholder="Icon"
                //     //   error={'imageId' in errors}
                //     //   id={'imageId'}
                //     //   rules={{ required: <AppCommon.RequiredField /> }}
                //     //   margin={'normal'}
                //     //   helperText={errors.imageId && errors.imageId.message}
                //     //   options={VectorIconsList}
                //     // />
                //     // <AppTextField
                //     //   variant="outlined"
                //     //   margin="normal"
                //     //   size="medium"
                //     //   error={'imageId' in errors}
                //     //   helperText={errors.imageId && errors.imageId.message}
                //     //   rules={{}}
                //     //   fullWidth
                //     //   id="imageId"
                //     //   control={control}
                //     //   label="Image ID"
                //     //   autoComplete="imageId"
                //     //   name="imageId"
                //     // />
                //   ),
                //   size: collectionType === null ? 4 : 6,
                //   md: 12,
                // },
                {
                  id: 'type',
                  element: (
                    <AppSelect
                      variant="outlined"
                      size="medium"
                      margin="normal"
                      error={'type' in errors}
                      helperText={errors.type && errors.type.message}
                      rules={{ required: <AppCommon.RequiredField /> }}
                      required
                      fullWidth
                      style={{
                        display:
                          collectionType === null || Boolean(routineId)
                            ? 'block'
                            : 'none',
                      }}
                      id="type"
                      label={<TranslatedText defaultText={'Type'} />}
                      control={control}
                      autoComplete="type"
                      name="type"
                      options={CollectionTypeOptions}
                    />
                  ),
                  size: 12,
                },
                {
                  id: 'quizTypes',
                  element: (
                    <QuizTypesWrapper
                      id="quizTypes"
                      control={control}
                      placeholder="Quiz Types"
                      name="quizTypes"
                      rules={{}}
                      margin="normal"
                      isMulti
                      options={QuizTypes}
                      helperText={
                        errors.quizTypes && (errors.quizTypes as any).message
                      }
                    />
                  ),
                  size: 12,
                  md: 12,
                },
                {
                  id: 'namespaces',
                  element: (
                    <AppAdvancedselect
                      control={control}
                      name="namespaces"
                      placeholder="Workspaces"
                      error={'namespaces' in errors}
                      id={'namespaces'}
                      rules={{}}
                      margin={'normal'}
                      isMulti
                      helperText={
                        errors.namespaces && errors.namespaces.message
                      }
                      options={namespacesOptions}
                    />
                  ),
                  size: 12,
                  rowStyle: {
                    marginBottom: 0,
                  },
                },
                {
                  id: 'link',
                  element: (
                    <AppTextField
                      variant="outlined"
                      size="medium"
                      margin="normal"
                      error={'link' in errors}
                      helperText={errors.link && errors.link.message}
                      rules={{}}
                      fullWidth
                      id="link"
                      label={<TranslatedText defaultText={'Video url'} />}
                      control={control}
                      autoComplete="link"
                      icon={{
                        ComponentRight: (
                          // <IconButton
                          //   onClick={() =>
                          //     getValues().link &&
                          //     window.open(getValues().link ?? '', '_blank')
                          //   }
                          // >
                          //   <VideocamOutlinedIcon />
                          // </IconButton>
                          <VideoActionsMenu />
                        ),
                      }}
                      name="link"
                    />
                  ),
                  size: 12,
                },
                {
                  id: 'paymentType',
                  element: (
                    <AppSelect
                      variant="outlined"
                      size="medium"
                      margin="normal"
                      error={'paymentType' in errors}
                      helperText={
                        errors.paymentType && errors.paymentType.message
                      }
                      rules={{ required: <AppCommon.RequiredField /> }}
                      required
                      fullWidth
                      id="paymentType"
                      label={<TranslatedText defaultText={'PaymentType'} />}
                      control={control}
                      autoComplete="paymentType"
                      name="paymentType"
                      options={PaymentTypeOptions}
                    />
                  ),
                  size: 12,
                  md: 12,
                },
                {
                  id: 'difficulty',
                  element: (
                    <AppTextField
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      error={'difficulty' in errors}
                      helperText={
                        errors.difficulty && errors.difficulty.message
                      }
                      rules={{}}
                      fullWidth
                      id="difficulty"
                      control={control}
                      label="Difficulty"
                      autoComplete="difficulty"
                      name="difficulty"
                      type="number"
                    />
                  ),
                  size: 12,
                  md: 12,
                },
              ]}
            />
          </AppCommon.Section>

          {CollectionTypeNeedsDescription.includes(
            collectionType as CollectionTypeEnum
          ) && (
            <AppCommon.Section
              // contentIsVisible={params.mode === 'edit'}
              title="Description"
              footer={
                <SelectedLanguages fieldName="description" control={control} />
              }
              iconLeft={
                <IconButton
                  onClick={() => {
                    handleTranslation('description', 'Description', {
                      type: 'richtext',
                      tabs: true,
                      modalSize: 'xlarge',
                      autoTranslation: {
                        textToTranslate: descriptionEditorStateRef.current
                          ? convertDraftToHtml(
                              descriptionEditorStateRef.current
                            )
                          : '',
                        execute: async (text, lanTo) => {
                          return await translation(text, {
                            lanTo,
                            lanFrom: 'defaultLan',
                          });
                        },
                      },
                    });
                  }}
                  size="small"
                  style={{ marginRight: '0.3rem' }}
                >
                  <LanguageIcon />
                </IconButton>
              }
            >
              <GridLayout
                justify="flex-start"
                elements={[
                  {
                    id: 'description',
                    element: (
                      <RichTextEditor
                        initialState={initialRichText}
                        handleChange={(state) => {
                          descriptionEditorStateRef.current = state;
                          // console.log('State::', convertDraftToHtml(state));
                        }}
                      />
                      // <AppTextField
                      //   variant="outlined"
                      //   margin="normal"
                      //   size="medium"
                      //   error={'description' in errors}
                      //   helperText={
                      //     errors.description && errors.description.message
                      //   }
                      //   rules={{}}
                      //   required
                      //   fullWidth
                      //   id="description"
                      //   control={control}
                      //   placeholder="Enter description.."
                      //   autoComplete="description"
                      //   name="description"
                      //   multiline
                      //   rows={8}
                      // />
                    ),
                    size: 12,
                  },
                ]}
              />
            </AppCommon.Section>
          )}

          <AppCommon.Section title="Course Content">
            <TrickList
              config={null}
              ref={tricksRef}
              register={register}
              setValue={setValue}
              control={control}
              type={collectionType}
              collectionId={routineId ?? null}
            />
          </AppCommon.Section>

          <Box className={classes.submitContainer}>
            {/* <SelectButton
              buttonProps={{
                className: classes.submit,
                color: 'primary',
                variant: 'contained',
                startIcon: <DeleteIcon />,
                style: {
                  background: colors.red['600'],
                  color: colors.common.white,
                },
              }}
              items={[
                {
                  action: async () => {
                    withVerification({
                      action1Event: () => {
                        dispatch(
                          RoutineDetailsActions.removeRoutineAction({
                            payload: {
                              collectionId: routineId as string,
                            },
                            onSuccess: () => {
                              history.push(
                                NavigationConfig.routinesPage().path
                              );
                            },
                          })
                        );
                      },
                      title: trickDetails.data?.title ?? `Remove ${type}`,
                      description: (
                        <span>
                          <span
                            style={{ fontSize: '1.15em' }}
                          >{`Are you sure you want to remove the ${type} `}</span>
                          <span
                            style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                          >
                            {`${trickDetails.data?.title}?`}
                          </span>
                        </span>
                      ),
                    });
                  },
                  id: 'removeCollectionOnly',
                  children: (
                    <>
                      <ListItemIcon>
                        <ViewStreamIcon />
                      </ListItemIcon>
                      <ListItemText primary="Collection Only" />
                    </>
                  ),
                },
                {
                  action: async () => {
                    withVerification({
                      action1Event: () => {
                        dispatch(
                          RoutineDetailsActions.removeRoutineAction({
                            onSuccess: () => {
                              history.push(
                                NavigationConfig.routinesPage().path
                              );
                            },
                            payload: {
                              collectionId: routineId as string,
                              removeContent: true,
                            },
                          })
                        );
                      },
                      title: trickDetails.data?.title ?? `Remove ${type}`,
                      description: (
                        <span style={{ fontSize: '1.15em' }}>
                          <span>{`Are you sure you want to remove the ${type} `}</span>
                          <span
                            style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                          >
                            {`${trickDetails.data?.title}`}
                          </span>
                          {' with content?'}
                        </span>
                      ),
                    });
                  },
                  id: 'removeCollectionAndItems',
                  children: (
                    <>
                      <ListItemIcon>
                        <FormatListNumberedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Collection & Items" />
                    </>
                  ),
                },
              ]}
            >
              <TranslatedText defaultText={'Remove'} />
            </SelectButton> */}

            <Button
              variant="contained"
              color="inherit"
              style={{
                background: colors.red['600'],
                color: colors.common.white,
              }}
              startIcon={<DeleteIcon />}
              className={classes.submit}
              onClick={() => {
                const removeItemImageId = 'removeItemImage';
                const removeCollectionImageId = 'removeCollectionImage';
                const removeContentId = 'removeContent';

                withVerification({
                  checkboxes: [
                    {
                      id: removeCollectionImageId,
                      checked: true,
                      content: 'Remove collection image',
                    },
                    {
                      id: removeContentId,
                      checked: false,
                      content:
                        'Remove collection content (phrases, words, exercises)',
                    },
                    {
                      id: removeItemImageId,
                      checked: false,
                      content: 'Remove items image',
                    },
                  ],
                  action1Event: (payload) => {
                    dispatch(
                      RoutineDetailsActions.removeRoutineAction({
                        payload: {
                          collectionId: routineId as string,
                          removeCollectionImage: payload?.checkboxes.find(
                            (el) => el.id === removeCollectionImageId
                          )?.checked,
                          removeContent: payload?.checkboxes.find(
                            (el) => el.id === removeContentId
                          )?.checked,
                          removeItemImage: payload?.checkboxes.find(
                            (el) => el.id === removeItemImageId
                          )?.checked,
                        },
                        onSuccess: () => {
                          history.push(NavigationConfig.routinesPage().path);
                        },
                      })
                    );
                  },
                  title: trickDetails.data?.title ?? `Remove ${collectionType}`,
                  description: (
                    <span>
                      <span
                        style={{ fontSize: '1.15em' }}
                      >{`Are you sure you want to remove the ${collectionType} `}</span>
                      <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                        {`${trickDetails.data?.title}?`}
                      </span>
                    </span>
                  ),
                });
              }}
            >
              <TranslatedText defaultText={'Remove'} />
            </Button>

            {params.mode !== 'add' && (
              <SelectButton
                buttonProps={{
                  className: classes.submit,
                  color: 'primary',
                  variant: 'contained',
                  startIcon: <SystemUpdateAltOutlinedIcon />,
                }}
                items={[
                  {
                    action: async () => {
                      try {
                        await downloadRoutine(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'downloadDocx',
                    children: (
                      <>
                        <ListItemIcon>
                          <AssignmentOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="DOCX FILE" />
                      </>
                    ),
                  },
                  {
                    action: async () => {
                      try {
                        await downloadCsvCollection(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'downloadCsv',
                    children: (
                      <>
                        <ListItemIcon>
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="CSV FILE" />
                      </>
                    ),
                  },
                  // ...(routineId && roles.includes(Roles.Admin)
                  //   ? [
                  //       {
                  //         action: async () => {
                  //           const response =
                  //             await OpenAIService.fetchCollectionPrompt(
                  //               routineId
                  //             );

                  //           const mapData: Required<
                  //             OverrideValues<
                  //               ICollectionPromptData,
                  //               ItemTypeEnum | null
                  //             >
                  //           > = {
                  //             description: null,
                  //             titleTranslations: null,
                  //             itemsMultipleChoice: ItemTypeEnum.MultipleChoice,
                  //             itemsTrueFalse: ItemTypeEnum.TrueOrFalse,
                  //             itemsRearrangementQuestionAnswer:
                  //               ItemTypeEnum.RearrangementAnswer,
                  //             itemsQuestionAnswer: ItemTypeEnum.QuestionAnswer,
                  //             itemsRearrangement: ItemTypeEnum.Rearrangement,
                  //             itemsPhrasesByText: ItemTypeEnum.Phrase,
                  //             itemsPhrasesByTopic: ItemTypeEnum.Phrase,
                  //             itemsWordsByText: ItemTypeEnum.Word,
                  //             itemsWordsByTopic: ItemTypeEnum.Word,
                  //             descriptionByItems: null,
                  //             descriptionByTopic: null,
                  //           };

                  //           openPromptsModal({
                  //             prompts: Object.keys(response.data ?? {}).map(
                  //               (el) => ({
                  //                 title:
                  //                   formatCamelOrUnderscoreCaseToSentenceCase(
                  //                     el
                  //                   ),
                  //                 content: _.get(response.data, el),
                  //                 handleAddData: _.get(mapData, el)
                  //                   ? () => {
                  //                       openPromptInputModalModal({
                  //                         title: `${el} Prompt`,
                  //                         handleResult: async (data) => {
                  //                           try {
                  //                             await addItemsDataByPrompt({
                  //                               collectionId: routineId,
                  //                               promptResult: data,
                  //                               itemType: _.get(mapData, el),
                  //                             });
                  //                           } catch (error) {
                  //                             console.log(
                  //                               'openPromptInputModalModal:',
                  //                               error.message
                  //                             );
                  //                           }

                  //                           // refetch data
                  //                           dispatch(
                  //                             RoutineDetailsActions.fetchRoutineDetailsAction(
                  //                               routineId as string
                  //                             )
                  //                           );
                  //                           closePromptsModal();
                  //                         },
                  //                       });
                  //                     }
                  //                   : undefined,
                  //               })
                  //             ),
                  //           });
                  //         },
                  //         id: 'ViewPrompt',
                  //         children: (
                  //           <>
                  //             <ListItemIcon>
                  //               <DescriptionOutlinedIcon />
                  //             </ListItemIcon>
                  //             <ListItemText primary="View Prompt" />
                  //           </>
                  //         ),
                  //       },
                  //     ]
                  //   : []),
                ]}
              >
                <TranslatedText defaultText={'EXPORT'} />
              </SelectButton>
            )}

            {params.mode !== 'view' && (
              <SubmitWrapper
                type="submit"
                variant="contained"
                color="secondary"
                startIcon={<EditOutlinedIcon />}
                className={classes.submit}
                onClick={() => {
                  onSubmit();
                }}
              >
                <TranslatedText
                  defaultText={
                    params.mode === 'add'
                      ? 'Add Course'
                      : params.mode === 'edit'
                      ? 'Edit Course'
                      : '-'
                  }
                />
              </SubmitWrapper>
            )}
          </Box>
        </AppCommon.FormContainer>
      </div>
    </>
  );
}

const QuizTypesWrapper = (props: Omit<IAdvancedSelectProps, ''>) => {
  const watchType = useWatch({
    control: props.control,
    name: 'type',
  }) as CollectionTypeEnum;
  return (
    <>
      {Object.values(CollectionTypeNeedsQuizType).includes(watchType) && (
        <Box display="flex">
          <div style={{ flex: 1, marginRight: '0.5rem' }}>
            <AppAdvancedselect {...props} />
          </div>
          <QuizTypesButton />
        </Box>
      )}
    </>
  );
};

const TagsSelectorWrapper = (props: Omit<IAdvancedSelectProps, 'options'>) => {
  const tagsList = RoutineDetailsSelectors.useTagsList();
  const options =
    tagsList.data?.items.map((el) => ({
      id: el.name,
      label: el.description,
    })) || [];
  const dispatch = useDispatch();

  return (
    <Box display="flex">
      <Button
        style={{ textTransform: 'none', marginTop: 15, marginBottom: 9 }}
        variant="outlined"
        onClick={() =>
          openTagDetailsModal({
            mode: 'add',
            onSuccess: () => {
              closeTagDetailsModal();
              dispatch(RoutineDetailsActions.fetchTagsListAction());
            },
            defaultValues: {
              // type: TagTypes.Collection,
              description: '',
              name: '',
              namespaces: [],
              status: null,
              level: null,
              iconId: null,
              imageId: null,
              order: null,
              subType: null,
              iconImageId: null,
            },
            tagType: TagTypes.Collection,
          })
        }
      >
        <AddOutlinedIcon />
      </Button>
      <div style={{ flex: 1, marginLeft: '0.5rem' }}>
        <AppAdvancedselect {...props} isMulti options={options} />
      </div>
    </Box>
  );
};

const QuizTypes = Object.values(QuizTypeEnum).map((el) => ({
  id: el,
  label: el,
}));

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const addTrickStatus = RoutineDetailsSelectors.useAddRoutineStatus();
  const editTrickStatus = RoutineDetailsSelectors.useEditRoutineStatus();

  return (
    <Button
      {...props}
      color="primary"
      disabled={
        addTrickStatus === StateStatus.Pending ||
        editTrickStatus === StateStatus.Pending
      }
    />
  );
};
