import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
export interface IState {
  registerUserStatus: StateStatus | null | undefined;
  registerUserEmail: string | null;
}

const initialState: IState = {
  registerUserStatus: null,
  registerUserEmail: null,
};

const RegisterReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.REGISTER_USER:
      return {
        ...state,
        registerUserStatus: action.status,
        registerUserEmail:
          KEEP_THE_SAME === action.data
            ? state.registerUserEmail
            : action.data?.email,
      };
    default:
      return {
        ...state,
      };
  }
};

export default RegisterReducer;
