import { Button } from '@material-ui/core';
import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormData, VectorIconsList, defaultValues } from '../../configuration';
import _ from 'lodash';
import { getQuizzesByChapterType } from '../../common';
import { CollectionTypeEnum } from 'constants/typesConst';

const QuizTypesButton = () => {
  const { control, setValue } = useFormContext<IFormData>();
  // const watchTags = useWatch({
  //   control,
  //   name: 'tags',
  // });
  const watchType = useWatch({
    control,
    name: 'type',
  }) as CollectionTypeEnum;

  return (
    <>
      {watchType && (
        <Button
          variant="outlined"
          style={{
            textTransform: 'none',
            marginTop: 15,
            marginBottom: 9,
          }}
          onClick={() => {
            setValue('quizTypes', getQuizzesByChapterType(watchType));
          }}
        >
          <HelpIcon />
        </Button>
      )}
    </>
  );
};

export default React.memo(QuizTypesButton);
