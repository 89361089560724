import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as PaymentsService from 'services/api/payments';

export interface IState {
  paymentsList: {
    status: StateStatus | null;
    data: PaymentsService.IFetchPaymentsData | null;
  };
}

const initialState: IState = {
  paymentsList: {
    status: null,
    data: null,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_PAYMENTS_LIST:
      return {
        ...state,
        paymentsList: {
          ...state.paymentsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.paymentsList.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
