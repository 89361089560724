import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useImagesList */
export const useImagesList = (): IState['imagesList'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.DISCOVER_IMAGES_REDUCER] as IState).imagesList
  );
};

/* selector: useImagesStatus */
export const useImagesStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.DISCOVER_IMAGES_REDUCER] as IState).uploadImageStatus
  );
};
