import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: 0,
    },
  },
  buttonsActions: {
    display: 'flex',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      '& > *': {
        marginBottom: theme.spacing(1), // 1rem = 2 * theme.spacing(1)
      },
      flex: 1,
    },
  },
}));

export default useStyles;
