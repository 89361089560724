import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USER_SETTINGS_DETAILS: string = typeCreatorV2(
  'NamespaceSettingsModal',
  'SetUserSettingsDetails'
);

export const UPDATE_USER_SETTINGS: string = typeCreatorV2(
  'NamespaceSettingsModal',
  'UpdateUserSettings'
);
