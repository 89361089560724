import React, { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import DataList from '../../../DataList';
import useStyles from './styles';
import {
  Button,
  Divider,
  List,
  ListItem,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { IProps, Order, IFilteringData } from '../../configuration';
import { tableSort, getComparator } from '../../utils';
import _ from 'lodash';
import TableTitleContainer from '../TableTitleContainer';
import FilteringModal from '../FilteringModal';
import LoadingView from 'components/atoms/LoadingView';

interface ITableProps<T> extends IProps<T> {
  orderBy: string;
  page: number;
  // order: Order;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  filteringData: {
    current: IFilteringData;
  };
  handleFiltering: (resetForm: boolean) => void;
  createSortHandler: (
    property: string,
    inData: boolean
  ) => (event: React.MouseEvent<unknown>) => void;
}

export default function MobileList<T>(props: ITableProps<T>): ReactElement {
  const classes = useStyles({});
  const {
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    filteringData,
    renderItemInSmallDevices,
    handleFiltering,
    columns,
    resetFilteringAction,
  } = props;

  return (
    <>
      <FilteringModal
        filteringData={filteringData}
        handleFiltering={handleFiltering}
        columns={columns}
        resetFilteringAction={resetFilteringAction}
      />
      {(props.title || props.filtering) && (
        <TableTitleContainer
          filteringData={filteringData}
          title={props.title}
          filteringPlaceholder={props.filtering?.globalSearchPlaceholder}
          handleFiltering={handleFiltering}
          globalSearchDefaultValue={props.filtering?.globalSearchDefaultValue}
        />
      )}

      {props.loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingView style={{ margin: 40 }} fullScreen={false} size={30} />
        </div>
      ) : (
        <DataList
          data={props.data}
          renderElement={(row, index) =>
            renderItemInSmallDevices ? (
              renderItemInSmallDevices(row, index)
            ) : (
              <div />
            )
          }
        />
      )}
      {props.paging && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 200]}
          component="div"
          count={props.paging?.total || props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
