import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
// import { openUpdateFileDataModal } from '../EditFileDataModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

interface IColumnNameProps {
  name: string | null;
}

const ColumnName = ({ name }: IColumnNameProps) => {
  return <React.Fragment>{name}</React.Fragment>;
};

export default ColumnName;
