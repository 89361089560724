import React from 'react';
import NestedMenu, { INestedMenuProps } from 'components/molecules/NestedMenu';
import {
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useNamespacesList } from 'redux/reducers/gl_common_reducer/selectors';
import CategoryIcon from '@material-ui/icons/Category';
import AirplayIcon from '@material-ui/icons/Airplay';
import { useDispatch } from 'react-redux';
import CollectionDetailsActions from '../../store/actions';
import {
  BulkAccessEnum,
  BulkStatusEnum,
  CollectionBulkStatusNames,
} from 'constants/collectionConst';
import { withVerification } from 'components/molecules/ModalVerifyAction';
import * as CollectionDetailsSelectors from '../../store/selectors';
import {
  CollectionItemHasTags,
  CollectionTypeEnum,
} from 'constants/typesConst';
import { useContextProps } from '../../provider';
import ResponsiveHorizontalListMenu from 'components/molecules/ResponsiveHorizontalListMenu';
import AdjustIcon from '@material-ui/icons/Adjust';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import AIPromptsButton from '../AIPromptsButton';

const BulkActionsMenu = () => {
  const namespacesList = useNamespacesList();
  const theme = useTheme();
  const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const params = useContextProps();
  const tags = CollectionDetailsSelectors.useItemTags();
  const details = CollectionDetailsSelectors.useRoutineDetails();

  return (
    <ResponsiveHorizontalListMenu maxWidth={isDownMedium ? '100vw' : '55vw'}>
      <NestedMenu
        items={Object.values(BulkStatusEnum).map((el) => ({
          component: CollectionBulkStatusNames[el],
          onClick: () => {
            withVerification({
              title: 'Change Status',
              description: (
                <span>
                  <span
                    style={{ fontSize: '1.15em' }}
                  >{`Are you sure you want to change the `}</span>
                  <span
                    style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                  >{`status `}</span>
                  <span style={{}}>{`to `}</span>
                  <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                    {CollectionBulkStatusNames[el]}
                  </span>
                  <span style={{}}>{`?`}</span>
                </span>
              ),
              action1Event: () => {
                dispatch(
                  CollectionDetailsActions.collectionBulkActionsAction({
                    onSuccess: () => {
                      dispatch(
                        CollectionDetailsActions.fetchRoutineDetailsAction(
                          params.collectionId as string
                        )
                      );
                    },
                    payload: {
                      status: el,
                      collectionId: params.collectionId as string,
                    },
                  })
                );
              },
            });
          },
        }))}
      >
        <Button
          style={{ borderRadius: 0 }}
          variant="contained"
          color="primary"
          startIcon={<AdjustIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          {'Status'}
        </Button>
      </NestedMenu>

      <NestedMenu
        items={
          namespacesList.data?.map((el) => ({
            component: el.name,
            onClick: () => {
              withVerification({
                title: 'Change Workspace',
                description: (
                  <span>
                    <span
                      style={{ fontSize: '1.15em' }}
                    >{`Are you sure you want to change the `}</span>
                    <span
                      style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                    >{`workspace `}</span>
                    <span style={{}}>{`to `}</span>
                    <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                      {el.name}
                    </span>
                    <span style={{}}>{`?`}</span>
                  </span>
                ),
                action1Event: () => {
                  dispatch(
                    CollectionDetailsActions.collectionBulkActionsAction({
                      onSuccess: () => {
                        dispatch(
                          CollectionDetailsActions.fetchRoutineDetailsAction(
                            params.collectionId as string
                          )
                        );
                      },
                      payload: {
                        namespaces: [el._id],
                        collectionId: params.collectionId as string,
                      },
                    })
                  );
                },
              });
            },
          })) ?? []
        }
      >
        <Button
          style={{ borderRadius: 0 }}
          variant="contained"
          color="primary"
          startIcon={<AmpStoriesIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          {'Namespace'}
        </Button>
      </NestedMenu>

      <NestedMenu
        items={Object.values(BulkAccessEnum).map((el) => ({
          component: el,
          onClick: () => {
            withVerification({
              title: 'Change Visibility',
              description: (
                <span>
                  <span
                    style={{ fontSize: '1.15em' }}
                  >{`Are you sure you want to change the `}</span>
                  <span
                    style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                  >{`visibility `}</span>
                  <span style={{}}>{`to `}</span>
                  <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                    {el}
                  </span>
                  <span style={{}}>{`?`}</span>
                </span>
              ),
              action1Event: () => {
                dispatch(
                  CollectionDetailsActions.collectionBulkActionsAction({
                    onSuccess: () => {
                      dispatch(
                        CollectionDetailsActions.fetchRoutineDetailsAction(
                          params.collectionId as string
                        )
                      );
                    },
                    payload: {
                      access: el,
                      collectionId: params.collectionId as string,
                    },
                  })
                );
              },
            });
          },
        }))}
      >
        <Button
          style={{ borderRadius: 0 }}
          variant="contained"
          color="primary"
          startIcon={<AirplayIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          {'Visibility'}
        </Button>
      </NestedMenu>

      {CollectionItemHasTags.includes(
        details.data?.type as CollectionTypeEnum
      ) && (
        <NestedMenu
          items={[
            {
              component: (
                <Typography style={{ fontWeight: 'bold' }}>
                  {'Change Item Tags'}
                </Typography>
              ),
              items: [],
            },
            ...(tags.data?.items ?? []).map((el) => ({
              component: el.name,
              onClick: () => {
                withVerification({
                  title: 'Change Item Tags',
                  description: (
                    <span>
                      <span
                        style={{ fontSize: '1.15em' }}
                      >{`Are you sure you want to change the `}</span>
                      <span
                        style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                      >{`tag `}</span>
                      <span style={{}}>{`to `}</span>
                      <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                        {el.description}
                      </span>
                      <span style={{}}>{`?`}</span>
                    </span>
                  ),
                  action1Event: () => {
                    dispatch(
                      CollectionDetailsActions.collectionBulkActionsAction({
                        onSuccess: () => {
                          dispatch(
                            CollectionDetailsActions.fetchRoutineDetailsAction(
                              params.collectionId as string
                            )
                          );
                        },
                        payload: {
                          itemTag: el.name,
                          collectionId: params.collectionId as string,
                        },
                      })
                    );
                  },
                });
              },
            })),
          ]}
        >
          <Button
            style={{ borderRadius: 0 }}
            variant="contained"
            color="primary"
            endIcon={<ArrowDropDownIcon />}
            startIcon={<CategoryIcon />}
          >
            {'Categories'}
          </Button>
        </NestedMenu>
      )}

      <AIPromptsButton />
    </ResponsiveHorizontalListMenu>
  );
};

export default BulkActionsMenu;
