//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const VERIFY_ACCOUNT: string = typeCreatorV2(
  'VerifyAccountReducer',
  'VerifyAccount'
);

export const RESEND_EMAIL: string = typeCreatorV2(
  'VerifyAccountReducer',
  'ResendEmail'
);
