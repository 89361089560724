import _ from 'lodash';

export function objectWithoutProperties(obj: any, keys: Array<string>) {
  let target: any = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}
export function removeEmptyObject(obj: { [key: string]: any }) {
  return Object.values(obj).length > 0 ? obj : undefined;
}

export function cleanObject(
  obj: { [key: string]: any },
  clearEmptyObject = false
) {
  const result = _.pickBy(obj, (v) => v !== undefined);
  return clearEmptyObject ? removeEmptyObject(result as any) : result;
}

type JSONObject = { [key: string]: JSONValue };
type JSONArray = JSONValue[];
type JSONValue = string | number | boolean | null | JSONObject | JSONArray;

export function removeEmptyFields<T extends JSONValue>(obj: T): T {
  if (obj === null || obj === undefined) {
    return undefined as any;
  }
  if (typeof obj === 'string' && obj === '') {
    return undefined as any;
  }
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      const result: JSONArray = [];
      for (const value of obj) {
        const newValue = removeEmptyFields(value) as JSONValue;
        if (newValue !== undefined) {
          result.push(newValue);
        }
      }
      return result.length === 0 ? (undefined as any) : (result as any);
    } else {
      const result: JSONObject = {};
      for (const key in obj) {
        const value = removeEmptyFields(_.get(obj, key) as any) as JSONValue;
        if (value !== undefined) {
          result[key] = value as JSONValue;
        }
      }
      return Object.keys(result).length === 0
        ? (undefined as any)
        : (result as any);
    }
  }
  return obj;
}
