import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
// import { FormType } from '../configuration';

export interface IState {
  verifySessionStatus: StateStatus | null;
  resendEmailStatus: StateStatus | null;
}

const initialState: IState = {
  verifySessionStatus: null,
  resendEmailStatus: null,
};

const ConfirmSessionReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // VERIFY_SESSION
    case Types.VERIFY_SESSION:
      return {
        ...state,
        verifySessionStatus: action.status || null,
      };
    // RESEND_EMAIL
    case Types.RESEND_EMAIL:
      return {
        ...state,
        resendEmailStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ConfirmSessionReducer;
