import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UtilsService from 'services/api/utils';

export interface IState {
  imagesList: {
    data: UtilsService.IFetchImagesFromExternalProvidersData | null;
    status: StateStatus | null;
  };
  uploadImageStatus: StateStatus | null;
}

const initialState: IState = {
  imagesList: {
    data: null,
    status: null,
  },
  uploadImageStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_IMAGES_LIST:
      return {
        ...state,
        imagesList: {
          ...state.imagesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.imagesList.data : action.data,
        },
      };
    case Types.UPLOAD_IMAGE_FROM_EXTERNAL:
      return {
        ...state,
        uploadImageStatus: action.status || null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
