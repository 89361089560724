import { ItemStatusEnum } from 'constants/statusConst';
import { ItemTypeEnum } from 'constants/typesConst';
import { IFormData } from './types';

export enum SendNotificationType {
  ToSelectedUsers = '1',
  ToTopics = '2',
}

interface IDefaultPaging {
  page: number;
  pageSize: number;
  sortField: string;
  orderType: 'asc' | 'desc';
}

export const DefaultPaging: IDefaultPaging = {
  page: 0,
  pageSize: 25,
  sortField: 'createdAt',
  orderType: 'desc',
};

export interface IItemDetailsProps {
  trickId: string | null;
  mode: 'edit' | 'view' | 'add';
  access: 'modal' | 'page';
  onAddItem?: () => void;
  type: ItemTypeEnum | null;
  onDeleteItem?: () => void;
}

export const AnswerSplitItem = '∎';

export const BlankSplitItem = '_____';

export const PauseSplitItem = '[...]';

export const ItemTypeTitleMap: {
  [key in ItemTypeEnum]: string;
} = {
  [ItemTypeEnum.Rearrangement]: 'Rearrangement',
  [ItemTypeEnum.RearrangementSpelling]: 'Rearrangement-Spelling',
  [ItemTypeEnum.Word]: 'Word',
  [ItemTypeEnum.Phrase]: 'Phrase',
  [ItemTypeEnum.Sentence]: 'Sentence',
  [ItemTypeEnum.Text]: 'Text Fragment',
  [ItemTypeEnum.MultipleChoice]: 'Multiple-Choice',
  [ItemTypeEnum.QuestionAnswer]: 'Question-Answer',
  [ItemTypeEnum.RearrangementAnswer]: 'Rearrangement-Answer',
  [ItemTypeEnum.TrueOrFalse]: 'True-False',
  [ItemTypeEnum.FillTheGapMultiple]: 'Fill-The-Gab-Mulitple',
  [ItemTypeEnum.FillTheGapMultipleSpelling]: 'Fill-The-Gab Mulitple Spelling',
  [ItemTypeEnum.GenericItem]: 'Generic-Item',
  [ItemTypeEnum.Trick]: 'Trick',
  [ItemTypeEnum.BookSection]: 'Book-Senction',
  [ItemTypeEnum.Quote]: 'Quote',
  [ItemTypeEnum.Saying]: 'Saying',
  [ItemTypeEnum.Idiom]: 'Idiom',
};

export const getDefaultValues = (
  mode: IItemDetailsProps['mode'],
  type: ItemTypeEnum | null
): IFormData => ({
  title: null,
  description: null,
  type,
  link: null,
  tags: null,
  public: 'true',
  difficulty: 3,
  examples: mode === 'add' ? [] : [],
  additionalInfo: null,
  namespaces: null,
  question: null,
  status: ItemStatusEnum.Active,
  imageId: null,
  etymologyEvolution: [],
  bookSection_Content: null,
  bookSection_ContentTranslation: null,
  hiddenInfo: null,
});

export const MAX_WORDS_PER_PAGE = 400;
