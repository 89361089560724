import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useFieldsMenuDispatcher, useMenuIsVisible } from '../../provider';
import { IProps as IDatatableProps } from '../../configuration';
import TableFilteringField from '../TableFilteringField';
import { IFilteringData } from '../../configuration';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { TransitionProps } from '@material-ui/core/transitions';
import { Slide } from '@material-ui/core';

interface IFilteringModalProps<T> {
  columns: IDatatableProps<T>['columns'];
  filteringData: {
    current: IFilteringData;
  };
  handleFiltering?: (resetForm: boolean) => void;
  resetFilteringAction: IDatatableProps<T>['resetFilteringAction'];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilteringModal<T>({
  columns,
  filteringData,
  handleFiltering,
  resetFilteringAction,
}: IFilteringModalProps<T>) {
  const menuIsVisible = useMenuIsVisible();
  const setMenuVisible = useFieldsMenuDispatcher();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      onClose={() => setMenuVisible(false)}
      aria-labelledby="simple-dialog-title"
      open={menuIsVisible}
      fullScreen={matches}
      fullWidth
      style={{ marginTop: matches ? '20vh' : undefined }}
      TransitionComponent={matches ? Transition : undefined}
    >
      <DialogTitle id="simple-dialog-title">{'Filtering'}</DialogTitle>
      <DialogContent dividers>
        <Box display={'flex'} justifyContent="center" flexDirection="column">
          {columns
            .filter((headCell) => headCell.filtering)
            .map((headCell, index) => {
              return (
                <Box
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  key={index}
                >
                  <Typography style={{ fontSize: 17, fontWeight: 500 }}>
                    {`${headCell.label}:`}
                  </Typography>
                  <TableFilteringField
                    name={headCell.id}
                    type={headCell?.filtering?.type}
                    options={headCell?.filtering?.options}
                    label={headCell?.label}
                    style={{ width: '100%', flex: 1 }}
                    filteringData={filteringData}
                    handleFiltering={handleFiltering}
                    defaultValue={headCell.filtering?.defaultValue}
                  />
                </Box>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        {resetFilteringAction && (
          <Button
            autoFocus
            onClick={() => {
              resetFilteringAction();
              setMenuVisible(false);
            }}
            color="secondary"
          >
            {'Reset'}
          </Button>
        )}
        <Button autoFocus onClick={() => setMenuVisible(false)} color="primary">
          {'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
