import BaseModal from 'components/molecules/BaseModal';
import React from 'react';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import PrettyPrintJson from 'components/atoms/PrettyPrintJson';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import ButtonCopy from 'components/atoms/ButtonCopy';
import { Button } from '@material-ui/core';
import { openUrlToNewTab } from 'utils/window';

interface ITranscriptTextModalParams {
  data: string;
}

const TranscriptTextModal = () => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_TRANSCRIPT_TEXT}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: ITranscriptTextModalParams) => (
        <div
          style={{
            minHeight: '50vh',
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
          }}
        >
          {params.data}
        </div>
      )}
      renderHeader={() => <>{'Transcript text'}</>}
      renderFooter={(params: ITranscriptTextModalParams) => {
        return (
          <>
            <div>
              <Button
                onClick={() => {
                  openUrlToNewTab('https://chat.openai.com/chat');
                }}
              >
                {'Open ChatGPT'}
              </Button>
            </div>
            <div>
              <ButtonCopy text={params.data} />
            </div>
          </>
        );
      }}
    />
  );
};

export const openTranscriptTextModal = (params: ITranscriptTextModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_TRANSCRIPT_TEXT,
      params,
    })
  );
};

export const closeTranscriptTextModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_TRANSCRIPT_TEXT,
    })
  );
};

export default React.memo(TranscriptTextModal);
