import React, { ReactElement } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { nativeAxios } from 'utils/request';

interface Iprops {
  rules: any;
  variant?: 'outlined' | 'contained';
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium';
  name: string;
  label: string | React.ReactElement;
  register: ReturnType<typeof useForm>['register'];
  setValue: ReturnType<typeof useForm>['setValue'];
  watch: ReturnType<typeof useForm>['watch'];
  error?: boolean;
  helperText?: React.ReactElement | string;
  fullWidth?: boolean;
  fileInitialValues?: IFileInitialValues;
  multiple?: boolean;
  disabled?: boolean;
  direction?: 'top' | 'bottom';
  accept?: 'image/*' | '.csv' | string;
}

export type IFileInitialValues = Array<{
  url: string;
  fileName: string;
}>;

export type IUploadedFile = Array<{
  src: string | null;
  isImage: boolean;
  fileName: string;
  url: string;
  // initialValue?: boolean;
}>;

const ERROR_COLOR = '#f44336';

export default function UploadPicker(props: Iprops) {
  const {
    rules,
    setValue,
    fullWidth,
    variant,
    label,
    name,
    helperText,
    error,
    fileInitialValues,
    size,
    multiple,
    margin,
    register,
    disabled = false,
    watch,
    direction = 'bottom',
    accept,
    ...other
  } = props;
  const hiddenFileInput = React.useRef<any>(null);
  const classes = useStyles({ margin: margin === 'normal' });
  const uploadedFiles: IUploadedFile = watch(name) || [];

  let _rules = rules;
  if (fileInitialValues && _rules) {
    _rules = {
      ...rules,
      required: false,
    };
  }

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const files: any = event?.target?.files || [];
    // console.log('Files::', files);

    const filesArr = multiple ? [...uploadedFiles] : [];
    for (let fileInput of files) {
      filesArr.push({
        src: fileInput, //URL.createObjectURL(),
        isImage: Boolean(fileInput.name.match(/.(jpg|jpeg|png|gif)$/i)),
        fileName: fileInput.name,
        url: URL.createObjectURL(fileInput),
      });
    }
    // setUploadedFiles(filesArr);

    console.log('Set values:', filesArr);
    setValue(name, filesArr);
  };

  const UploadButton = (
    <div
      style={{
        marginTop: direction === 'top' ? 0 : '0.5rem',
        marginBottom: direction === 'bottom' ? 0 : '0.5rem',
      }}
    >
      <Button
        style={{
          padding: size === 'medium' ? '0.8rem' : '0.4rem',
          textTransform: 'capitalize',
          margin: 'auto',
          color: error ? ERROR_COLOR : undefined,
          borderColor: error ? ERROR_COLOR : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disabled={disabled}
        variant={variant || 'outlined'}
        size="large"
        fullWidth={fullWidth ?? true}
        onClick={handleClick}
      >
        <BackupOutlinedIcon style={{ marginRight: '1rem' }} />
        <Typography component="h5">{label}</Typography>
        <input
          className="upload"
          type="file"
          accept={accept}
          name={name}
          multiple={multiple}
          ref={(data) => {
            hiddenFileInput.current = data;
            register(name, _rules);
          }}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </Button>
      {helperText && (
        <Typography
          style={{
            color: error ? ERROR_COLOR : undefined,
            fontSize: 12,
            marginLeft: '1rem',
            paddingTop: 1,
          }}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );

  return (
    <div className={classes.container}>
      {direction === 'top' && UploadButton}
      {uploadedFiles.length > 0 && multiple && (
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {uploadedFiles.map((uploadedFile, index) => (
              <UploadedFile
                key={index}
                margin={margin}
                disabled={disabled}
                uploadedFile={uploadedFile}
                uploadedFiles={uploadedFiles}
                setValue={setValue}
                name={name}
                hiddenFileInput={hiddenFileInput}
              />
            ))}
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {uploadedFiles.length > 0 && !multiple && (
          <UploadedFile
            margin={margin}
            disabled={disabled}
            uploadedFile={uploadedFiles[0]}
            uploadedFiles={uploadedFiles}
            setValue={setValue}
            name={name}
            hiddenFileInput={hiddenFileInput}
          />
        )}
      </div>
      {direction === 'bottom' && UploadButton}
    </div>
  );
}

interface IUploadedFileProps {
  margin?: string;
  disabled: boolean;
  uploadedFile: IUploadedFile[0];
  uploadedFiles: IUploadedFile;
  setValue: ReturnType<typeof useForm>['setValue'];
  name: string;
  hiddenFileInput: {
    current: any;
  };
}

const UploadedFile = ({
  margin,
  disabled,
  uploadedFile,
  uploadedFiles,
  setValue,
  name,
  hiddenFileInput,
}: IUploadedFileProps) => {
  const classes = useStyles({ margin: margin === 'normal' });

  const removeFile = React.useCallback(
    (_fileName) => {
      const files = uploadedFiles.filter(
        ({ fileName }) => _fileName !== fileName
      );
      console.log('File data::', hiddenFileInput.current.value);

      // setUploadedFiles([...files]);
      const _files = [...files];
      console.log('Set values:', files);

      setValue(name, _files);
    },
    [uploadedFiles, setValue]
  );

  const downloadFile = React.useCallback(
    async (uploadedFile: IUploadedFile[0]) => {
      try {
        let url: string | null = uploadedFile.src
          ? window.URL.createObjectURL(
              new Blob([uploadedFile.src as any], {
                type: 'application/octet-stream',
              })
            )
          : null;

        if (!uploadedFile.src) {
          const response = await nativeAxios({
            method: 'get',
            url: uploadedFile.url,
            responseType: 'blob',
          });

          url = window.URL.createObjectURL(new Blob([response.data]));
        }

        var a = document.createElement('a');
        a.href = url || '';
        a.download = uploadedFile.fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      } catch (error) {}
    },
    []
  );

  return (
    <Paper
      variant="outlined"
      // key={index}
      className={classes.imageContainer}
    >
      <div className={classes.overlayContainer}>
        {!disabled && (
          <IconButton
            onClick={() => removeFile(uploadedFile.fileName)}
            className={clsx(classes.uploadFileButton)}
          >
            <DeleteOutlineOutlinedIcon
              className={classes.imageContainerButton}
              style={{ color: 'white' }}
            />
          </IconButton>
        )}
        <IconButton
          onClick={async () => downloadFile(uploadedFile)}
          className={clsx(classes.uploadFileButton)}
        >
          <GetAppOutlinedIcon
            className={classes.imageContainerButton}
            style={{ color: 'white' }}
          />
        </IconButton>
      </div>
      <div className={classes.image}>
        <img
          src={
            uploadedFile.isImage
              ? uploadedFile.url
              : require('assets/images/icons/file.png')
          }
          className={classes.imageEl}
        />
      </div>
      {uploadedFile.src && (
        <Typography component="h5">{uploadedFile.fileName}</Typography>
      )}
    </Paper>
  );
};
