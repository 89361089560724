import React from 'react';

interface IVectorProps {
  style?: React.CSSProperties;
  fontSize?: number;
  code?: number;
  fontFamily?: string;
  name?: string;
}

const Vector = ({
  code,
  fontSize = 30,
  style,
  fontFamily = 'MaterialIcons',
  name,
}: IVectorProps) => {
  return (
    <span
      style={{
        fontFamily,
        fontSize,
        fontWeight: 'normal',
        ...style,
      }}
    >
      {code ? String.fromCodePoint(code) : name}
    </span>
  );
};

export default React.memo(Vector);
