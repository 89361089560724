import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: ({ margin }: any) => ({
    marginTop: margin ? '0.95rem' : '0.1rem',
    marginBottom: margin ? '0.95rem' : '0.1rem',
    borderWidth: '1px',
    borderRadius: 4,
    borderColor: '#c4c4c4',
    color: 'rgb(48, 48, 48)',
    borderStyle: 'solid',
    padding: '2px 2px 2px 8px',
  }),
}));

export default useStyles;
