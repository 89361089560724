import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_ACTIVE_USERS_COUNT = typeCreatorV2(
  'DashboardReducer',
  'SetActiveUsersCount'
);

export const SET_TOTAL_USERS_COUNT = typeCreatorV2(
  'DashboardReducer',
  'SetTotalUsersCount'
);

export const SET_TOTAL_ITEMS_COUNT = typeCreatorV2(
  'DashboardReducer',
  'SetTotalItemsCount'
);

export const SET_TOTAL_COLLECTIONS_COUNT = typeCreatorV2(
  'DashboardReducer',
  'SetTotalCollectionsCount'
);

export const SET_TOTAL_USERS_PER_NAMESPACE = typeCreatorV2(
  'DashboardReducer',
  'SetTotalUsersPerNamespace'
);

export const SET_ITEMS_PER = typeCreatorV2('DashboardReducer', 'SetItemsPer');

export const SET_COLLECTIONS_PER = typeCreatorV2(
  'DashboardReducer',
  'SetCollectionsPer'
);

export const SET_REGISTRATIONS_PER = typeCreatorV2(
  'DashboardReducer',
  'SetRegistrations'
);

export const SET_TOTAL_ELEMENTS_BY_LEVEL = typeCreatorV2(
  'DashboardReducer',
  'SetTotalElementsByLevel'
);

export const SET_TOTAL_USERS_PER_LEVEL = typeCreatorV2(
  'DashboardReducer',
  'SetTotalUsersPerLevel'
);
