import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';

export interface IState {
  verifyVerificationCodeStatus: StateStatus | null;
  // email: string | null;
  // resendEmailStatus: StateStatus | null;
}

const initialState: IState = {
  verifyVerificationCodeStatus: null,
  // email: null,
  // resendEmailStatus: null,
};

const ForgotPasswordReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // VERIFICATION_CODE
    case Types.VERIFY_VERIFICATION_CODE:
      return {
        ...state,
        verifyVerificationCodeStatus: action.status || null,
      };
    // // RESEND_EMAIL
    // case Types.RESEND_EMAIL:
    //   return {
    //     ...state,
    //     resendEmailStatus: action.status || null,
    //   };
    default:
      return {
        ...state,
      };
  }
};

export default ForgotPasswordReducer;
