import React from 'react';
import _ from 'lodash';

const LandingFragment = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '4rem',
      }}
    >
      <img
        src={require('assets/images/logos/app-logo.png')}
        style={{ width: 80, height: 80 }}
      />
    </div>
  );
};

export default React.memo(LandingFragment);
