import React from 'react';
import {
  Button,
  Divider,
  LinearProgress,
  IconButton,
  Tooltip,
  Typography,
  InputAdornment,
  Box,
  TextField,
  Hidden,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import useStyles from './styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import { useMenuIsVisible, useFieldsMenuDispatcher } from '../../provider';
import { IFilteringData } from '../../configuration';

interface ITableTitleContainerProps {
  title?: React.ReactNode;
  filteringPlaceholder?: string;
  globalSearchDefaultValue?: string;
  handleFiltering?: (resetForm: boolean) => void;
  filteringData: {
    current: IFilteringData;
  };
}

const TableTitleContainer = (props: ITableTitleContainerProps) => {
  const classes = useStyles({});
  const setMenuVisible = useFieldsMenuDispatcher();
  const menuIsVisible = useMenuIsVisible();

  const searchWrapper = (
    <TextFieldWrapper
      handleFiltering={props.handleFiltering}
      filteringData={props.filteringData}
      filteringPlaceholder={props.filteringPlaceholder}
      globalSearchDefaultValue={props.globalSearchDefaultValue}
    />
  );

  return (
    <Box>
      <Box className={classes.titleContainer}>
        <div style={{ maxWidth: '85%' }}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props.title}
          </Typography>
        </div>
        {/* menuIsVisible &&  */}
        {props.filteringPlaceholder && (
          <Hidden smDown>
            <div
              style={{ flex: 1, padding: 10, display: 'flex', maxWidth: '50%' }}
            >
              {searchWrapper}
            </div>
          </Hidden>
        )}
        <div>
          <Tooltip title="Open filtering menu">
            <IconButton
              onClick={() => {
                const visibility = !menuIsVisible;
                if (!visibility) {
                  props.filteringData.current = {};
                  props.filteringData.current.__globalValue = null;
                }
                setMenuVisible(visibility);
              }}
              aria-label="filter list"
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
      {props.filteringPlaceholder && (
        <Hidden mdUp>
          <div
            style={{
              flex: 1,
              paddingBottom: 10,
              paddingLeft: '1rem',
              paddingRight: '1rem',
              display: 'flex',
            }}
          >
            {searchWrapper}
          </div>
        </Hidden>
      )}
    </Box>
  );
};

interface ITextFieldWrapperProps {
  filteringData: ITableTitleContainerProps['filteringData'];
  handleFiltering?: ITableTitleContainerProps['handleFiltering'];
  filteringPlaceholder: ITableTitleContainerProps['filteringPlaceholder'];
  globalSearchDefaultValue: ITableTitleContainerProps['globalSearchDefaultValue'];
}

const TextFieldWrapper = (props: ITextFieldWrapperProps) => {
  const [value, setValue] = React.useState<string | undefined>(
    props.globalSearchDefaultValue
  );
  const [debouncedValue, setDebouncedValue] = React.useState<
    string | undefined
  >(value);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedValue), 500);
    return () => clearTimeout(timer);
  }, [debouncedValue]);

  React.useEffect(() => {
    props.filteringData.current.__globalValue = value;
    props.handleFiltering && props.handleFiltering(false);
  }, [value]);

  return (
    <TextField
      id="search"
      placeholder={props.filteringPlaceholder}
      fullWidth
      variant={matches ? 'outlined' : 'standard'}
      value={debouncedValue || ''}
      onChange={(event) => setDebouncedValue(event.target.value)}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>
                props.handleFiltering && props.handleFiltering(false)
              }
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default React.memo(TableTitleContainer);
