import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@material-ui/core';
import React from 'react';

interface IUploadFileButtonProps {
  accept: 'image/*' | '.csv' | string;
  handleChange: (files: string[]) => void;
  multiple?: boolean;
  type: 'button' | 'iconButton';
  componentProps?: Partial<ButtonProps | IconButtonProps>;
  children?: React.ReactNode;
}

function UploadFileButton({
  accept,
  componentProps,
  handleChange: _handleChange,
  multiple,
  type,
  children,
}: IUploadFileButtonProps) {
  const handleChange = (event: any) => {
    const files = event?.target?.files;
    _handleChange(files);
    // console.log('Set values:', file);
  };
  const props: Partial<ButtonProps | IconButtonProps> = {
    ...componentProps,
    ...({ component: 'label' } as any),
  };

  const Component = type === 'button' ? Button : (IconButton as React.FC<any>);

  return (
    <>
      <Component {...props}>
        <>
          {children}
          <input
            multiple={multiple}
            hidden
            accept={accept}
            type="file"
            onChange={handleChange}
          />
        </>
      </Component>
    </>
  );
}

export default UploadFileButton;
