import { LanguagesEnum } from 'constants/languagesConst';
export interface IErrorCode {
  code: string;
  message: {
    [LanguagesEnum.English]: string;
    [LanguagesEnum.Greek]: string;
  };
}

export interface IErrorCodes {
  INVALID_EMAIL_FORMAT: IErrorCode;
  INVALID_PASSWORD_FORMAT: IErrorCode;
  USER_EMAIL_NOT_VERIFIED: IErrorCode;
  EMAIL_ALREADY_EXISTS: IErrorCode;
  USERNAME_ALREADY_EXISTS: IErrorCode;
  PHONE_ALREADY_EXISTS: IErrorCode;
  INVALID_CREDENTIALS: IErrorCode;
  ACCOUNT_BLOCKED: IErrorCode;
  ACCOUNT_NOT_VERIFIED: IErrorCode;
  ACCOUNT_ALREADY_VERIFIED: IErrorCode;
  ACCESS_TOKEN_NOT_VALID: IErrorCode;
  REFRESH_TOKEN_NOT_VALID: IErrorCode;
  ACCESS_DENIED: IErrorCode;
  USER_NOT_FOUND: IErrorCode;
  INVALID_VERIFICATION_CODE: IErrorCode;
  VERIFICATION_CODE_EXPIRED: IErrorCode;
  CONFIRM_PASSWORD_MISMATCH: IErrorCode;
  OLD_PASSWORD_NOT_MATCH: IErrorCode;
  INVALID_SESSION: IErrorCode;
  SESSION_NOT_VERIFIED: IErrorCode;
  FORCE_CHANGE_PASSWORD: IErrorCode;
  PASSWORD_HAS_ALREADY_CHANGE: IErrorCode;
}

const ErrorCodes: IErrorCodes = {
  INVALID_EMAIL_FORMAT: {
    code: 'INVALID_EMAIL_FORMAT',
    message: {
      [LanguagesEnum.English]: 'Invalid email format',
      [LanguagesEnum.Greek]: 'Invalid email format',
    },
  },
  INVALID_PASSWORD_FORMAT: {
    code: 'INVALID_PASSWORD_FORMAT',
    message: {
      [LanguagesEnum.English]: 'Invalid password format',
      [LanguagesEnum.Greek]: 'Invalid password format',
    },
  },
  USER_EMAIL_NOT_VERIFIED: {
    code: 'USER_EMAIL_NOT_VERIFIED',
    message: {
      [LanguagesEnum.English]: 'User email not verified',
      [LanguagesEnum.Greek]: 'User email not verified',
    },
  },
  EMAIL_ALREADY_EXISTS: {
    code: 'EMAIL_ALREADY_EXISTS',
    message: {
      [LanguagesEnum.English]: 'Email already exists',
      [LanguagesEnum.Greek]: 'Email already exists',
    },
  },
  USERNAME_ALREADY_EXISTS: {
    code: 'USERNAME_ALREADY_EXISTS',
    message: {
      [LanguagesEnum.English]: 'Username already exists',
      [LanguagesEnum.Greek]: 'Username already exists',
    },
  },
  PHONE_ALREADY_EXISTS: {
    code: 'PHONE_ALREADY_EXISTS',
    message: {
      [LanguagesEnum.English]: 'Phone already exists',
      [LanguagesEnum.Greek]: 'Phone already exists',
    },
  },
  INVALID_CREDENTIALS: {
    code: 'INVALID_CREDENTIALS',
    message: {
      [LanguagesEnum.English]: 'Invalid credentials provided',
      [LanguagesEnum.Greek]: 'Invalid credentials provided',
    },
  },
  ACCOUNT_BLOCKED: {
    code: 'ACCOUNT_BLOCKED',
    message: {
      [LanguagesEnum.English]: 'Account blocked',
      [LanguagesEnum.Greek]: 'Account blocked',
    },
  },
  ACCOUNT_NOT_VERIFIED: {
    code: 'ACCOUNT_NOT_VERIFIED',
    message: {
      [LanguagesEnum.English]: 'Account not verified',
      [LanguagesEnum.Greek]: 'Account not verified',
    },
  },
  ACCESS_TOKEN_NOT_VALID: {
    code: 'ACCESS_TOKEN_NOT_VALID',
    message: {
      [LanguagesEnum.English]: 'Access token not valid',
      [LanguagesEnum.Greek]: 'Access token not valid',
    },
  },
  REFRESH_TOKEN_NOT_VALID: {
    code: 'REFRESH_TOKEN_NOT_VALID',
    message: {
      [LanguagesEnum.English]: 'Refresh token not valid',
      [LanguagesEnum.Greek]: 'Refresh token not valid',
    },
  },
  ACCESS_DENIED: {
    code: 'ACCESS_DENIED',
    message: {
      [LanguagesEnum.English]: 'User do not have access to this client',
      [LanguagesEnum.Greek]: 'User do not have access to this client',
    },
  },
  USER_NOT_FOUND: {
    code: 'USER_NOT_FOUND',
    message: {
      [LanguagesEnum.English]: 'Unable to find user',
      [LanguagesEnum.Greek]: 'Unable to find user',
    },
  },
  ACCOUNT_ALREADY_VERIFIED: {
    code: 'ACCOUNT_ALREADY_VERIFIED',
    message: {
      [LanguagesEnum.English]: 'Account is already verified',
      [LanguagesEnum.Greek]: 'Account is already verified',
    },
  },
  INVALID_VERIFICATION_CODE: {
    code: 'INVALID_VERIFICATION_CODE',
    message: {
      [LanguagesEnum.English]: 'Invalid verification code',
      [LanguagesEnum.Greek]: 'Invalid verification code',
    },
  },
  VERIFICATION_CODE_EXPIRED: {
    code: 'VERIFICATION_CODE_EXPIRED',
    message: {
      [LanguagesEnum.English]: 'Verification Code Expired',
      [LanguagesEnum.Greek]: 'Verification Code Expired',
    },
  },
  CONFIRM_PASSWORD_MISMATCH: {
    code: 'CONFIRM_PASSWORD_MISMATCH',
    message: {
      [LanguagesEnum.English]: 'Password and confirm password should much',
      [LanguagesEnum.Greek]: 'Password and confirm password should much',
    },
  },
  OLD_PASSWORD_NOT_MATCH: {
    code: 'OLD_PASSWORD_NOT_MATCH',
    message: {
      [LanguagesEnum.English]:
        "Old password that you provided does not match with user's old password",
      [LanguagesEnum.Greek]:
        "Old password that you provided does not match with user's old password",
    },
  },
  INVALID_SESSION: {
    code: 'INVALID_SESSION',
    message: {
      [LanguagesEnum.English]: 'Invalid session provided',
      [LanguagesEnum.Greek]: 'Invalid session provided',
    },
  },
  SESSION_NOT_VERIFIED: {
    code: 'SESSION_NOT_VERIFIED',
    message: {
      [LanguagesEnum.English]: 'Session not verified',
      [LanguagesEnum.Greek]: 'Session not verified',
    },
  },
  FORCE_CHANGE_PASSWORD: {
    code: 'FORCE_CHANGE_PASSWORD',
    message: {
      [LanguagesEnum.English]: 'Current password must change',
      [LanguagesEnum.Greek]: 'Current password must change',
    },
  },
  PASSWORD_HAS_ALREADY_CHANGE: {
    code: 'PASSWORD_HAS_ALREADY_CHANGE',
    message: {
      [LanguagesEnum.English]: 'Password has already change',
      [LanguagesEnum.Greek]: 'Password has already change',
    },
  },
};

export default ErrorCodes;
