import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ItemsService from 'services/api/items';
import * as CollectionService from 'services/api/collections';
import * as TagsService from 'services/api/tags';
import type { IConvertTextToAudioData } from 'services/api/utils';

export interface IState {
  collectionBulkActionsStatus: StateStatus | null;
  tagsList: {
    status: StateStatus | null;
    data: TagsService.FetchTagListData | null;
  };
  tagsItemList: {
    status: StateStatus | null;
    data: TagsService.FetchTagListData | null;
  };
  addRoutineStatus: StateStatus | null;
  routineDetails: {
    status: StateStatus | null;
    data: CollectionService.FetchRoutineDetailsData | null;
  };
  editRoutineStatus: StateStatus | null;
  tricksList: {
    status: StateStatus | null;
    data: ItemsService.FetchTrickListData | null;
  };
  selectedItemsMap: {
    [key: string]: {
      id: string;
      title: string;
      tags?: string[];
    };
  } | null;
  selectedTags: string[] | null;
  removeRoutineStatus: StateStatus | null;
  uploadCsvFile: {
    status: StateStatus | null;
    data: any | null;
  };
  initBookItemsStatus: StateStatus | null;
  // collectionPrompts: {
  //   status: StateStatus | null;
  //   data: OpenAIService.ICollectionPromptData | null;
  // };
  generateAudioFile: {
    status: StateStatus | null;
    data: IConvertTextToAudioData | null;
  };
}

const initialState: IState = {
  collectionBulkActionsStatus: null,
  tagsList: {
    status: null,
    data: null,
  },
  tagsItemList: {
    status: null,
    data: null,
  },
  addRoutineStatus: null,
  routineDetails: {
    status: null,
    data: null,
  },
  editRoutineStatus: null,
  tricksList: {
    status: null,
    data: null,
  },
  selectedItemsMap: null,
  selectedTags: null,
  removeRoutineStatus: null,
  uploadCsvFile: {
    status: null,
    data: null,
  },
  initBookItemsStatus: null,
  // collectionPrompts: {
  //   status: null,
  //   data: null,
  // },
  generateAudioFile: {
    status: null,
    data: null,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.INIT_BOOK_ITEMS:
      return {
        ...state,
        initBookItemsStatus: action.status ?? null,
      };

    case Types.SET_ITEM_TAGS:
      return {
        ...state,
        tagsItemList: {
          status: action.status ?? null,
          data: action.data,
        },
      };
    case Types.COLLECTION_BULK_ACTIONS:
      return {
        ...state,
        collectionBulkActionsStatus: action.status ?? null,
      };
    case Types.UPLOAD_CSV_FILE:
      return {
        ...state,
        uploadCsvFile: {
          status: action.status ?? null,
          data: action.data,
        },
      };
    case Types.SET_SELECTED_ITEMS:
      return {
        ...state,
        selectedItemsMap: action.data,
      };
    case Types.SET_COLLECTION_TAGS:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.tagsList.data : action.data,
        },
      };
    case Types.ADD_ROUTINE:
      return {
        ...state,
        addRoutineStatus: action.status || null,
      };
    case Types.SET_ROUTINE_DETAILS:
      return {
        ...state,
        routineDetails: {
          ...state.routineDetails,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.EDIT_ROUTINE:
      return {
        ...state,
        editRoutineStatus: action.status || null,
      };
    case Types.REMOVE_ROUTINE:
      return {
        ...state,
        removeRoutineStatus: action.status || null,
      };
    case Types.SET_TRICKS:
      return {
        ...state,
        tricksList: {
          ...state.tricksList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.tricksList.data : action.data,
        },
      };
    case Types.SET_SELECTED_TAGS:
      return {
        ...state,
        selectedTags: action.data,
      };
    case Types.GENERATE_AUDIO_FILE:
      return {
        ...state,
        generateAudioFile: {
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
