import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import React from 'react';
import { useDispatch } from 'react-redux';
import { IState } from '../../store/reducer';
import Checkbox from '@material-ui/core/Checkbox';
import StudentsSelectorActions from '../../store/actions';
import { useParams } from '../../common';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { openCollectionItemsPerStudentModal } from 'components/compounds/CollectionItemsPerStudentModal';

interface ITableActionsProps {
  params: NonNullable<IState['collectionsList']['data']>['items'][0];
}

const TableActions = React.memo((props: ITableActionsProps) => {
  return (
    <ButtonGroup size="small">
      <OptionsButton {...props} />
    </ButtonGroup>
  );
});

export const OptionsButton = ({ params }: ITableActionsProps) => {
  return (
    <>
      <Tooltip title="View course answers">
        <IconButton
          onClick={() => {
            // open student answers
            openCollectionItemsPerStudentModal({
              collectionId: params.collectionn._id,
              title: `${params.user.email} (${params.user.firstName} ${params.user.lastName})`,
              studentId: params.user._id,
              collectionType: params.collectionn.type,
            });
          }}
        >
          <AspectRatioIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default TableActions;
