import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import { IAuthData } from 'services/api/authenticate';

interface IUserData {
  username: string | null;
  email: string | null;
  id: string | null;
  twoStepVerification: boolean | null;
  sessionIsVerified: boolean | null;
  hasPassword: boolean | null;
  settings: IAuthData['settings'] | null;
  accessPortal: IAuthData['accessPortal'] | null;
}
export interface IState {
  status: string | null | undefined;
  roles: Array<number | string> | null;
  stayLogin: boolean;
  data: IUserData;
  fetchUserDataStatus: StateStatus | null | undefined;
}

const initialState: IState = {
  status: null,
  roles: null,
  stayLogin: false,
  fetchUserDataStatus: null,
  data: {
    username: null,
    email: null,
    id: null,
    twoStepVerification: null,
    sessionIsVerified: null,
    hasPassword: null,
    settings: null,
    accessPortal: null,
  },
};

const AuthReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    // AUTHENTICATE
    case Types.LOGIN_TYPE:
      return {
        ...state,
        status: action.status,
        ...action.data,
      };
    case Types.LOGOUT_TYPE:
      return {
        ...state,
        ...initialState,
      };
    case Types.USER_DETAILS_TYPE:
      return {
        ...state,
        fetchUserDataStatus: action.status,
        ...action.data,
      };
    case Types.REFRESH_TOKEN_TYPE:
      return {
        ...state,
        ...action.data,
      };
    case Types.LOGIN_TWO_STEP_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          // twoStepVerification: null,
          sessionIsVerified: true,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default AuthReducer;
