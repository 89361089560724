import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import reactLogger from 'utils/logger';
import { getState } from 'redux/store';
import { IState } from './reducer';
import * as UtilsService from 'services/api/utils';

class Reset implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method Not Implemented');
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    throw new Error('Method Not Implemented');
  }

  action(): IAction {
    return this.onSuccess();
  }
}

/** Generate Audio File  */
interface IGenerateAudioFilePayload {
  text: string;
  title: string;
  speakers?: NonNullable<
    UtilsService.IConvertTextToAudioPayload['dialogue']
  >['speakers'];
  onSuccess: (data: IState['generateAudioFile']['data']) => void;
}

class GenerateAudioFile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.GENERATE_AUDIO_FILE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: IState['generateAudioFile']['data']): IAction {
    return {
      type: Types.GENERATE_AUDIO_FILE,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.GENERATE_AUDIO_FILE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IGenerateAudioFilePayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await UtilsService.convertTextToAudio({
          text: payload.text,
          title: payload.title,
          dialogue: payload.speakers
            ? {
                speakers: payload.speakers,
              }
            : undefined,
        });

        console.log('REsponse::::::', response);
        payload.onSuccess(response.data);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Generate Audio File Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  generateAudioFileAction: (payload: IGenerateAudioFilePayload) =>
    new GenerateAudioFile().action(payload),
  resetAction: () => new Reset().action(),
};
