import _ from 'lodash';
import React from 'react';
import { Redirect, withRouter } from 'react-router'; // import the react-router-dom components
import { AuthRoles } from '../../constants';
import NavigationConfig, { IPage } from '../../config/navigationConfig';
import reactLogger from 'utils/logger';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import * as ArrayUtils from 'utils/arrays';

const MainPage = (props: any) => {
  reactLogger.log('MainPage');
  const roles = AuthSelectors.useSelectUserRoles();
  // const verifySession = AuthSelectors.useVerifySession();
  // console.log('Require auth.. 2', verifySession);

  // replace main page with login Page
  if (roles.includes(AuthRoles.Unknown)) {
    return <Redirect to={NavigationConfig.loginPage().path} />;
  }

  // else if (verifySession) {
  //   return <Redirect to={NavigationConfig.confirmSessionPage().path} />;
  // }
  else {
    // get first page with match role:
    for (const renderPage of Object.values(NavigationConfig)) {
      if (
        ArrayUtils.arraysHasCommonElements((renderPage() as IPage).roles, roles)
      ) {
        return <Redirect to={(renderPage() as IPage).path} />;
      }
    }
    return <div>Page not found..</div>;
  }
};

export default withRouter(MainPage);
