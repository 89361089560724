export const openUrlToNewTab = (path: string) => {
  window.open(path, '_blank', 'noopener,noreferrer');
};

export const openUrl = (path: string) => {
  // window.open(path);
  window.location.href = path;
};

// export const copyToClipboard = async (text: string) => {
//   try {
//     await navigator.clipboard.writeText(text);
//   } catch (error) {
//     alert('Copy Error' + error.message);
//   }
// };

// export const pasteFromClipboard = async () => {
//   try {
//     return await navigator.clipboard.readText();
//   } catch (error) {
//     alert('Paste Error' + error.message);
//   }
// };

export const copyToClipboard = async (text: string) => {
  try {
    const permission = await navigator?.permissions?.query({
      name: 'clipboard-write' as any,
    });

    if (permission?.state === 'denied') {
      throw new Error('Not allowed to write clipboard.');
    }

    if (navigator?.clipboard) {
      await navigator?.clipboard?.writeText(text);
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
  } catch (error) {
    alert('Copy Error' + error.message);
  }
};

export const pasteFromClipboard = async () => {
  try {
    const permission = await navigator?.permissions?.query({
      name: 'clipboard-read' as any,
    });

    if (permission?.state === 'denied') {
      throw new Error('Not allowed to read clipboard.');
    }

    if (navigator?.clipboard) {
      return await navigator.clipboard.readText();
    } else {
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      textarea.focus();
      document.execCommand('paste');
      const clipboardText = textarea.value;
      document.body.removeChild(textarea);
      return clipboardText;
    }
  } catch (error) {
    alert('Paste Error' + error.message);
  }
};

interface IOpenNestedWindowOptions {
  height?: number;
  width?: number;
  left?: number;
  top?: number;
}

export const openNestedWindow = (
  url: string,
  options?: IOpenNestedWindowOptions
) => {
  window.open(
    url,
    '_blank',
    `width=${options?.width ?? 600},height=${options?.height ?? 800},right=${
      options?.left ?? 200
    },top=${options?.top ?? 200}`
  );
};
