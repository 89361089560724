import { ReducerKeys } from '../../config';
import { getState } from '../../store';
import * as Types from './types';
import { IAction, IActionMethods } from '../../utils/common';

/** Close Modal Action */

interface IOpenModalInput {
  modalID: Types.ModalIDs;
  params: any;
}

interface IOpenModalSuccessInput {
  modals: any;
  order: Types.ModalIDs[];
}

class OpenModal implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: IOpenModalSuccessInput): IAction {
    const { modals, order } = data;
    return {
      type: Types.SET_MODAL_VISIBILITY,
      data: {
        modals: modals,
        order: order,
      },
    };
  }
  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: IOpenModalInput): IAction {
    const { modalID, params } = data;
    const { modals, order } = getState().ModalReducer;
    modals[modalID] = {
      params: params,
      visible: true,
    };
    order.push(modalID);
    return this.onSuccess({ modals, order });
  }
}

/** Open Modal Action */

interface ICloseModalInput {
  modalID: Types.ModalIDs;
}

interface ICloseModalSuccessInput {
  modals: any;
  order: Types.ModalIDs[];
}

class CloseModal implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: ICloseModalSuccessInput): IAction {
    const { modals, order } = data;
    return {
      type: Types.SET_MODAL_VISIBILITY,
      data: {
        modals: modals,
        order: order,
      },
    };
  }
  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: ICloseModalInput): IAction {
    let { modalID } = data;
    const { modals, order } = getState().ModalReducer;
    var _order = order;
    if (modalID) {
      _order = order.filter((item: any) => item !== modalID);
    } else {
      modalID = _order.pop() as Types.ModalIDs;
    }

    if (!modalID) return this.onFailed();

    const temp = { ...modals[modalID] };
    temp.visible = false;
    modals[modalID] = {
      ...temp,
    };
    return this.onSuccess({ modals, order: _order });
  }
}

export default {
  openModalAction: (data: IOpenModalInput) => new OpenModal().action(data),
  closeModalAction: (data: ICloseModalInput) => new CloseModal().action(data),
};
