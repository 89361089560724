import BaseModal from 'components/molecules/BaseModal';
import React from 'react';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import PrettyPrintJson from 'components/atoms/PrettyPrintJson';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import { Box, Button, IconButton } from '@material-ui/core';
import UploadFileButton from 'components/atoms/UploadFileButton';
import { useDispatch } from 'react-redux';
import CollectionDetailsActions from '../../store/actions';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useContextProps } from '../../provider';
import { useFieldArray, useForm } from 'react-hook-form';
import GridLayout from 'components/molecules/GridLayout';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  AppCommon,
  AppTextField,
  AppUploadPicker,
} from 'components/molecules/FormControls';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { DeepNonNullable } from 'utils/generics';
import FormLabel from '@material-ui/core/FormLabel';

interface IUploadPdfModalParams {}

const UploadPdfModal = () => {
  const refSubmit = React.useRef();

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_COLLECTION_DETAILS_UPLOAD_PDF}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: IUploadPdfModalParams) => (
        <Content {...params} ref={refSubmit} />
      )}
      renderHeader={() => <>{'Config Book Sections'}</>}
      renderFooter={() => <Footer ref={refSubmit} />}
    />
  );
};

export interface IFormData {
  senctions: Array<{
    pageFrom: number | null;
    pageTo: number | null;
    title: string | null;
  }>;
  uploadedFile:
    | {
        src: string;
      }[]
    | null;
}

const EmptySection: IFormData['senctions'][0] = {
  pageFrom: null,
  pageTo: null,
  title: null,
};

const defaultValues: IFormData = {
  senctions: [EmptySection, EmptySection, EmptySection],
  uploadedFile: null,
};

const Content = React.forwardRef((params: IUploadPdfModalParams, ref: any) => {
  const dispatch = useDispatch();
  const props = useContextProps();
  const {
    reset,
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
    watch,
  } = useForm<IFormData>({ defaultValues });
  const senctionArrayFields = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'senctions', // unique name for your Field Array
    // keyName: 'arrayId', //default to "id", you can change the key name
  });

  const onSubmit = handleSubmit((data: DeepNonNullable<IFormData>) => {
    dispatch(
      CollectionDetailsActions.initBookItemsAction({
        pdfFile: data.uploadedFile?.[0].src,
        payload: {
          collectionId: props.collectionId as string,
          senctions: data.senctions ?? [],
        },
        onFinish: () => {
          dispatch(
            CollectionDetailsActions.fetchRoutineDetailsAction(
              props.collectionId as string
            )
          );
        },
      })
    );
  });

  React.useLayoutEffect(() => {
    _.set(ref, 'current.onSubmit', onSubmit);
  });

  const getName = (name: string, index: number) =>
    `senctions[${index}].${name}`;

  return (
    <Box>
      <Box>
        <FormLabel>{'Senctions'}</FormLabel>
        {senctionArrayFields.fields.map((element, index) => {
          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              key={element.id ?? index}
            >
              <GridLayout
                elements={[
                  {
                    element: (
                      <AppTextField
                        type="number"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={_.has(errors, getName('pageFrom', index))}
                        helperText={
                          _.get(errors, `${getName('pageFrom', index)}.message`) // errors.title && errors.title.message
                        }
                        rules={{ required: <AppCommon.RequiredField /> }}
                        required
                        fullWidth
                        id={getName('pageFrom', index)}
                        control={control}
                        placeholder="Page from.."
                        autoComplete={getName('pageFrom', index)}
                        name={getName('pageFrom', index)}
                      />
                    ),
                    size: 3,
                    id: 'pageFrom',
                  },
                  {
                    element: (
                      <AppTextField
                        type="number"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={_.has(errors, getName('pageTo', index))}
                        helperText={
                          _.get(errors, `${getName('pageTo', index)}.message`) // errors.title && errors.title.message
                        }
                        rules={{ required: <AppCommon.RequiredField /> }}
                        required
                        fullWidth
                        id={getName('pageTo', index)}
                        control={control}
                        placeholder="Page to.."
                        autoComplete={getName('pageTo', index)}
                        name={getName('pageTo', index)}
                      />
                    ),
                    size: 3,
                    id: 'pageTo',
                  },
                  {
                    element: (
                      <AppTextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={_.has(errors, getName('title', index))}
                        helperText={
                          _.get(errors, `${getName('title', index)}.message`) // errors.title && errors.title.message
                        }
                        rules={{ required: <AppCommon.RequiredField /> }}
                        required
                        fullWidth
                        id={getName('title', index)}
                        control={control}
                        placeholder="Enter Title.."
                        autoComplete={getName('title', index)}
                        name={getName('title', index)}
                      />
                    ),
                    size: 6,
                    id: 'title',
                  },
                ]}
              />
              <IconButton
                size="small"
                onClick={() => {
                  senctionArrayFields.remove(index);
                }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          );
        })}
        <Button
          size="small"
          style={{ marginBottom: 15, marginTop: 10 }}
          startIcon={<AddIcon />}
          onClick={() => {
            senctionArrayFields.append(EmptySection);
          }}
        >
          {'Add new Section'}
        </Button>
      </Box>

      <Box>
        <FormLabel>{'Upload PDF'}</FormLabel>

        <AppUploadPicker
          setValue={setValue}
          register={register}
          watch={watch}
          helperText={errors.uploadedFile && errors.uploadedFile.message}
          size="medium"
          margin="normal"
          error={'uploadedFile' in errors}
          rules={{ uploadedFile: <AppCommon.RequiredField /> }}
          name={'uploadedFile'}
          label={'Upload PDF'}
          accept=".pdf"
        />
      </Box>

      {/* <UploadFileButton
        type="button"
        handleChange={(files) => {
          setUploadedFile(files?.[0]);
          // dispatch(
          //   CollectionDetailsActions.initBookItemsAction({
          //     pdfFile: files?.[0],
          //     collectionId: props.collectionId as string,
          //     onFinish: () => {
          //       dispatch(
          //         CollectionDetailsActions.fetchRoutineDetailsAction(
          //           props.collectionId as string
          //         )
          //       );
          //     },
          //   })
          // );
        }}
        accept=".pdf"
        componentProps={{
          color: 'primary',
          startIcon: <PictureAsPdfIcon />,
          variant: 'contained',
          fullWidth: true,
          disabled: senctionArrayFields.fields.length === 0,
          // size: 'small',
        }}
      >
        <div>{'Upload Book PDF'}</div>
      </UploadFileButton> */}
    </Box>
  );
});

const Footer = React.forwardRef((params: IUploadPdfModalParams, ref: any) => {
  return (
    <>
      <Button
        onClick={() => {
          ref.current.onSubmit();
        }}
      >
        {'Submit'}
      </Button>
    </>
  );
});

export const openUploadPdfModal = (params: IUploadPdfModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_DETAILS_UPLOAD_PDF,
      params,
    })
  );
};

export const closeCsvStatusMModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_DETAILS_UPLOAD_PDF,
    })
  );
};

export default UploadPdfModal;
