import React from 'react';
import {
  openTranslationModalFormModal,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import {
  getFieldTranslations,
  ITranslationType,
  translation,
} from 'utils/translation';
import { useFormContext } from 'react-hook-form';
import { IFormData } from './types';
import _ from 'lodash';
import { LanguagesEnum } from 'constants/languagesConst';

interface IHandleTranslationOptions {
  name: string;
  title: string;
  type: 'textarea' | 'text';
  textToTranslate?: string;
  lanFrom: ITranslationType;
  tabs?: boolean;
  modalSize?: 'small' | 'medium' | 'large' | 'xlarge';
  rows?: number;
}

export const useHandleTranslation = () => {
  const {
    reset,
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    setValue,
    watch,
  } = useFormContext<IFormData>();

  const handleTranslation = React.useCallback(
    ({
      name,
      title,
      type,
      textToTranslate,
      lanFrom,
      tabs,
      modalSize,
      rows,
    }: IHandleTranslationOptions) => {
      openTranslationModalFormModal({
        onSubmit: (data) => {
          Object.keys(data).forEach((lan) => {
            const key = `translations.${lan}.${name}`;
            register(key);
            setValue(key, data[lan as LanguagesEnum]);
          });
        },
        // type: 'text',
        type,
        title,
        initialData: getFieldTranslations(name, getValues() as any) as any,
        modalSize: modalSize ?? 'large',
        textinputProps: {
          rows: rows ?? 1,
          maxRows: (rows ?? 0) < 5 ? 5 : rows,
        },
        tabs,
        autoTranslation: {
          textToTranslate: textToTranslate ?? _.get(getValues(), name),
          execute: async (text, lanTo) => {
            return await translation(text, {
              lanTo,
              lanFrom: lanFrom ?? undefined,
            });
          },
        },
      });
    },
    []
  );

  return { handleTranslation };
};
