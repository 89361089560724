import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getDefaultValues } from '../../configuration';
import { IItemDetailsProps } from '../../configuration';

const FormProviderWrapper = ({
  type,
  mode,
  children,
}: IItemDetailsProps & { children: React.ReactNode }) => {
  const defaultValues = React.useMemo(
    () => getDefaultValues(mode, type ?? null),
    [mode, type]
  );

  const methods = useForm({
    defaultValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FormProviderWrapper;
