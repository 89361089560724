import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USER_LIST: string = typeCreatorV2(
  'UsersReducer',
  'SetUsersList'
);

export const ADD_USER: string = typeCreatorV2('UsersReducer', 'AddUser');

export const EDIT_USER: string = typeCreatorV2('UsersReducer', 'EditUser');

export const SET_USER_SETTINGS_DETAILS: string = typeCreatorV2(
  'UsersReducer',
  'SetUserSettingsDetails'
);

export const UPDATE_USER_SETTINGS: string = typeCreatorV2(
  'UsersReducer',
  'UpdateUserSettings'
);

export const SET_PORTAL_ACCESS_DETAILS = typeCreatorV2(
  'UsersReducer',
  'SetPortalAccessDetails'
);

export const REMOVE_USER = typeCreatorV2('UsersReducer', 'RemoveUser');
