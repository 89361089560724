import React from 'react';
import CategoryIcon from '@material-ui/icons/Category';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';

export enum TagTypes {
  Item = 'Item',
  Collection = 'Collection',
}

export enum TagStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Hidden = 'Hidden',
}

export enum TagSubTypes {
  Hidden = 'Hidden',

  // Collection
  CollectionPersonalPlan = 'Collection.PersonalPlan',
  CollectionExpCategories = 'Collection.ExpCategories',
  CollectionBooks = 'Collection.Books',
  CollectionLibraryTags = 'Collection.LibraryTags',
  CollectionBundleExercises = 'Collection.BundleExercises',

  // Item
  ItemExpCategories = 'Item.ExpCategories',
  ItemLibraryTags = 'Item.LibraryTags',
}

// export enum TagSubTypes {
//   CollectionHidden = 'Collection.Hidden',
//   CollectionCategories = 'Collection.Categories',
//   CollectionBooks = 'Collection.Books',
//   CollectionDiscover = 'Collection.Discover',

//   ItemHidden = 'Item.Hidden',
//   ItemDiscover = 'Item.Discover',
//   ItemCategories = 'Item.Categories',
// }

// export enum TagLevel {
//   Beginner = 1,
//   Elementary = 2,
//   PreIntermediate = 3,
//   Intermediate = 4,
//   UpperIntermediate = 5,
//   Advanced = 6,
//   Proficient = 7,
//   // Expert = 8,
// }

export enum TagLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
  Level6 = 6,
  Level7 = 7,
  Level8 = 8,
}

export const TagTypesNameMap: {
  [key in TagTypes]: {
    name: string;
    Icon: React.FC;
  };
} = {
  Item: {
    name: 'Item',
    Icon: CategoryIcon,
  },
  Collection: {
    name: 'Collection',
    Icon: FilterNoneOutlinedIcon,
  },
};
