import BaseModal from 'components/molecules/BaseModal';
import React from 'react';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import PrettyPrintJson from 'components/atoms/PrettyPrintJson';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';

interface ICsvStatusModalParams {
  data: any;
}

const CsvStatusModal = () => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_CSV_STATUS_DETAILS}
      modalProps={{
        fullWidth: true,
      }}
      renderContent={(params: ICsvStatusModalParams) => (
        <div>
          <PrettyPrintJson data={params.data} />
        </div>
      )}
      renderHeader={() => <>CSV Data Status</>}
    />
  );
};

export const openCsvStatusModal = (params: ICsvStatusModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_CSV_STATUS_DETAILS,
      params,
    })
  );
};

export const closeCsvStatusMModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_CSV_STATUS_DETAILS,
    })
  );
};

export default CsvStatusModal;
