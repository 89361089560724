import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USER_PROFILE: string = typeCreatorV2(
  'UserProfileReducer',
  'SetUserProfile'
);

export const EDIT_PROFILE: string = typeCreatorV2(
  'UserProfileReducer',
  'EditProfile'
);

export const CHANGE_PASSWORD: string = typeCreatorV2(
  'UserProfileReducer',
  'ChangePassword'
);

// export const DOWNLOAD_BACKUP: string = typeCreatorV2(
//   'UserProfileReducer',
//   'DownloadBackup'
// );

export const UPDATE_SETTINGS: string = typeCreatorV2(
  'UserProfileReducer',
  'UpdateSettings'
);

export const SET_NAMESPACES_LIST = typeCreatorV2(
  'NamespacesReducer',
  'SetNamespacesList'
);
