import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.up('sm')]: {
      minWidth: 160,
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '0.8rem',
      // margin: '0px',
    },
  },
  submitContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  formControl: {},
  layout_links: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
  },
}));

export default useStyles;
