import React from 'react';
import { components, Props } from 'react-select';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FormControl, PropTypes, FormHelperText, Box } from '@material-ui/core';
import useStyles from './styles';
import _ from 'lodash';
const { ValueContainer, Placeholder } = components;

interface IOption {
  id: string | number;
  label: string;
  iconLeft?: React.ReactNode;
}

interface IGroupedOption {
  label: string;
  options: Array<{ id: string; label: string }>;
}

export interface IAdvancedSelectProps
  extends Omit<
    Props<IOption>,
    | 'onChange'
    | 'value'
    | 'name'
    | 'options'
    | 'getOptionLabel'
    | 'getOptionValue'
  > {
  name: string;
  control: Control<any>;
  rules: any;
  options: Array<IOption | IGroupedOption>;
  handleChange?: (
    value: Array<string | number> | string | number | null
  ) => void;
  controllerExtras?: any;
  margin?: PropTypes.Margin;
  error?: boolean;
  disabled?: boolean;
  customValueContainer?: boolean;
  helperText?: string;
}

export default function AdvancedSelect(props: IAdvancedSelectProps) {
  const {
    control,
    rules,
    handleChange,
    controllerExtras = {},
    margin,
    error,
    disabled,
    helperText,
    customValueContainer = true,
    ...other
  } = props;

  const gettV = React.useCallback(
    (value: any) => {
      let _value: string | number | undefined = undefined;
      let _label: string | undefined = undefined;

      for (const option of other.options ?? []) {
        if ('id' in option && option.id === value) {
          _value = option.id;
          _label = option.label;
        } else if ('options' in option) {
          for (const _option of option.options) {
            if (_option.id === value) {
              _value = _option.id;
              _label = _option.label;

              break;
            }
          }
        }

        if (_value) {
          break;
        }
      }

      return {
        _value,
        _label,
      };
    },
    [other.options]
  );

  // field: { onChange, onBlur, value, name, ref },
  // fieldState: { invalid, isTouched, isDirty, error },
  // formState,

  return (
    <Controller
      render={({ value, onChange }) => {
        console.log('Value::::', value);

        const values = Array.isArray(value)
          ? value
              .map((el) => {
                const res = gettV(el);

                return {
                  id: res?._value!,
                  label: res?._label!,
                };
              })
              .filter((el) => el.id)
          : gettV(value)?._value
          ? {
              id: gettV(value)?._value,
              label: gettV(value)?._label,
            }
          : undefined;

        return (
          <FormControl
            margin={margin}
            error={Boolean(error)}
            disabled={Boolean(disabled)}
            fullWidth
          >
            <Select
              components={{
                ...(customValueContainer
                  ? {
                      ValueContainer: CustomValueContainer,
                    }
                  : undefined),
              }}
              placeholder={'Select..'}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (provided, state) => ({
                  ...provided,
                  ...(error
                    ? {
                        borderColor: 'red !important',
                        '&:hover': {
                          borderColor: 'red',
                        },
                        ...(state.isFocused
                          ? {
                              boxShadow: '0px 0px 2px red',
                            }
                          : undefined),
                      }
                    : undefined),
                }),
              }}
              getOptionLabel={(option: IOption) =>
                (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {option.iconLeft}
                    {option.label}
                  </Box>
                ) as any
              }
              getOptionValue={(option: IOption) => `${option.id}`}
              className="react-select"
              value={values as any}
              // value={
              //   _value
              //     ? {
              //         label: _label ?? 'Not found',
              //         id: _value,
              //       }
              //     : undefined
              // }
              // value={(other.options ?? []).filter(
              //   (item) =>
              //     (Array.isArray(value) ? value : [value]).filter(
              //       (element) => `${element}` === `${item.id}`
              //     ).length > 0
              // )}
              onChange={(value) => {
                const getValue = (
                  value:
                    | Array<IOption | IGroupedOption>
                    | IGroupedOption
                    | IOption
                    | null
                ) => {
                  if (other.isMulti) {
                    if (Array.isArray(value)) {
                      return value?.flatMap((item) => {
                        if ('id' in item) {
                          return item.id;
                        }
                        return item.options?.flatMap((el) => el.id);
                      });
                    } else return [];
                  } else {
                    if (value && 'id' in value) {
                      return value.id;
                    } else if (value && 'options' in value) {
                      return value.options.map((el) => el.id);
                    }
                    // return (value as IOption).id;
                    else return null;
                  }
                };
                const data = getValue(value as Array<IOption> | IOption | null);
                handleChange && handleChange(data);

                console.log('DAATTAA:', data);
                onChange(data);
              }}
              {...other}
            />
            {helperText && (
              <FormHelperText
                style={{ color: Boolean(error) ? 'red' : undefined }}
              >
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}

const CustomValueContainer = ({ children, ...props }: any) => {
  const classes = useStyles();
  const { hasValue, selectProps } = props;
  return (
    <ValueContainer {...props} className={classes.valueContainer}>
      <Placeholder
        {...props}
        className={
          hasValue || _.get(selectProps ?? {}, 'menuIsOpen', false)
            ? classes.floatingPlaceholder
            : undefined
        }
      >
        {props.selectProps.placeholder}
      </Placeholder>

      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
