import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TaskService from 'services/api/tasks';

export interface IState {
  removeTaskStatus: StateStatus | null;
  addTaskStatus: StateStatus | null;
  editTaskStatus: StateStatus | null;
  taskDetails: {
    status: StateStatus | null;
    data: TaskService.FetchTaskDetailsData | null;
  };
}

const initialState: IState = {
  removeTaskStatus: null,
  addTaskStatus: null,
  editTaskStatus: null,
  taskDetails: {
    status: null,
    data: null,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_TASK_DETAILS:
      return {
        ...state,
        taskDetails: {
          data: action.data,
          status: action.status || null,
        },
      };
    case Types.EDIT_TASK:
      return {
        ...state,
        editTaskStatus: action.status || null,
      };
    case Types.ADD_TASK:
      return {
        ...state,
        addTaskStatus: action.status || null,
      };
    case Types.RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
