import React from 'react';
import useStyles from '../../styles';
import StudentsDatable from '../CollectionsDatable';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import { useDispatch } from 'react-redux';
import StudentsActions from '../../store/actions';
import { alpha, Box, colors, useTheme } from '@material-ui/core';
import * as Common from '../../common';
import {
  IColLibrariesPerCollectionProps,
  IColLibrariesPerCollectionParams,
} from '../../types';
import { ContextProvider } from '../../provider';
import ActionHeader from '../ActionHeader';

const Content = React.forwardRef(
  ({
    // onSubmit,
    // multipleSelections,
    // defaultSelections,
    // defaultFilters,
    ...props
  }: IColLibrariesPerCollectionProps) => {
    const classes = useStyles({});
    const theme = useTheme();
    // console.log('Hlobal search:::::', defaultFilters?.globalSearch);
    const [params, setParams] =
      React.useState<IColLibrariesPerCollectionParams>({
        ...props,
        // multipleSelections: multipleSelections ?? false,
        // globalSearch: defaultFilters?.globalSearch,
        // type: defaultFilters?.type,
        // isTemplate: defaultFilters?.isTemplate,
      });
    const dispatch = useDispatch();

    // React.useEffect(() => {
    //   if (defaultSelections) {
    //     dispatch(
    //       StudentsActions.setSelectedCollectionsAction({
    //         action: 'add',
    //         collectionId: defaultSelections,
    //         multiple: !!multipleSelections,
    //       })
    //     );
    //   }
    // }, [defaultSelections, multipleSelections]);

    React.useEffect(() => {
      return () => {
        // reset
        dispatch(StudentsActions.resetAction());
      };
    }, [dispatch]);

    return (
      <>
        <ContextProvider
          props={{
            ...params,
            setProps: setParams,
          }}
        >
          <ProviderContent />
        </ContextProvider>
      </>
    );
  }
);

const ProviderContent = React.memo(() => {
  const theme = useTheme();
  const params = Common.useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      StudentsActions.fetchLibraryCollectionsByCollectionIdAction({
        filter: {
          ...params,
          page: params.page,
          pageSize: params.pageSize,
        },
      })
    );
  }, [dispatch, params]);

  return (
    <>
      <Box
        style={{
          background:
            theme.palette.type === 'light'
              ? alpha(colors.common.white, 0.7)
              : alpha(colors.common.black, 0.3),
        }}
      >
        <ActionHeader />
        <StudentsDatable />
      </Box>
    </>
  );
});

export default Content;
