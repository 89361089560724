import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import * as Types from './types';
import { Dispatch } from 'redux';
import * as NamespacesService from 'services/api/namespaces';
import { IState } from './reducer';
import * as UserService from 'services/api/user';
import { getState } from 'redux/store';

/** Fetch Namespaces List  */
class FetchNamespacesList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_NAMESPACES_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: IState['namespacesList']['data']): IAction {
    return {
      type: Types.SET_NAMESPACES_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_NAMESPACES_LIST,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  // payload: NamespacesService.IFetchnamespaceListInput
  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await UserService.fetchUserPortalDetails(
          getState().AuthReducer.data.id as string
        );
        // const response = await NamespacesService.fetchnamespaceList({
        //   ...payload,
        //   disableTranslation: 'true',
        //   showTranslationsDictionary: 'false',
        // });
        dispatch(this.onSuccess(response.data.assignNamespaces));
      } catch (error) {
        console.log('FetchNamespacesList:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Update Portal Access Details Status  */
interface IUpdatePortalInput {
  payload: UserService.UserPortalDetailsPayload;
  onSuccess?: () => void;
  userId: string;
}

class UpdatePortalAccessDetails implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_PORTAL_ACCESS_DETAILS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPDATE_PORTAL_ACCESS_DETAILS,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_PORTAL_ACCESS_DETAILS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IUpdatePortalInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await UserService.editUserPortalDetails(
          payload.userId,
          payload.payload
        );
        payload.onSuccess && payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        console.log('UpdatePortalAccessDetails:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Namespace Detailst  */
class FetchNamespaceDetails implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_NAMESPACE_DETAILS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: IState['namespacesDetails']['data']): IAction {
    return {
      type: Types.SET_NAMESPACE_DETAILS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_NAMESPACE_DETAILS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: NamespacesService.IFetchnamespaceDetailsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await NamespacesService.fetchnamespaceDetails({
          ...payload,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Fetch Namespace Details:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Reset */
class Reset implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented yet');
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented yet');
  }

  action(): IAction {
    return this.onSuccess();
  }
}

/** Show Loading View */
interface IShowLoadingViewInput {
  data: IState['loadingView'];
}

class ShowLoadingView implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented yet');
  }
  onSuccess(data: IState['loadingView']): IAction {
    return {
      type: Types.SHOW_LOADING_VIEW,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented yet');
  }

  action({ data }: IShowLoadingViewInput): IAction {
    return this.onSuccess(data);
  }
}

export default {
  showLoadingViewAction: (payload: IShowLoadingViewInput) =>
    new ShowLoadingView().action(payload),
  updatePortalAccessDetailsAction: (payload: IUpdatePortalInput) =>
    new UpdatePortalAccessDetails().action(payload),
  fetchNamespacesListAction: () => new FetchNamespacesList().action(),
  fetchNamespaceDetailsAction: (
    payload: NamespacesService.IFetchnamespaceDetailsInput
  ) => new FetchNamespaceDetails().action(payload),
  resetAction: () => new Reset().action(),
};
