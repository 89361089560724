import store from 'redux/store';
import { IModalParams } from './types';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';

export const openUploadedFilesModal = (params: IModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_UPLOADED_FILES_LIST,
      params,
    })
  );
};

export const closeUploadedFilesModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_UPLOADED_FILES_LIST,
    })
  );
};
