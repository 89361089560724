import { Chip } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';

interface ITagsProps {
  tagsList?: string[];
}

const Tags = ({ tagsList }: ITagsProps) => {
  return (
    <div
      style={{
        display: 'inline-flex',
        flexWrap: 'wrap',
        marginTop: '0.5rem',
        gap: 6,
      }}
    >
      {tagsList?.map((tag: any, index) => (
        <Chip
          style={{ fontSize: 10 }}
          size="small"
          key={index}
          variant="outlined"
          label={tag}
        />
      ))}
    </div>
  );
};

export default Tags;
