import React from 'react';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import AuthActions, {
  handleAuthErrorCodes,
} from 'redux/reducers/gl_auth_reducer/actions';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import { StateStatus } from 'redux/utils/common';
import { loadFromLocalStorage } from 'redux/utils/storage';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useHistory } from 'react-router';
import NavigationConfig from 'config/navigationConfig';
import { showToastAction } from 'components/atoms/ToastMessage';
import useQuery from 'hooks/useQuery';
import LandingFragment from 'components/app_common/LandingFragment';
import { getState } from 'redux/store';

interface IIcognitoProviderProps {
  children: React.ReactNode;
}

const IcognitoProvider = (props: IIcognitoProviderProps) => {
  const query = useQuery();
  const icognito = query.get('icognito') === 'true';
  const [proceed, setProceed] = React.useState(icognito ? false : true);

  React.useEffect(() => {
    if (!proceed) {
      if (icognito) {
        localStorage.clear();
      }
      setProceed(true);
    }

    // return () => {
    //   if (icognito) {
    //     localStorage.clear();
    //   }
    // };
  }, [proceed, setProceed, icognito]);

  if (!proceed) {
    return <LandingFragment />;
  }

  return <>{props.children}</>;
};

export default React.memo(IcognitoProvider);
