import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppSelect,
  AppSwitch,
  AppTimepicker,
} from 'components/molecules/FormControls';
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import * as UsersSelectors from './store/selectors';
import UserActions from './store/actions';
import { StateStatus } from 'redux/utils/common';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNamespacesList } from 'redux/reducers/gl_common_reducer/selectors';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import FormCommon from 'components/molecules/FormControls/common';
import store from 'redux/store';
import { LanguageOptions, LevelOptions } from 'constants/dropdownOptions';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';

export interface IModalParams {
  name: string;
  namespaceId: string;
  userid: string;
}

const NamespaceSettingsModal = () => {
  const dispatch = useDispatch();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = (props: IModalParams) =>
    handleSubmit((data: IFormData) => {
      dispatch(
        UserActions.updateUserSettingsAction({
          payload: {
            namespaceSettings: {
              [props.namespaceId]: {
                level: data.selectedLevel
                  ? parseInt(data.selectedLevel, 10)
                  : undefined,
                reminderEnable: data.reminderTimeEnabled ?? undefined,
                reminderTime: data.reminderTime
                  ? new Date(data.reminderTime).getTime()
                  : undefined,
              },
            },
          },
          userId: props.userid,
          onSuccess: () => {
            closeNamespaceSettingsModal();
          },
        })
      );
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_USER_NAMESPACE_SETTINGS_MODAL}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            register={register}
            control={control}
            errors={errors}
            getValues={getValues}
            watch={watch}
            selectedNamespaceId={props.namespaceId}
          />
        )}
        modalProps={{ fullWidth: false, fullScreen: matches }}
        renderHeader={(props: IModalParams) => (
          <>
            <TranslatedText defaultText={'Edit User Namespace Settings'} />
            <Typography>{props.name}</Typography>
          </>
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() => closeNamespaceSettingsModal()}
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper onSubmit={onSubmit(props)} />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
}

const SubmitWrapper = ({ onSubmit }: ISubmitWrapper) => {
  const updateSettingsStatus = UsersSelectors.useUpdateSettingsStatus();

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={updateSettingsStatus === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Update`} />
    </Button>
  );
};

interface IFormData {
  selectedLevel: string | null;
  reminderTime: Date | null;
  reminderTimeEnabled: boolean | null;
  // studyWeekDaysEnabled: boolean | null;
  // studyDurationEnabled: boolean | null;
  // coursesPerDayEnabled: boolean | null;
  // wordsPerDayEnabled: boolean | null;
}

const defaultValues: IFormData = {
  selectedLevel: null,
  reminderTime: null,
  reminderTimeEnabled: null,
  // studyWeekDaysEnabled: null,
  // studyDurationEnabled: null,
  // coursesPerDayEnabled: null,
  // wordsPerDayEnabled: null,
};

interface IModalFormProps {
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  register: ReturnType<typeof useForm>['register'];
  reset: ReturnType<typeof useForm>['reset'];
  params: IModalParams;
  getValues: ReturnType<typeof useForm>['getValues'];
  watch: ReturnType<typeof useForm>['watch'];
  selectedNamespaceId: string;
}

const ModalForm = ({
  control,
  errors,
  getValues,
  register,
  watch,
  params,
  reset,
  selectedNamespaceId,
}: IModalFormProps) => {
  const dispatch = useDispatch();
  const userSettingsDetails = UsersSelectors.useUserSettingsDetails();

  React.useEffect(() => {
    dispatch(
      UserActions.fetchUserSettingsDetailsAction({
        userId: params.userid,
      })
    );
  }, [dispatch, params.userid]);

  React.useEffect(() => {
    if (
      userSettingsDetails.status === StateStatus.Success &&
      userSettingsDetails.data
    ) {
      reset({
        selectedLevel:
          userSettingsDetails.data.namespaceSettings?.[selectedNamespaceId]
            ?.level,
        reminderTime:
          userSettingsDetails.data.namespaceSettings?.[selectedNamespaceId]
            ?.reminderTime,
        reminderTimeEnabled:
          userSettingsDetails.data.namespaceSettings?.[selectedNamespaceId]
            ?.reminderEnable,
      });
    }
  }, [params, reset, selectedNamespaceId, userSettingsDetails]);

  React.useEffect(() => {
    return () => {
      reset({ ...defaultValues });
    };
  }, [reset]);

  return (
    <React.Fragment>
      <AppSelect
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="selectedLevel"
        label={<TranslatedText defaultText={'Selected Level'} />}
        name="selectedLevel"
        autoComplete="selectedLevel"
        control={control}
        error={'selectedLevel' in errors}
        helperText={errors.selectedLevel && errors.selectedLevel.message}
        rules={{
          required: <FormCommon.RequiredField />,
        }}
        options={LevelOptions}
      />
      <AppSwitch
        rules={{}}
        name="reminderTimeEnabled"
        control={control}
        label="Reminder Time Enabled"
        margin="normal"
      />
      <AppTimepicker
        fullWidth
        label={<TranslatedText defaultText={'Reminder Time'} />}
        autoOk
        helperText={errors.reminderTime && errors.reminderTime.message}
        error={'reminderTime' in errors}
        control={control}
        rules={{}}
        name="reminderTime"
        margin="normal"
      />
    </React.Fragment>
  );
};

interface INamespacesSelectWrapperProps {
  control: any;
  errors: any;
}

const NamespacesSelectWrapper = ({
  control,
  errors,
}: INamespacesSelectWrapperProps) => {
  const namespacesData = useNamespacesList();
  const options = React.useMemo(
    () =>
      namespacesData.data?.map((el) => ({
        label: el.name,
        id: el._id,
      })),
    [namespacesData]
  );
  return (
    <AppSelect
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="selectedNamespace"
      label={<TranslatedText defaultText={'Selected Namespace'} />}
      name="selectedNamespace"
      autoComplete="selectedNamespace"
      control={control}
      error={'selectedNamespace' in errors}
      helperText={errors.selectedNamespace && errors.selectedNamespace.message}
      rules={{
        required: <FormCommon.RequiredField />,
      }}
      options={options ?? []}
    />
  );
};

export const openNamespaceSettingsModal = (params: IModalParams) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_USER_NAMESPACE_SETTINGS_MODAL,
      params,
    })
  );
};

export const closeNamespaceSettingsModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_USER_NAMESPACE_SETTINGS_MODAL,
    })
  );
};

export default withReducer(ReducerKeys.USER_NAMESPACE_SETTINGS_REDUCER)(
  NamespaceSettingsModal
);
