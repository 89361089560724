import React from 'react';
import useTimeout from 'hooks/useTimeout';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface CustomSuspenseProps extends Omit<React.SuspenseProps, 'fallback'> {
  fallback?: keyof React.SuspenseProps['fallback'];
}

interface IAppLoadingProps {
  delay?: number;
}

function AppLoading(props: IAppLoadingProps) {
  const classes = useStyles({});
  const [showLoading, setShowLoading] = React.useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay || 100);

  if (!showLoading) {
    return null;
  }

  return (
    <div className={classes.root}>
      {/* <Typography variant="h5" color="textSecondary">
        Loading...
      </Typography> */}
      <CircularProgress size="2rem" />
    </div>
  );
}

const CustomSuspense = (props: CustomSuspenseProps) => {
  return <React.Suspense fallback={<AppLoading />} {...props} />;
};

export default React.memo(CustomSuspense);
