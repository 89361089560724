import React, { useState, useRef } from 'react';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

interface IOption {
  label: React.ReactNode;
  id: string | number;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  disabled?: boolean;
}

interface ICustomOption extends IOption {
  // suboptions?: IOption[];
  onClick?: () => void;
}

export interface IOptionsMenuProps {
  children?: React.ReactElement<{
    [key: string]: any;
    onClick: (event: React.MouseEvent<HTMLElement>) => any;
  }>;
  id: string;
  options: Array<ICustomOption>;
  closeOnClick?: boolean;
}

const OptionsMenu = ({
  children,
  id,
  options,
  closeOnClick = true,
}: IOptionsMenuProps) => {
  const [menuPosition, setMenuPosition] = useState<any>({ top: 10, left: 10 });
  const menuItemRef = useRef<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = React.useCallback(
    (forceClose?: boolean) => {
      (closeOnClick || forceClose) && setAnchorEl(null);
    },
    [setAnchorEl, closeOnClick]
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  // const handleRightClick = (event: React.MouseEvent) => {
  //   if (menuPosition) {
  //     return;
  //   }
  //   event.preventDefault();
  //   setMenuPosition({
  //     top: event.pageY,
  //     left: event.pageX,
  //   });
  // };

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
    handleClose();
  };

  return (
    <React.Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: handleClick,
        })}
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => handleClose(true)}
      >
        {options.map(({ ...option }) => {
          return (
            <MenuItem
              disabled={option.disabled}
              key={option.id}
              onClick={(event) => {
                option.onClick && option.onClick();
                handleItemClick(event);
              }}
            >
              {option.iconLeft && (
                <ListItemIcon>{option.iconLeft}</ListItemIcon>
              )}
              {option.label}
              {option.iconRight && (
                <ListItemIcon>{option.iconRight}</ListItemIcon>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default OptionsMenu;
