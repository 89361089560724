import { IAction } from '../../utils/common';
import * as Types from './types';

export interface IState {
  modals: any;
  order: Types.ModalIDs[];
}

const modalIDs = Object.values(Types.ModalIDs);
const modals = () => {
  const map: {
    [key: string]: {
      params: unknown;
      visible: boolean;
    };
  } = {};
  for (var id of modalIDs) {
    map[id] = {
      params: {},
      visible: false,
    };
  }
  return map;
};

const initialState = {
  modals: modals(),
  order: [],
};

const modalReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_MODAL_VISIBILITY:
      return {
        ...state,
        modals: action.data.modals,
        order: action.data.order,
      };
    default:
      return {
        ...state,
      };
  }
};

export default modalReducer;
