import React from 'react';
import CollectionDetailsContent from './components/Content';
import { Box, Button, Checkbox, IconButton, Theme } from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { CollectionTypeEnum, ItemTypeEnum } from 'constants/typesConst';
import { CollectionTypeTitleMap } from './configuration';
import store from 'redux/store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import type { CollectionDetailsModalParams } from './types';

export { CollectionDetailsContent };

const CollectionDetailsModal = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_COLLECTION_DETAILS}
      showExitIcon={true}
      modalProps={{ fullWidth: true, maxWidth: 'lg', fullScreen: matches }}
      renderHeader={(params: CollectionDetailsModalParams) => (
        <Header {...params} />
      )}
      renderContent={(params: CollectionDetailsModalParams) => {
        return <CollectionDetailsContent {...params} />;
      }}
    />
  );
};

const Header = (props: CollectionDetailsModalParams) => {
  const title = props.type ? CollectionTypeTitleMap[props.type] : 'Collection';

  return (
    <Box alignItems="center" display="flex">
      <TranslatedText
        defaultText={`${props.collectionId ? 'Edit' : 'Add'} ${title}`}
      />
    </Box>
  );
};

export const openCollectionDetailsModal = (
  params: CollectionDetailsModalParams
) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_DETAILS,
      params,
    })
  );
};

export const closeCollectionDetailsModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_DETAILS,
    })
  );
};

export default React.memo(CollectionDetailsModal);
