import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TasksService from 'services/api/tasks';

export interface IState {
  tasksList: {
    status: StateStatus | null;
    data: TasksService.FetchTasksListData | null;
  };
  removeTaskStatus: StateStatus | null;
}

const initialState: IState = {
  tasksList: {
    status: null,
    data: null,
  },
  removeTaskStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_TASKS_LIST:
      return {
        ...state,
        tasksList: {
          ...state.tasksList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.tasksList.data : action.data,
        },
      };
    case Types.REMOVE_TASK:
      return {
        ...state,
        removeTaskStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
