import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IFormData, VectorIconsList, defaultValues } from '../../configuration';
import { CollectionTypeEnum } from 'constants/typesConst';
import { PaymentTypeEnum } from 'constants/paymentsConst';
import { CollectionStatusEnum } from 'constants/statusConst';

interface IFormProviderProps {
  type: CollectionTypeEnum | null;
  children: React.ReactNode;
}

const FormProviderWrapper = ({ type, children }: IFormProviderProps) => {
  const methods = useForm({
    defaultValues: defaultValues(type),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default FormProviderWrapper;
