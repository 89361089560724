import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppSelect,
  AppCommon,
  AppAdvancedselect,
  IAdvancedSelectProps,
} from 'components/molecules/FormControls';
import {
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Paper,
} from '@material-ui/core';
import * as TagSelectors from './store/selectors';
import TagActions from './store/actions';
import { StateStatus } from 'redux/utils/common';
import {
  TagLevel,
  TagStatus,
  TagSubTypes,
  TagTypes,
  TagTypesNameMap,
} from 'constants/tagsConst';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import { NoNullFields } from 'utils/generics';
import {
  useSelectedPortalNamespace,
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import {
  openTranslationModalFormModal,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import TranslateIcon from '@material-ui/icons/Translate';
import { DefaultLanguage, LanguagesEnum } from 'constants/languagesConst';
import {
  fixTranslations,
  getFieldTranslations,
  translation,
} from 'utils/translation';
import {
  useLevelNamespaceLevelsOptions,
  useNamespacesList,
} from 'redux/reducers/gl_common_reducer/selectors';
import {
  ItemVocabularyCategoriesOptions,
  LevelOptions,
  TagSubTypeOptions,
} from 'constants/dropdownOptions';
import { getFileDetails, openFileToNewTabAsync } from 'services/api/file';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import {
  openUploadedFilesModal,
  closeUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import { FileType } from 'constants/filesConst';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import _ from 'lodash';
import LanguageIcon from '@material-ui/icons/Language';
import { arraysHasCommonElements } from 'utils/arrays';
import { Roles } from 'constants/authRolesConst';
import { BaseImageWrapper } from 'components/app_common/FormFileWrapper';
import { VectorIconsList } from '../CollectionDetailsModal/configuration';
import { ItemTypeEnum } from 'constants/typesConst';

export interface IModalParams {
  mode: 'add' | 'edit';
  tagid?: string;
  defaultValues?: IFormData;
  onSuccess: () => void;
  tagType: TagTypes;
}

const ModalAddEditTag = () => {
  const dispatch = useDispatch();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const onSubmit = (onSuccess: () => void, tagType: TagTypes, tagid?: string) =>
    handleSubmit((data: IFormData) => {
      if (tagid) {
        dispatch(
          TagActions.editTagAction({
            data: {
              description: data.description ?? '',
              name: data.name ?? undefined,
              type: tagType,
              namespaces: data.namespaces ?? undefined,
              status: data.status ?? undefined,
              iconId: data.iconId ?? undefined,
              level: data.level ?? undefined,
              translations: fixTranslations(data.translations),
              imageId: data.imageId ?? undefined,
              order: data.order
                ? parseInt(data.order.toString(), 10)
                : undefined,
              subType: data.subType ?? undefined,
              iconImageId: data.iconImageId ?? undefined,
              item_vocabCategories: data.item_vocabCategories ?? undefined,
            },
            onSuccess,
            tagId: tagid,
          })
        );
      } else {
        dispatch(
          TagActions.addTagAction({
            data: {
              description: data.description ?? '',
              name: data.name ?? '',
              type: tagType,
              namespaces: data.namespaces ?? [],
              status: data.status ?? TagStatus.Inactive,
              iconId: data.iconId ?? undefined,
              level: data.level ?? undefined,
              translations: fixTranslations(data.translations),
              imageId: data.imageId ?? undefined,
              order: data.order
                ? parseInt(data.order.toString(), 10)
                : undefined,
              subType: data.subType ?? undefined,
              iconImageId: data.iconImageId ?? undefined,
              item_vocabCategories: data.item_vocabCategories ?? undefined,
            },
            onSuccess,
          })
        );
      }
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_ADD_EDIT_TAG}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            register={register}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
          />
        )}
        modalProps={{ fullWidth: true, fullScreen: matches }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText
            defaultText={
              props.mode === 'add'
                ? `Add ${TagTypesNameMap[props.tagType]?.name}`
                : `Edit ${TagTypesNameMap[props.tagType]?.name}`
            }
          />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button variant="outlined" onClick={closeTagDetailsModal}>
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper
              mode={props.mode}
              onSubmit={onSubmit(props.onSuccess, props.tagType, props.tagid)}
            />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
  mode: 'edit' | 'add';
}

const SubmitWrapper = ({ onSubmit, mode }: ISubmitWrapper) => {
  const addStatus = TagSelectors.useAddTagStatus();
  const editStatus = TagSelectors.useEditTagStatus();

  let status = mode === 'add' ? addStatus : editStatus;

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IFormData {
  name: string | null;
  description: string | null;
  // type: TagTypes | null;
  namespaces: string[] | null;
  status: TagStatus | null;
  level: TagLevel[] | null;
  iconId: string | null;
  imageId: string | null;
  iconImageId: string | null;
  order: number | null;
  subType: TagSubTypes[] | null;
  item_vocabCategories?: ItemTypeEnum[] | null;
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string;
      }>;
    }
  >;
}

const defaultValues: IFormData = {
  name: null,
  description: null,
  // type: null,
  namespaces: null,
  status: null,
  level: null,
  iconId: null,
  imageId: null,
  order: null,
  subType: null,
  iconImageId: null,
  item_vocabCategories: null,
};

interface IModalFormProps {
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  register: ReturnType<typeof useForm>['register'];
  reset: ReturnType<typeof useForm>['reset'];
  params: IModalParams;
  getValues: ReturnType<typeof useForm>['getValues'];
  setValue: ReturnType<typeof useForm>['setValue'];
}

const ModalForm = ({
  control,
  errors,
  getValues,
  register,
  params,
  reset,
  setValue,
}: IModalFormProps) => {
  const portalNamespaceSelected = useSelectedPortalNamespace();
  const roles = useSelectUserRoles();
  const namespaceLevelsOptions = useLevelNamespaceLevelsOptions();

  React.useEffect(() => {
    const namespaces =
      params.mode === 'add' && portalNamespaceSelected
        ? [portalNamespaceSelected]
        : params.defaultValues?.namespaces ?? [];

    reset({
      ...defaultValues,
      ...params.defaultValues,
      namespaces,
    });
    register('translations');

    return () => {
      reset({ ...defaultValues });
    };
  }, [params, reset, portalNamespaceSelected]);

  const handleTranslation = React.useCallback((name: string, title: string) => {
    openTranslationModalFormModal({
      onSubmit: (data) => {
        Object.keys(data).forEach((lan) => {
          const key = `translations.${lan}.${name}`;
          register(key);
          setValue(key, data[lan as LanguagesEnum]);
        });
      },
      type: 'text',
      title,
      initialData: getFieldTranslations(name, getValues() as any) as any,
      autoTranslation: {
        textToTranslate: _.get(getValues(), name),
        execute: async (text, lanTo) => {
          return await translation(text, {
            lanTo,
            lanFrom: 'defaultLan',
          });
        },
      },
    });
  }, []);

  return (
    <React.Fragment>
      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label={<TranslatedText defaultText={'Name'} />}
        name="name"
        autoComplete="name"
        control={control}
        // Boolean(params.tagid)
        disabled={
          !arraysHasCommonElements(roles, [Roles.Admin]) &&
          !Boolean(params.tagid)
        }
        error={'name' in errors}
        helperText={errors.name && errors.name.message}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
      />

      <Paper
        style={{ padding: '1rem', marginTop: '0.5rem', marginBottom: '0.3rem' }}
        variant="outlined"
      >
        <AppTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="description"
          label={<TranslatedText defaultText={'Description'} />}
          name="description"
          autoComplete="description"
          control={control}
          error={'description' in errors}
          helperText={errors.description && errors.description.message}
          rules={{
            required: <AppCommon.RequiredField />,
          }}
          icon={{
            ComponentRight: (
              <IconButton
                onClick={() => {
                  handleTranslation('description', 'Description');
                }}
                size="small"
              >
                <LanguageIcon />
              </IconButton>
            ),
          }}
        />
        <SelectedLanguages fieldName="description" control={control} />
      </Paper>

      <NamespacesWrapper
        control={control}
        name="namespaces"
        disabled={
          !arraysHasCommonElements(roles, [Roles.Admin, Roles.Supervisor])
        }
        placeholder="Namespaces"
        error={'namespaces' in errors}
        id={'namespaces'}
        rules={{}}
        margin={'normal'}
        isMulti
        helperText={errors.namespaces && errors.namespaces.message}
      />
      <AppSelect
        variant="outlined"
        size="medium"
        margin="normal"
        error={'status' in errors}
        helperText={errors.status && errors.status.message}
        required
        fullWidth
        id="status"
        label={<TranslatedText defaultText={'Status'} />}
        control={control}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
        autoComplete="status"
        name="status"
        options={TagStatusesOptions}
      />
      {/* <AppSelect
        variant="outlined"
        size="medium"
        margin="normal"
        error={'type' in errors}
        helperText={errors.type && errors.type.message}
        required
        fullWidth
        id="type"
        label={<TranslatedText defaultText={'Tag Type'} />}
        control={control}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
        autoComplete="type"
        name="type"
        options={TagTypesOptions}
      /> */}

      <AppAdvancedselect
        // variant="outlined"
        // size="medium"
        margin="normal"
        error={'subType' in errors}
        helperText={errors.subType && errors.subType.message}
        // required
        // fullWidth
        id="subType"
        placeholder={'Visiblity'}
        control={control}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
        isMulti
        // autoComplete="subType"
        name="subType"
        options={TagSubTypeOptions(params.tagType)}
      />

      {params.tagType === TagTypes.Item && (
        <AppAdvancedselect
          margin="normal"
          error={'item_vocabCategories' in errors}
          helperText={
            errors.item_vocabCategories && errors.item_vocabCategories.message
          }
          id="item_vocabCategories"
          placeholder={'Filtering Categories'}
          control={control}
          rules={{}}
          isMulti
          name="item_vocabCategories"
          options={ItemVocabularyCategoriesOptions}
        />
      )}

      <AppTextField
        variant="outlined"
        size="medium"
        margin="normal"
        error={'order' in errors}
        helperText={errors.order && errors.order.message}
        required
        fullWidth
        id="order"
        type="number"
        label={<TranslatedText defaultText={'Order'} />}
        control={control}
        rules={
          {
            // required: <AppCommon.RequiredField />,
          }
        }
        autoComplete="order"
        name="order"
      />

      <AppAdvancedselect
        margin="normal"
        error={'level' in errors}
        helperText={errors.level && errors.level.message}
        id="level"
        placeholder={'Level'}
        control={control}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
        isMulti
        name="level"
        options={namespaceLevelsOptions}
      />
      {/* 
      <AppSelect
        variant="outlined"
        size="medium"
        margin="normal"
        error={'level' in errors}
        helperText={errors.level && errors.level.message}
        required
        fullWidth
        id="level"
        label={<TranslatedText defaultText={'Level'} />}
        control={control}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
        autoComplete="level"
        name="level"
        options={namespaceLevelsOptions}
      /> */}

      <div>
        <AppTextField
          variant="outlined"
          size="medium"
          margin="normal"
          error={'imageId' in errors}
          helperText={errors.imageId && errors.imageId.message}
          rules={{}}
          fullWidth
          required
          id="imageId"
          disabled
          label={<TranslatedText defaultText={'Image Url'} />}
          control={control}
          autoComplete="imageId"
          icon={{
            ComponentRight: (
              <>
                <IconButton
                  onClick={async () => {
                    let initialValue = getValues().name;
                    if (getValues().imageId) {
                      try {
                        const fileDetails = await getFileDetails(
                          getValues().imageId as string
                        );
                        initialValue = fileDetails.data.name;
                      } catch (error) {}
                    }

                    openUploadedFilesModal({
                      handleSelectedFile: (filePath) => {
                        setValue('imageId', filePath);
                        closeUploadedFilesModal();
                      },
                      selectItem: true,
                      initialSearch: initialValue,
                      type: FileType.Image,
                    });
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setValue('imageId', null);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </>
            ),
            ComponentLeft: (
              <IconButton
                onClick={async () => {
                  getValues().imageId &&
                    (await openFileToNewTabAsync(getValues().imageId ?? ''));
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            ),
          }}
          name="imageId"
        />
        <BaseImageWrapper
          type="img"
          fieldId="imageId"
          getValues={getValues}
          control={control}
        />
      </div>

      <div>
        <AppTextField
          variant="outlined"
          size="medium"
          margin="normal"
          error={'iconImageId' in errors}
          helperText={errors.iconImageId && errors.iconImageId.message}
          rules={{}}
          fullWidth
          id="iconImageId"
          disabled
          label={<TranslatedText defaultText={'Image Icon Url'} />}
          control={control}
          autoComplete="iconImageId"
          icon={{
            ComponentRight: (
              <>
                <IconButton
                  onClick={async () => {
                    let initialValue = getValues().name;
                    if (getValues().iconImageId) {
                      try {
                        const fileDetails = await getFileDetails(
                          getValues().iconImageId as string
                        );
                        initialValue = fileDetails.data.name;
                      } catch (error) {}
                    }

                    openUploadedFilesModal({
                      handleSelectedFile: (filePath) => {
                        setValue('iconImageId', filePath);
                        closeUploadedFilesModal();
                      },
                      selectItem: true,
                      initialSearch: initialValue,
                      type: FileType.Image,
                    });
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setValue('iconImageId', null);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </>
            ),
            ComponentLeft: (
              <IconButton
                onClick={async () => {
                  getValues().iconImageId &&
                    (await openFileToNewTabAsync(
                      getValues().iconImageId ?? ''
                    ));
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            ),
          }}
          name="iconImageId"
        />
        <BaseImageWrapper
          type="img"
          fieldId="iconImageId"
          getValues={getValues}
          control={control}
        />
      </div>

      <AppSelect
        variant="outlined"
        size="medium"
        margin="normal"
        error={'iconId' in errors}
        helperText={errors.iconId && errors.iconId.message}
        // required
        fullWidth
        id="iconId"
        label={<TranslatedText defaultText={'Icon'} />}
        control={control}
        rules={
          {
            // required: <AppCommon.RequiredField />,
          }
        }
        autoComplete="iconId"
        name="iconId"
        options={VectorIconsList}
      />
    </React.Fragment>
  );
};

interface INamespacesWrapperProps
  extends Omit<IAdvancedSelectProps, 'options'> {}

const NamespacesWrapper = (props: INamespacesWrapperProps) => {
  const namespaces = useNamespacesList();

  const namespacesOptions = React.useMemo(
    () => namespaces.data?.map((el) => ({ id: el._id, label: el.name })) ?? [],
    [namespaces]
  );
  // const autData = useSelectUserData();

  // const namespacesOptions = React.useMemo(
  //   () =>
  //     autData.namespaces?.map((el) => ({ id: el._id, label: el.name })) ?? [],
  //   [autData]
  // );

  return <AppAdvancedselect {...props} options={namespacesOptions} />;
};

// const TagTypesOptions = Object.values(TagTypes).map((el) => ({
//   label: el,
//   id: el,
// }));

// const LevelOptions = Object.values(TagLevel)
//   .filter((el) => typeof el === 'number')
//   .map((el) => ({
//     label: el.toString(),
//     id: el,
//   }));

const TagStatusesOptions = Object.values(TagStatus).map((el) => ({
  label: el,
  id: el,
}));

export const openTagDetailsModal = (params: IModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_ADD_EDIT_TAG,
      params,
    })
  );
};

export const closeTagDetailsModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_ADD_EDIT_TAG,
    })
  );
};

export default withReducer(ReducerKeys.TAG_DETAILS_REDUCER)(ModalAddEditTag);
