import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  AppTextField,
  AppSelect,
  AppCommon,
  AppAdvancedselect,
  IAdvancedSelectProps,
} from 'components/molecules/FormControls';
import {
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Paper,
  Box,
  FormLabel,
} from '@material-ui/core';
import { StateStatus } from 'redux/utils/common';
import {
  TagLevel,
  TagStatus,
  TagSubTypes,
  TagTypes,
  TagTypesNameMap,
} from 'constants/tagsConst';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import AddIcon from '@material-ui/icons/Add';
import {
  useSelectedPortalNamespace,
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import _ from 'lodash';
import TextToAudioActions from './store/actions';
import * as TextToAudioSelectors from './store/selectors';
import GridLayout from 'components/molecules/GridLayout';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { DeepNonNullable } from 'utils/generics';

export interface IModalParams {
  defaultValues: IFormData;
  title: string;
  onSuccess?: (result: { id: string }) => void;
  dialogue: boolean;
}

const TextToAudioModal = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const refSubmit = React.useRef();

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_TEXT_TO_AUDIO}
      renderContent={(props: IModalParams) => (
        <ModalForm {...props} ref={refSubmit} />
      )}
      modalProps={{ fullWidth: true, fullScreen: matches }}
      renderHeader={(props: IModalParams) => (
        <TranslatedText defaultText={'Audio Text'} />
      )}
      renderFooter={(props: IModalParams) => (
        <Footer {...props} ref={refSubmit} />
      )}
    />
  );
};

interface IFormData {
  text: string | null;
  speakers: Array<{
    name: string | null;
    gender: string | null;
  }>;
}

const defaultValues: IFormData = {
  text: null,
  speakers: [],
};

const EmptySection: NonNullable<IFormData['speakers']>[0] = {
  name: null,
  gender: null,
};

const ModalForm = React.forwardRef((params: IModalParams, ref: any) => {
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const speakersArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'speakers', // unique name for your Field Array
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    reset({
      ...defaultValues,
      ...params.defaultValues,
    });

    return () => {
      reset({ ...defaultValues });
    };
  }, [params, reset]);

  const onSubmit = handleSubmit((data: DeepNonNullable<IFormData>) => {
    dispatch(
      TextToAudioActions.generateAudioFileAction({
        text: data.text,
        title: `Generated-Audio: ${params.title}`,
        onSuccess: (data) => {
          params.onSuccess &&
            params.onSuccess({
              id: data?._id as string,
            });
          closeTextToAudioModal();
        },
        speakers:
          data?.speakers?.length === 0
            ? undefined
            : (data.speakers as Array<{
                name: string;
                gender: 'male' | 'female';
              }>),
      })
    );
  });

  React.useLayoutEffect(() => {
    _.set(ref, 'current.onSubmit', onSubmit);
  });

  const getName = (name: string, index: number) => `speakers[${index}].${name}`;

  return (
    <React.Fragment>
      <AppTextField
        variant="filled"
        margin="normal"
        required
        fullWidth
        multiline
        id="text"
        label={<TranslatedText defaultText={'Audio Text'} />}
        name="text"
        autoComplete="text"
        control={control}
        error={'text' in errors}
        helperText={errors.text && errors.text.message}
        rules={{
          required: <AppCommon.RequiredField />,
        }}
      />

      {params.dialogue && (
        <Box style={{ marginTop: 10 }}>
          <FormLabel>{'Speakers'}</FormLabel>
          {speakersArray.fields.map((element, index) => {
            return (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                key={element.id ?? index}
              >
                <GridLayout
                  elements={[
                    {
                      element: (
                        <AppTextField
                          variant="outlined"
                          margin="dense"
                          size="small"
                          error={_.has(errors, getName('name', index))}
                          helperText={
                            _.get(errors, `${getName('name', index)}.message`) // errors.title && errors.title.message
                          }
                          rules={{ required: <AppCommon.RequiredField /> }}
                          required
                          fullWidth
                          id={getName('name', index)}
                          control={control}
                          placeholder="Name.."
                          autoComplete={getName('name', index)}
                          name={getName('name', index)}
                        />
                      ),
                      size: 6,
                      id: 'name',
                    },
                    {
                      element: (
                        <AppSelect
                          type="number"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          error={_.has(errors, getName('gender', index))}
                          helperText={
                            _.get(errors, `${getName('gender', index)}.message`) // errors.title && errors.title.message
                          }
                          rules={{ required: <AppCommon.RequiredField /> }}
                          required
                          fullWidth
                          id={getName('gender', index)}
                          control={control}
                          placeholder="Gender"
                          autoComplete={getName('gender', index)}
                          name={getName('gender', index)}
                          options={[
                            {
                              label: 'Male',
                              id: 'male',
                            },
                            {
                              label: 'Female',
                              id: 'female',
                            },
                          ]}
                        />
                      ),
                      size: 6,
                      id: 'gender',
                    },
                  ]}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    speakersArray.remove(index);
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            );
          })}
          <Box>
            <Button
              size="small"
              style={{ marginBottom: 15, marginTop: 15 }}
              startIcon={<AddIcon />}
              onClick={() => {
                speakersArray.append(EmptySection);
              }}
            >
              {'Add new Section'}
            </Button>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
});

const Footer = React.forwardRef((params: IModalParams, ref: any) => {
  const dispatch = useDispatch();
  const audioFile = TextToAudioSelectors.useGenerateAudioFile();

  return (
    <>
      <Button onClick={closeTextToAudioModal} color="primary">
        {'Cancel'}
      </Button>
      <Button
        disabled={audioFile.status === StateStatus.Pending}
        onClick={() => {
          ref.current.onSubmit();
        }}
        color="primary"
        autoFocus
      >
        {'Generate Audio'}
      </Button>
    </>
  );
});

export const openTextToAudioModal = (params: IModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_TEXT_TO_AUDIO,
      params,
    })
  );
};

export const closeTextToAudioModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_TEXT_TO_AUDIO,
    })
  );
};

export default withReducer(ReducerKeys.TEXT_TO_AUDIO_REDUCER)(TextToAudioModal);
