import { colors } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

// PALLETE
interface IColorFormat {
  transparency?: number;
  darkMode?: boolean;
}

// export const APP_COLOR_SECONDARY = ({
//   darkMode,
//   transparency = 1,
// }: IColorFormat) =>
//   darkMode
//     ? `rgba(39, 43, 47, ${transparency || 1})`
//     : `rgba(47, 128, 237, ${transparency})`;
// export const APP_COLOR_PRIMARY = ({
//   darkMode,
//   transparency = 1,
// }: IColorFormat) =>
//   darkMode
//     ? `rgba(255, 255, 255, ${transparency})`
//     : `rgba(47, 128, 237, ${transparency})`;
// export const APP_COLOR_HIGHLIGHT = ({
//   darkMode,
//   transparency = 1,
// }: IColorFormat) => `rgba(3, 155, 229, ${transparency || 1})`;
// export const APP_COLOR_DARK = ({ darkMode, transparency = 1 }: IColorFormat) =>
//   `rgba(45, 50, 62, ${transparency || 1})`;
// export const ERROR_COLOR = 'red';
// export const TEXT_COLOR_PRIMARY = ({
//   darkMode,
//   transparency = 1,
// }: IColorFormat) =>
//   darkMode
//     ? `rgba(255, 255, 255, ${transparency || 1})`
//     : `rgba(0, 0, 0, ${transparency || 1})`;
// export const TEXT_COLOR_HIGHLIGHT = ({
//   darkMode,
//   transparency,
// }: IColorFormat) =>
//   darkMode
//     ? `rgba(255, 255, 255, ${transparency || 1})`
//     : `rgba(0, 0, 0, ${transparency || 1})`;
// export const TEXT_COLOR_DARK = ({ darkMode, transparency = 1 }: IColorFormat) =>
//   darkMode
//     ? `rgba(255, 255, 255, ${transparency || 1})`
//     : `rgba(0, 0, 0, ${transparency || 1})`;
// export const TEXT_COLOR_SECONDARY = ({
//   darkMode,
//   transparency = 1,
// }: IColorFormat) =>
//   darkMode
//     ? `rgba(0, 0, 0, ${transparency || 1})`
//     : `rgba(255, 255, 255, ${transparency || 1})`;

export const CHART_BACKGROUND_COLOR = (theme: Theme) =>
  theme.palette.type === 'dark' ? '#1E1E1E' : '#FFFFFF';
export const LOGIN_REGISTER_BACKGROUND_COLOR = (theme: Theme) =>
  theme.palette.type === 'dark' ? '#272B2F' : '#FFFFFF';
export const NAVBAR_ITEM_SELECTED = (theme: Theme) =>
  theme.palette.type === 'dark' ? '#2F80ED' : 'rgba(5, 41, 77, 1)';
export const SIDE_BAR_COLOR = (theme: Theme) =>
  theme.palette.type === 'dark' ? '#070807' : 'white';
