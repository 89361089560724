import React from 'react';
import { Redirect, useLocation, withRouter, matchPath } from 'react-router';
import {
  useSelectUserRoles,
  useVerifySession,
} from 'redux/reducers/gl_auth_reducer/selectors';
import NavigationConfig from '../../config/navigationConfig';
import { IRequireAuthProps, IWithnavbarProps } from './types';
import * as ArrayUtils from 'utils/arrays';
import { Roles } from 'constants/authRolesConst';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import CustomSuspense from 'components/molecules/CustomSuspense';

export const RequireAuth = ({ Component, roles }: IRequireAuthProps): any => {
  const userRoles = useSelectUserRoles();

  // console.log('Require auth..', verifySession, roles, userRoles);

  return ArrayUtils.arraysHasCommonElements(roles, userRoles) ? (
    <CustomSuspense>
      <Component roles={userRoles} />
    </CustomSuspense>
  ) : (
    <Redirect to={NavigationConfig.mainPage().path} />
  );
};

export const withRoles = (Component: any, roles: Array<Roles>): any => {
  const ComposedComponent = (props: any) => {
    const userRoles = useSelectUserRoles();
    return ArrayUtils.arraysHasCommonElements(roles, userRoles) ? (
      <Component {...props} />
    ) : null;
  };

  return ComposedComponent;
};

export const WithNavbar = withRouter((props: IWithnavbarProps): any => {
  const location = useLocation();
  const { Component, select, include, exclude } = props;
  const path = location.pathname;
  let visible: boolean = false;
  const accessFromMobileDev = ThemeSelectors.useIsMobileAppAccess();
  // reactLogger.log('Call Withnumber', accessFromMobileDev, location);

  const arrayMatch = (_paths: string[], _path: string): boolean => {
    for (let ___path of _paths) {
      if (
        matchPath(_path, {
          path: ___path,
          exact: true,
          strict: false,
        })?.isExact
      ) {
        return true;
      }
    }
    return false;
  };

  if (select === 'exclude') {
    visible =
      exclude !== 'all' && Array.isArray(exclude) && !arrayMatch(exclude, path);
  } else if (select === 'include') {
    visible =
      include === 'all' ||
      (Array.isArray(include) && arrayMatch(include, path));
  }

  return (
    <React.Fragment>
      <Component visible={accessFromMobileDev ? false : visible} />
    </React.Fragment>
  );
});
