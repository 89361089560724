import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_STUDENTS_LIST: string = typeCreatorV2(
  'StudentsSelectorReducer',
  'SetUsersList'
);

export const SET_SELECTED_STUDENTS = typeCreatorV2(
  'StudentsSelectorReducer',
  'SetSelectedStudents'
);

export const RESET = typeCreatorV2('StudentsSelectorReducer', 'Reset');
