import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import { FetchnamespaceMembersData } from 'services/api/namespaces';

export interface IState {
  namespaceMembers: {
    data: FetchnamespaceMembersData | null;
    status: StateStatus | null;
  };
  editNamespaceStatus: StateStatus | null;
}

const initialState: IState = {
  namespaceMembers: {
    data: null,
    status: null,
  },
  editNamespaceStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.FETCH_NAMESPACE_DETAILS:
      return {
        ...state,
        namespaceMembers: {
          data: action.data ?? null,
          status: action.status || null,
        },
      };
    case Types.EDIT_NAMESPACE:
      return {
        ...state,
        editNamespaceStatus: action.status || null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
