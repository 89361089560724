import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import type * as StatisticsService from 'services/api/statistics';

export interface IState {
  totalUsers: {
    status: StateStatus | null;
    data: StatisticsService.IFetchTotalUsersData | null;
  };
  activeUsers: {
    status: StateStatus | null;
    data: StatisticsService.IFetchActiveUsersData | null;
  };
  totalItems: {
    status: StateStatus | null;
    data: StatisticsService.IFetchTotalItemsData | null;
  };
  totalCollections: {
    status: StateStatus | null;
    data: StatisticsService.IFetchTotalCollectionsData | null;
  };
  usersPerNamespace: {
    status: StateStatus | null;
    data: StatisticsService.IFetchUsersPerNamespaceData | null;
  };
  registrationsPer: {
    status: StateStatus | null;
    data: StatisticsService.IFetchRegistrationsPerData | null;
  };
  collectionsPer: {
    status: StateStatus | null;
    data: StatisticsService.IFetchCollectionsPerData | null;
  };
  itemsPer: {
    status: StateStatus | null;
    data: StatisticsService.IFetchItemsPerData | null;
  };
  totalElementsByLevel: {
    status: StateStatus | null;
    data: StatisticsService.ITotalElementsByLevelData | null;
  };
  totalUsersPerLevel: {
    status: StateStatus | null;
    data: StatisticsService.ITotalUsersPerLevelData | null;
  };
}

const initialState: IState = {
  totalUsers: {
    status: null,
    data: null,
  },
  activeUsers: {
    status: null,
    data: null,
  },
  totalItems: {
    status: null,
    data: null,
  },
  totalCollections: {
    status: null,
    data: null,
  },
  usersPerNamespace: {
    status: null,
    data: null,
  },
  registrationsPer: {
    status: null,
    data: null,
  },
  collectionsPer: {
    status: null,
    data: null,
  },
  itemsPer: {
    status: null,
    data: null,
  },
  totalElementsByLevel: {
    status: null,
    data: null,
  },
  totalUsersPerLevel: {
    status: null,
    data: null,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_TOTAL_ELEMENTS_BY_LEVEL:
      return {
        ...state,
        totalElementsByLevel: {
          ...state.totalElementsByLevel,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalElementsByLevel.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_USERS_COUNT:
      return {
        ...state,
        totalUsers: {
          ...state.totalUsers,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.totalUsers.data : action.data,
        },
      };
    case Types.SET_ACTIVE_USERS_COUNT:
      return {
        ...state,
        activeUsers: {
          ...state.activeUsers,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.activeUsers.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_ITEMS_COUNT:
      return {
        ...state,
        totalItems: {
          ...state.totalItems,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.totalItems.data : action.data,
        },
      };
    case Types.SET_TOTAL_COLLECTIONS_COUNT:
      return {
        ...state,
        totalCollections: {
          ...state.totalCollections,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalCollections.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_USERS_PER_NAMESPACE:
      return {
        ...state,
        usersPerNamespace: {
          ...state.usersPerNamespace,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.usersPerNamespace.data
              : action.data,
        },
      };
    case Types.SET_REGISTRATIONS_PER:
      return {
        ...state,
        registrationsPer: {
          ...state.registrationsPer,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.registrationsPer.data
              : action.data,
        },
      };
    case Types.SET_ITEMS_PER:
      return {
        ...state,
        itemsPer: {
          ...state.itemsPer,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.itemsPer.data : action.data,
        },
      };
    case Types.SET_COLLECTIONS_PER:
      return {
        ...state,
        collectionsPer: {
          ...state.collectionsPer,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.collectionsPer.data
              : action.data,
        },
      };
    case Types.SET_TOTAL_USERS_PER_LEVEL:
      return {
        ...state,
        totalUsersPerLevel: {
          ...state.totalUsersPerLevel,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.totalUsersPerLevel.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
