import React, { ReactElement } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Control, Controller } from 'react-hook-form';
import { IconButton } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

interface ITimePickerProps
  extends Omit<KeyboardTimePickerProps, 'onChange' | 'value'> {
  control: Control;
  rules: any;
  name: string;
  handleChange?: (data: Date | null) => void;
  controllerExtras?: any;
}

export default function TimePicker(props: ITimePickerProps): ReactElement {
  const {
    control,
    rules,
    handleChange,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={({ onChange, value }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            onChange={(date, value) => {
              handleChange && handleChange(date);
              onChange(date, value);
            }}
            {...other}
            ampm={false}
            // format={other.format || 'HH:mm'}
            variant={other.variant || 'inline'}
            inputVariant={other.inputVariant || 'outlined'}
            value={value}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            keyboardIcon={<AccessTimeIcon />}
          />
        </MuiPickersUtilsProvider>
      )}
      name={other.name}
      rules={rules}
      control={control}
      {...controllerExtras}
    />
  );
}
