// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request from 'utils/request';
import * as PathUtils from 'utils/path';
import { CollectionTypeEnum } from 'constants/typesConst';

/** fetch Collection Libraries per collection list */
export type CollectionLibraryPerCollectionData = {
  items: Array<
    Omit<Types.ILibraryCollection, 'collectionn' | 'user'> & {
      collectionn: {
        _id: string;
        title: string;
        type: CollectionTypeEnum;
      };
      user: {
        _id: string;
        email: string;
        firstName: string;
        lastName: string;
        username: string;
        settings: {
          namespaceSettings: {
            [key: string]: {
              reminderEnable: boolean;
              reminderTime: number;
              level: number;
              image: string;
              statistics: {
                currentLevelPoints: number;
                score: number;
                totalLevelPoints: number;
                userLevel: number;
                trophies: string[];
                itemWordsLearned: number;
                itemExercisesLearned: number;
                collectionCoursesLearned: number;
                itemBookModulesLearned: number;
              };
              practiceCollectionsPerDay: number;
              practiceItemsPerDay: number;
              practiceReminderEnable: boolean;
              collectionCoursesLearnedGoal: number | null;
              itemTricksLearnedGoal: number | null;
              itemWordsLearnedGoal: number;
              studyTimeDurationGoal: number;
              weekDaysStudy: string[];
              itemBookModulesLearnedGoal: number;
            };
          };
        };
      };
    }
  >;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface ICollectionLibraryPerCollectionInput {
  collectionId: string;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  page?: number;
  pageSize?: number;
}

export const fetchCollectionLibrariesPerCollection = async (
  payload: ICollectionLibraryPerCollectionInput
): Promise<Types.ResponseOutputV2<CollectionLibraryPerCollectionData>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },

      {
        key: 'orderType',
        value: payload.orderType,
      },
    ],
  });
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection-library/collection/${payload.collectionId}${query}`
  );

  return data;
};
