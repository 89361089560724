import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import reactLogger from 'utils/logger';
import * as TricksService from 'services/api/items';
import * as RoutineService from 'services/api/collections';
import { getState } from 'redux/store';
import { IState } from './reducer';
import { ReducerKeys } from 'redux/config';
import * as FileService from 'services/api/file';
import { FilePathTypes } from 'constants/filesConst';
import * as TagsService from 'services/api/tags';
import { TagTypes } from 'constants/tagsConst';
import * as UtilsService from 'services/api/utils';

/** AddTrick  */
interface IAddTrick {
  payload: RoutineService.IAddRoutineInput;
  onSuccess: () => void;
}

class AddRoutine implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_ROUTINE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_ROUTINE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_ROUTINE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IAddTrick): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await RoutineService.addRoutine(payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('AddRoutine:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Tags  */
class FetchLTagsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COLLECTION_TAGS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: IState['tagsList']['data']): IAction {
    return {
      type: Types.SET_COLLECTION_TAGS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COLLECTION_TAGS,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        // const response = await RoutineService.fetchCollectionTagsList();

        const response = await TagsService.fetchTagList({
          pageSize: Number.MAX_SAFE_INTEGER,
          type: TagTypes.Collection,
        });

        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchLTagsList:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Routine Details  */
class FetchRoutineDetails implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ROUTINE_DETAILS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: RoutineService.FetchRoutineDetailsData): IAction {
    return {
      type: Types.SET_ROUTINE_DETAILS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ROUTINE_DETAILS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(trickId: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await RoutineService.fetchRoutineDetails(trickId);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchRoutineDetails:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** EditRoutine  */
interface IEditRoutine {
  routineId: string;
  payload: RoutineService.IEditRoutineInput;
  onSuccess: () => void;
}

class EditRoutine implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_ROUTINE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.EDIT_ROUTINE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_ROUTINE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IEditRoutine): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await RoutineService.editRoutine(payload.routineId, payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('EditRoutine:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Tricks List */
interface IFetchTricksListInput {
  tags?: Array<string>;
  public?: boolean;
  globalSearch?: string;
  fetchMore?: boolean;
  createdBy?: string;
}

class FetchTricksList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRICKS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: TricksService.FetchTrickListData): IAction {
    return {
      type: Types.SET_TRICKS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRICKS,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(input: IFetchTricksListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const state = (getState() as any)[
          ReducerKeys.ROUTINE_DETAILS_REDUCER
        ] as IState;
        // Number.MAX_SAFE_INTEGER
        const response = await TricksService.fetchTrickList({
          pageSize: input.fetchMore
            ? (state.tricksList.data?.paging.currentSize ?? 0) + 5
            : 15,
          public: input.public,
          tag: input.tags?.join(','),
          globalSearch: input.globalSearch,
          createdBy: input.createdBy,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchTricksList:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** SetSelected Trick */
type ISelectedTrickInput = {
  element: {
    id: string;
    title: string;
    tags?: string[];
  };
  action: 'add' | 'remove';
} | null;

class SetSelectedTrick implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: IState['selectedItemsMap']): IAction {
    return {
      type: Types.SET_SELECTED_ITEMS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: ISelectedTrickInput): IAction {
    let map = (
      (getState() as any)[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState
    ).selectedItemsMap;

    let result = null;
    if (data === null) {
      result = null;
    } else if (data.action === 'remove') {
      // console.log('REMOVE map', map);

      delete map?.[data.element.id];
      result = map;
    } else if (data.action === 'add') {
      if (map) {
        map[data.element.id] = data.element;
      } else {
        map = {
          [data.element.id]: data.element,
        };
      }
      result = map;
    }

    // console.log('RESSLLL', result);
    return this.onSuccess(result);
  }
}

/** SetSelected Tags  */
type ISelectedTagsInput = string[] | null;

class SetSelectedTags implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: ISelectedTagsInput): IAction {
    return {
      type: Types.SET_SELECTED_TAGS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: ISelectedTagsInput): IAction {
    return this.onSuccess(data);
  }
}

/** Remove Routine  */
interface IRemoveRoutineInput {
  payload: RoutineService.IRemoveCollectionPayload;
  // routineId: string;
  onSuccess: () => void;
  // removeContent: boolean;
}

class RemoveRoutine implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_ROUTINE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_ROUTINE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_ROUTINE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IRemoveRoutineInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await RoutineService.removeRoutine(input.payload);
        input.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('RemoveRoutine:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

class Reset implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method Not Implemented');
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    throw new Error('Method Not Implemented');
  }

  action(): IAction {
    return this.onSuccess();
  }
}

/** Upload CSV file  */
interface IUploadCsvFileInput {
  collectionId: string;
  fileData: string;
  onFinish: (data: RoutineService.IInsertCsvCollectionItemsData | null) => void;
}

class UploadCsvFile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPLOAD_CSV_FILE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPLOAD_CSV_FILE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPLOAD_CSV_FILE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IUploadCsvFileInput): any {
    let data: RoutineService.IInsertCsvCollectionItemsData;

    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const result = await FileService.uploadSingleFile({
          file: payload.fileData,
          path: FilePathTypes.TempFiles,
        });
        data = (
          await RoutineService.insertCsvCollectionItems({
            collectionId: payload.collectionId,
            fileName: result.data.fileName,
          })
        ).data;
        await FileService.removeFile(result.data._id);
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('UploadCSVFile:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish(data ?? null);
      }
    };
  }
}

/** Collection bulk actions  */
interface ICollectionBulkActionsInput {
  onSuccess?: () => void;
  payload: RoutineService.CollectionsBulkActionsPayload;
}

class CollectionBulkActions implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.COLLECTION_BULK_ACTIONS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.COLLECTION_BULK_ACTIONS,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.COLLECTION_BULK_ACTIONS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: ICollectionBulkActionsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await RoutineService.collectionBulkActions(input.payload);
        input.onSuccess && input.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('CollectionBulkActions:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Item Tags actions  */
interface IFetchItemTagsInput {
  onSuccess?: () => void;
}

class FetchItemTags implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ITEM_TAGS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: IState['tagsItemList']['data']): IAction {
    return {
      type: Types.SET_ITEM_TAGS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ITEM_TAGS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IFetchItemTagsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await TagsService.fetchTagList({
          pageSize: Number.MAX_SAFE_INTEGER,
          type: TagTypes.Item,
        });
        input.onSuccess && input.onSuccess();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('CollectionBulkActions:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// /** Fetch Collection Prompts */
// interface IFFetchCollectionPromptsInput {
//   onSuccess?: () => void;
//   collectionId: string;
// }

// class FetchCollectionPrompts implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_COLLECTION_PROMPTS,
//       status: StateStatus.Pending,
//       data: null,
//     };
//   }
//   onSuccess(data: IState['collectionPrompts']['data']): IAction {
//     return {
//       type: Types.SET_COLLECTION_PROMPTS,
//       status: StateStatus.Success,
//       data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_COLLECTION_PROMPTS,
//       status: StateStatus.Failed,
//       data: null,
//     };
//   }

//   action(input: IFFetchCollectionPromptsInput): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         const response = await OpenAIService.fetchCollectionPrompt(
//           input.collectionId
//         );
//         input.onSuccess && input.onSuccess();
//         dispatch(this.onSuccess(response.data));
//       } catch (error) {
//         reactLogger.error('FetchCollectionPrompts:', error.message); // '<ClassName> Error: <error>'
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

/** IInitBookItemsInput  */
interface IInitBookItemsInput {
  // collectionId: string;
  onFinish: () => void;
  pdfFile: string;
  payload: Omit<TricksService.IUpdateBookItemsPayload, 'fileId'>;
}

class InitBookItems implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.INIT_BOOK_ITEMS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.INIT_BOOK_ITEMS,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.INIT_BOOK_ITEMS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IInitBookItemsInput): any {
    return async (dispatch: Dispatch<any>) => {
      let fileId = null;
      try {
        dispatch(this.onPending());
        const result = await FileService.uploadSingleFile({
          file: payload.pdfFile,
          path: FilePathTypes.TempFiles,
        });
        fileId = result.data._id;

        await TricksService.updateBookItems({
          ...payload.payload,
          fileId,
        });

        dispatch(this.onSuccess());
      } catch (error) {
        console.log('InitBookItems Error', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Generate Audio File  */
interface IGenerateAudioFilePayload {
  text: string;
  title: string;
  speakers?: NonNullable<
    UtilsService.IConvertTextToAudioPayload['dialogue']
  >['speakers'];
  onSuccess: (data: IState['generateAudioFile']['data']) => void;
}

class GenerateAudioFile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.GENERATE_AUDIO_FILE,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: IState['generateAudioFile']['data']): IAction {
    return {
      type: Types.GENERATE_AUDIO_FILE,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.GENERATE_AUDIO_FILE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IGenerateAudioFilePayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await UtilsService.convertTextToAudio({
          text: payload.text,
          title: payload.title,
          dialogue: payload.speakers
            ? {
                speakers: payload.speakers,
              }
            : undefined,
        });

        console.log('REsponse::::::', response);
        payload.onSuccess(response.data);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('Generate Audio File Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  generateAudioFileAction: (payload: IGenerateAudioFilePayload) =>
    new GenerateAudioFile().action(payload),
  initBookItemsAction: (payload: IInitBookItemsInput) =>
    new InitBookItems().action(payload),
  resetAction: () => new Reset().action(),
  fetchItemTagsAction: (payload: IFetchItemTagsInput) =>
    new FetchItemTags().action(payload),
  removeRoutineAction: (payload: IRemoveRoutineInput) =>
    new RemoveRoutine().action(payload),
  setSelectedTagsAction: (payload: ISelectedTagsInput) =>
    new SetSelectedTags().action(payload),
  setSelectedTrickeAction: (payload: ISelectedTrickInput) =>
    new SetSelectedTrick().action(payload),
  addRoutineAction: (payload: IAddTrick) => new AddRoutine().action(payload),
  editRoutineAction: (payload: IEditRoutine) =>
    new EditRoutine().action(payload),
  fetchTagsListAction: () => new FetchLTagsList().action(),
  fetchRoutineDetailsAction: (routineId: string) =>
    new FetchRoutineDetails().action(routineId),
  fetchTricksListAction: (payload: IFetchTricksListInput) =>
    new FetchTricksList().action(payload),
  uploadCsvFileAction: (payload: IUploadCsvFileInput) =>
    new UploadCsvFile().action(payload),
  collectionBulkActionsAction: (payload: ICollectionBulkActionsInput) =>
    new CollectionBulkActions().action(payload),
  // fetchCollectionPromptsAction: (payload: IFFetchCollectionPromptsInput) =>
  //   new FetchCollectionPrompts().action(payload),
};
