import { colors } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // overflow: 'auto',
  },
  container: {
    height: (props: any) => props?.height || undefined,
    minHeight: (props: any) => props?.minHeight || undefined,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pagingPaper: {
    marginTop: 6,
  },
  // stickyRight: {
  //   position: 'sticky',
  //   right: 0,
  //   background: alpha(colors.grey['50'], 1),
  //   // boxShadow: '8px 5px 8px grey',
  //   borderWidth: 0,
  //   display: 'flex',
  //   justifyContent: 'center',
  //   margin: 'auto',
  // },
  // stickyLeft: {
  //   position: 'sticky',
  //   left: 0,
  //   background: alpha(colors.grey['50'], 1),
  //   // boxShadow: '5px 2px 5px grey',
  //   borderWidth: 0,
  //   display: 'flex',
  //   justifyContent: 'center',
  // },

  stickyRight: {
    position: 'sticky',
    right: 0,
    boxShadow: '9px 7px 29px -10px rgba(153,153,153,1)',
    zIndex: 1,
    background: colors.grey['50'],
    top: 0,
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    boxShadow: '9px 7px 29px -10px rgba(153,153,153,1)',
    zIndex: 1,
    background: colors.grey['50'],
  },
}));

export default useStyles;
