import React from 'react';
import clsx from 'clsx';
import * as NavigationSelectors from '../../../../redux/reducers/gl_navigation_reducer/selectors';
import useStyles from '../styles';
import { Routes } from '../../../config';
import reactLogger from 'utils/logger';
import Configuration from '../configuration';
import { NavbarTypeEnum } from 'constants/navigationConst';

export default function SidebarContent() {
  const open = NavigationSelectors.useSidebarIsVisible();
  const classes = useStyles({});
  reactLogger.renderPage('SidebarContent');
  const navbarType = NavigationSelectors.useNavigationType();

  return (
    <React.Fragment>
      <div
        className={clsx({
          [classes.content]: true,
          // [classes.contentShift]: !Configuration.sidebar.enable || open,
          [classes.contentShift]:
            navbarType !== NavbarTypeEnum.SIDE_MENU || open,
        })}
      >
        <div className={classes.drawerHeader} />
        <RoutesWrapper />
      </div>
    </React.Fragment>
  );
}

const RoutesWrapper = React.memo(() => {
  return <Routes />;
});
