import React from 'react';
// import { View, TextInput, SafeAreaView, ViewStyle } from 'react-native';
// import { Colors, IconButton, useTheme } from 'react-native-paper';
// import { alpha } from 'utils/colors';
// import AntDesign from 'react-native-vector-icons/AntDesign';
// import Ionicons from 'react-native-vector-icons/Ionicons';
import useDebounce from 'hooks/useDebounce';
import TextField from '@material-ui/core/TextField';
import { Box, colors, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';

interface ISearchbarProps {
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  color?: string;
}

const Searchbar = ({ onChange, color }: ISearchbarProps) => {
  const [text, setText] = React.useState<string>('');
  const value = useDebounce(text, 300);
  const _color = color ?? colors.common.white;

  React.useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <Box style={{ flex: 1, paddingTop: '1rem', marginBottom: '0.5rem' }}>
      <TextField
        id="search-basic"
        placeholder="Search..."
        size="medium"
        value={text}
        margin="none"
        fullWidth
        onChange={({ target: { value } }) => {
          console.log('Check...', value);
          setText(value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment:
            text.length > 0 ? (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setText('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          style: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
        }}
      />
    </Box>
  );
};

export default React.memo(Searchbar, () => true);
