export enum LanguagesEnum {
  Greek = 'Greek',
  English = 'English',
  Italian = 'Italian',
  German = 'German',
  French = 'French',
  Spanish = 'Spanish',
  Portuguese = 'Portuguese',
  Russian = 'Russian',
  Swedish = 'Swedish',
  Ukrainian = 'Ukrainian',
  Polish = 'Polish',
  Dutch = 'Dutch',
  Serbian = 'Serbian',
  Croatian = 'Croatian',
  Bulgarian = 'Bulgarian',
  Slovenian = 'Slovenian',
  Hungarian = 'Hungarian',
  Turkish = 'Turkish',
}

export const TranslationsExceptDefaultList = Object.values(
  LanguagesEnum
).filter((el) => el !== LanguagesEnum.English);

export const DefaultLanguage = LanguagesEnum.English;

type IFlagsMap = {
  [key in LanguagesEnum]: string;
};

export const FlagsMap: IFlagsMap = {
  [LanguagesEnum.English]: require('assets/images/flags/english.png'),
  [LanguagesEnum.Greek]: require('assets/images/flags/greek.png'),
  [LanguagesEnum.Italian]: require('assets/images/flags/italian.png'),
  [LanguagesEnum.German]: require('assets/images/flags/german.png'),
  [LanguagesEnum.French]: require('assets/images/flags/france.png'),
  [LanguagesEnum.Spanish]: require('assets/images/flags/spanish.png'),
  [LanguagesEnum.Portuguese]: require('assets/images/flags/portuguese.png'),
  [LanguagesEnum.Russian]: require('assets/images/flags/russian.png'),
  [LanguagesEnum.Swedish]: require('assets/images/flags/swedish.png'),
  [LanguagesEnum.Ukrainian]: require('assets/images/flags/ukrainian.png'),
  [LanguagesEnum.Polish]: require('assets/images/flags/polish.png'),
  [LanguagesEnum.Dutch]: require('assets/images/flags/dutch.png'),
  [LanguagesEnum.Serbian]: require('assets/images/flags/serbian.png'),
  [LanguagesEnum.Croatian]: require('assets/images/flags/croatian.png'),
  [LanguagesEnum.Bulgarian]: require('assets/images/flags/bulgarian.png'),
  [LanguagesEnum.Slovenian]: require('assets/images/flags/slovenian.png'),
  [LanguagesEnum.Hungarian]: require('assets/images/flags/hungarian.png'),
  [LanguagesEnum.Turkish]: require('assets/images/flags/turkish.png'),
};

type IGoogleTranslateLans = {
  [key in LanguagesEnum]: string;
};

// language codes: https://cloud.google.com/translate/docs/languages
export const GoogleTranslateLans: IGoogleTranslateLans = {
  English: 'en',
  Greek: 'el',
  Italian: 'it',
  German: 'de',
  French: 'fr',
  Spanish: 'es',
  Portuguese: 'pt',
  Russian: 'ru',
  Swedish: 'sv',
  Ukrainian: 'uk',
  Polish: 'pl',
  Dutch: 'nl',
  Serbian: 'sr',
  Croatian: 'hr',
  Bulgarian: 'bg',
  Slovenian: 'sl',
  Hungarian: 'hu',
  Turkish: 'tr',
};

export default {
  ...LanguagesEnum,
  All: Object.values(LanguagesEnum),
};
