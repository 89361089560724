import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import { FormType } from '../configuration';

export interface IState {
  forgotPasswordStatus: StateStatus | null;
  verificationCodeStatus: StateStatus | null;
  selectedformType: FormType;
  email: string | null;
  resendEmailStatus: StateStatus | null;
}

const initialState: IState = {
  forgotPasswordStatus: null,
  verificationCodeStatus: null,
  selectedformType: 'EnterEmailForm',
  email: null,
  resendEmailStatus: null,
};

const ForgotPasswordReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // FORGOT_PASSOWRD
    case Types.FORGOT_PASSOWRD:
      return {
        ...state,
        forgotPasswordStatus: action.status || null,
        email: action.data === KEEP_THE_SAME ? state.email : action.data.email,
      };
    // VERIFICATION_CODE
    case Types.VERIFICATION_CODE:
      return {
        ...state,
        verificationCodeStatus: action.status || null,
      };
    // SET_SELECTED_FORM
    case Types.SET_SELECTED_FORM:
      return {
        ...state,
        selectedformType: action.data,
        email:
          (action.data as FormType) === 'EnterEmailForm' ? null : state.email,
      };
    // RESEND_EMAIL
    case Types.RESEND_EMAIL:
      return {
        ...state,
        resendEmailStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ForgotPasswordReducer;
