import React, { useState, useRef, useEffect } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

type IResponsiveHorizontalListMenuProps = {
  children: Array<any>;
  maxWidth: number | string;
};

const ResponsiveHorizontalListMenu: React.FC<IResponsiveHorizontalListMenuProps> =
  ({ children, maxWidth }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const checkOverflow = () => {
      if (listRef.current) {
        const { scrollWidth, clientWidth } = listRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };

    const scrollList = (direction: 'left' | 'right') => {
      if (listRef.current) {
        const { current: list } = listRef;
        const scrollAmount = 200; // customize this value based on your needs
        const newScrollPosition =
          direction === 'left'
            ? Math.max(scrollPosition - scrollAmount, 0)
            : Math.min(
                scrollPosition + scrollAmount,
                list.scrollWidth - list.clientWidth
              );

        list.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth',
        });
        setScrollPosition(newScrollPosition);
      }
    };

    useEffect(() => {
      checkOverflow();
      window.addEventListener('resize', checkOverflow);

      return () => {
        window.removeEventListener('resize', checkOverflow);
      };
    }, [children]);

    return (
      <div
        style={{
          maxWidth,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {/* {isOverflowing && scrollPosition > 0 && (
          <button onClick={() => scrollList('left')}>
            <ArrowBackIosIcon />
          </button>
        )} */}

        <IconButton
          style={{ marginRight: '0.2rem' }}
          disabled={!(isOverflowing && scrollPosition > 0)}
          onClick={() => scrollList('left')}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <div
          ref={listRef}
          style={{
            overflowX: 'auto',
            display: 'flex',
            whiteSpace: 'nowrap',
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none', // For Internet Explorer and Edge
            // '&::-webkit-scrollbar': {
            //   display: 'none', // For Chrome, Safari, and Opera
            // },
          }}
        >
          {children.map((child, index) => (
            <div
              key={index}
              style={{ display: 'inline-block', marginRight: '8px' }}
            >
              {child}
            </div>
          ))}
        </div>
        {/* {isOverflowing &&
          scrollPosition <
            (listRef.current as any)?.scrollWidth -
              (listRef.current as any)?.clientWidth && (
            <button onClick={() => scrollList('right')}>
              <ArrowForwardIosIcon />
            </button>
          )} */}

        <IconButton
          style={{ marginLeft: '0.2rem' }}
          disabled={
            !(
              isOverflowing &&
              scrollPosition <
                (listRef.current as any)?.scrollWidth -
                  (listRef.current as any)?.clientWidth
            )
          }
          onClick={() => scrollList('right')}
        >
          <NavigateNextIcon />
        </IconButton>
      </div>
    );
  };

export default ResponsiveHorizontalListMenu;
