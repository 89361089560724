import React from 'react';
import Content from './components/Content';
import { Box, Button, Checkbox, IconButton, Theme } from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { CollectionTypeEnum, ItemTypeEnum } from 'constants/typesConst';
import store from 'redux/store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import type { IColLibrariesPerCollectionProps } from './types';
import Footer from './components/ModalFooter';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import useTextSelector from 'hooks/useTextSelector';
import { AppTypes } from 'constants/namespaceConst';
import IsolateColLibrariesPerCollection from './components/IsolateColLibrariesPerCollection';

const ColLibrariesPerCollectionModal = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_COLLECTION_LIBRARIES_PER_COLLECTION}
      showExitIcon={true}
      modalProps={{ fullWidth: true, maxWidth: 'lg', fullScreen: matches }}
      renderHeader={(params: IColLibrariesPerCollectionProps) => (
        <Header {...params} />
      )}
      renderContent={(params: IColLibrariesPerCollectionProps) => {
        return <Content {...params} />;
      }}
      renderFooter={(params: IColLibrariesPerCollectionProps) => (
        <Footer
          {...params}
          handleCancelAction={closeColLibrariesPerCollectionModal}
        />
      )}
    />
  );
};

const Header = (props: IColLibrariesPerCollectionProps) => {
  return (
    <Box alignItems="center" display="flex">
      <TranslatedText defaultText={props.title} />
    </Box>
  );
};

export const openColLibrariesPerCollectionModal = (
  params: IColLibrariesPerCollectionProps
) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_LIBRARIES_PER_COLLECTION,
      params,
    })
  );
};

export const closeColLibrariesPerCollectionModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_LIBRARIES_PER_COLLECTION,
    })
  );
};

export default withReducer(
  ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER
)(ColLibrariesPerCollectionModal);

export { IsolateColLibrariesPerCollection };
