// import PageLayout from 'components/app_common/PageLayout';
import React from 'react';
import ItemDetailsContent from './components/Content';
import ContentModal from './components/ContentModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import store from 'redux/store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import type { TricksDetailsModalParams } from './components/ContentModal';

export { ItemDetailsContent };

export type { TricksDetailsModalParams };

export const openItemDetailsModal = (params: TricksDetailsModalParams) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_TRICK_DETAILS,
      params,
    })
  );
};

export const closeItemDetailsModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_TRICK_DETAILS,
    })
  );
};

export default ContentModal;
