import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useAddRoutineStatus */
export const useAddRoutineStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).addRoutineStatus
  );
};

/* selector: useUploadCsvFile */
export const useUploadCsvFile = (): IState['uploadCsvFile'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).uploadCsvFile
  );
};

/* selector: useTagsList */
export const useTagsList = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).tagsList
  );
};

/* selector: useRoutineDetails */
export const useRoutineDetails = (): IState['routineDetails'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).routineDetails
  );
};

/* selector: useEditRoutineStatus */
export const useEditRoutineStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).editRoutineStatus
  );
};

/* selector: useTricksList */
export const useTricksList = (): IState['tricksList'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).tricksList
  );
};

/* selector: useItemIsSelected */
export const useItemIsSelected = (itemid: string) => {
  const res = useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).selectedItemsMap?.[
        itemid
      ]
  );
  return Boolean(res);
};

/* selector: useSelectedTags */
export const useSelectedTags = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).selectedTags
  );
};

/* selector: useRemoveRoutineStatus */
export const useRemoveRoutineStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).removeRoutineStatus
  );
};

/* selector: useCollectionBulkActionsStatus */
export const useCollectionBulkActionsStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState)
        .collectionBulkActionsStatus
  );
};

/* selector: useItemTags */
export const useItemTags = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).tagsItemList
  );
};

/* selector: useInitBookItemsStatus */
export const useInitBookItemsStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).initBookItemsStatus
  );
};

/* selector: useGenerateAudioFile  */
export const useGenerateAudioFile = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState).generateAudioFile
  );
};
