import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import { IFormData } from '../../types';
import { BlankSplitItem, PauseSplitItem } from '../../configuration';
import PauseIcon from '@material-ui/icons/Pause';

// .
interface IExerciseActionsProps {
  setValue: (name: string, value: string) => void;
  getValues: () => IFormData;
  configuration: {
    blank: boolean;
    bold: boolean;
    underline: boolean;
    pause: boolean;
  };
}

const ExerciseActions = React.forwardRef<any, IExerciseActionsProps>(
  ({ setValue, getValues, configuration }, exerciseRef: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {configuration.blank && (
          <Tooltip style={{ marginRight: '1rem' }} title="Add blank">
            <Button
              variant="outlined"
              onClick={() => {
                exerciseRef.current && exerciseRef.current.focus();
                setValue(
                  'title',
                  `${
                    getValues().title ? `${getValues().title} ` : ''
                  }${BlankSplitItem} `
                );
              }}
              size="small"
            >
              <SpaceBarIcon />
            </Button>
          </Tooltip>
        )}
        {configuration.bold && (
          <Tooltip style={{ marginRight: '1rem' }} title="Bold">
            <Button
              variant="outlined"
              onClick={() => {
                exerciseRef.current && exerciseRef.current.focus();
                setValue(
                  'title',
                  `${getValues().title ? `${getValues().title} ` : ''}<b></b>`
                );
              }}
              size="small"
            >
              <FormatBoldIcon />
            </Button>
          </Tooltip>
        )}
        {configuration.underline && (
          <Tooltip style={{ marginRight: '1rem' }} title="Underline">
            <Button
              variant="outlined"
              onClick={() => {
                exerciseRef.current && exerciseRef.current.focus();
                setValue(
                  'title',
                  `${getValues().title ? `${getValues().title} ` : ''}<u></u>`
                );
              }}
              size="small"
            >
              <FormatUnderlinedIcon />
            </Button>
          </Tooltip>
        )}
        {configuration.pause && (
          <Tooltip style={{ marginRight: '1rem' }} title="Pause">
            <Button
              variant="outlined"
              onClick={() => {
                exerciseRef.current && exerciseRef.current.focus();
                setValue(
                  'title',
                  `${
                    getValues().title ? `${getValues().title} ` : ''
                  }${PauseSplitItem}`
                );
              }}
              size="small"
            >
              <PauseIcon />
            </Button>
          </Tooltip>
        )}
      </div>
    );
  }
);

export default ExerciseActions;
