import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import { ThemesModeEnum } from 'constants/index';
import { IState } from './reducer';

export const useSelectTheme = (): ThemesModeEnum => {
  return useSelector(
    (state: any) => state[ReducerKeys.THEME_REDUCER].themeMode
  );
};

export const useIsMobileAppAccess = (): boolean => {
  return useSelector(
    (state: any) => state[ReducerKeys.THEME_REDUCER].accessFromMobileApp
  );
};

export const usePalleteId = () => {
  return useSelector(
    (state: any) => (state[ReducerKeys.THEME_REDUCER] as IState).palleteId
  );
};

export const useThemeSettingsPanelOpen = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.THEME_REDUCER] as IState).themeSettingsPanelIsOpen
  );
};
