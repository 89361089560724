import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { IState } from './reducer';

/* selector: useRemoveFileStatus */
export const useRemoveFileStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.UPLOADED_FILES_REDUCER] as IState).removeFileStatus
  );
};

/* selector: useUploadFileStatus */
export const useUploadFileStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.UPLOADED_FILES_REDUCER] as IState).uploadFileStatus
  );
};

/* selector: useFilesList */
export const useFilesList = (): IState['filesList'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.UPLOADED_FILES_REDUCER] as IState).filesList
  );
};

/* selector: useEditFileStatus */
export const useEditFileStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.UPLOADED_FILES_REDUCER] as IState).editFileStatus
  );
};

/* selector: useUploadFileFromURLStatus */
export const useUploadFileFromURLStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.UPLOADED_FILES_REDUCER] as IState)
        .uploadFileFromURLStatus
  );
};
