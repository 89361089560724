import Environment from '../../config/environmentConfig';
import axios from 'axios';
import { getState } from '../../redux/store';
import { loadFromLocalStorage } from 'redux/utils/storage';

const Configuration = {
  authTokenParser: authTokenParser,
  executeRequestParser: executeRequestParser,
  AUTH_HEADER_KEY: 'Authorization',
  NAMESPACE_HEADER_KEY: 'Namespace',
};

interface IParserOutput {
  refreshToken: string;
  token: string;
}

/***  change this block  ************************/
function authTokenParser(requestResponse: IParserData): IParserOutput {
  return {
    refreshToken: requestResponse.data.refreshToken,
    token: requestResponse.data.accessToken,
  };
}

// refreshTokenAction
interface IParserData {
  data: IRefreshTokenData;
}

interface IRefreshTokenData {
  accessToken: string;
  refreshToken: string;
  userid: string;
}

interface IParser {
  data: {
    data: IRefreshTokenData;
  };
}

function executeRequestParser(): Promise<IParser> {
  const { refreshToken, token } = loadFromLocalStorage();
  // const refreshToken = getState().AuthReducer.refreshToken;
  return axios.post(`${Environment.API_BASE_URL}/api/auth/refresh-token`, {
    accessToken: token,
    refreshToken,
  });
}

/********************************************** */

export default Configuration;
