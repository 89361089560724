import React from 'react';
import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import _ from 'lodash';
import { AuthRoles } from '../../../constants';
import useShallowEqualSelector from '../../../hooks/useShallowEqualsSelector';
import { Roles } from 'constants/authRolesConst';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';
import { arraysHasCommonElements } from 'utils/arrays';

export const useSelectUserRoles = (): Array<Roles> => {
  const defaultRole = React.useMemo(() => [AuthRoles.Unknown], []);
  return (
    useSelector((state: any) => state[ReducerKeys.AUTH_REDUCER].roles) ||
    defaultRole
  );
};

export const useSelectUserData = (): Omit<IState['data'], 'settings'> => {
  const result = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.AUTH_REDUCER] as IState).data
  );
  return result;
};

export const useSelectAuthStatus = () => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].status
  );
  return status;
};

export const useSelectUserDataStatus = (): StateStatus | null => {
  return useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].fetchUserDataStatus
  );
};

export const useVerifySession = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.AUTH_REDUCER] as IState).data.twoStepVerification &&
      !(state[ReducerKeys.AUTH_REDUCER] as IState).data.sessionIsVerified
  );
};

export const useHasPassword = () => {
  return useSelector(
    (state: any) => (state[ReducerKeys.AUTH_REDUCER] as IState).data.hasPassword
  );
};

export const useIsLogin = () => {
  const isLogin = useSelector(
    (state: any) =>
      arraysHasCommonElements(
        AuthRoles.Registered,
        state[ReducerKeys.AUTH_REDUCER].roles ?? []
      ) && (state[ReducerKeys.AUTH_REDUCER] as IState).data.sessionIsVerified
  );
  return isLogin;
};

export const useSelectedPortalNamespace = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.AUTH_REDUCER] as IState).data.accessPortal
        ?.selectedNamespace
  );
};
