// import { Box } from '@material-ui/core';
// import React from 'react';

// interface IEtymologyEvolutionProps {}

// const EtymologyEvolution = ({}: IEtymologyEvolutionProps) => {
//   return <Box>

//   </Box>;
// };

// export default EtymologyEvolution;

import React from 'react';
import {
  Button,
  ButtonProps,
  Box,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import {
  AppGroupText,
  AppMultipleSelect,
  AppSelect,
  AppDatePicker,
  AppTextField,
  AppRadioGroup,
  AppCommon,
} from 'components/molecules/FormControls';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import GridLayout from 'components/molecules/GridLayout';
import _ from 'lodash';
import TranslatedText from 'components/atoms/TranslatedText';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { IFormData } from '../../types';
import DataList from 'components/molecules/DataList';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { ItemTypeEnum } from 'constants/typesConst';

interface DefaultDataWrapperProps {
  control: any;
  errors: ReturnType<typeof useForm>['formState']['errors'];
  fieldName: string;
  setValue: ReturnType<typeof useForm>['setValue'];
  removeButtonEnabled: boolean;
  addButtonEnabled: boolean;
  itemType: ItemTypeEnum;
}

export const DefaultData = { example: '' };

const SortableListContainer = SortableContainer(
  ({
    errors,
    control,
    fieldName,
    arrayFields,
    removeButtonEnabled,
    addButtonEnabled,
    itemType,
  }: DefaultDataWrapperProps & {
    arrayFields: ReturnType<typeof useFieldArray>;
  }) => {
    const { fields, append, prepend, remove, swap, move, insert } = arrayFields;

    return (
      <React.Fragment>
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          {fields?.map((element, index) => (
            <SortableItem
              remove={remove}
              key={element.id ?? index}
              control={control}
              element={element as any}
              _index={index}
              index={index}
              errors={errors}
              fieldName={fieldName}
              removeButtonEnabled={removeButtonEnabled}
              addButtonEnabled={addButtonEnabled}
              itemType={itemType}
            />
          ))}
        </div>

        {addButtonEnabled && (
          <Button
            onClick={() => {
              append({
                ...DefaultData,
              });
            }}
            style={{ textTransform: 'none', marginTop: '1rem' }}
            variant="text"
            color="secondary"
          >
            <TranslatedText defaultText="+ Add new" />
          </Button>
        )}
      </React.Fragment>
    );
  }
);

interface ISortableItemProps {
  errors: ReturnType<typeof useForm>['errors'];
  control: Control<any>;
  element: IFormData['examples'][0];
  remove: ReturnType<typeof useFieldArray>['remove'];
  _index: number;
  fieldName: string;
  removeButtonEnabled: boolean;
  addButtonEnabled: boolean;
  itemType: ItemTypeEnum;
  index: number;
}

const SortableItem = SortableElement(
  ({
    element,
    errors,
    remove,
    _index,
    fieldName,
    control,
    removeButtonEnabled,
    itemType,
    index,
  }: ISortableItemProps) => {
    const dispatch = useDispatch();

    const getId = (field: string) => `${fieldName}[${_index}].${field}`;

    return (
      // key is important
      <Paper
        key={index}
        // display="flex"
        // alignItems="center"
        variant="outlined"
        style={{
          marginTop: '1rem',
          display: 'flex',
          alignItems: 'center',
          padding: '0.7rem',
        }}
      >
        <div style={{ cursor: 'move' }}>
          <DragIndicatorIcon />
        </div>
        <GridLayout
          elements={[
            {
              id: getId('language'),
              element: (
                <AppTextField
                  variant="outlined"
                  margin="none"
                  size="small"
                  placeholder={'Add language..'}
                  fullWidth
                  id={getId('language')}
                  name={getId('language')}
                  autoComplete={getId('language')}
                  control={control}
                  error={Boolean(_.get(errors, getId('language')))}
                  helperText={_.get(errors, getId('language'))}
                  rules={{
                    required: <AppCommon.RequiredField />,
                  }}
                  controllerExtras={{
                    defaultValue: (element as any)?.['language'],
                  }}
                />
              ),
              size: 2, // total 12
            },
            {
              id: getId('word'),
              element: (
                <AppTextField
                  variant="outlined"
                  margin="none"
                  placeholder={'Add word..'}
                  fullWidth
                  size="small"
                  id={getId('word')}
                  name={getId('word')}
                  autoComplete={getId('word')}
                  control={control}
                  error={Boolean(_.get(errors, getId('word')))}
                  helperText={_.get(errors, getId('word'))}
                  rules={{
                    required: <AppCommon.RequiredField />,
                  }}
                  controllerExtras={{
                    defaultValue: (element as any)?.['word'],
                  }}
                />
              ),
              size: 2, // total 12
            },
            {
              id: getId('meaning'),
              element: (
                <AppTextField
                  variant="outlined"
                  margin="none"
                  placeholder={'Add meaning..'}
                  fullWidth
                  size="small"
                  id={getId('meaning')}
                  name={getId('meaning')}
                  autoComplete={getId('meaning')}
                  control={control}
                  error={Boolean(_.get(errors, getId('meaning')))}
                  helperText={_.get(errors, getId('meaning'))}
                  rules={{
                    required: <AppCommon.RequiredField />,
                  }}
                  controllerExtras={{
                    defaultValue: (element as any)?.['meaning'],
                  }}
                />
              ),
              size: 3, // total 12
            },
            {
              id: getId('etymology'),
              element: (
                <AppTextField
                  variant="outlined"
                  margin="none"
                  placeholder={'Add etymology..'}
                  fullWidth
                  size="small"
                  id={getId('etymology')}
                  name={getId('etymology')}
                  autoComplete={getId('etymology')}
                  control={control}
                  error={Boolean(_.get(errors, getId('etymology')))}
                  helperText={_.get(errors, getId('etymology'))}
                  rules={{
                    required: <AppCommon.RequiredField />,
                  }}
                  controllerExtras={{
                    defaultValue: (element as any)?.['etymology'],
                  }}
                />
              ),
              size: 3, // total 12
            },
            {
              id: getId('partOfSpeech'),
              element: (
                <AppTextField
                  variant="outlined"
                  margin="none"
                  placeholder={'Add part Of Speech..'}
                  fullWidth
                  size="small"
                  id={getId('partOfSpeech')}
                  name={getId('partOfSpeech')}
                  autoComplete={getId('partOfSpeech')}
                  control={control}
                  error={Boolean(_.get(errors, getId('partOfSpeech')))}
                  helperText={_.get(errors, getId('partOfSpeech'))}
                  rules={{
                    required: <AppCommon.RequiredField />,
                  }}
                  controllerExtras={{
                    defaultValue: (element as any)?.['partOfSpeech'],
                  }}
                />
              ),
              size: 2, // total 12
            },
          ]}
        />
        <>
          {removeButtonEnabled && (
            <IconButton
              style={{
                color: 'red',
                padding: 0,
                margin: 0,
              }}
              onClick={() => remove(_index)}
            >
              <RemoveCircleOutlineOutlinedIcon
                style={{ cursor: 'pointer', zIndex: 400 }}
              />
            </IconButton>
          )}
        </>
      </Paper>
    );
  }
);

const FieldWrapper = (props: DefaultDataWrapperProps) => {
  const arrayFields = useFieldArray({
    control: props.control, // control props comes from useForm (optional: if you are using FormContext)
    name: props.fieldName, // unique name for your Field Array
  });

  return (
    <SortableListContainer
      {...props}
      distance={5}
      arrayFields={arrayFields}
      onSortEnd={({ oldIndex, newIndex }) => {
        props.setValue(
          props.fieldName,
          arrayMove(arrayFields.fields, oldIndex, newIndex)
        );
      }}
    />
  );
};

export default FieldWrapper;
