import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const FETCH_NAMESPACE_DETAILS = typeCreatorV2(
  'NamespaceMembersReducer',
  'FetchNamespaceDetails'
);

export const EDIT_NAMESPACE = typeCreatorV2(
  'NamespaceMembersReducer',
  'EditNamespace'
);
