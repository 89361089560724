import { combineReducers } from 'redux';
import AuthReducer from './gl_auth_reducer/reducer';
import TranslationReducer from './gl_translations_reducer/reducer';
import NavigationReducer from './gl_navigation_reducer/reducer';
import ThemeReducer from './gl_theme_reducer/reducer';
import ModalReducer from './gl_modal_reducer/reducer';
import CommonReducer from './gl_common_reducer/reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { LOGOUT_TYPE } from './gl_auth_reducer/types';

// ASYNC REDUCERS
import RegisterReducer from 'scenes/auth_Register_page/store/reducer';
import UserProfileReducer from 'scenes/Profile_page/store/reducer';
import ResetPasswordReducer from 'scenes/auth_ResetPassword_page/store/reducer';
import ForgotPasswordReducer from 'scenes/auth_ForgotPassword_page/store/reducer';
import ConfirmAccountReducer from 'scenes/auth_ConfirmAccount_page/store/reducer';
import UsersReducer from 'scenes/Users_page/store/reducer';
import LogsReducer from 'scenes/Logs_page/store/reducer';
import ActivitiesReducer from 'scenes/Activities_page/store/reducer';
import NotificationReducer from 'scenes/Notification_page/store/reducer';
import VerifySessionReducer from 'scenes/auth_ConfirmSession_page/store/reducer';
import ForceChangePasswordResetReducer from 'scenes/auth_ForceChangePasswordReset_page/store/reducer';
import ForceChangePasswordVerifyReducer from 'scenes/auth_ForceChangePasswordVerify_page/store/reducer';
import SessionReducer from 'scenes/Session_page/store/reducer';
import UploadedFilesReducer from 'components/compounds/UploadedFilesModal/store/reducer';

// new reducers
import TricksReducer from 'scenes/Items_page/store/reducer';
import TrickDetailsReducer from 'components/compounds/ItemDetailsModal/store/reducer';
import RoutinesReducer from 'scenes/Collections_page/store/reducer';
import RoutineDetailsReducer from 'components/compounds/CollectionDetailsModal/store/reducer';
import TagsReducer from 'scenes/Tags_page/store/reducer';
import TagDetailsReducer from 'components/compounds/TagDetailsModal/store/reducer';
import NamespacesReducer from 'scenes/Namespaces_page/store/reducer';
import NamespaceDetailsReducer from 'components/compounds/NamespaceDetailsModal/store/reducer';
import SystemSettingsReducer from 'scenes/SystemSettings_page/store/reducer';
import DashboardReducer from 'scenes/Dashboard_page/store/reducer';
import PaymentsReducer from 'scenes/Payments_page/store/reducer';
import DiscoverImagesReducer from 'components/compounds/DiscoverImagesModal/store/reducer';
import ReviewEntityReducer from 'scenes/ReviewEntity_page/store/reducer';
import TextToAudioModalReducer from 'components/app_common/TextToAudioModal/store/reducer';
import NamespaceMembersReducer from 'components/compounds/NamespaceMembersModal/store/reducer';
import RankingReducer from 'scenes/Ranking_page/store/reducer';
import StudentsReducer from 'scenes/Students_page/store/reducer';
import UserNamespaceSettingsReducer from 'components/compounds/UserNamespaceSettingsModal/store/reducer';
import TasksReducer from 'scenes/Tasks_page/store/reducer';
import TaskDEtailsModalReducer from 'components/compounds/TaskDetailsModal/store/reducer';
import StudentsSelectorModalReducer from 'components/compounds/StudentsSelectorModal/store/reducer';
import CollectionSelectorModalReducer from 'components/compounds/CollectionSelectorModal/store/reducer';
import ColLibrariesPerCollectionReducer from 'components/compounds/ColLibrariesPerCollectionModal/store/reducer';
import CollectionItemsPerStudentReducer from 'components/compounds/CollectionItemsPerStudentModal/store/reducer';
import UrlAliasReducer from 'scenes/UrlAlias_page/store/reducer';
import UrlAliasDetailsReducer from 'components/compounds/UrlAliasDetailsModal/store/reducer';

import { ReducerKeys } from '../config';
import _ from 'lodash';

// const authPersistConfig = {
//   key: ReducerKeys.AUTH_REDUCER,
//   storage: storage,
//   blacklist: ['status'],
// };

const translationPersistConfig = {
  key: ReducerKeys.TRANSL_REDUCER,
  storage: storage,
};

const navigationPersistConfig = {
  key: ReducerKeys.NAVIGATION_REDUCER,
  storage: storage,
};

const themePersistConfig = {
  key: ReducerKeys.THEME_REDUCER,
  storage: storage,
  // blacklist: ['accessFromMobileApp'],
};

export const asyncReducers = {
  [ReducerKeys.REGISTER_REDUCER]: RegisterReducer,
  [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
  [ReducerKeys.RESET_PASSOWRD_REDUCER]: ResetPasswordReducer,
  [ReducerKeys.FORGOT_PASSWORD_REDUCER]: ForgotPasswordReducer,
  [ReducerKeys.CONFIRM_ACCOUNT_REDUCER]: ConfirmAccountReducer,
  [ReducerKeys.USERS_REDUCER]: UsersReducer,
  [ReducerKeys.LOGS_REDUCER]: LogsReducer,
  [ReducerKeys.ACTIVITIES_REDUCER]: ActivitiesReducer,
  [ReducerKeys.NOTIFICATION_REDUCER]: NotificationReducer,
  [ReducerKeys.CONFIRM_SESSION_REDUCER]: VerifySessionReducer,
  [ReducerKeys.SESSION_REDUCER]: SessionReducer,
  [ReducerKeys.FORCE_CHANGE_PASSWORD_RESET_REDUCER]:
    ForceChangePasswordResetReducer,
  [ReducerKeys.FORCE_CHANGE_PASSWORD_VERIFY_REDUCER]:
    ForceChangePasswordVerifyReducer,
  [ReducerKeys.TRICKS_REDUCER]: TricksReducer,
  [ReducerKeys.TRICK_DETAILS_REDUCER]: TrickDetailsReducer,
  [ReducerKeys.ROUTINES_REDUCER]: RoutinesReducer,
  [ReducerKeys.ROUTINE_DETAILS_REDUCER]: RoutineDetailsReducer,
  [ReducerKeys.TAGS_REDUCER]: TagsReducer,
  [ReducerKeys.TAG_DETAILS_REDUCER]: TagDetailsReducer,
  [ReducerKeys.NAMESPACES_REDUCER]: NamespacesReducer,
  [ReducerKeys.NAMESPACE_DETAILS_REDUCER]: NamespaceDetailsReducer,
  [ReducerKeys.SYSTEM_SETTINGS_REDUCER]: SystemSettingsReducer,
  [ReducerKeys.UPLOADED_FILES_REDUCER]: UploadedFilesReducer,
  [ReducerKeys.DASHBOARD_REDUCER]: DashboardReducer,
  [ReducerKeys.PAYMENTS_REDUCER]: PaymentsReducer,
  [ReducerKeys.DISCOVER_IMAGES_REDUCER]: DiscoverImagesReducer,
  [ReducerKeys.REVIEW_ENTITY_REDUCER]: ReviewEntityReducer,
  [ReducerKeys.TEXT_TO_AUDIO_REDUCER]: TextToAudioModalReducer,
  [ReducerKeys.NAMESPACE_MEMBERS_REDUCER]: NamespaceMembersReducer,
  [ReducerKeys.RANKING_REDUCER]: RankingReducer,
  [ReducerKeys.STUDENTS_REDUCER]: StudentsReducer,
  [ReducerKeys.USER_NAMESPACE_SETTINGS_REDUCER]: UserNamespaceSettingsReducer,
  [ReducerKeys.ASSIGNED_TASKS_REDUCER]: TasksReducer,
  [ReducerKeys.TASK_DETAILS_REDUCER]: TaskDEtailsModalReducer,
  [ReducerKeys.STUDENTS_SELECTOR_REDUCER]: StudentsSelectorModalReducer,
  [ReducerKeys.COLLECTION_SELECTOR_REDUCER]: CollectionSelectorModalReducer,
  [ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER]:
    ColLibrariesPerCollectionReducer,
  [ReducerKeys.COLLECTION_ITEMS_PER_STUDENT_REDUCER]:
    CollectionItemsPerStudentReducer,
  [ReducerKeys.URL_ALIAS_REDUCER]: UrlAliasReducer,
  [ReducerKeys.URL_ALIAS_DETAILS_REDUCER]: UrlAliasDetailsReducer,
};

export const staticReducers = {
  // global
  [ReducerKeys.AUTH_REDUCER]: AuthReducer,
  [ReducerKeys.TRANSL_REDUCER]: persistReducer(
    translationPersistConfig,
    TranslationReducer
  ),
  [ReducerKeys.NAVIGATION_REDUCER]: persistReducer(
    navigationPersistConfig,
    NavigationReducer
  ),
  [ReducerKeys.THEME_REDUCER]: persistReducer(themePersistConfig, ThemeReducer),
  [ReducerKeys.MODAL_REDUCER]: ModalReducer,
  [ReducerKeys.COMMON_REDUCER]: CommonReducer,
};

// export default function createReducer(asyncReducers = {}, store = {}) {
//   return combineReducers({
//     ...staticReducers,
//     ...asyncReducers,
//   });
// }

// export default
function createReducer(asyncReducers = {}, store = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

const rootReducer =
  (asyncReducers = {}, store = {}) =>
  (state: any, action: any) => {
    if (action.type === LOGOUT_TYPE) {
      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:root');
      console.log('LOGOUT USER !!!!!!!!!!');

      return createReducer(asyncReducers, store)(undefined, action);
    }
    return createReducer(asyncReducers, store)(state, action);
  };

export default rootReducer;
