import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from '../../../hooks/useShallowEqualsSelector';
import { ModalIDs } from './types';
import { IState } from './reducer';

interface IuseModalDataOutput {
  params: any;
  visible: boolean;
}

export const useModalData = (modalID: ModalIDs): IuseModalDataOutput => {
  const data: IuseModalDataOutput = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.MODAL_REDUCER].modals[modalID]
  );
  return data;
};

export const useHasModals = () => {
  return useSelector((state: any) => {
    const length =
      (state[ReducerKeys.MODAL_REDUCER] as IState).order.length ?? 0;
    return length > 0;
  });
};
