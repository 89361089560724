interface IOptions {
  errorCode: string;
  statusCode: number;
}

export class ClientError extends Error {
  readonly errorCode?: string;
  readonly statusCode?: number;

  constructor(message: string, options?: IOptions) {
    super(message);
    this.errorCode = options?.errorCode;
    this.statusCode = options?.statusCode;
    this.message = message;
  }
}
