export enum DurationType {
  LastXDays = 'LastXDays',
  PerMonthLastYear = 'PerMonthLastYear',
}

export const DurationNamingMap: {
  [key in DurationType]: string;
} = {
  LastXDays: 'Last 30 days',
  PerMonthLastYear: 'Per Month',
};
