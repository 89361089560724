import {
  Box,
  Button,
  ButtonGroup,
  colors,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { IState } from '../../store/reducer';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { withVerification } from 'components/molecules/ModalVerifyAction';
import BackupIcon from '@material-ui/icons/Backup';
import { IModalParams } from '../../types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DiscoverImagesActions from '../../store/actions';

interface ITableActionsProps extends IModalParams {
  params: NonNullable<IState['imagesList']['data']>['items'][0];
  small?: boolean;
}

const TableActions = React.memo((props: ITableActionsProps) => {
  return (
    <ButtonGroup size="small">
      <OptionsButton {...props} />
    </ButtonGroup>
  );
});

export const OptionsButton = ({
  params,
  handleSelectedFile,
  small,
}: ITableActionsProps) => {
  const dispatch = useDispatch();

  return (
    <Tooltip title="Add File">
      <IconButton
        size={small ? 'small' : 'medium'}
        color="primary"
        onClick={() => {
          handleSelectedFile(params, (payload, onFinish) => {
            dispatch(
              DiscoverImagesActions.uploadImageFromExternalAction({
                payload: {
                  imageUrl: payload.imageUrl,
                  name: payload.name,
                },
                onSuccess: onFinish,
              })
            );
          });
        }}
      >
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default TableActions;
