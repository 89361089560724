import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TricksService from 'services/api/items';
import { IWordInfoData } from 'services/api/utils';
import * as TagsService from 'services/api/tags';

export interface IState {
  tagsList: {
    status: StateStatus | null; // data: TricksService.FetchTrickTagsData | null;
    data: TagsService.FetchTagListData | null;
  };
  addTrickStatus: StateStatus | null;
  trickDetails: {
    status: StateStatus | null;
    data: TricksService.FetchTrickDetailsData | null;
  };
  editTrickStatus: StateStatus | null;
  removeTrickStatus: StateStatus | null;
  wordInfo: {
    status: StateStatus | null;
    data: IWordInfoData | null;
  };
}

const initialState: IState = {
  tagsList: {
    status: null,
    data: null,
  },
  addTrickStatus: null,
  trickDetails: {
    status: null,
    data: null,
  },
  editTrickStatus: null,
  removeTrickStatus: null,
  wordInfo: {
    status: null,
    data: null,
  },
  // itemPrompts: {
  //   status: null,
  //   data: null,
  // },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    // case Types.SET_ITEM_PROMPTS:
    //   return {
    //     ...state,
    //     itemPrompts: {
    //       status: action.status ?? null,
    //       data: action.data,
    //     },
    //   };
    case Types.SET_TAGS:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.tagsList.data : action.data,
        },
      };
    case Types.ADD_TRICK:
      return {
        ...state,
        addTrickStatus: action.status || null,
      };
    case Types.SET_TRICK_DETAILS:
      return {
        ...state,
        trickDetails: {
          ...state.trickDetails,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.EDIT_TRICK:
      return {
        ...state,
        editTrickStatus: action.status || null,
      };
    case Types.REMOVE_TRICK:
      return {
        ...state,
        removeTrickStatus: action.status || null,
      };
    case Types.WORD_INFO:
      return {
        ...state,
        wordInfo: {
          ...state.wordInfo,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
