import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as NavigationSelectors from '../../../../redux/reducers/gl_navigation_reducer/selectors';
import useStyles from '../styles';
import { useDispatch } from 'react-redux';
import NavigationActions from '../../../../redux/reducers/gl_navigation_reducer/actions';
import { useHistory, useLocation } from 'react-router';
import { getNavbarConfig, useSideBarConfig } from '../../../config';
import {
  Box,
  Collapse,
  colors,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as AuthenticationSelectors from '../../../../redux/reducers/gl_auth_reducer/selectors';
import Configuration from '../configuration';
import * as Paths from '../../../../utils/path';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigationConfig from '../../../../config/navigationConfig';
import reactLogger from 'utils/logger';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NavbarTypeEnum } from 'constants/navigationConst';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const SidebarList = () => {
  reactLogger.renderComponent('SidebarList');

  const classes = useStyles({});
  const theme = useTheme();
  const open = NavigationSelectors.useSidebarIsVisible();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navMobRef = React.useRef();
  const navDeskRef = React.useRef();
  const navbarType = NavigationSelectors.useNavigationType();

  React.useLayoutEffect(() => {
    if (matches) {
      dispatch(NavigationActions.setSideMenuVisiblityAction(true));
    } else {
      dispatch(NavigationActions.setSideMenuVisiblityAction(false));
    }
  }, [matches]);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      dispatch(NavigationActions.setSideMenuVisiblityAction(false));
    };

  const AppDrawer = React.useMemo(
    () => (
      <React.Fragment>
        {matches && navbarType === NavbarTypeEnum.SIDE_MENU && (
          <Drawer
            className={clsx(classes.drawer)}
            variant="persistent"
            ref={navDeskRef}
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{ elevation: 0 }}
            open={open}
          >
            <Toolbar style={{ visibility: 'hidden' }} />
            <Content headerShown={false} />
          </Drawer>
        )}

        {matches && navbarType === NavbarTypeEnum.MINI_SIDE_MENU && (
          <Drawer
            className={clsx(classes.drawer, {
              [classes.miniDrawerOpen]: open,
              [classes.miniDrawerClose]: !open,
            })}
            variant="permanent"
            ref={navDeskRef}
            anchor="left"
            classes={{
              paper: clsx({
                [classes.miniDrawerOpen]: open,
                [classes.miniDrawerClose]: !open,
                [classes.drawerPaperMini]: true,
              }),
            }}
            PaperProps={{
              elevation: 0,
              // style: { background:  theme.palette.primary.main },
            }}
          >
            <Toolbar style={{ visibility: 'hidden' }} />
            <Content headerShown={false} isMini={!open} />
          </Drawer>
        )}

        {!matches && (
          <Drawer
            className={clsx(classes.drawer)}
            variant="temporary"
            ref={navMobRef}
            classes={{ paper: classes.drawerPaper }}
            onClose={toggleDrawer()}
            anchor="left"
            PaperProps={{ elevation: 0 }}
            open={open}
          >
            <Content headerShown />
          </Drawer>
        )}
      </React.Fragment>
    ),
    [open, classes, matches, navbarType]
  );

  return <React.Fragment>{AppDrawer}</React.Fragment>;
};

interface IContentProps {
  isMini?: boolean;
  headerShown: boolean;
}

const Content = React.memo(({ isMini, headerShown }: IContentProps) => {
  const classes = useStyles({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const { username, email } = AuthenticationSelectors.useSelectUserData();
  const history = useHistory();

  const handleDrawerClose = () => {
    dispatch(NavigationActions.setSideMenuVisiblityAction(false));
  };

  return (
    <React.Fragment>
      {headerShown && (
        <div className={classes.drawerHeader}>
          <IconButton style={{ color: 'inherit' }} onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </div>
      )}
      <div
        className="custom-scrollbar"
        style={{ overflowX: 'hidden', overflowY: 'auto' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <img
            className={classes.avatar}
            src={Configuration.profile.config?.iconSrc || null}
            onClick={() => history.push(NavigationConfig.mainPage().path)}
          />
          {!isMini && (
            <div style={{ marginBottom: '1rem' }}>
              {username && (
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{
                    textAlign: 'center',
                    // color: Configuration.sidebar.config?.textColor1,
                  }}
                >
                  {username}
                </Typography>
              )}
              <Typography
                color="textSecondary"
                variant="body2"
                style={{
                  textAlign: 'center',
                  // color: Configuration.sidebar.config?.textColor1,
                }}
              >
                {email}
              </Typography>
            </div>
          )}
        </Box>
        <Divider />
        <Box
          marginTop="0.8rem"
          display={'flex'}
          flexDirection="column"
          flex="1"
        >
          <SidebarListContent isMiniSideMenu={isMini} />
        </Box>
      </div>
    </React.Fragment>
  );
});

interface ISidebarListContentProps {
  isMiniSideMenu?: boolean;
}

const SidebarListContent = React.memo((props: ISidebarListContentProps) => {
  console.log('Render SidebarListContent');
  // const roles = AuthenticationSelectors.useSelectUserRoles();
  const dispatch = useDispatch();
  const history = useHistory();

  // filter items by user role
  const sidebarData = useSideBarConfig();
  //  getNavbarConfig(roles);

  return (
    <List>
      {sidebarData.map(({ title, path, Icon, id, subItems }, index) => (
        <SidebarElement
          key={id}
          title={title}
          path={path}
          Icon={Icon}
          isMiniSideMenu={props.isMiniSideMenu}
          subItems={subItems}
          id={id}
          onClick={() => {
            path &&
              dispatch(
                NavigationActions.navigateToScreenAction(history, path, id)
              );
          }}
        />
      ))}
    </List>
  );
});

interface ISubItems {
  path: string;
  title: React.ReactNode;
  component: React.FC;
  id: number | string;
  // isSideMenu: boolean;
}

interface ISubItemProps extends ISubItems {
  isMiniSideMenu?: boolean;
}

interface ISidebarElementProps {
  title: React.ReactNode;
  path: string | null;
  subItems?: ISubItems[];
  Icon: React.FC;
  id: number | string;
  isMiniSideMenu?: boolean;
  onClick: () => void;
}

const SidebarSubElement = (props: ISubItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = Paths.pathsIsTheSame(currentPath, props.path);
  const classes = useStyles({ isSelected: isSelected });

  const ListElement = (
    <ListItem
      className={clsx(classes.drawerItem, classes.subElement)}
      onClick={() =>
        dispatch(
          NavigationActions.navigateToScreenAction(
            history,
            props.path,
            props.id
          )
        )
      }
      key={props.id}
    >
      <ListItemIcon>
        <FiberManualRecordIcon
          style={{
            color: colors.grey['400'],
            height: props.isMiniSideMenu ? '1.3rem' : '0.8rem',
            width: props.isMiniSideMenu ? '1.3rem' : '0.8rem',
            marginLeft: props.isMiniSideMenu ? 0 : '0.3rem',
          }}
        />
      </ListItemIcon>
      <ListItemText
        classes={{
          secondary: classes.drawerListSubItemText,
        }}
        secondary={props.title}
      />
    </ListItem>
  );

  return (
    <React.Fragment>
      {props.isMiniSideMenu ? (
        <Tooltip title={props.title as React.ReactChild}>{ListElement}</Tooltip>
      ) : (
        ListElement
      )}
    </React.Fragment>
  );
};

const SidebarElement = (props: ISidebarElementProps) => {
  const { Icon, title, path, onClick, id, subItems, isMiniSideMenu } = props;
  const location = useLocation();
  const currentPath = location.pathname;
  const [expand, setExpand] = React.useState<boolean>(false);
  const isSelected = subItems
    ? false
    : path && Paths.pathsIsTheSame(currentPath, path);
  const classes = useStyles({ isSelected: isSelected });

  const expandByPath: boolean = React.useMemo(() => {
    if (subItems) {
      for (let item of subItems) {
        if (Paths.pathsIsTheSame(currentPath, item.path)) {
          return true;
        }
      }
    }
    return false;
  }, [subItems, currentPath]);

  React.useEffect(() => {
    setExpand(expandByPath);
  }, [expandByPath]);

  const ListItemElement = (
    <ListItem
      className={clsx(classes.drawerItem)}
      onClick={subItems ? () => setExpand(!expand) : onClick}
      button
      key={id}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      {!isMiniSideMenu && (
        <ListItemText
          classes={{
            secondary: classes.drawerListItemText,
          }}
          secondary={title}
        />
      )}
      {subItems && (
        <ExpandMoreIcon
          className={clsx(classes.expand, {
            [classes.expandOpen]: expand,
          })}
          aria-expanded={expand}
        />
      )}
      <Divider />
    </ListItem>
  );

  return (
    <Box
      display="flex"
      style={{
        paddingRight: '0.3rem',
        paddingLeft: '0.3rem',
      }}
      flexDirection="column"
    >
      {isMiniSideMenu ? (
        <Tooltip title={title as React.ReactChild}>{ListItemElement}</Tooltip>
      ) : (
        ListItemElement
      )}
      {subItems && (
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <ListSubItemsBlock
            subItems={subItems}
            isMiniSideMenu={isMiniSideMenu}
          />
        </Collapse>
      )}
    </Box>
  );
};

interface ISubItemsProps {
  subItems: ISubItemProps[];
  isMiniSideMenu?: boolean;
}

const ListSubItemsBlock = React.memo(
  ({ subItems, isMiniSideMenu }: ISubItemsProps) => {
    return (
      <List style={{ paddingRight: '0.5rem' }}>
        {subItems.map(({ path, title, component, id }, index) => (
          <SidebarSubElement
            component={component}
            title={title}
            path={path}
            key={id}
            id={id}
            isMiniSideMenu={isMiniSideMenu}
          />
        ))}
      </List>
    );
  }
);

export default SidebarList;
