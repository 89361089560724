import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import store from 'redux/store';
import {
  DefaultLanguage,
  FlagsMap,
  LanguagesEnum,
  TranslationsExceptDefaultList,
} from 'constants/languagesConst';
import { AppTextField, AppCommon } from 'components/molecules/FormControls';
import RichTextEditor, {
  convertDraftToHtml,
  convertHtmlToDraft,
} from 'components/atoms/RichTextEditor';
import { Control, Controller, useWatch, useForm } from 'react-hook-form';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { LanguageOptions } from 'constants/dropdownOptions';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { IFormData, ITranslationsModalFormParams } from './types';
import TranslateButton from './components/TranslateButton';
import RichTextEditorWrapper from './components/RichTextEditorWrapper';
import _ from 'lodash';
import { removeEmptyFields } from 'utils/objects';

export type { ITranslationsModalFormParams };

const TranslationsModalForm = () => {
  const submitRef = React.useRef<any>();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_TRANSLATIONS_FORM}
      showExitIcon={true}
      modalProps={{ fullWidth: false }}
      contentProps={{ style: { margin: 0, padding: 0 } }}
      renderHeader={(params: ITranslationsModalFormParams) => (
        <div>
          {
            <TranslatedText
              defaultText={params.title ?? 'Enter Translations'}
            />
          }
        </div>
      )}
      renderContent={(params: ITranslationsModalFormParams) => (
        <Content {...params} ref={submitRef} />
      )}
      renderFooter={(params: ITranslationsModalFormParams) => {
        return (
          <div>
            <Box
              style={{
                flexDirection: 'row-reverse',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Button
                color="primary"
                onClick={() => submitRef.current && submitRef.current()}
              >
                {'Save'}
              </Button>
              <Button
                style={{ marginRight: '0.5rem' }}
                onClick={closeTranslationModalFormModal}
              >
                {'Cancel'}
              </Button>
            </Box>
          </div>
        );
      }}
    />
  );
};

const defaultValues: IFormData = {};

const Content = React.forwardRef(
  (
    {
      initialData,
      type,
      tabs,
      textinputProps,
      autoTranslation,
      ...props
    }: ITranslationsModalFormParams,
    ref: any
  ) => {
    const {
      reset,
      handleSubmit,
      errors,
      control,
      register,
      setValue,
      getValues,
    } = useForm<IFormData>({
      defaultValues,
    });
    const [tab, setTab] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);
    };

    React.useEffect(() => {
      if (initialData) {
        const keyToDelete = Object.keys(initialData).find(
          (key) => _.get(initialData, key) === DefaultLanguage
        );

        reset({
          ...defaultValues,
          ...(keyToDelete ? _.omit(initialData, keyToDelete) : initialData), // remove default language
        });
      }
    }, [reset, initialData]);

    ref.current = handleSubmit((data) => {
      // console.log('SUBMI T DATA1::', data);

      // // remove languages not supported
      // Object.keys(data ?? {}).forEach((key) => {
      //   if (!(key in LanguagesEnum)) {
      //     delete data?.[key as LanguagesEnum];
      //   }
      // });

      // console.log('SUBMI T DATA2::', data, removeEmptyFields(data));

      props.onSubmit(data);
      closeTranslationModalFormModal();
    });

    return (
      <Box
        style={{
          width:
            props.modalSize === 'xlarge'
              ? 600
              : props.modalSize === 'large'
              ? 500
              : props.modalSize === 'medium'
              ? 420
              : 350,
          overflow: 'auto',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {tabs && (
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            // style={{ alignSelf: 'center' }}
          >
            {TranslationsExceptDefaultList.map((lan, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={FlagsMap[lan]}
                        width={'16px'}
                        height={'15px'}
                        alt="flag"
                        style={{ marginRight: 8 }}
                      />
                      {lan}
                    </div>
                  }
                  {...a11yProps(index)}
                  style={{ display: 'flex', flexDirection: 'row' }}
                />
              );
            })}
          </Tabs>
        )}

        <Divider />
        <div style={{ padding: '1rem' }}>
          {TranslationsExceptDefaultList.map((lan, index) => {
            return (
              <TabPanel
                key={index}
                tabs={Boolean(tabs)}
                value={tab}
                index={index}
                lan={lan}
                setValue={setValue}
                autoTranslation={autoTranslation}
              >
                <div>
                  {['text', 'textarea'].includes(type) ? (
                    <AppTextField
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      error={lan in errors}
                      helperText={errors?.[lan] && errors?.[lan]?.message}
                      rules={{}}
                      fullWidth
                      id={lan}
                      control={control}
                      multiline={type === 'textarea'}
                      rows={7}
                      label={lan}
                      autoComplete={lan}
                      name={lan}
                      controllerExtras={{
                        defaultValue: '',
                      }}
                      icon={{
                        ComponentRight: (
                          <img width={20} height={20} src={FlagsMap[lan]} />
                        ),
                        ComponentLeft: autoTranslation ? (
                          <TranslateButton
                            execute={async () => {
                              const str = await autoTranslation.execute(
                                autoTranslation.textToTranslate,
                                lan
                              );
                              setValue(lan, str);
                              return str;
                            }}
                          />
                        ) : undefined,
                      }}
                      {...textinputProps}
                    />
                  ) : (
                    <RichTextEditorWrapper
                      register={register}
                      initialData={initialData}
                      lan={lan}
                      autoTranslation={autoTranslation}
                      setValue={setValue}
                    />
                    // <Box style={{ position: 'relative' }}>
                    //   <input
                    //     name={lan}
                    //     style={{ display: 'none' }}
                    //     ref={register()}
                    //   />
                    //   <RichTextEditor
                    //     initialState={convertHtmlToDraft(
                    //       initialData?.[lan] || ''
                    //     )}
                    //     handleChange={(state) => {
                    //       setValue(lan, convertDraftToHtml(state));
                    //       // descriptionEditorStateRef.current = state;
                    //       // console.log('State::', convertDraftToHtml(state));
                    //     }}
                    //   />
                    //   {autoTranslation && (
                    //     <div
                    //       style={{ position: 'absolute', bottom: -5, right: 0 }}
                    //     >
                    //       <TranslateButton
                    //         size="medium"
                    //         execute={async () => {
                    //           // const str = await autoTranslation.execute(
                    //           //   autoTranslation.textToTranslate,
                    //           //   lan
                    //           // );
                    //           setValue(lan, 'che ck check');
                    //           return 'str....';
                    //         }}
                    //       />
                    //     </div>
                    //   )}
                    // </Box>
                  )}
                </div>
              </TabPanel>
            );
          })}
        </div>

        {/* <Divider style={{ marginTop: '1rem' }} /> */}

        {/* <div style={{ padding: '0.5rem' }}>
        <Box
          style={{
            flexDirection: 'row-reverse',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Button color="primary" onClick={onSubmit}>
            {'Save'}
          </Button>
          <Button
            style={{ marginRight: '0.5rem' }}
            onClick={closeTranslationModalFormModal}
          >
            {'Cancel'}
          </Button>
        </Box>
      </div> */}
      </Box>
    );
  }
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  tabs: boolean;
  autoTranslation: ITranslationsModalFormParams['autoTranslation'];
  lan: LanguagesEnum;
  setValue: ReturnType<typeof useForm>['setValue'];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, autoTranslation, lan, setValue, ...other } =
    props;

  if (!props.tabs) {
    return <>{children}</>;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export const openTranslationModalFormModal = (
  params: ITranslationsModalFormParams
) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_TRANSLATIONS_FORM,
      params,
    })
  );
};

export const closeTranslationModalFormModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_TRANSLATIONS_FORM,
    })
  );
};

interface ILanguageChipProps {
  label: React.ReactNode;
  control: ReturnType<typeof useForm>['control']; // remove the watch...
  name: string;
  lan: LanguagesEnum;
}

const LanguageChip = ({ label, name, lan, control }: ILanguageChipProps) => {
  const watchField = useWatch({
    control,
    name: lan === DefaultLanguage ? name : `translations.${lan}.${name}`,
  });

  return (
    <>
      {watchField && (
        <Chip
          style={{ marginRight: '0.5rem' }}
          size="small"
          label={label}
          icon={
            <span style={{ marginLeft: '10px' }}>
              <img
                src={FlagsMap[lan]}
                width={'16px'}
                height={'15px'}
                alt="flag"
              />
            </span>
          }
        />
      )}
    </>
  );
};

interface IActveLanguagesProps {
  control: ReturnType<typeof useForm>['control'];
  fieldName: string;
}

export const SelectedLanguages = React.memo(
  ({ fieldName, control }: IActveLanguagesProps) => {
    return (
      <Box sx={{ marginTop: '1rem' }}>
        <Typography style={{ fontWeight: 700, fontSize: '0.9em' }}>
          <TranslatedText defaultText={'Current Languages'} />
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '0.5rem' }}>
          {LanguageOptions.map((el, index) => (
            <LanguageChip
              control={control}
              name={fieldName}
              label={el.label}
              lan={el.id}
              key={index}
            />
          ))}
        </Box>
      </Box>
    );
  }
);

export default React.memo(TranslationsModalForm);
