import React from 'react';
import { alpha, colors, Paper, PaperProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IFormContainerProps extends PaperProps {}

const FormContainer = (props: IFormContainerProps) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.root}
      style={{ background: alpha(colors.grey['300'], 0.25) }}
      variant="outlined"
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
}));

export default FormContainer;
