import { Box } from '@material-ui/core';
import React from 'react';
import RichTextEditor, {
  convertDraftToHtml,
  convertHtmlToDraft,
} from 'components/atoms/RichTextEditor';
import { Control, Controller, useWatch, useForm } from 'react-hook-form';
import { ITranslationsModalFormParams } from '../../types';
import { LanguagesEnum } from 'constants/languagesConst';
import TranslateButton from '../../components/TranslateButton';

interface IRichTextEditorWrapperProps {
  register: ReturnType<typeof useForm>['register'];
  initialData: ITranslationsModalFormParams['initialData'];
  lan: LanguagesEnum;
  autoTranslation: ITranslationsModalFormParams['autoTranslation'];
  setValue: ReturnType<typeof useForm>['setValue'];
}

const RichTextEditorWrapper = ({
  register,
  initialData,
  lan,
  autoTranslation,
  setValue,
}: IRichTextEditorWrapperProps) => {
  const [state, setState] = React.useState<string | undefined>(
    initialData?.[lan]
  );

  React.useEffect(() => {
    setState(initialData?.[lan]);
  }, [initialData?.[lan]]);

  return (
    <Box style={{ position: 'relative' }}>
      <input name={lan} style={{ display: 'none' }} ref={register()} />
      <RichTextEditor
        initialState={convertHtmlToDraft(state || '')}
        handleChange={(state) => {
          setValue(lan, convertDraftToHtml(state));
        }}
      />
      {autoTranslation && (
        <div style={{ position: 'absolute', bottom: -5, right: 0 }}>
          <TranslateButton
            size="medium"
            execute={async () => {
              const str = await autoTranslation.execute(
                autoTranslation.textToTranslate,
                lan
              );
              setState(str ?? undefined);
              //   setValue(lan, str);
              return str;
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default RichTextEditorWrapper;
