//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from 'redux/utils/common';

export const REGISTER_USER: string = typeCreatorV2(
  'RegisterReducer',
  'RegisterUser'
);

// export const SET_CONTENT_TYPE: string = typeCreatorV2(
//   'RegisterReducer',
//   'SetContentType'
// );
