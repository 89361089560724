import React from 'react';
import FilerobotImageEditor from 'filerobot-image-editor';
import { useModalData } from 'redux/reducers/gl_modal_reducer/selectors';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import store from 'redux/store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','),
    mime = (arr[0].match(/:(.*?);/) as any)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

interface IImageEditorProps {
  src: string;
  onCloseImageEditor: () => void;
  filename: string;
  onEditComplete: (file: File) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

const ImageEditor = ({
  src,
  onCloseImageEditor,
  filename,
  onEditComplete,
}: IImageEditorProps) => {
  return (
    <FilerobotImageEditor
      show={true}
      src={src}
      config={{
        translations: {
          en: {
            'toolbar.download': 'Save',
            'toolbar.apply': 'Apply',
            'header.image_editor_title': 'Image Editor',
          },
        },
        colorScheme: 'light',
      }}
      onClose={() => {
        onCloseImageEditor();
      }}
      translations={{
        en: {
          'toolbar.save': 'Save',
          'toolbar.apply': 'Apply',
        },
      }}
      //   onOpen={() => console.log('Editor is opened.')}
      onComplete={(props: any) => {
        const base64 = props.canvas.toDataURL('image/jpeg', 1.0);
        const file = dataURLtoFile(base64, filename);
        onEditComplete(file);
      }}
      onBeforeComplete={(props: any) => {
        // const base64 = props.canvas.toDataURL('image/jpeg', 1.0);
        // console.log('onBeforeComplete', base64);
        return false;
      }}
    />
  );
};

export const openImageEditorModal = (params: IImageEditorProps) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_EDIT_IMAGE,
      params,
    })
  );
};

export const closeImageEditorModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_EDIT_IMAGE,
    })
  );
};

export const ImageEditorModal = React.memo(() => {
  const classes = useStyles();
  const { visible, params } = useModalData(ModalIDs.MODAL_EDIT_IMAGE);
  const _params = params as IImageEditorProps;
  return (
    <>
      {visible && (
        <>
          <Backdrop
            className={classes.backdrop}
            open
            onClick={closeImageEditorModal}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <ImageEditor {..._params} />
            </div>
          </Backdrop>
        </>
      )}
    </>
  );
});

export default ImageEditor;
