import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_URL_ALIAS_LIST = typeCreatorV2(
  'UrlAliasReducer',
  'SetUrlAliasList'
);

export const REMOVE_URL_ALIAS = typeCreatorV2(
  'UrlAliasReducer',
  'RemoveUrlAlias'
);
