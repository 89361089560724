import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request from '../../../utils/request';
import * as PathUtils from 'utils/path';
import { Roles as RolesEnum } from 'constants/authRolesConst';
import { LanguagesEnum } from 'constants/languagesConst';
import {
  AppTypes,
  Namespacestatus,
  NamespaceType,
} from 'constants/namespaceConst';
import { WeekDaysEnum } from 'constants/dailyActivityConst';
import { TagLevel } from 'constants/tagsConst';
import { DeepPartial } from 'utils/generics';

/** get user profile  */
export type GetUserData = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  createdAt: string;
  image: string;
  twoStepVerification: boolean;
};

interface IGetUserProfileOutput extends Types.ResponseOutput {
  data: GetUserData;
}

export const getUserProfile = async (): Promise<IGetUserProfileOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/profile`
  );

  return data;
};

/** edit user profile  */
interface IEditUserProfileOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditUserProfileInput {
  firstName: string;
  lastName: string;
  username: string;
  image?: string;
  twoStepVerification: boolean;
}

export const editUserProfile = async (
  payload: IEditUserProfileInput
): Promise<IEditUserProfileOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user/profile`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  let output: IEditUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** fetch users list */
interface IFetchUsersOutput extends Types.ResponseOutput {
  data: IFetchUsersData;
}

export interface IFetchUsersData {
  items: Array<{
    _id: string;
    roles: string[];
    isVerified: boolean;
    isBlocked: boolean;
    username: string;
    email?: string;
    phone: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    updatedAt: string;
    topics: string[];
    twoStepVerification: boolean;
    premium?: {
      enable: boolean;
      planType: string | null;
      planDurationType: null;
      renewPlanDate: Date | null;
    } | null;
  }>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
}

export interface IFetchUsersInput {
  page: number;
  pageSize: number;
  orderType: 'asc' | 'desc';
  sortField: string;
  globalSearch?: string;
}

export const fetchUsers = async (
  payload: IFetchUsersInput
): Promise<IFetchUsersOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'globalSearch',
        value: payload.globalSearch,
      },
      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'pageSize',
        value: payload.pageSize.toString(),
      },
      {
        key: 'page',
        value: payload.page.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/list${query}`
  );

  return data;
};

/** register user by admin  */
export type RegisterUserByAdminData = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  createdAt: string;
};

export interface IRegisterUserByAdminInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone?: string;
  username?: string;
  roles: Array<RolesEnum>;
}

interface IRegisterUserByAdminOutput extends Types.ResponseOutput {
  data: RegisterUserByAdminData;
}

export const registerUserByAdmin = async (
  input: IRegisterUserByAdminInput
): Promise<IRegisterUserByAdminOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/register-admin`,
    {
      body: input,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

export interface IEditUserByAdminInput {
  email: string;
  firstName: string;
  lastName: string;
  username?: string;
  phone?: string;
  roles: Array<RolesEnum>;
  isBlocked: boolean;
  isVerified: boolean;
  userid: string;
  twoStepVerification?: boolean;
}

interface IEditUserByAdminOutput extends Types.ResponseOutput {
  data: null;
}

export const editUserByAdmin = async (
  input: IEditUserByAdminInput
): Promise<IEditUserByAdminOutput> => {
  const { userid, ...body } = input;
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user/${userid}`,
    {
      body,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** register user by supervisor  */
export type RegisterUserBySupervisorData = {
  // email: string;
  // firstName: string;
  // lastName: string;
  // username: string;
  // createdAt: string;
};

export interface IRegisterUserBySupervisorInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  username: string;
  roles: [RolesEnum.User, RolesEnum.Supervisor];
}

interface IRegisterUserBySupervisorOutput extends Types.ResponseOutput {
  data: RegisterUserBySupervisorData;
}

export const registerUserBySupervisor = async (
  input: IRegisterUserBySupervisorInput
): Promise<IRegisterUserBySupervisorOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/register-supervisor`,
    {
      body: input,
    }
  );

  return data;
};

/** edit user settings  */
export interface IEditUserSettingsInput {
  selectedNamespace: string;
}

export const editUserSettings = async (
  payload: IEditUserSettingsInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user/settings`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** get user settings details  */
export type GetUserSettingsByAdminData = Types.IUserSettings;

export const getUserSettingsDetailsByAdmin = async (
  userId: string
): Promise<Types.ResponseOutputV2<GetUserSettingsByAdminData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/settings/admin/${userId}`
  );

  return data;
};

/** edit user settings by admin  */
export type EditUserSettingsPayload = {
  // previous...
  // language?: LanguagesEnum;
  // selectedNamespace?: string;
  // selectedLevel?: {
  //   [key: string]: number;
  // };
  // darkMode?: boolean;
  // practiceReminder?: {
  //   enabled?: boolean;
  //   reminderTime?: Date;
  // };
} & Omit<DeepPartial<Types.IUserSettings>, 'selectedLevel'>;

export const editUserSettingsByAdmin = async (
  userId: string,
  payload: EditUserSettingsPayload
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user/settings/admin/${userId}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** fetch user portal details */
export interface UserPortalDetailsData {
  assignNamespaces?: Array<{
    _id: string;
    description: string;
    name: string;
    status: Namespacestatus;
    type: NamespaceType;
    appType?: AppTypes;
    // createdAt: string;
    // updatedAt: string;
    // imageId: string;
  }>;
  selectedNamespace?: string;
}

export const fetchUserPortalDetails = async (
  userId: string
): Promise<Types.ResponseOutputV2<UserPortalDetailsData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/portal/details/${userId}`
  );

  return data;
};

/** fetch user portal details */
export interface UserPortalDetailsPayload {
  assignNamespaces?: Array<string>;
  selectedNamespace?: string | null;
}

export const editUserPortalDetails = async (
  userId: string,
  payload: UserPortalDetailsPayload
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user/portal/details/${userId}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

interface IRemoveUserByAdminOutput extends Types.ResponseOutput {
  data: null;
}

export const removeUserByAdmin = async (
  userId: string
): Promise<IRemoveUserByAdminOutput> => {
  const { data } = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/user/${userId}`,
    {
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};
