import React from 'react';
import BasicTemplate from '../../BasicTemplate';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
  MAX_WORDS_PER_PAGE,
} from '../../../configuration';
import { splitTextIntoChunks } from 'utils/chunks';
import { showToastAction } from 'components/atoms/ToastMessage';

const BookSenctionItemForm = (props: IItemDetailsProps) => {
  return (
    <BasicTemplate
      {...props}
      config={{
        sectionTitle: {
          visibility: {
            includeTitle: true,
            includeContentTranslation: true,
          },
        },
        // sectionExamples: {
        //   visibility: { includeExamples: true },
        //   renameLabel: 'Senction Pages',
        //   lineNumbers: 10,
        // },
        // senctionCategories: {
        //   visibility: { includeCategories: true },
        // },
        senctionBasicInfo: {
          visibility: {
            includeStatus: true,
            includePublic: true,
            includeType: true,
            includeDifficulty: true,
            includeNamespaces: true,
            includeLink: true,
          },
        },
        // senctionVideo: {
        //   visibility: {},
        // },
        // senctionImage: {
        //   visibility: {},
        // },
        // senctionExplanation: {
        //   visibility: {
        //     includeDescription: true,
        //   },
        // },

        sectionBookContent: {
          visibility: {
            includeContent: true,
          },
        },

        sectionBookContentTranslation: {
          visibility: {
            includeContentTranslation: true,
          },
        },

        validationOnSave: (result) => {
          if (result.bookSection_ContentTranslation) {
            const valid = !!splitTextIntoChunks(
              result.bookSection_Content ?? '',
              result.bookSection_ContentTranslation ?? '',
              MAX_WORDS_PER_PAGE
            );

            if (!valid) {
              showToastAction(
                'Invalid book content. (Content and translation content has not the same number of paragraphs)',
                'error'
              );
            }
            return valid;
          }

          return true;
        },
      }}
    />
  );
};

export default BookSenctionItemForm;
