import React from 'react';
import DataList from 'components/molecules/DataList';
import { useDispatch } from 'react-redux';
import * as RoutineDetailsSelectors from '../../store/selectors';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Chip,
  colors,
  Divider,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import { Control, useForm, useFieldArray } from 'react-hook-form';
import { IFormData } from '../../configuration';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import AddIcon from '@material-ui/icons/Add';
import { TricksListModalParams } from '../ItemsListModal';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import Tags from '../Tags';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import {
  closeItemDetailsModal,
  openItemDetailsModal,
  TricksDetailsModalParams,
} from 'components/compounds/ItemDetailsModal';
import useStyles from './styles';
import SelectButton from 'components/molecules/SelectButton';
import {
  CollectionItemMapping,
  CollectionTypeEnum,
  ItemGroups,
  ItemTypeEnum,
  ItemTypeNameMapping,
} from 'constants/typesConst';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import UploadFileButton from 'components/atoms/UploadFileButton';
import CollectionDetailsActions from '../../store/actions';
import { openCsvStatusModal } from '../CsvStatusModal';
import { arraysHasCommonElements } from 'utils/arrays';
import { useIsMobileAppAccess } from 'redux/reducers/gl_theme_reducer/selectors';
import { IAddEditCollectionFormProps } from '../../types';
import UploadPdfButton from '../UploadPdfButton';

interface ISortableItemProps {
  register: ReturnType<typeof useForm>['register'];
  element: IFormData['items'][0];
  remove: ReturnType<typeof useFieldArray>['remove'];
  _index: number;
  type: ItemTypeEnum;
  collectionId: string | null;
}

const SortableItem = SortableElement(
  ({
    element,
    register,
    remove,
    _index,
    type,
    collectionId,
  }: ISortableItemProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const chipContainer = (
      <Box
        display="flex"
        alignItems="center"
        flexWrap={'wrap'}
        style={{ gap: '0.3rem' }}
      >
        <Chip
          size="small"
          label={element.status}
          style={{ marginLeft: '0.5rem' }}
          variant="outlined"
          color="primary"
        />
        <Chip
          style={{ marginLeft: '0.5rem' }}
          size="small"
          label={ItemTypeNameMapping?.[element.type]?.label}
          variant="outlined"
        />
        <Chip
          style={{ marginLeft: '0.5rem' }}
          size="small"
          label={element.difficulty}
          color="secondary"
        />
        <Chip
          style={{ marginLeft: '0.5rem' }}
          size="small"
          label={element.public ? 'Public' : 'Private'}
          variant="outlined"
        />
      </Box>
    );

    return (
      <Box
        display="flex"
        alignItems="center"
        marginBottom={'1rem'}
        width="100%"
        // paddingRight={'1rem'}
        className={classes.listItemContainer}
      >
        {/* <div
          style={{
            marginRight: '0.5rem',
            width: 30,
            background: alpha(colors.common.black, 0.8),
            color: colors.common.white,
            height: 30,
            borderRadius: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: 11,
          }}
        >
          <span>{_index}</span>
        </div> */}
        <Paper
          style={{
            width: '100%',
            position: 'relative',
            paddingTop: '0.3rem',
            paddingBottom: '0.2rem',
            background: colors['grey']['50'],
          }}
          variant="outlined"
        >
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              borderBottomRightRadius: 6,
              borderStyle: 'solid',
              borderWidth: 0,
              borderBottomWidth: 1,
              borderRightWidth: 1,
              fontSize: 10,
              minWidth: 30,
              borderColor: colors.grey['300'],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {_index + 1}
          </span>
          <input
            name={`items[${_index}].id`}
            defaultValue={element.id} // make sure to set up defaultValue
            ref={register()}
            hidden
          />
          <input
            name={`items[${_index}].title`}
            defaultValue={element.title} // make sure to set up defaultValue
            ref={register()}
            hidden
          />

          <input
            hidden
            defaultChecked={element.public}
            type="checkbox"
            name={`items[${_index}].public`}
          />

          <Box>
            <Box
              display="flex"
              key={element.id}
              justifyContent="space-between"
              alignItems="center"
              padding="0.5rem"
              flex={1}
            >
              <Box alignItems="center" display="flex">
                <IconButton
                  size="small"
                  onClick={() => {
                    // console.log('REmove:!!:', _index);
                    remove(_index);
                  }}
                  style={{ marginRight: '0.5rem', marginLeft: '0.4rem' }}
                >
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
                <div dangerouslySetInnerHTML={{ __html: element.title }} />
                {/* {element.title}</div> */}
              </Box>
              <Box justifyContent="center" alignItems="center" display="flex">
                <Tags tagsList={element.tags ?? []} />
                <div className={classes.chipContainerMd}>{chipContainer}</div>
                {/* <Chip
                  size="small"
                  label={element.status}
                  style={{ marginRight: '0.5rem' }}
                />
                <Chip size="small" label={element.type} /> */}
                <Tooltip
                  title={
                    <TranslatedText
                      defaultText={`View Details (ID: ${element.id})`}
                    />
                  }
                >
                  <IconButton
                    style={{ marginLeft: '0.5rem' }}
                    onClick={() => {
                      openItemDetailsModal({
                        trickId: element.id,
                        onDeleteItem: () => {
                          closeItemDetailsModal();
                          remove(_index);
                        },
                        type: type,
                      });
                    }}
                  >
                    <LaunchIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <div className={classes.chipContainerSm}>
              <Divider />
              <Box
                flex={1}
                style={{
                  padding: '0.4rem',
                  paddingTop: '0.5rem',
                  background: colors.grey['100'],
                }}
              >
                {chipContainer}
              </Box>
            </div>
          </Box>
        </Paper>
      </Box>
    );
  }
);

interface ITricksProps {
  control: Control;
  register: ReturnType<typeof useForm>['register'];
  setValue: ReturnType<typeof useForm>['setValue'];
  type: CollectionTypeEnum | null;
  collectionId: string | null;
  config: IAddEditCollectionFormProps['config'] | null;
}

//  & { arrayFields: ReturnType<typeof useFieldArray> }
const SortableListContainer = SortableContainer(
  ({
    register,
    control,
    arrayFields,
    type,
    collectionId,
    config,
  }: ITricksProps & { arrayFields: ReturnType<typeof useFieldArray> }) => {
    const dispatch = useDispatch();
    const { fields, append, prepend, remove, swap, move, insert } = arrayFields;
    const classes = useStyles();
    const lastItemRef = React.useRef<any>();
    const disableDrag = useIsMobileAppAccess();

    const buttonOptions = React.useMemo(
      () =>
        type
          ? CollectionItemMapping[type].map((el) => ({
              action: () => {
                openItemDetailsModal({
                  onAddItem: () => {
                    closeItemDetailsModal();
                    dispatch(
                      ModalActions.openModalAction({
                        modalID: ModalIDs.MODAL_TRICKS_LIST,
                        params: {
                          append: append,
                          tricks: fields,
                          collectionType: type,
                        } as TricksListModalParams,
                      })
                    );
                  },
                  type: el,
                  trickId: null,
                });
              },
              id: el,
              children: (
                <span
                  style={{ minWidth: 175 }}
                >{`${ItemTypeNameMapping[el].label}`}</span>
              ),
            }))
          : [],
      [type]
    );

    return (
      <div>
        <Paper
          style={{
            marginTop: '1rem',
            backgroundColor: alpha(colors.grey['50'], 0.3),
            paddingRight: '1rem',
            paddingLeft: '1rem',
            marginBottom: '1rem',
          }}
          variant="outlined"
        >
          <Box className={classes.buttonsBarAction}>
            <Box className={classes.buttonsNestedAction}>
              {config !== null &&
                config?.sectionCollectionItems?.visibility
                  ?.includeSelectItemsButton && (
                  <Button
                    startIcon={<AddIcon />}
                    color="secondary"
                    variant="outlined"
                    style={{
                      textTransform: 'none',
                      marginRight: 5,
                    }}
                    onClick={() => {
                      dispatch(
                        ModalActions.openModalAction({
                          modalID: ModalIDs.MODAL_TRICKS_LIST,
                          params: {
                            append: append,
                            tricks: fields,
                            onItemAdded: () => {
                              setTimeout(() => {
                                (lastItemRef as any)?.current?.scrollIntoView &&
                                  (lastItemRef as any).current.scrollIntoView({
                                    behavior: 'smooth',
                                  });
                              }, 200);
                            },
                            collectionType: type,
                          } as TricksListModalParams,
                        })
                      );
                    }}
                  >
                    <TranslatedText defaultText="Select Existing Item" />
                  </Button>
                )}
              {config !== null &&
                config?.sectionCollectionItems?.visibility
                  ?.includeCreateItemButton && (
                  <SelectButton
                    // startI con={<AddIcon />}
                    items={buttonOptions}
                    // color="primary"
                    buttonProps={{
                      startIcon: <AddIcon />,
                      variant: 'outlined',
                      style: {
                        textTransform: 'none',
                        marginRight: 5,
                      },
                      color: 'secondary',
                    }}
                  >
                    <TranslatedText defaultText="Create and Add Item" />
                  </SelectButton>
                )}

              {config?.sectionCollectionItems?.visibility
                ?.includeUploadPdfButton && (
                <div style={{ marginRight: 5 }}>
                  <UploadPdfButton />
                </div>
              )}
            </Box>
            <Box>
              {!!collectionId &&
                arraysHasCommonElements(
                  CollectionItemMapping?.[type as CollectionTypeEnum] ?? [],
                  ItemGroups.Vocabulary
                ) && (
                  <UploadFileButton
                    type="button"
                    handleChange={(files) => {
                      dispatch(
                        CollectionDetailsActions.uploadCsvFileAction({
                          fileData: files?.[0],
                          collectionId: collectionId,
                          onFinish: (result) => {
                            dispatch(
                              CollectionDetailsActions.fetchRoutineDetailsAction(
                                collectionId
                              )
                            );
                            openCsvStatusModal({
                              data: result?.insertedItems ?? {},
                            });
                          },
                        })
                      );
                    }}
                    accept=".csv"
                    componentProps={{
                      color: 'primary',

                      startIcon: <BackupOutlinedIcon />,
                      variant: 'contained',
                      fullWidth: true,
                    }}
                  >
                    <div>Upload CSV</div>
                  </UploadFileButton>
                )}
            </Box>
          </Box>
        </Paper>
        {/* <SelectButton */}
        <div className={classes.container}>
          {fields.map((element, index) => (
            <SortableItem
              remove={remove}
              // key={index}
              key={element.id ?? index}
              register={register}
              element={element as any}
              _index={index}
              index={index}
              type={element?.type}
              disabled={disableDrag} // disable drag
              collectionId={collectionId}
            />
          ))}

          <div ref={lastItemRef} />
        </div>
      </div>
    );
  }
);

const TricksList = (props: ITricksProps, tricksRef: any) => {
  const { control, setValue } = props;
  const arrayFields = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'items', // unique name for your Field Array
    keyName: 'arrayId', //default to "id", you can change the key name
  });

  React.useEffect(() => {
    tricksRef.current = arrayFields.fields;
  }, [arrayFields.fields]);

  return (
    <div>
      <SortableListContainer
        {...props}
        distance={6}
        arrayFields={arrayFields}
        onSortEnd={({ oldIndex, newIndex }) => {
          setValue('items', arrayMove(arrayFields.fields, oldIndex, newIndex));
        }}
      />
    </div>
  );
};

// const ListActionsRow = React.memo(() => {
//   return (

//   )
// })

export default React.forwardRef(TricksList);
