import { Avatar, Box, Divider, Typography } from '@material-ui/core';
import React from 'react';

interface ISimpleListElementProps {
  title?: React.ReactNode;
  actionLeftButton?: React.ReactNode;
  actionRightButton?: React.ReactNode;
  subTitle?: React.ReactNode;
  avatar?: {
    visible: boolean;
    src?: string;
    alt?: string;
    text?: string;
  };
}

const SimpleListElement = ({
  title,
  subTitle,
  actionLeftButton,
  actionRightButton,
  avatar,
}: ISimpleListElementProps) => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          // flexWrap: 'wrap',
        }}
      >
        {avatar?.visible && (
          <Avatar
            alt={avatar?.alt ?? undefined}
            style={{ marginRight: '0.5rem' }}
            src={avatar?.src}
          >
            {avatar.text}
          </Avatar>
        )}
        <Box>{actionLeftButton}</Box>
        <Box style={{ flex: 1 }}>
          {title && (
            <Box style={{ marginBottom: '0.3rem' }}>
              <Typography
                style={{
                  fontSize: '1em',
                  fontWeight: 600,
                  textOverflow: 'ellipsis',
                  overflow: 'auto',
                }}
                variant="h1"
              >
                {title}
              </Typography>
            </Box>
          )}

          {subTitle && (
            <Box style={{ fontSize: '0.9em', width: '100%' }}>
              {/* <Typography
                style={{ fontSize: '0.9em', width: '100%' }}
                variant="h5"
              >
                {subTitle}
              </Typography> */}
              {subTitle}
            </Box>
          )}
        </Box>
        {actionRightButton && (
          <Box
            style={{
              // flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {actionRightButton}
          </Box>
        )}
      </Box>
      <Divider style={{ marginTop: '0.5rem' }} />
    </Box>
  );
};

export default SimpleListElement;
