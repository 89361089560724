import { useSelector } from 'react-redux';
import { ReducerKeys } from 'redux/config';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { IState } from './reducer';
import { TagLevel } from 'constants/tagsConst';

/* selector: useNamespacesList */
export const useNamespacesList = () => {
  return useSelector(
    (state: any) => (state[ReducerKeys.COMMON_REDUCER] as IState).namespacesList
  );
};

/* selector: useUpdatePortalAccessStatus */
export const useUpdatePortalAccessStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.COMMON_REDUCER] as IState).portalAccessDetailsStatus
  );
};

/* selector: useNamespaceDetails */
export const useNamespaceDetails = (): IState['namespacesDetails'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.COMMON_REDUCER] as IState).namespacesDetails
  );
};

/* selector: useAppType */
export const useAppType = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.COMMON_REDUCER] as IState).namespacesDetails.data
        ?.appType
  );
};

export const useLevelNamespaceLevelsOptions = () => {
  return useSelector(
    (state: any) =>
      (
        state[ReducerKeys.COMMON_REDUCER] as IState
      ).namespacesDetails.data?.levels?.map((el) => ({
        label: TagLevel[el as number],
        id: el,
      })) ?? []
  );
};

/* selector: useLoadingView */
export const useLoadingView = () => {
  return useSelector(
    (state: any) => (state[ReducerKeys.COMMON_REDUCER] as IState).loadingView
  );
};
