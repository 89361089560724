import { Box, Button } from '@material-ui/core';
import React from 'react';
import { IColLibrariesPerCollectionProps } from '../../types';
// import {
//   useSelectedCollectionsMapping,
//   useTotalSelections,
// } from '../../store/selectors';
// import LoadingView from 'components/atoms/LoadingView';

const Footer = React.forwardRef(
  (
    props: IColLibrariesPerCollectionProps & { handleCancelAction: () => void },
    ref: any
  ) => {
    // const totalSelections = useTotalSelections();
    // const dataMapping = useSelectedCollectionsMapping();
    // const [loading, setLoading] = React.useState(false);

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flex: 1,
        }}
      >
        <Button
          onClick={props.handleCancelAction}
          style={{ marginRight: '0.5rem' }}
        >
          {'Close'}
        </Button>
        {/* <Button
          startIcon={loading ? <LoadingView size="1rem" /> : undefined}
          disabled={totalSelections === 0 || loading}
          color="primary"
          onClick={() => {
            // if (props.onSubmit) {
            //   setLoading(true);
            //   props.onSubmit(dataMapping, () => {
            //     setLoading(false);
            //   });
            // }
          }}
        >
          {`Submit (${totalSelections})`}
        </Button> */}
      </Box>
    );
  }
);

export default Footer;
