import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_NOTIFICATION_LIST: string = typeCreatorV2(
  'NotificationReducer',
  'SetNotificationList'
);

export const SEND_NOTIFICATION: string = typeCreatorV2(
  'NotificationReducer',
  'SendNotification'
);

export const SET_USERS: string = typeCreatorV2(
  'NotificationReducer',
  'SetUsers'
);

export const REMOVE_NOTIFICATION: string = typeCreatorV2(
  'NotificationReducer',
  'RemoveNotification'
);
