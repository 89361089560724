import React from 'react';
import BasicTemplatePrevious from '../../BasicTemplate/BasicTemplatePrevious';
import { CollectionDetailsProps } from '../../../types';
import { useContextProps } from 'components/compounds/CollectionDetailsModal/provider';

const DefaultItemForm = () => {
  const props = useContextProps();

  return (
    <BasicTemplatePrevious
      type={props.type}
      routineId={props.collectionId ?? undefined}
    />
  );
};

export default DefaultItemForm;
