import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useGenerateAudioFile  */
export const useGenerateAudioFile = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TEXT_TO_AUDIO_REDUCER] as IState).generateAudioFile
  );
};
