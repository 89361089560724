import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_STUDENTS_LIST: string = typeCreatorV2(
  'StudentsReducer',
  'SetUsersList'
);

export const REMOVE_STUDENT_FROM_CLASS = typeCreatorV2(
  'StudentsReducer',
  'RemoveStudentFromClass'
);

export const ADD_STUDENT_TO_CLASS = typeCreatorV2(
  'StudentsReducer',
  'AddStudentToClass'
);
