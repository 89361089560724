import { ThemesModeEnum } from 'constants/themeConst';
import { PalletesEnum } from 'constants/palletesConst';
import { IAction, IActionMethods } from '../../utils/common';
import * as Types from './types';

class ChangeTheme implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(result: ThemesModeEnum): IAction {
    return {
      type: Types.SET_THEME,
      data: result,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_THEME,
      data: null,
    };
  }

  action(data: ThemesModeEnum): IAction {
    try {
      return this.onSuccess(data);
    } catch (error) {
      // reactLogger.log('ChangeTheme Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

class SetAccess implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(isMobileApp: boolean): IAction {
    return {
      type: Types.SET_ACCESS,
      data: isMobileApp,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ACCESS,
      data: null,
    };
  }

  action(data: boolean): IAction {
    try {
      return this.onSuccess(data);
    } catch (error) {
      return this.onFailed();
    }
  }
}

class SetPalleteId implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(data: PalletesEnum): IAction {
    return {
      type: Types.SET_PALLETE_ID,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PALLETE_ID,
      data: null,
    };
  }

  action(data: PalletesEnum): IAction {
    try {
      return this.onSuccess(data);
    } catch (error) {
      return this.onFailed();
    }
  }
}

class SetThemeSettingsPanelOpen implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }

  onSuccess(data: boolean): IAction {
    return {
      type: Types.SET_THEME_SETTINGS_PANEL_OPEN,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_THEME_SETTINGS_PANEL_OPEN,
      data: null,
    };
  }

  action(open: boolean): IAction {
    try {
      return this.onSuccess(open);
    } catch (error) {
      return this.onFailed();
    }
  }
}

export default {
  changeThemeAction: (data: ThemesModeEnum) => new ChangeTheme().action(data),
  setAccessAction: (isMobileApp: boolean) =>
    new SetAccess().action(isMobileApp),
  setPalleteIdAction: (data: PalletesEnum) => new SetPalleteId().action(data),
  setThemeSettingsPanelOpenAction: (data: boolean) =>
    new SetThemeSettingsPanelOpen().action(data),
};
