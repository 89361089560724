import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_COLLECTIONS_LIST: string = typeCreatorV2(
  'CollectionItemsPerStudentReducer',
  'SetCollectionsList'
);

export const SET_SELECTED_COLLECTIONS = typeCreatorV2(
  'CollectionItemsPerStudentReducer',
  'SetSelectedCollections'
);

export const RESET = typeCreatorV2('CollectionItemsPerStudentReducer', 'Reset');
