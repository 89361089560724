import React from 'react';
import Datatable from 'components/molecules/Datatable';
import { useDispatch } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import * as CollectionSelectors from '../../store/selectors';
import * as Common from '../../common';
import SimpleListElement from 'components/atoms/SimpleListElement';
import { OptionsButton } from '../TableAction';
import NavigationConfig from 'config/navigationConfig';
import { cleanObject } from 'utils/objects';
import { useHistory } from 'react-router';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'constants/authRolesConst';
import * as DateUtils from 'utils/dates';
import LaunchIcon from '@material-ui/icons/Launch';
import TableActions from '../TableAction';
import Vector from 'components/atoms/Vector';
import { viewFileUrl } from 'services/api/file';
import { colors } from '@material-ui/core';
import useTextSelector from 'hooks/useTextSelector';
import { AppTypes } from 'constants/namespaceConst';
import { useContextProps } from '../../provider';
import { useNamespaceDetails } from 'redux/reducers/gl_common_reducer/selectors';
import {
  CollectionTypeEnum,
  CollectionTypeNeedsQuizType,
  DefaultExerciseQuestionsMap,
  KnowledgeLevelEnum,
} from 'constants/typesConst';
import KnowledgeLevel from 'components/app_common/KnowledgeLevel';

const CollectionsDatable = () => {
  const result = CollectionSelectors.useCollectionsList();
  const dispatch = useDispatch();
  const params = Common.useParams();
  const sortField = React.useRef<string>(params.sortField);
  const props = useContextProps();
  const sortType = React.useRef<'asc' | 'desc'>(params.orderType);
  const updateState = Common.useUpdateState();
  const columns = Common.useTableColumns();
  const { textSelector } = useTextSelector();
  const namespaceDetails = useNamespaceDetails();

  const paging = {
    page: params.page ?? Common.DefaultPaging.page,
    pageSize: params.pageSize ?? Common.DefaultPaging.pageSize,
    total: result.data?.paging.total || 0,
  };

  return (
    <Datatable
      paging={paging}
      data={result.data?.items || []}
      sorting={{
        sortField: sortField.current,
        order: sortType.current,
      }}
      // filtering={{
      //   globalSearchPlaceholder: textSelector('Search collections..', {
      //     [AppTypes.LanguageLearn]: 'Search students...',
      //   }),
      //   // globalSearchDefaultValue: params.globalSearch,
      //   searchFields: true,
      // }}
      title={textSelector('Manage Items', {
        [AppTypes.LanguageLearn]: 'Student Answers',
      })}
      disablePaper
      renderItemInSmallDevices={(data, index) => {
        const question =
          data.question || DefaultExerciseQuestionsMap?.[data.type as never];

        return (
          <SimpleListElement
            title={
              <div>
                {question && (
                  <div style={{ marginBottom: '0.8rem' }}>{question}</div>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {CollectionTypeNeedsQuizType.includes(
                    params.collectionType as CollectionTypeEnum
                  ) && (
                    <KnowledgeLevel
                      level={
                        data.library?.knowledgeLevel.level ??
                        KnowledgeLevelEnum.NoLevel
                      }
                    />
                  )}
                  <div>{data.title}</div>
                </div>
              </div>
            }
            subTitle={
              <Box style={{ marginTop: '0.7rem' }}>
                <div>
                  {`- ${
                    data.library?.latestQuizAnswer?.value ?? 'Not Answered'
                  }`}
                </div>
                {/* <Chip
                  style={{
                    background:
                      data.library?.latestQuizAnswer?.isCorrect === true
                        ? colors['green']['500']
                        : data.library?.latestQuizAnswer?.isCorrect === false
                        ? colors['red']['500']
                        : colors['orange']['500'],
                    color: colors.common.white,
                    fontWeight: 500,
                    marginTop: '0.4rem',
                  }}
                  label={
                    data.library?.latestQuizAnswer?.isCorrect === true
                      ? 'Correct'
                      : data.library?.latestQuizAnswer?.isCorrect === false
                      ? 'Incorrect'
                      : 'Not Answered'
                  }
                /> */}
              </Box>
            }
            actionRightButton={
              <Chip
                size="small"
                style={{
                  background:
                    data.library?.latestQuizAnswer?.isCorrect === true
                      ? colors['green']['500']
                      : data.library?.latestQuizAnswer?.isCorrect === false
                      ? colors['red']['500']
                      : colors['orange']['500'],
                  color: colors.common.white,
                  fontWeight: 500,
                  marginTop: '0.4rem',
                }}
                label={
                  data.library?.latestQuizAnswer?.isCorrect === true
                    ? 'Correct'
                    : data.library?.latestQuizAnswer?.isCorrect === false
                    ? 'Incorrect'
                    : 'Not Answered'
                }
              />
              // <TableActions params={data} />
            }
            // actionLeftButton={
            //   <div
            //     style={{
            //       marginRight: '0.8rem',
            //     }}
            //   >
            //     {data?.imageId?.path ? (
            //       <img
            //         style={{ width: 30, height: 30, borderRadius: 10 }}
            //         src={viewFileUrl(data?.imageId?.path)}
            //       />
            //     ) : (
            //       <Vector
            //         style={{
            //           fontWeight: 400,
            //           color: colors.grey['800'],
            //         }}
            //         fontSize={25}
            //         code={59555}
            //       />
            //     )}
            //   </div>
            // }
          />
        );
      }}
      minHeight={'50vh'}
      paperProps={{
        style: {
          borderRadius: 2,
          //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
          overflow: 'hidden',
        },
        // square: false,
        variant: 'outlined',
      }}
      tableCellProps={() => ({
        style: {
          //   paddingTop: 0,
          //   paddingBottom: 0,
          borderLeft: 'none',
          borderRight: 'none',
        },
      })}
      tableCellMainRowProps={() => ({
        style: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
          //   backgroundColor: Colors.APP_COLOR_DARK({
          //     darkMode: theme.palette.type === 'dark',
          //   }),
          //   color: 'white',
        },
      })}
      loading={false}
      columns={columns}
      resetFilteringAction={() => {
        updateState &&
          updateState({
            collectionId: params.collectionId,
            title: params.title,
            studentId: params.studentId,
            collectionType: params.collectionType,
          });
      }}
      handleDataChange={(
        selectedPage,
        pageSize,
        previousOrder,
        _sortField,
        newOrder,
        filteringData
      ) => {
        // 'asc' | 'desc'
        sortType.current = newOrder;
        sortField.current = _sortField;

        updateState &&
          updateState({
            ...props,
            ...cleanObject({
              page: selectedPage,
              pageSize,
              sortField: _sortField,
              orderType: previousOrder,
            }),
          });
      }}
    />
  );
};

export default React.memo(CollectionsDatable);
