import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UsersService from 'services/api/user';
import * as NamespacesService from 'services/api/namespaces';
import type * as CollectionsServices from 'services/api/collections';

export interface IState {
  collectionsList: {
    status: StateStatus | null;
    data: CollectionsServices.FetchRoutineListData | null;
  };
  selectedCollectionsMapping: {
    [key: string]: {
      id: string;
    };
  } | null;
}

const initialState: IState = {
  collectionsList: {
    status: null,
    data: null,
  },
  selectedCollectionsMapping: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_COLLECTIONS_LIST:
      return {
        ...state,
        collectionsList: {
          ...state.collectionsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.collectionsList.data
              : action.data,
        },
      };
    case Types.SET_SELECTED_COLLECTIONS:
      return {
        ...state,
        selectedCollectionsMapping: action.data,
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
