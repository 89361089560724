import React from 'react';
import Content from '../Content';
import Footer from '../ModalFooter';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import { IColLibrariesPerCollectionProps } from '../../types';

type IIsolateCollectionSelectorProps = IColLibrariesPerCollectionProps & {
  showFooter?: boolean;
};

const IsolateCollectionSelector = ({
  showFooter,
  ...props
}: IIsolateCollectionSelectorProps) => {
  return (
    <>
      <Content {...props} />
      {showFooter && <Footer {...props} handleCancelAction={() => {}} />}
    </>
  );
};

export default withReducer<IIsolateCollectionSelectorProps>(
  ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER
)(IsolateCollectionSelector);
