//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_SIDE_MENU_VISIBILITY: string = typeCreatorV2(
  'NavigationReducer',
  'SetSideMenuVisibility'
);
export const SET_NAVIGATE_TO_SCREEN: string = typeCreatorV2(
  'NavigationReducer',
  'SetNavigateToScreen'
);

export const SET_NAVBAR_TYPE: string = typeCreatorV2(
  'NavigationReducer',
  'SetNavbarType'
);
