import React from 'react';
import BasicTemplatePrevious from '../../BasicTemplate/Previous';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../../configuration';

const DefaultItemForm = (props: IItemDetailsProps) => {
  return <BasicTemplatePrevious {...props} />;
};

export default DefaultItemForm;
