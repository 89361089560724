import {
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { CSSProperties } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  FilteringType,
  DropdownOptionsType,
  IFilteringData,
  IDateRange,
  IFilteringFieldsStateData,
} from '../../configuration';
import { useMenuIsVisible } from '../../provider';
import _ from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import usePrevious from 'hooks/usePrevious';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { PaymentTypeEnum } from 'constants/paymentsConst';
// import useStyles from '../TableList/styles';
import useStyles from './styles';

interface ITableFilteringFieldProps {
  options?: DropdownOptionsType;
  label: string;
  name: string;
  type?: FilteringType;
  filteringData: {
    current: IFilteringData;
  };
  handleFiltering?: (resetForm: boolean) => void;
  defaultValue?: any;
  style?: CSSProperties;
}

const DROPDOWN_DEFAULT_VALUE = '';
const STANDARD_WIDTH = 150;

// https://material-table.com/#/docs/features/filtering
const TableFilteringField = (props: ITableFilteringFieldProps) => {
  const [value, setValue] = React.useState<IFilteringFieldsStateData>();
  const [debouncedValue, setDebouncedValue] = React.useState<
    string | number | undefined
  >(
    (props.type === 'text' || props.type === 'number') &&
      (typeof value === 'string' || typeof value === 'number')
      ? value
      : undefined
  );
  const classes = useStyles({});
  const menuIsVisible = useMenuIsVisible();
  const previous = usePrevious(value);

  // console.log('PREVIOUS!!::', props.name, previous, value);

  React.useEffect(() => {
    setDebouncedValue(props.defaultValue ?? '');
    setValue(props.defaultValue ?? '');
  }, [menuIsVisible]);

  // event: React.ChangeEvent<{ value: any }>
  const handleChange = (name: string, value: any) => {
    _.set(props.filteringData.current, name, value);
    if (props.type !== 'text' && props.type !== 'number')
      setValue(value as any);
    else setDebouncedValue(value as string);
  };

  React.useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedValue), 500);
    return () => clearTimeout(timer);
  }, [debouncedValue]);

  React.useEffect(() => {
    if (value || previous) {
      props.handleFiltering && props.handleFiltering(false);
    }
  }, [value]);

  if (!menuIsVisible && !props.defaultValue) {
    return null;
  }

  return (
    <React.Fragment>
      {props.type === 'datetime' ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            autoOk
            margin="none"
            format={'dd-MM-yyyy'}
            fullWidth
            id="date-picker-inline"
            style={{ width: STANDARD_WIDTH, ...props.style }}
            placeholder={props.label}
            value={value || null}
            onChange={(date: MaterialUiPickersDate | null) =>
              handleChange(props.name, date)
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      ) : props.type === 'date-range' ? (
        // style={{ display: 'flex', flexDirection: 'row' }}
        <div className={classes.dateRangeContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              style={{ width: STANDARD_WIDTH, ...props.style }}
              autoOk
              margin="none"
              format={'dd-MM-yyyy'}
              // fullWidth
              id="date-picker-inline"
              placeholder={'Start date'}
              value={(value as IDateRange)?.start || null}
              onChange={(date: MaterialUiPickersDate | null) =>
                handleChange(props.name, {
                  ...(value as IDateRange),
                  start: date,
                })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              style={{
                // marginLeft: '.5rem',
                width: STANDARD_WIDTH,
                ...props.style,
              }}
              disableToolbar
              variant="inline"
              autoOk
              margin="none"
              format={'dd-MM-yyyy'}
              // fullWidth
              id="date-picker-inline"
              placeholder={'End date'}
              value={(value as IDateRange)?.end || null}
              onChange={(date: MaterialUiPickersDate | null) =>
                handleChange(props.name, {
                  ...(value as IDateRange),
                  end: date,
                })
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      ) : props.type === 'time' ? (
        <TextField
          id="time"
          placeholder={props.label}
          type="time"
          fullWidth
          onChange={(event) => handleChange(props.name, event.target.value)}
          // className={classes.timepicker}
          style={{ width: STANDARD_WIDTH, ...props.style }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      ) : props.type === 'dropdown' ? (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || DROPDOWN_DEFAULT_VALUE}
          fullWidth
          displayEmpty
          style={{ width: STANDARD_WIDTH, ...props.style }}
          label={'props.label'}
          onChange={(event) => handleChange(props.name, event.target.value)}
        >
          <MenuItem value={DROPDOWN_DEFAULT_VALUE}>{props.label}</MenuItem>
          {(props.options || []).map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
      ) : props.type === 'text' || props.type === 'number' ? (
        <TextField
          value={
            props.type === 'text'
              ? debouncedValue
              : props.type === 'number'
              ? debouncedValue
              : ''
          }
          fullWidth
          onChange={(event) => handleChange(props.name, event.target.value)}
          placeholder={props.label}
          type={props.type === 'text' ? 'search' : props.type}
        />
      ) : props.type === 'multiple-select' ? (
        <TextField
          fullWidth
          style={{ width: STANDARD_WIDTH, ...props.style }}
          select
          onChange={(event) => handleChange(props.name, event.target.value)}
          SelectProps={{
            multiple: true,
            value: value || [],
            renderValue: (selected: any) => (
              <div
                style={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  gap: 6,
                }}
              >
                {selected.length > 1 ? (
                  <React.Fragment>
                    <Chip
                      size="small"
                      label={
                        _.keyBy(props.options || [], 'id')[selected[0]].label
                      }
                    />
                    <Chip size="small" label={`+${selected.length - 1} more`} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {selected.map((_value: any, index: any) => (
                      <Chip
                        size="small"
                        key={index}
                        label={_.keyBy(props.options || [], 'id')[_value].label}
                      />
                    ))}
                  </React.Fragment>
                )}
              </div>
            ),
          }}
        >
          {(props.options || []).map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <React.Fragment>
                <Checkbox
                  checked={Array.isArray(value) && value.includes(item.id)}
                />
                <ListItemText primary={item.label} />
              </React.Fragment>
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField style={{ visibility: 'hidden' }} />
      )}
    </React.Fragment>
  );
};

export default React.memo(TableFilteringField);
