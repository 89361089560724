import { useSelector } from 'react-redux';
import { getState } from 'redux/store';
import { ReducerKeys } from '../../config';
import { IState } from './reducer';

export const useSidebarIsVisible = (): boolean => {
  return useSelector(
    (state: any) => state[ReducerKeys.NAVIGATION_REDUCER].sideMenuIsOpen
  );
};

export const useNavbarElementIsSelected = (elementID: number): boolean => {
  return useSelector(
    (state: any) =>
      state[ReducerKeys.NAVIGATION_REDUCER].selectedItemID === elementID
  );
};

export const useNavigationType = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.NAVIGATION_REDUCER] as IState).navigationType
  );
};

// https://material-ui.com/customization/breakpoints/
export const isMobileScreen = (): boolean => {
  return window.innerWidth <= 960;
};

export const sideBarIsVisible = (): boolean => {
  return getState().NavigationReducer.sideMenuIsOpen;
};
