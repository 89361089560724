import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import { ISystemSettingsData, IOSInfoData } from 'services/api/system';
import * as ScriptsService from 'services/api/scripts';

export interface IState {
  manageBackup: {
    status: StateStatus | null;
  };
  updateSettings: {
    status: StateStatus | null;
  };
  systemSettingsDetails: {
    status: StateStatus | null;
    data: ISystemSettingsData | null;
  };
  osInfo: {
    status: StateStatus | null;
    data: IOSInfoData | null;
  };
  uploadAndRestoreDatabase: {
    status: StateStatus | null;
  };
  backupsList: {
    status: StateStatus | null;
    data: ScriptsService.IBackupsListData | null;
  };
  removeBackupFileStatus: StateStatus | null;
  restoreDatabaseStatus: StateStatus | null;
}

const initialState: IState = {
  updateSettings: {
    status: null,
  },
  manageBackup: {
    status: null,
  },
  systemSettingsDetails: {
    status: null,
    data: null,
  },
  osInfo: {
    status: null,
    data: null,
  },
  uploadAndRestoreDatabase: {
    status: null,
  },
  backupsList: {
    status: null,
    data: null,
  },
  removeBackupFileStatus: null,
  restoreDatabaseStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_BACKUPS_LIST:
      return {
        ...state,
        backupsList: {
          data:
            action.data === KEEP_THE_SAME
              ? state.backupsList.data
              : action.data,
          status: action.status || null,
        },
      };
    case Types.REMOVE_BACKUP_FILE:
      return {
        ...state,
        removeBackupFileStatus: action.status || null,
      };
    case Types.RESTORE_DATABASE:
      return {
        ...state,
        restoreDatabaseStatus: action.status || null,
      };
    case Types.DOWNLOAD_BACKUP:
      return {
        ...state,
        manageBackup: {
          status: action.status || null,
        },
      };
    case Types.UPDATE_SYSTEM_SETTINGS:
      return {
        ...state,
        updateSettings: {
          status: action.status || null,
        },
      };
    case Types.SET_SYSTEM_SETTINGS_DETAILS:
      return {
        ...state,
        systemSettingsDetails: {
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.SET_OS_INFO:
      return {
        ...state,
        osInfo: {
          status: action.status || null,
          data: action.data === KEEP_THE_SAME ? state.osInfo.data : action.data,
        },
      };
    case Types.UPLOAD_AND_RESTORE_DATABASE:
      return {
        ...state,
        uploadAndRestoreDatabase: {
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
