import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { IState } from './reducer';

/* selector: useCollectionsList */
export const useCollectionsList = (): IState['collectionsList'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.COLLECTION_ITEMS_PER_STUDENT_REDUCER] as IState)
        .collectionsList
  );
};
