import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import type * as ItemsServices from 'services/api/items';

export interface IState {
  collectionsList: {
    status: StateStatus | null;
    data: ItemsServices.IViewCollectionItemsPerStudentData | null;
  };
}

const initialState: IState = {
  collectionsList: {
    status: null,
    data: null,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_COLLECTIONS_LIST:
      return {
        ...state,
        collectionsList: {
          ...state.collectionsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.collectionsList.data
              : action.data,
        },
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
