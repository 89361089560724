import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import {
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from '@material-ui/core';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import store from 'redux/store';
import FilesList from './components/FilesList';
import UploadedFilesAction from './store/actions';
import { DefaultPaging } from './configuration';
import UploadFileButton from './components/UploadFileButton';
import { IModalParams } from './types';
import EditFileDataModal from './components/EditFileDataModal';
import LoadingView from 'components/atoms/LoadingView';
import { useUploadFileStatus } from './store/selectors';
import { StateStatus } from 'redux/utils/common';
import { ImageEditorModal } from 'components/molecules/ImageEditor';
import ShowWarningsButton from './components/ShowWarningsButton';
import UploadFileFromExternalProvider from './components/UploadFileFromExternalProvider';
import useStyles from './styles';
import { FileType } from 'constants/filesConst';
import { ContextProvider, IParams } from './provider';

export { openUploadedFilesModal, closeUploadedFilesModal } from './common';

const ModalAUploadedFiles = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_UPLOADED_FILES_LIST}
      renderContent={(props: IModalParams) => (
        <UploadedFilesContent {...props} />
      )}
      modalProps={{
        fullWidth: true,
        fullScreen: matches,
        maxWidth: 'lg',
        style: {
          minHeight: '75vh',
        },
      }}
      renderHeader={(props: IModalParams) => (
        <TranslatedText defaultText={'Files List'} />
      )}
    />
  );
};

export const UploadedFilesContent = withReducer<IModalParams>(
  ReducerKeys.UPLOADED_FILES_REDUCER
)((props: IModalParams) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [params, setParams] = React.useState<IParams>({
    globalSearch: props.initialSearch,
    fileType: props.type,
  });

  const refetch = React.useCallback(
    (initialSearch?: boolean) => {
      let _params = params;
      if (initialSearch) {
        _params = {
          // globalSearch: props.initialSearch,
          fileType: props.type,
        };
        setParams(_params);
      }
      dispatch(
        UploadedFilesAction.fetchFilesAction({
          payload: {
            // ...DefaultPaging,
            // globalSearch: props.initialSearch,
            // fileType: props.type,
            ..._params,
          },
          onSuccess: () => {},
        })
      );
    },
    [params, setParams, dispatch]
  );

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  // React.useEffect(() => {
  //   dispatch(
  //     UploadedFilesAction.fetchFilesAction({
  //       payload: {
  //         ...DefaultPaging,
  //         globalSearch: props.initialSearch,
  //         fileType: props.type,
  //       },
  //       onSuccess: () => {},
  //     })
  //   );
  // }, [dispatch, props.initialSearch, props.type]);

  return (
    <ContextProvider
      props={{
        ...params,
        setParams,
        refetch,
      }}
    >
      <ImageEditorModal />
      <Loading />
      <EditFileDataModal />
      <Box className={classes.buttonsActions}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'row'} flex={1}>
          <UploadFileButton fileType={props.type} />
          {(props.type === FileType.Image ||
            props.type === FileType.File ||
            !props.type) && (
            <UploadFileFromExternalProvider
              initialSearch={props.initialSearch}
            />
          )}
        </Box>

        <ShowWarningsButton />
        {/* <UploadFileFromURL /> */}
      </Box>
      <FilesList {...props} />
    </ContextProvider>
  );
});

const Loading = () => {
  const uploadStatus = useUploadFileStatus();
  return (
    <>{uploadStatus === StateStatus.Pending && <LoadingView fullScreen />}</>
  );
};

export default ModalAUploadedFiles;
