import React from 'react';
import { SwitchProps, Switch as MuiSwitch } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import useStyles from './styles';
import clsx from 'clsx';

interface Iprops extends Omit<SwitchProps, 'onChange' | 'checked'> {
  variant?: 'outlined' | 'normal';
  control: Control;
  rules: any;
  name: string;
  margin?: 'none' | 'dense' | 'normal';
  label?: string | React.ReactElement;
  controllerExtras?: any;
  spaceBetween?: boolean;
}

export default function Switch(props: Iprops) {
  const {
    variant = 'outlined',
    control,
    label,
    rules,
    controllerExtras = {},
    spaceBetween = true,
    ...other
  } = props;
  const classes = useStyles({ margin: props.margin === 'normal' });

  return (
    <section
      className={clsx({
        [classes.container]: variant === 'outlined',
      })}
      style={
        spaceBetween
          ? {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }
          : undefined
      }
    >
      <label>{label}</label>
      <Controller
        name={other.name}
        render={(props) => (
          <MuiSwitch
            {...props}
            {...other}
            onChange={(e) => props.onChange(e.target.checked)}
            checked={props.value}
          />
        )}
        control={control}
        {...controllerExtras}
      />
    </section>
  );
}
