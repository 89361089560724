import React from 'react';
import OptionsMenu from 'components/atoms/OptionsMenu';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormData } from '../../configuration';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { IconButton } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { transcriptYoutubeVideo } from 'services/api/utils';
import TranscriptTextModal, {
  openTranscriptTextModal,
} from './TranscriptTextModal';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';

const VideoActionsMenu = () => {
  const { getValues, control } = useFormContext<IFormData>();
  const watchVideoUrl = useWatch({
    control,
    name: 'link',
  }) as string;

  return (
    <OptionsMenu
      id="video-more"
      options={[
        {
          id: 'preview',
          label: 'Preview',
          onClick: () => {
            getValues().link && window.open(getValues().link ?? '', '_blank');
          },
          iconLeft: <VideocamOutlinedIcon />,
        },
        {
          id: 'transcript',
          label: 'Transcript',
          disabled: (watchVideoUrl?.length ?? 0) === 0,
          onClick: async () => {
            const result = await transcriptYoutubeVideo({
              videoUrl: getValues().link ?? '',
            });
            openTranscriptTextModal({
              data: result.data.transcript.map((el) => el.text).join(' '),
            });
          },
          iconLeft: <ClosedCaptionIcon />,
        },
      ]}
    >
      <IconButton>
        <MenuOpenIcon />
      </IconButton>
    </OptionsMenu>
  );
};

export default React.memo(VideoActionsMenu);
