import { Box, Button, Paper, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import { NavbarTypeEnum } from 'constants/navigationConst';
import React from 'react';
import { useDispatch } from 'react-redux';
import NavigationActions from 'redux/reducers/gl_navigation_reducer/actions';
import useStyles from './styles';
import * as NavigationSelector from 'redux/reducers/gl_navigation_reducer/selectors';
import clsx from 'clsx';

const NavType = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navMenu = NavigationSelector.useNavigationType();

  return (
    <Box display="flex" flexDirection="column">
      <Paper
        variant="outlined"
        className={clsx({
          [classes.element]: true,
          [classes.selected]: navMenu === NavbarTypeEnum.TOP_MENU,
        })}
        onClick={() =>
          dispatch(
            NavigationActions.setNavbarTypeAction(NavbarTypeEnum.TOP_MENU)
          )
        }
      >
        <Typography>
          <TranslatedText defaultText={'Toolbar Menu'} />
        </Typography>
      </Paper>

      <Paper
        variant="outlined"
        className={clsx({
          [classes.element]: true,
          [classes.selected]: navMenu === NavbarTypeEnum.SIDE_MENU,
        })}
        onClick={() =>
          dispatch(
            NavigationActions.setNavbarTypeAction(NavbarTypeEnum.SIDE_MENU)
          )
        }
      >
        <Typography>
          <TranslatedText defaultText={'Sidebar Menu'} />
        </Typography>
      </Paper>

      <Paper
        variant="outlined"
        className={clsx({
          [classes.element]: true,
          [classes.selected]: navMenu === NavbarTypeEnum.MINI_SIDE_MENU,
        })}
        onClick={() =>
          dispatch(
            NavigationActions.setNavbarTypeAction(NavbarTypeEnum.MINI_SIDE_MENU)
          )
        }
      >
        <Typography>
          <TranslatedText defaultText={'Mini Sidebar Menu'} />
        </Typography>
      </Paper>
    </Box>
  );
};

export default React.memo(NavType);
