export const capitalizeFirstLetter = (s: string | undefined) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeFirstLetterLowerRest = (s: string | undefined) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const replaceElementWith = (
  inputString: string,
  replaceThis: string,
  withThis: string
) => {
  let re = new RegExp(`${replaceThis}`, 'gi');
  return inputString.replace(re, withThis);
};

export const removeTagsFromString = (text: string) => {
  return text.replace(/(<([^>]+)>)/gi, '') ?? '';
};

export const removeExtraSpaces = (text: string) => {
  return text.replace(/\s+/g, ' ').trim();
};

export function formatCamelOrUnderscoreCaseToSentenceCase(
  input: string
): string {
  // Replace underscores with spaces
  let str = input.replace(/_/g, ' ');

  // Replace camelCase with words separated by spaces
  str = str?.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  str = str?.replace(/\b\w/g, (match) => match.toUpperCase());

  return str;
}

export function extractUniqueNames(dialogue: string): string[] {
  const lines = dialogue.split('\n');
  const names = new Set<string>();

  for (const line of lines) {
    const parts = line.split(':');
    if (parts.length > 1) {
      names.add(parts[0].trim());
    }
  }

  return Array.from(names);
}
