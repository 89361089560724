import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const UPLOAD_IMAGE_FROM_EXTERNAL = typeCreatorV2(
  'DiscoverImagesReducer',
  'UploadFile'
);

export const SET_IMAGES_LIST = typeCreatorV2(
  'DiscoverImagesReducer',
  'SetImagesList'
);
