import ContextBuilder from 'providers/ContextBuilder';
import type { IColLibrariesPerCollectionParams } from './types';

export const { ContextProvider, useContextProps } = ContextBuilder<
  IColLibrariesPerCollectionParams & {
    setProps?: (newProps: IColLibrariesPerCollectionParams) => void;
  }
>({
  collectionId: '',
  title: '',
  studentId: '',
  collectionType: '' as never,
});
