import { Box } from '@material-ui/core';
import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import PrettyPrintJson from 'components/atoms/PrettyPrintJson';
import TranslatedText from 'components/atoms/TranslatedText';
import store from 'redux/store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { IWordInfoPayload } from 'services/api/utils';
import { useDispatch } from 'react-redux';
import { useWordInfo } from '../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import ItemDetailsActions from '../../store/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IWordInfoDataParams {
  type: IWordInfoPayload['type'];
  title: React.ReactNode;
  text: string;
}

const WordInfoModal = () => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_WORD_INFO}
      showExitIcon={true}
      contentProps={{ style: { minHeight: '40vh' } }}
      modalProps={{ fullWidth: true }}
      renderHeader={(params) => (
        <TranslatedText defaultText={params.name || `Info`} />
      )}
      renderContent={Content}
    />
  );
};

const Content = (params: IWordInfoDataParams) => {
  const dispatch = useDispatch();
  const data = useWordInfo();

  React.useEffect(() => {
    // if (params.type && params.text) {
    //   dispatch(
    //     ItemDetailsActions.setWordInfoAction({
    //       type: params.type,
    //       text: params.text,
    //       lanTo: 'en',
    //     })
    //   );
    // }
  }, [dispatch, params]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {data.status === StateStatus.Pending ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <PrettyPrintJson data={data.data as any} />
      )}
    </Box>
  );
};

export const openWordInfoModal = (params: IWordInfoDataParams) => {
  store.dispatch(
    ModalActions.openModalAction({ modalID: ModalIDs.MODAL_WORD_INFO, params })
  );
};

export const closeWordInfoModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({ modalID: ModalIDs.MODAL_WORD_INFO })
  );
};

export default React.memo(WordInfoModal);
