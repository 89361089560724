import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import reactLogger from 'utils/logger';
import * as TricksService from 'services/api/items';
import * as UtilsService from 'services/api/utils';
import { IState } from './reducer';
import * as TagsService from 'services/api/tags';
import { TagTypes } from 'constants/tagsConst';

/** AddTrick  */
interface IAddTrick {
  payload: TricksService.IAddTrickInput;
  onSuccess: () => void;
}

class AddTrick implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_TRICK,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_TRICK,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_TRICK,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IAddTrick): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await TricksService.addTrick(payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('AddTrick:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Tags  */
class FetchLTagsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TAGS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: IState['tagsList']['data']): IAction {
    return {
      type: Types.SET_TAGS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TAGS,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        // const response = await TricksService.fetchItemTagsList();
        const response = await TagsService.fetchTagList({
          pageSize: Number.MAX_SAFE_INTEGER,
          type: TagTypes.Item,
        });

        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchLTagsList:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Trick Details  */
class FetchTrickDetails implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_TRICK_DETAILS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: TricksService.FetchTrickDetailsData): IAction {
    return {
      type: Types.SET_TRICK_DETAILS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_TRICK_DETAILS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(trickId: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await TricksService.fetchTrickDetails(trickId);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchTrickDetails:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** EditTrick  */
interface IEditTrick {
  trickId: string;
  payload: TricksService.IEditTrickInput;
  onSuccess: () => void;
}

class EditTrick implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_TRICK,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.EDIT_TRICK,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_TRICK,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IEditTrick): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        // console.log('PAYLOAD::::::::::', payload);
        await TricksService.editTrick(payload.trickId, payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('EditTrick:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** IRemoveTrick  */
interface IRemoveTrick {
  payload: TricksService.IRemoveItemPayload;
  onSuccess: () => void;
}

class RemoveTrick implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_TRICK,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_TRICK,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_TRICK,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IRemoveTrick): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await TricksService.removeTrick(input.payload);
        input.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('RemoveTrick:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

class Reset implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method Not Implemented');
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    throw new Error('Method Not Implemented');
  }

  action(): IAction {
    return this.onSuccess();
  }
}

/** Set Word Info  */
class SetWordInfo implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.WORD_INFO,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: UtilsService.IWordInfoData): IAction {
    return {
      type: Types.WORD_INFO,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.WORD_INFO,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: UtilsService.IWordInfoPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await UtilsService.getWordInfo(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('SetWordInfo:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// /** Fetch Item Prompts */
// interface IFetchItemPromptsInput {
//   onSuccess?: () => void;
//   itemId: string;
// }

// class FetchItemPrompts implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_ITEM_PROMPTS,
//       status: StateStatus.Pending,
//       data: null,
//     };
//   }
//   onSuccess(data: IState['itemPrompts']['data']): IAction {
//     return {
//       type: Types.SET_ITEM_PROMPTS,
//       status: StateStatus.Success,
//       data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_ITEM_PROMPTS,
//       status: StateStatus.Failed,
//       data: null,
//     };
//   }

//   action(input: IFetchItemPromptsInput): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         const response = await OpenAIService.fetchItemPrompt(input.itemId);
//         input.onSuccess && input.onSuccess();
//         dispatch(this.onSuccess(response.data));
//       } catch (error) {
//         reactLogger.error('FetchItemPrompts:', error.message); // '<ClassName> Error: <error>'
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

export default {
  resetAction: () => new Reset().action(),
  removeTrickAction: (payload: IRemoveTrick) =>
    new RemoveTrick().action(payload),
  addTrickAction: (payload: IAddTrick) => new AddTrick().action(payload),
  editTrickAction: (payload: IEditTrick) => new EditTrick().action(payload),
  setWordInfoAction: (payload: UtilsService.IWordInfoPayload) =>
    new SetWordInfo().action(payload),
  fetchTagsListAction: () => new FetchLTagsList().action(),
  fetchTrickDetailsAction: (trickId: string) =>
    new FetchTrickDetails().action(trickId),
  // fetchItemPromptsAction: (payload: IFetchItemPromptsInput) =>
  //   new FetchItemPrompts().action(payload),
};
