import { LanguagesEnum } from 'constants/languagesConst';
import { NavbarTypeEnum } from 'constants/navigationConst';
import { ThemesModeEnum } from 'constants/themeConst';
import { ToastEnum } from 'constants/toastConst';
import { ClientEnum } from 'constants/clientConst';
import { PalletesEnum } from 'constants/palletesConst';

interface Environment {
  API_BASE_URL: string;
  APP_NAME: string;
  DEFAULT_NAVIGATION_TYPE: NavbarTypeEnum;
  TOAST_TYPE: ToastEnum;
  DEFAULT_LANGUAGE: LanguagesEnum;
  SIDE_MENU_WIDTH: 240 | 260 | 300;
  DEFAULT_THEME: ThemesModeEnum;
  CLIENT_TYPE: ClientEnum;
  DEFAULT_PALLET: PalletesEnum;
  MOB_ADS_SNIPPET: string;
  APP_MOB_BASE_URL: string;
  PRODUCTION: boolean;
}

const DefaultEnv: Environment = {
  API_BASE_URL: 'http://localhost:8001', // 'http://192.168.2.38:8001', //
  APP_NAME: 'Admin Portal',
  DEFAULT_NAVIGATION_TYPE: NavbarTypeEnum.MINI_SIDE_MENU,
  TOAST_TYPE: ToastEnum.Toastify,
  DEFAULT_LANGUAGE: LanguagesEnum.Greek,
  SIDE_MENU_WIDTH: 260,
  DEFAULT_THEME: ThemesModeEnum.Light,
  CLIENT_TYPE: ClientEnum.Web,
  DEFAULT_PALLET: PalletesEnum.DefaultPallete,
  APP_MOB_BASE_URL: 'http://localhost:3000',
  MOB_ADS_SNIPPET: 'google.com, pub-4604915772456866, DIRECT, f08c47fec0942fa0',

  PRODUCTION: false,
};

const Prodution: Environment = {
  APP_NAME: DefaultEnv.APP_NAME,
  DEFAULT_NAVIGATION_TYPE: DefaultEnv.DEFAULT_NAVIGATION_TYPE,
  TOAST_TYPE: DefaultEnv.TOAST_TYPE,
  DEFAULT_LANGUAGE: DefaultEnv.DEFAULT_LANGUAGE,
  SIDE_MENU_WIDTH: DefaultEnv.SIDE_MENU_WIDTH,
  DEFAULT_THEME: DefaultEnv.DEFAULT_THEME,
  CLIENT_TYPE: DefaultEnv.CLIENT_TYPE,
  DEFAULT_PALLET: DefaultEnv.DEFAULT_PALLET,
  MOB_ADS_SNIPPET: DefaultEnv.MOB_ADS_SNIPPET,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || DefaultEnv.API_BASE_URL,
  APP_MOB_BASE_URL:
    process.env.REACT_APP_MOB_BASE_URL || DefaultEnv.APP_MOB_BASE_URL,
  PRODUCTION: true,
};

const Development: Environment = {
  ...DefaultEnv,
};

export default process.env.NODE_ENV === 'production' ? Prodution : Development;
