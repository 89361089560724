import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  useTheme,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import BaseModal from 'components/molecules/BaseModal';
import React from 'react';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useFieldArray, useForm } from 'react-hook-form';
import store, { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import FilterListIcon from '@material-ui/icons/FilterList';
import OptionsMenu from 'components/atoms/OptionsMenu';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonCopy from 'components/atoms/ButtonCopy';
import { openUrlToNewTab } from 'utils/window';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

export interface PromptsModalParams {
  prompts: Array<{
    title: string;
    content: string;
    handleAddData?: () => void;
  }>;
}

const PromptsModal = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <BaseModal
        modalID={ModalIDs.MODAL_PROMPTS}
        showExitIcon={true}
        contentProps={{
          style: { padding: 0, margin: 0 },
        }}
        modalProps={{
          fullWidth: true,
          fullScreen: matches,
        }}
        renderHeader={(params) => <Header {...params} />}
        renderContent={(params: PromptsModalParams) => {
          return <Content {...params} />;
        }}
        renderFooter={(params) => <Footer {...params} />}
      />
    </>
  );
};

const Header = () => {
  return (
    <Box alignItems="center" display="flex">
      View Prompts
    </Box>
  );
};

const Footer = () => {
  return (
    <Box display="flex">
      <Button
        onClick={() => {
          openUrlToNewTab('https://chat.openai.com/chat');
        }}
      >
        {'Open ChatGPT'}
      </Button>
    </Box>
  );
};

const Content = (params: PromptsModalParams) => {
  const dispatch = useDispatch();

  return (
    <div style={{ padding: '1rem' }}>
      {params.prompts.map((el, index) => (
        <div style={{ marginBottom: '0.5rem' }} key={index}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3>{el.title}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Paper
                  variant="outlined"
                  style={{
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {el.content}
                </Paper>
                <Box marginTop={'0.5rem'} alignItems={'center'} display="flex">
                  {el.handleAddData && (
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      style={{ marginRight: '0.4rem' }}
                      onClick={() => {
                        el.handleAddData && el.handleAddData();
                      }}
                    >
                      {'Add Data'}
                    </Button>
                  )}
                  <ButtonCopy
                    size="small"
                    text={el.content}
                    style={{ alignSelf: 'flex-end' }}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>

        // <div style={{ marginBottom: '0.5rem' }} key={index}>
        //   <h3>{el.title}</h3>
        //   <Paper
        //     variant="outlined"
        //     style={{
        //       padding: '1rem',
        //       display: 'flex',
        //       flexDirection: 'column',
        //     }}
        //   >
        //     {el.content}
        //     <ButtonCopy
        //       size="small"
        //       text={el.content}
        //       style={{ alignSelf: 'flex-end' }}
        //     />
        //   </Paper>
        // </div>
      ))}
    </div>
  );
};

export const openPromptsModal = (params: PromptsModalParams) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_PROMPTS,
      params,
    })
  );
};

export const closePromptsModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_PROMPTS,
    })
  );
};

export default React.memo(PromptsModal);
