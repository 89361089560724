import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useRemoveTagStatus */
export const useRemoveTagStatus = (): IState['removeTagStatus'] => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TAG_DETAILS_REDUCER] as IState).removeTagStatus
  );
};

/* selector: useEditTagStatus */
export const useEditTagStatus = (): IState['editTagStatus'] => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TAG_DETAILS_REDUCER] as IState).editTagStatus
  );
};

/* selector: useAddTagStatus */
export const useAddTagStatus = (): IState['addTagStatus'] => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TAG_DETAILS_REDUCER] as IState).addTagStatus
  );
};
