interface ILogger {
  warn: (...data: any[]) => void;
  log: (...data: any[]) => void;
  error: (...data: any[]) => void;
  renderComponent: (data: string) => void;
  renderPage: (data: string) => void;
}

const cssPage = 'font-size: 16px; color: blue';
const cssComponent = 'font-size: 14px; color: green; margin-left: 10px';

const reactLogger: ILogger = {
  warn: (...data: any[]): void => {
    process.env.NODE_ENV !== 'production' && console.warn(...data);
  },
  log: (...data: any[]): void => {
    process.env.NODE_ENV !== 'production' && console.log(...data);
  },
  error: (...data: any[]): void => {
    process.env.NODE_ENV !== 'production' && console.error(...data);
  },
  renderComponent: (componentName): void => {
    process.env.NODE_ENV !== 'production' &&
      console.log(`%cRendering Component -> %s`, cssComponent, componentName);
  },
  renderPage: (componentName): void => {
    process.env.NODE_ENV !== 'production' &&
      console.log(`%cRendering Page -> %s`, cssPage, componentName);
  },
};

export default reactLogger;
