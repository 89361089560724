import React from 'react';
import {
  IconButton,
  Box,
  Paper,
  PaperProps,
  Typography,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

interface ISectionProps extends Omit<PaperProps, 'children' | 'title'> {
  children: React.ReactElement;
  title?: React.ReactNode;
  contentIsVisible?: boolean;
  iconLeft?: React.ReactNode;
  footer?: React.ReactNode;
}

const Section = ({
  children,
  title,
  contentIsVisible = true,
  iconLeft,
  footer,
  ...props
}: ISectionProps) => {
  const [visibleContent, setVisible] = React.useState(contentIsVisible);

  return (
    <Paper
      square
      {...props}
      variant="outlined"
      style={{
        padding: '1rem',
        marginBottom: '2rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0.3rem',
          }}
        >
          {iconLeft}
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '1.3em',
              visibility: title ? 'visible' : 'hidden',
            }}
          >
            {title}
          </Typography>
        </Box>

        <IconButton size="small" onClick={() => setVisible(!visibleContent)}>
          {visibleContent ? (
            <RemoveCircleOutlineIcon />
          ) : (
            <AddCircleOutlineOutlinedIcon />
          )}
        </IconButton>
      </Box>

      <Box style={{ display: visibleContent ? 'block' : 'none' }}>
        {children}
        {footer && <Box>{footer}</Box>}
      </Box>
    </Paper>
  );
};

export default Section;
