import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import NestedMenuItem from './components/NestedMenuItem';
import CommonMenu from './components/CommonMenu';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { MenuProps } from 'react-select';

interface IITems {
  component: React.ReactNode;
  onClick?: () => void;
  items?: Array<IITems>;
  icon?: React.ReactNode;
  componentProps?: Partial<MenuProps>;
}

export interface INestedMenuProps<T = {}> {
  children: React.ReactElement<
    {
      onClick: (event: any) => void;
      [key: string]: any;
    } & T
  >;
  items: Array<IITems>;
  closeOnClick?: boolean;
  overrideDefaultOnClick?: boolean;
  menuDirection?: 'left' | 'right';
}

const NestedMenu = ({
  children,
  items,
  closeOnClick = true,
  overrideDefaultOnClick = true,
  menuDirection = 'right',
}: INestedMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick =
    (item: IITems, index: number) => (event: React.MouseEvent) => {
      item.onClick && item.onClick();
      if (closeOnClick) {
        setAnchorEl(null);
      }
    };

  const customRender = (items: IITems[]) => {
    return items.map((item, index) => {
      if ((item.items?.length ?? 0) > 0) {
        return (
          <NestedMenuItem
            label={item.component}
            parentMenuOpen={!!anchorEl}
            onClick={handleItemClick(item, index)}
            key={index}
            menuDirection={menuDirection}
            leftIcon={menuDirection === 'left' ? <ArrowLeft /> : undefined}
            rightIcon={menuDirection === 'right' ? <ArrowRight /> : undefined}
            {...item.componentProps}
          >
            {customRender(item.items ?? [])}
          </NestedMenuItem>
        );
      }
      return (
        <CommonMenu
          onClick={handleItemClick(item, index)}
          iconRight={item.icon}
          key={index}
        >
          {item.component}
        </CommonMenu>
      );
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {overrideDefaultOnClick
        ? React.cloneElement(children, {
            onClick: handleClick,
            id: 'basic-button',
            'aria-controls': open ? 'basic-menu' : undefined,
            'aria-haspopup': 'true',
            'aria-expande': open ? 'true' : undefined,
          })
        : children}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {customRender(items)}
      </Menu>
    </>
  );
};

export default NestedMenu;
