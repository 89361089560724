import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_RANKING_LIST = typeCreatorV2(
  'RankingReducer',
  'SetRankingList'
);

export const REMOVE_NAMESPACE = typeCreatorV2(
  'RankingReducer',
  'RemoveNamespace'
);
