import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_ROUTINES_LIST = typeCreatorV2(
  'RoutinesReducer',
  'SetRoutinesList'
);

export const CHANGE_DIFFICULTY = typeCreatorV2(
  'RoutinesReducer',
  'ChangeDifficulty'
);

export const HIDE_COLLECTION = typeCreatorV2(
  'RoutinesReducer',
  'HideCollection'
);

export const CLONE_COLLECTION_FULL = typeCreatorV2(
  'RoutinesReducer',
  'CloneCollctionFull'
);
