import React, { ReactElement } from 'react';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import {
  AppAdvancedselect,
  AppTextField,
  AppSelect,
  IAdvancedSelectProps,
  AppCommon,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ExerciseActions from './ExerciseActions';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { getFileDetails, openFileToNewTabAsync } from 'services/api/file';
import { FileType } from 'constants/filesConst';
import { getState } from 'redux/store';
import * as OpenAIService from 'services/api/openai';
import { IState } from '../../store/reducer';
import { formatCamelOrUnderscoreCaseToSentenceCase } from 'utils/strings';
import { Roles } from 'constants/authRolesConst';
import { IItemPromptData } from 'services/api/openai';
import {
  openTranslationModalFormModal,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import type { IFormData } from '../../types';
import GridLayout, {
  IElement,
  IElementsProps,
} from 'components/molecules/GridLayout';
import { useHandleTranslation } from '../../hooks';
import { ItemGroups, ItemTypeEnum } from 'constants/typesConst';
import LanguageIcon from '@material-ui/icons/Language';
import { IBasicTemplateProps } from './types';
import { magicFormat, pairParagraphs } from 'utils/chunks';

export type IBookContentSectionProps = {
  config: IBasicTemplateProps['config'];
};

const BookContentTranslationSection = ({
  config,
}: IBookContentSectionProps) => {
  const { errors, control, getValues, setValue } = useFormContext<IFormData>();
  const { handleTranslation } = useHandleTranslation();

  return (
    <AppCommon.Section
      contentIsVisible={false}
      iconLeft={
        <>
          <IconButton
            onClick={() => {
              handleTranslation({
                title: 'Content Translation',
                name: 'bookSection_ContentTranslation',
                type: 'textarea',
                textToTranslate: getValues().bookSection_Content ?? '',
                lanFrom: 'namespaceLan',
                tabs: true,
                rows: 25,
                modalSize: 'xlarge',
              });
            }}
            size="small"
            style={{ marginRight: '0.3rem' }}
          >
            <LanguageIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              const result = pairParagraphs(
                getValues().bookSection_Content ?? '',
                getValues().bookSection_ContentTranslation ?? '',
                (text1ParLength, _) => text1ParLength
              );

              setValue(
                'bookSection_ContentTranslation',
                result.pairedParagraphs.map((el) => el.text2).join('\n\n')
              );
            }}
            size="small"
            style={{ marginRight: '0.3rem' }}
          >
            <HorizontalSplitIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              const result = magicFormat(
                getValues().bookSection_ContentTranslation ?? ''
              );

              setValue('bookSection_ContentTranslation', result);
            }}
            size="small"
            style={{ marginRight: '0.3rem' }}
          >
            <FormatLineSpacingIcon />
          </IconButton>
        </>
      }
      title={'Book Content Translation'}
      footer={
        <SelectedLanguages
          fieldName="bookSection_ContentTranslation"
          control={control}
        />
      }
    >
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'bookSection_ContentTranslation',
            element: (
              <AppTextField
                variant="outlined"
                size="medium"
                margin="normal"
                error={'bookSection_ContentTranslation' in errors}
                helperText={
                  errors.bookSection_ContentTranslation &&
                  errors.bookSection_ContentTranslation.message
                }
                rules={{}}
                fullWidth
                multiline
                rows={30}
                id="bookSection_ContentTranslation"
                placeholder={'Enter book content translation..'}
                control={control}
                autoComplete="bookSection_ContentTranslation"
                name="bookSection_ContentTranslation"
              />
            ),
            size: 12,
          },
        ]}
      />
    </AppCommon.Section>
  );
};

export default React.memo(BookContentTranslationSection);
