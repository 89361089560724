import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UserServices from 'services/api/user';
import * as NamespacesService from 'services/api/namespaces';

export interface IState {
  profileData: {
    status: StateStatus | null;
    data: UserServices.GetUserData | null;
  };
  editProfile: {
    status: StateStatus | null;
  };
  changePassword: {
    status: StateStatus | null;
  };
  // downloadBackup: {
  //   status: StateStatus | null;
  // };
  updateSettings: {
    status: StateStatus | null;
  };
  namespacesList: {
    status: StateStatus | null;
    data: UserServices.UserPortalDetailsData | null;
  };
}

const initialState: IState = {
  profileData: {
    status: null,
    data: null,
  },
  editProfile: {
    status: null,
  },
  changePassword: {
    status: null,
  },
  // downloadBackup: {
  //   status: null,
  // },
  updateSettings: {
    status: null,
  },
  namespacesList: {
    status: null,
    data: null,
  },
};

const UserPorifleReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_PROFILE:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.profileData.data
              : action.data,
        },
      };
    case Types.EDIT_PROFILE:
      return {
        ...state,
        editProfile: {
          ...state.editProfile,
          status: action.status || null,
        },
      };
    case Types.CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          status: action.status || null,
        },
      };

    case Types.UPDATE_SETTINGS:
      return {
        ...state,
        updateSettings: {
          ...state.updateSettings,
          status: action.status || null,
        },
      };
    case Types.SET_NAMESPACES_LIST:
      return {
        ...state,
        namespacesList: {
          ...state.namespacesList,
          status: action.status || null,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserPorifleReducer;
