import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const REMOVE_URL_ALIAS = typeCreatorV2(
  'UrlAliasDetailsReducer',
  'RemoveUrlAlias'
);

export const ADD_URL_ALIAS = typeCreatorV2(
  'UrlAliasDetailsReducer',
  'AddUrlAlias'
);

export const EDIT_URL_ALIAS = typeCreatorV2(
  'UrlAliasDetailsReducer',
  'EditUrlAlias'
);
