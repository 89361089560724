import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]

export const DOWNLOAD_BACKUP: string = typeCreatorV2(
  'SystemSettingsReducer',
  'DownloadBackup'
);

export const UPDATE_SYSTEM_SETTINGS: string = typeCreatorV2(
  'SystemSettingsReducer',
  'UpdateSettings'
);

export const SET_SYSTEM_SETTINGS_DETAILS: string = typeCreatorV2(
  'SystemSettingsReducer',
  'SetSystemSettingsDetails'
);

export const SET_OS_INFO: string = typeCreatorV2(
  'SystemSettingsReducer',
  'SetOsInfo'
);

export const UPLOAD_AND_RESTORE_DATABASE: string = typeCreatorV2(
  'SystemSettingsReducer',
  'UploadAndRestoreDatabase'
);

export const RESTORE_DATABASE: string = typeCreatorV2(
  'SystemSettingsReducer',
  'RestoreDatabase'
);

export const SET_BACKUPS_LIST: string = typeCreatorV2(
  'SystemSettingsReducer',
  'SetBackupsList'
);

export const REMOVE_BACKUP_FILE: string = typeCreatorV2(
  'SystemSettingsReducer',
  'RemoveBackupFile'
);
