import React from 'react';
import BasicTemplate from '../../BasicTemplate';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../../configuration';
import { showToastAction } from 'components/atoms/ToastMessage';
import { validateFillTheBlankMultiple } from 'components/compounds/ItemDetailsModal/utils';

const FillTheBlankMultipleForm = (props: IItemDetailsProps) => {
  return (
    <BasicTemplate
      {...props}
      config={{
        sectionTitle: {
          visibility: {
            includeTitle: true,
            includeExerciseActions: true,
          },
          type: 'textarea',
        },
        senctionBasicInfo: {
          visibility: {
            includeStatus: true,
            includePublic: true,
            includeType: true,
            includeDifficulty: true,
            includeNamespaces: true,
          },
        },
        senctionAdditionalInfo: {
          visibility: {
            includeAdditionalInfoText: true,
          },
          renameLabel: 'Answer',
        },
        senctionExplanation: {
          visibility: {
            includeDescription: true,
          },
        },
        senctionQuestion: {
          visibility: {
            includeQuestion: true,
          },
        },
        sectionExamples: {
          visibility: {
            includeExamples: true,
          },
          renameLabel: 'Extra options',
        },
        validationOnSave: (formData) => {
          const result = validateFillTheBlankMultiple(
            formData.title!,
            formData.additionalInfo!
          );

          if (!result.correct) {
            showToastAction(result.message, 'error');
            return false;
          }

          return true;
        },
      }}
    />
  );
};

export default FillTheBlankMultipleForm;
