import {
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertFromHTML,
  convertToRaw,
  ContentState,
} from 'draft-js';
import React from 'react';
import './rich-editor-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { copyToClipboard, pasteFromClipboard } from 'utils/window';
import { showToastAction } from 'components/atoms/ToastMessage';
import { removeExtraSpaces, removeTagsFromString } from 'utils/strings';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface IRichTextProps {
  handleChange?: (text: EditorState) => void;
  initialState?: EditorState;
}

const RichText = ({ handleChange, initialState }: IRichTextProps) => {
  const editor = React.useRef<any>();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  React.useEffect(() => {
    initialState && setEditorState(initialState);
  }, [initialState]);

  React.useEffect(() => {
    handleChange && handleChange(editorState);
  }, [editorState, handleChange]);

  const focusEditor = React.useCallback(() => {
    editor.current.focusEditor();
  }, [editor]);

  // React.useEffect(() => {
  //   // focusEditor();
  // }, []);

  return (
    <div>
      <Editor
        editorState={editorState}
        // ref={editor}
        onEditorStateChange={(editorState) => setEditorState(editorState)}
      />
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button
          onClick={async () => {
            await copyToClipboard(convertDraftToHtml(editorState));
            showToastAction('Copy text successfully', 'info', {
              position: 'bottom-left',
            });
          }}
          startIcon={<FileCopyOutlinedIcon />}
        >
          {'Copy'}
        </Button>
        <Button
          onClick={async () => {
            const result = await pasteFromClipboard();
            result && setEditorState(convertHtmlToDraft(result));
            showToastAction('Paste text successfully', 'info', {
              position: 'bottom-left',
            });
          }}
          startIcon={<AssignmentOutlinedIcon />}
        >
          {'Paste'}
        </Button>
        <Button
          onClick={async () => {
            setEditorState(EditorState.createEmpty());
            showToastAction('Delete text successfully', 'info', {
              position: 'bottom-left',
            });
          }}
          startIcon={<DeleteOutlineIcon />}
        >
          {'Delete'}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export const convertDraftToHtml = (editorState: EditorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const markup = draftToHtml(rawContentState);

  if (removeExtraSpaces(removeTagsFromString(markup)).length === 0) {
    return '';
  }

  return markup;
};

export const convertHtmlToDraft = (html: string) => {
  // const blocksFromHTML = convertFromHTML(html);
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;

  const descriptState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  return EditorState.createWithContent(descriptState);
};

export default RichText;
