import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_TAGS = typeCreatorV2('TrickDetailsReducer', 'SetTags');

export const SET_PLACES = typeCreatorV2('TrickDetailsReducer', 'SetPlaces');

export const ADD_TRICK = typeCreatorV2('TrickDetailsReducer', 'AddTrick');

export const SET_TRICK_DETAILS = typeCreatorV2(
  'TrickDetailsReducer',
  'SetTrickDetails'
);

export const EDIT_TRICK = typeCreatorV2('TrickDetailsReducer', 'EditTrick');

export const REMOVE_TRICK = typeCreatorV2('TrickDetailsReducer', 'RemoveTrick');

export const WORD_INFO = typeCreatorV2('TrickDetailsReducer', 'WordInfo');

// export const SET_ITEM_PROMPTS = typeCreatorV2(
//   'RoutineDetailsReducer',
//   'SetItemPrompts'
// );

export const RESET = typeCreatorV2('TrickDetailsReducer', 'Reset');
