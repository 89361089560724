import React from 'react';
import BasicTemplate from '../../BasicTemplate';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../../configuration';

const TrueFalseForm = (props: IItemDetailsProps) => {
  return (
    <BasicTemplate
      {...props}
      config={{
        sectionTitle: {
          visibility: {
            includeTitle: true,
            includeExerciseActions: true,
          },
        },
        senctionBasicInfo: {
          visibility: {
            includeStatus: true,
            includePublic: true,
            includeType: true,
            includeDifficulty: true,
            includeNamespaces: true,
          },
        },
        senctionAdditionalInfo: {
          visibility: {
            includeAdditionalInfoDropdown: true,
          },
        },
        senctionExplanation: {
          visibility: {
            includeDescription: true,
          },
        },
      }}
    />
  );
};

export default TrueFalseForm;
