import { Box } from '@material-ui/core';
import React from 'react';
import { useContextProps } from '../../provider';
import SelectButton from 'components/molecules/SelectButton';
import useStyles from './styles';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import {
  downloadCsvCollection,
  exportCollectionExercises,
  exportCollectionText,
  exportCollectionBook,
} from 'services/api/collections';
import * as RoutineDetailsSelectors from '../../store/selectors';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import TranslatedText from 'components/atoms/TranslatedText';
import { DefaultLanguage } from 'constants/languagesConst';

interface IExportCollectionButtonProps {
  export: {
    vocabulary?: boolean;
    book?: boolean;
    exercises?: boolean;
    vocabularyCsv?: boolean;
    description?: boolean;
  };
}

const ExportCollectionButton = (props: IExportCollectionButtonProps) => {
  const params = useContextProps();
  const classes = useStyles();
  const trickDetails = RoutineDetailsSelectors.useRoutineDetails();

  const routineId = params.collectionId;

  return (
    <Box>
      {params.mode !== 'add' && (
        <SelectButton
          buttonProps={{
            className: classes.submit,
            color: 'secondary',
            variant: 'outlined',
            startIcon: <SystemUpdateAltOutlinedIcon />,
            // fullWidth: true,
          }}
          items={[
            ...(props.export.exercises || props.export.vocabulary
              ? [
                  {
                    action: async () => {
                      try {
                        await exportCollectionExercises(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'exportCollectionExercisesVocabulary',
                    children: (
                      <>
                        <ListItemIcon>
                          <AssignmentOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Exercises/Vocabulary (.docx)" />
                      </>
                    ),
                  },
                ]
              : []),

            ...(props.export.description
              ? [
                  {
                    action: async () => {
                      try {
                        await exportCollectionText(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'exportCollectionText',
                    children: (
                      <>
                        <ListItemIcon>
                          <AssignmentOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Text (.docx)" />
                      </>
                    ),
                  },
                ]
              : []),

            ...(props.export.vocabularyCsv
              ? [
                  {
                    action: async () => {
                      try {
                        await downloadCsvCollection(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection'
                        );
                      } catch (error) {}
                    },
                    id: 'downloadCsv',
                    children: (
                      <>
                        <ListItemIcon>
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Exercises/Vocabulary (.csv)" />
                      </>
                    ),
                  },
                ]
              : []),

            ...(props.export.book
              ? [
                  {
                    action: async () => {
                      try {
                        await exportCollectionBook(
                          routineId as string,
                          trickDetails.data?.title ?? 'collection',
                          null
                        );

                        await exportCollectionBook(
                          routineId as string,
                          `${
                            trickDetails.data?.title ?? 'collection'
                          }-translation`,
                          DefaultLanguage
                        );
                      } catch (error) {}
                    },
                    id: 'bookDocx',
                    children: (
                      <>
                        <ListItemIcon>
                          <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Book (.docx)" />
                      </>
                    ),
                  },
                ]
              : []),

            // ...(routineId && roles.includes(Roles.Admin)
            //   ? [
            //       {
            //         action: async () => {
            //           const response =
            //             await OpenAIService.fetchCollectionPrompt(
            //               routineId
            //             );

            //           const mapData: Required<
            //             OverrideValues<
            //               ICollectionPromptData,
            //               ItemTypeEnum | null
            //             >
            //           > = {
            //             description: null,
            //             titleTranslations: null,
            //             itemsMultipleChoice: ItemTypeEnum.MultipleChoice,
            //             itemsTrueFalse: ItemTypeEnum.TrueOrFalse,
            //             itemsRearrangementQuestionAnswer:
            //               ItemTypeEnum.RearrangementAnswer,
            //             itemsQuestionAnswer: ItemTypeEnum.QuestionAnswer,
            //             itemsRearrangement: ItemTypeEnum.Rearrangement,
            //             itemsPhrasesByText: ItemTypeEnum.Phrase,
            //             itemsPhrasesByTopic: ItemTypeEnum.Phrase,
            //             itemsWordsByText: ItemTypeEnum.Word,
            //             itemsWordsByTopic: ItemTypeEnum.Word,
            //             itemsMultipleGap: ItemTypeEnum.FillTheGapMultiple,
            //             descriptionByItems: null,
            //             descriptionByTopic: null,
            //           };

            //           openPromptsModal({
            //             prompts: Object.keys(response.data ?? {}).map(
            //               (el) => ({
            //                 title:
            //                   formatCamelOrUnderscoreCaseToSentenceCase(
            //                     el
            //                   ),
            //                 content: _.get(response.data, el),
            //                 handleAddData: _.get(mapData, el)
            //                   ? () => {
            //                       openPromptInputModalModal({
            //                         title: `${el} Prompt`,
            //                         handleResult: async (data) => {
            //                           try {
            //                             await addItemsDataByPrompt({
            //                               collectionId: routineId,
            //                               promptResult: data,
            //                               itemType: _.get(mapData, el),
            //                             });
            //                           } catch (error) {
            //                             console.log(
            //                               'openPromptInputModalModal:',
            //                               error.message
            //                             );
            //                           }

            //                           // refetch data
            //                           dispatch(
            //                             RoutineDetailsActions.fetchRoutineDetailsAction(
            //                               routineId as string
            //                             )
            //                           );
            //                           closePromptsModal();
            //                         },
            //                       });
            //                     }
            //                   : undefined,
            //               })
            //             ),
            //           });
            //         },
            //         id: 'ViewPrompt',
            //         children: (
            //           <>
            //             <ListItemIcon>
            //               <DescriptionOutlinedIcon />
            //             </ListItemIcon>
            //             <ListItemText primary="View Prompt" />
            //           </>
            //         ),
            //       },
            //     ]
            //   : []),
          ]}
        >
          <TranslatedText defaultText={'EXPORT'} />
        </SelectButton>
      )}
    </Box>
  );
};

export default React.memo(ExportCollectionButton);
