import React from 'react';
import Content from './components/Content';
import { Box, Button, Checkbox, IconButton, Theme } from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import store from 'redux/store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import type { IColLibrariesPerCollectionProps } from './types';
import Footer from './components/ModalFooter';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import IsolateColLibrariesPerCollection from './components/IsolateColLibrariesPerCollection';

const CollectionItemsPerStudentModal = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_COLLECTION_ITEMS_PER_STUDENT}
      showExitIcon={true}
      modalProps={{ fullWidth: true, maxWidth: 'md', fullScreen: matches }}
      renderHeader={(params: IColLibrariesPerCollectionProps) => (
        <Header {...params} />
      )}
      renderContent={(params: IColLibrariesPerCollectionProps) => {
        return <Content {...params} />;
      }}
      renderFooter={(params: IColLibrariesPerCollectionProps) => (
        <Footer
          {...params}
          handleCancelAction={closeCollectionItemsPerStudentModal}
        />
      )}
    />
  );
};

const Header = (props: IColLibrariesPerCollectionProps) => {
  return (
    <Box alignItems="center" display="flex">
      <TranslatedText defaultText={props.title} />
    </Box>
  );
};

export const openCollectionItemsPerStudentModal = (
  params: IColLibrariesPerCollectionProps
) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_ITEMS_PER_STUDENT,
      params,
    })
  );
};

export const closeCollectionItemsPerStudentModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_COLLECTION_ITEMS_PER_STUDENT,
    })
  );
};

export default withReducer(ReducerKeys.COLLECTION_ITEMS_PER_STUDENT_REDUCER)(
  CollectionItemsPerStudentModal
);

export { IsolateColLibrariesPerCollection };
