// import { SideBarConfig } from '../../../navigation/config';
import { IAction } from '../../utils/common';
import * as Types from './types';
import Environment from 'config/environmentConfig';
import { NavbarTypeEnum } from 'constants/navigationConst';

export interface IState {
  sideMenuIsOpen: boolean;
  selectedItemID: number | string | null;
  navigationType: NavbarTypeEnum;
}

const initialState: IState = {
  sideMenuIsOpen: true,
  selectedItemID: null,
  //  SideBarConfig.length > 0 ? SideBarConfig[0].id : null,
  navigationType: Environment.DEFAULT_NAVIGATION_TYPE,
};

const NavigationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_SIDE_MENU_VISIBILITY:
      return {
        ...state,
        sideMenuIsOpen: action.data.sideMenuIsOpen,
      };
    case Types.SET_NAVIGATE_TO_SCREEN:
      return {
        ...state,
        sideMenuIsOpen: action.data.visibility,
        selectedItemID: action.data.selectedItemID,
      };
    case Types.SET_NAVBAR_TYPE:
      return {
        ...state,
        navigationType: action.data,
        sideMenuIsOpen: action.data === NavbarTypeEnum.SIDE_MENU,
      };
    default:
      return {
        ...state,
      };
  }
};

export default NavigationReducer;
