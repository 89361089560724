import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppAdvancedselect,
  AppTextField,
  AppSelect,
  IAdvancedSelectProps,
  AppCommon,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import _ from 'lodash';
import type { IFormData } from '../../types';
import GridLayout from 'components/molecules/GridLayout';
import { ItemGroups, ItemTypeEnum } from 'constants/typesConst';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import { IBasicTemplateProps } from './types';
import { IconButton } from '@material-ui/core';
import { magicFormat } from 'utils/chunks';

export type IBookContentSectionProps = {
  config: IBasicTemplateProps['config'];
};

const BookContentSection = ({ config }: IBookContentSectionProps) => {
  const { errors, control, getValues, setValue } = useFormContext<IFormData>();
  const itemType = useWatch({
    control,
    name: 'type',
  });

  return (
    <AppCommon.Section
      iconLeft={
        <>
          <IconButton
            onClick={() => {
              const result = magicFormat(getValues().bookSection_Content ?? '');

              setValue('bookSection_Content', result);
            }}
            size="small"
            style={{ marginRight: '0.3rem' }}
          >
            <FormatLineSpacingIcon />
          </IconButton>
        </>
      }
      title={'Book Content'}
    >
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'bookSection_Content',
            element: (
              <AppTextField
                multiline
                rows={30}
                variant="outlined"
                size="medium"
                margin="normal"
                error={'bookSection_Content' in errors}
                helperText={
                  errors.bookSection_Content &&
                  errors.bookSection_Content.message
                }
                rules={
                  [ItemTypeEnum.BookSection].includes(itemType as ItemTypeEnum)
                    ? { required: <AppCommon.RequiredField /> }
                    : {}
                }
                fullWidth
                id="bookSection_Content"
                placeholder={'Enter book content..'}
                control={control}
                autoComplete="bookSection_Content"
                name="bookSection_Content"
              />
            ),
            size: 12,
          },
        ]}
      />
    </AppCommon.Section>
  );
};

export default React.memo(BookContentSection);
