import React from 'react';
import DataList from 'components/molecules/DataList';
import { useDispatch } from 'react-redux';
import RoutineDetailsActions from '../../store/actions';
import * as RoutineDetailsSelectors from '../../store/selectors';
import {
  ListItem,
  Checkbox,
  Box,
  Divider,
  AppBar,
  Chip,
  Paper,
} from '@material-ui/core';
import TabsPanel from 'components/molecules/TabsPanel';
import TranslatedText from 'components/atoms/TranslatedText';
import Searchfield from 'components/atoms/Searchfield';
import { IState } from '../../store/reducer';
import { NonNullable } from 'utils/generics';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import {
  CollectionItemMapping,
  CollectionTypeEnum,
  ItemTypeEnum,
} from 'constants/typesConst';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import { StateStatus } from 'redux/utils/common';

interface IItemsTabsList {
  collectionType: CollectionTypeEnum;
}

const TricksTabsList = ({ collectionType }: IItemsTabsList) => {
  return (
    <div>
      <TabsPanel
        tabsContainer={<AppBar position="static" color="default" />}
        tabsProps={{
          variant: 'fullWidth',
        }}
        elementsConfig={[
          {
            title: <TranslatedText defaultText="My Creations" />,
            id: TabTypes.Own,
            content: (
              <TricksList collectionType={collectionType} type={TabTypes.Own} />
            ),
          },
          {
            title: <TranslatedText defaultText="All Items" />,
            id: TabTypes.Public,
            content: (
              <TricksList
                collectionType={collectionType}
                type={TabTypes.Public}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

enum TabTypes {
  Public,
  Own,
}

interface ITricksListProps {
  type: TabTypes;
  collectionType: CollectionTypeEnum;
}

const TricksList = ({ type, collectionType }: ITricksListProps) => {
  const dispatch = useDispatch();
  const tricksData = RoutineDetailsSelectors.useTricksList();
  const selectedTags = RoutineDetailsSelectors.useSelectedTags();
  const globalSearchRef = React.useRef<string>();
  const userId = useSelectUserData().id;

  React.useEffect(() => {
    return () => {
      dispatch(RoutineDetailsActions.setSelectedTrickeAction(null));
    };
  }, [dispatch]);

  const fetchMoreData = React.useCallback(() => {
    const currentState = (getState() as any)[
      ReducerKeys.ROUTINE_DETAILS_REDUCER
    ] as IState;

    if (userId && currentState.tricksList.status !== StateStatus.Pending) {
      dispatch(
        RoutineDetailsActions.fetchTricksListAction({
          public: true,
          tags: selectedTags ?? undefined,
          globalSearch: globalSearchRef.current,
          fetchMore: true,
          createdBy: type === TabTypes.Own ? userId : undefined,
        })
      );
    }
  }, [globalSearchRef, selectedTags, type, userId]);

  React.useEffect(() => {
    if (userId && typeof type === 'number') {
      dispatch(
        RoutineDetailsActions.fetchTricksListAction({
          public: true,
          tags: selectedTags ?? undefined,
          globalSearch: globalSearchRef.current,
          createdBy: type === TabTypes.Own ? userId : undefined,
        })
      );
    }
  }, [dispatch, type, selectedTags, userId]);

  return (
    <div>
      <Searchfield
        onChange={(value) => {
          const currentState = (getState() as any)[
            ReducerKeys.ROUTINE_DETAILS_REDUCER
          ] as IState;
          globalSearchRef.current = value;
          if (
            currentState.tricksList.status !== StateStatus.Pending &&
            typeof type === 'number' &&
            userId
          ) {
            dispatch(
              RoutineDetailsActions.fetchTricksListAction({
                public: type === TabTypes.Public ? true : undefined,
                tags: selectedTags ?? undefined,
                globalSearch: globalSearchRef.current,
                createdBy: type === TabTypes.Own ? userId : undefined,
              })
            );
          }
        }}
      />

      <DataList
        data={tricksData.data?.items ?? []}
        itemContainerProps={{
          button: true,
        }}
        fetchMore={{
          handleMore: fetchMoreData,
          totalItems: tricksData.data?.paging.total ?? 0,
        }}
        onElementClick={(element, index) => {
          const canChecked = CollectionItemMapping[collectionType].includes(
            element.type as ItemTypeEnum
          );
          canChecked &&
            dispatch(
              RoutineDetailsActions.setSelectedTrickeAction({
                element: {
                  id: element._id,
                  title: element.title,
                  tags: element.tags,
                },
                action:
                  element._id in
                  ((
                    (getState() as any)[
                      ReducerKeys.ROUTINE_DETAILS_REDUCER
                    ] as IState
                  ).selectedItemsMap ?? {})
                    ? 'remove'
                    : 'add',
              })
            );
        }}
        divider
        renderElement={(element, index) => {
          return (
            <SelectedElement
              collectionType={collectionType}
              key={element._id}
              element={element}
            />
          );
        }}
      />
    </div>
  );
};

interface ISelectedElementProps {
  element: NonNullable<IState['tricksList']['data']>['items'][0];
  collectionType: CollectionTypeEnum;
}

const SelectedElement = ({
  element,
  collectionType,
}: ISelectedElementProps) => {
  const checked = RoutineDetailsSelectors.useItemIsSelected(element._id);
  const canChecked = CollectionItemMapping[collectionType].includes(
    element.type as ItemTypeEnum
  );

  return (
    <Box style={{ width: '100%' }}>
      <Box
        key={element._id}
        flex={1}
        display="flex"
        style={{ height: '100%', width: '100%' }}
        alignItems="center"
      >
        <Checkbox
          checked={checked}
          style={{ marginRight: '1.5rem' }}
          disabled={!canChecked}
        />
        <Box
          justifyContent="center"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* <div>{`${element.title}`}</div> */}
            <div dangerouslySetInnerHTML={{ __html: element.title }} />

            {element.type && (
              <div
                style={{ fontWeight: 800, marginLeft: 10 }}
              >{`(${element.type})`}</div>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(TricksTabsList);
