import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UsersService from 'services/api/user';
import * as NamespacesService from 'services/api/namespaces';

export interface IState {
  studentsList: {
    status: StateStatus | null;
    data: NamespacesService.FetchnamespaceStudentsListData | null;
  };
  selectedStudentsMapping: {
    [key: string]: {
      id: string;
    };
  } | null;
}

const initialState: IState = {
  studentsList: {
    status: null,
    data: null,
  },
  selectedStudentsMapping: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_STUDENTS_LIST:
      return {
        ...state,
        studentsList: {
          ...state.studentsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.studentsList.data
              : action.data,
        },
      };
    case Types.SET_SELECTED_STUDENTS:
      return {
        ...state,
        selectedStudentsMapping: action.data,
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
