// export enum FilePathTypes {
//   AdminFiles = 'AdminFiles',
//   PublicFiles = 'PublicFiles',
//   AuthenticateFiles = 'AuthenticateFiles',
//   DatabaseBackup = 'DatabaseBackup',
// }

export enum FilePathTypes {
  AdminFiles = 'AdminFiles',
  PublicFiles = 'PublicFiles',
  AuthenticateFiles = 'AuthenticateFiles',
  StaticFiles = 'StaticFiles',
  AppFiles = 'AppFiles',
  DatabaseBackup = 'DatabaseBackup',
  TempFiles = 'TempFiles',
}

export enum FileType {
  Image = 'Image',
  Video = 'Video',
  File = 'File',
  Audio = 'Audio',
}

export const FileTypeActionsMapping: {
  [key in FileType]: {
    name: string;
    accept: string;
  };
} = {
  [FileType.Audio]: {
    name: 'Audio',
    accept: 'audio/mp3,audio/*',
  },
  [FileType.File]: { name: 'File', accept: 'image/*' },
  [FileType.Image]: { name: 'Image', accept: 'image/*' },
  [FileType.Video]: { name: 'Video', accept: 'image/*' },
};
