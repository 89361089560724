import _ from 'lodash';
import { showToastAction } from 'components/atoms/ToastMessage';
import store from '../../redux/store';
import axios from './middlewares';
import { ClientError } from 'utils/types';
import nativeAxios, { AxiosRequestConfig } from 'axios';

interface IOptions {
  body?: any;
  headers?: any;
  useMiddleware?: boolean;
  toastMessageVisibility?: {
    error?: boolean;
    success?: boolean;
  };
  config?: AxiosRequestConfig;
}

const request = async (
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  url: string,
  options?: IOptions
) => {
  const dispatch = store.dispatch;
  try {
    let object = {
      method: method,
      url: url,
      data: options?.body,
      headers: options?.headers,
      ...options?.config,
    };

    const result = await (options?.useMiddleware === false
      ? nativeAxios(object)
      : axios(object));
    if (result.data.message && options?.toastMessageVisibility?.success) {
      showToastAction(result.data.message, 'success');
    }
    return result;
  } catch (error) {
    if (error.message && options?.toastMessageVisibility?.error) {
      showToastAction(
        _.get(error, 'response.data.message', error.message),
        'error'
      );
    }
    throw new ClientError(
      _.get(error, 'response.data.message', error.message),
      {
        errorCode: _.get(error, 'response.data.errorCode'),
        statusCode: _.get(error, 'response.status'),
      }
    );
  }
};

export { nativeAxios };

export default request;
