import React, { ReactElement } from 'react';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import useStyles from './styles';
import { TableSortLabel } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { IProps, Order, IFilteringData } from '../../configuration';
import _ from 'lodash';
import TableTitleContainer from '../TableTitleContainer';
import TableFilteringField from '../TableFilteringField';
import LoadingView from 'components/atoms/LoadingView';

interface ITableProps<T> extends IProps<T> {
  orderBy: string;
  page: number;
  order: Order;
  rowsPerPage: number;
  filteringData: {
    current: IFilteringData;
  };
  handleFiltering: (resetForm: boolean) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  createSortHandler: (
    property: string,
    inData: boolean
  ) => (event: React.MouseEvent<unknown>) => void;
}

export default function AppTable<T>(props: ITableProps<T>): ReactElement {
  const classes = useStyles({
    height: props.height,
    minHeight: props.minHeight,
  });
  const {
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    createSortHandler,
    handleFiltering,
    loading,
    tableCellMainRowProps,
    tableCellProps,
    paperProps = {},
    filteringData,
    disablePaper = false,
  } = props;

  return (
    <PaperContainer disablePaper={disablePaper} {...paperProps}>
      {(props.title || props.filtering) && (
        <TableTitleContainer
          filteringData={filteringData}
          title={props.title}
          filteringPlaceholder={props.filtering?.globalSearchPlaceholder}
          handleFiltering={handleFiltering}
          globalSearchDefaultValue={props.filtering?.globalSearchDefaultValue}
        />
      )}
      <TableContainer className={classes.container}>
        {loading ? (
          <div
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingView fullScreen={false} size={50} style={{ margin: 50 }} />
          </div>
        ) : (
          <Table
            // stickyHeader={
            //   typeof props.stickyHeader !== 'boolean' ? true : props.stickyHeader
            // }
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {props.columns.map((headCell, index) => (
                  <TableCell
                    {...(tableCellMainRowProps
                      ? tableCellMainRowProps(index)
                      : {})}
                    key={headCell.id}
                    align={
                      headCell.align === 'right'
                        ? 'right'
                        : headCell.align === 'center'
                        ? 'center'
                        : 'left'
                    }
                    padding={props.disablePadding ? 'none' : 'normal'}
                    sortDirection={
                      props.orderBy === headCell.id ? props.order : false
                    }
                    style={{
                      minWidth: headCell.minWidth,
                    }}
                    className={
                      headCell.inData
                        ? headCell.sticky === 'right'
                          ? classes.stickyRight
                          : headCell.sticky === 'left'
                          ? classes.stickyLeft
                          : undefined
                        : undefined
                    }
                  >
                    {props.sorting ? (
                      <TableSortLabel
                        active={props.orderBy === headCell.id}
                        direction={
                          props.orderBy === headCell.id ? props.order : 'asc'
                        }
                        disabled={!headCell.inData}
                        onClick={createSortHandler(
                          headCell.id,
                          headCell.inData
                        )}
                      >
                        {headCell.label}
                        {props.orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {props.order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                    {props.filtering?.searchFields && (
                      <div>
                        <TableFilteringField
                          name={headCell.id}
                          type={headCell?.filtering?.type}
                          options={headCell?.filtering?.options}
                          label={headCell?.label}
                          filteringData={filteringData}
                          handleFiltering={handleFiltering}
                          defaultValue={headCell.filtering?.defaultValue}
                        />
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {props.columns.map((column, columnIndex) => {
                      const value = _.get(row, column.id, null);
                      return (
                        <TableCell
                          key={column.id}
                          align={
                            column.align === 'right'
                              ? 'right'
                              : column.align === 'center'
                              ? 'center'
                              : 'left'
                          }
                          style={{ height: '100%' }}
                          className={
                            column.sticky === 'right'
                              ? classes.stickyRight
                              : column.sticky === 'left'
                              ? classes.stickyLeft
                              : undefined
                          }
                          {...(tableCellProps
                            ? tableCellProps(columnIndex, rowIndex, value)
                            : {})}
                        >
                          {column.render(
                            rowIndex,
                            value,
                            columnIndex,
                            props.data
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}

        {props.paging && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200]}
            component="div"
            count={props.paging?.total || props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </PaperContainer>
  );
}

interface IPaperProps extends PaperProps {
  disablePaper: boolean;
}

const PaperContainer = (props: IPaperProps) => {
  const classes = useStyles({});
  const { disablePaper, children, ...paperProps } = props;

  return (
    <React.Fragment>
      {!disablePaper ? (
        <Paper
          elevation={0}
          variant="outlined"
          square
          className={classes.root}
          {...paperProps}
        >
          {props.children}
        </Paper>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};
