import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TagsService from 'services/api/tags';

export interface IState {
  removeTagStatus: StateStatus | null;
  addTagStatus: StateStatus | null;
  editTagStatus: StateStatus | null;
}

const initialState: IState = {
  removeTagStatus: null,
  addTagStatus: null,
  editTagStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.REMOVE_TAG:
      return {
        ...state,
        removeTagStatus: action.status || null,
      };
    case Types.EDIT_TAG:
      return {
        ...state,
        editTagStatus: action.status || null,
      };
    case Types.ADD_TAG:
      return {
        ...state,
        addTagStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
