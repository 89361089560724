import React from 'react';
import TableActions from './components/TableAction';
import { IState } from './store/reducer';
import { viewFileUrl, downloadFile, openFileToNewTab } from 'services/api/file';
import { FilePathTypes, FileType } from 'constants/filesConst';
import { formatDate } from 'utils/dates';
import { IModalParams } from './types';
import { FileTypeOptions } from 'constants/dropdownOptions';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { alpha, Box, colors, IconButton, Tooltip } from '@material-ui/core';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import UploadFileButton from './components/UploadFileButton';
import ColumnName from './components/ColumnName';
import { formatBytes } from 'utils/sizes';
import {
  closeImageEditorModal,
  openImageEditorModal,
} from 'components/molecules/ImageEditor';
import store from 'redux/store';
import WarningIcon from '@material-ui/icons/Warning';
import { IColumnProps } from 'components/molecules/Datatable/configuration';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditImageButton from './components/EditImageButton';

type ListDataType = NonNullable<IState['filesList']['data']>['items'];

export const TableColumns = (params: IModalParams): IColumnProps[] => [
  {
    label: 'File',
    id: 'path',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => (
      <div style={{ position: 'relative' }}>
        <img
          style={{ borderRadius: 8, cursor: 'pointer' }}
          width={90}
          height={80}
          src={
            data[rowIndex].fileType === FileType.Image
              ? viewFileUrl(cellValue)
              : require('assets/images/icons/uploaded-file.png')
          }
          onClick={async () => {
            try {
              await downloadFile({
                filepath: data[rowIndex].attachmentType,
                filename: data[rowIndex].fileName,
              });
            } catch (error) {}
          }}
        />
        {data[rowIndex].isOrphan && (
          <Tooltip title={'Image is orphan'}>
            <WarningIcon
              style={{
                zIndex: 1,
                position: 'absolute',
                right: 5,
                bottom: 0,
                color: colors['yellow']['900'],
              }}
            />
          </Tooltip>
        )}
        <UploadFileButton fileId={data[rowIndex]._id} />
      </div>
    ),
    inData: false,
  },
  {
    label: 'Preview',
    id: '√',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Tooltip title={cellValue}>
        <IconButton
          onClick={() => {
            openFileToNewTab(cellValue);
          }}
        >
          <PageviewIcon />
        </IconButton>
      </Tooltip>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'name',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => <ColumnName name={cellValue ?? null} fileId={data[rowIndex]._id} />,
    inData: true,
  },
  {
    label: 'Size',
    id: 'size',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{formatBytes(cellValue || 0)}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'File Id',
    id: '_id',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: false,
  },
  {
    label: 'Type',
    id: 'fileType',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => (
      <Box display={'flex'} alignItems="center">
        {cellValue === FileType.Image && (
          // <Tooltip title="Edit Image">
          //   <IconButton
          //     size="small"
          //     onClick={() => {
          //       openImageEditorModal({
          //         src: viewFileUrl(data[rowIndex].path ?? '') ?? '',
          //         onEditComplete: (file) => {
          //           // replace file
          //           // const file = event?.target?.files?.[0];

          //           // upload file
          //           store.dispatch(
          //             UploadedFilesAction.uploadFileAction({
          //               data: {
          //                 path: FilePathTypes.AppFiles,
          //                 file: file as any,
          //                 imageId: data[rowIndex]._id,
          //               },
          //               onSuccess: () => {
          //                 store.dispatch(
          //                   UploadedFilesAction.fetchFilesAction({
          //                     payload: DefaultPaging,
          //                     onSuccess: () => {},
          //                   })
          //                 );
          //               },
          //             })
          //           );
          //           closeImageEditorModal();
          //         },
          //         filename: data[rowIndex].name ?? data[rowIndex].fileName,
          //         onCloseImageEditor: () => {
          //           closeImageEditorModal();
          //         },
          //       });
          //     }}
          //   >
          //     <ImageSearchIcon />
          //   </IconButton>
          // </Tooltip>
          <EditImageButton
            _id={data[rowIndex]._id}
            path={data[rowIndex].path}
            name={data[rowIndex].name ?? ''}
            fileName={data[rowIndex].fileName}
          />
        )}

        {cellValue}
      </Box>
    ),
    inData: true,
    filtering: {
      type: 'dropdown' as any,
      options: FileTypeOptions,
      defaultValue: params.type,
    },
  },

  {
    label: 'Created At',
    id: 'createdAt',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{formatDate(cellValue)}</React.Fragment>
    ),
    inData: true,
    minWidth: 150,
  },
  {
    label: '',
    id: 'actions',
    align: 'center',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType

      // data: NonNullable<IState['filesList']['data']>['items']
    ) => <TableActions {...params} params={data[rowIndex]} />,
    inData: false,
    sticky: 'right',
  },
];

interface IDefaultPaging {
  page: number;
  pageSize: number;
  orderType: 'asc' | 'desc';
  sortField: string;
}

export const DefaultPaging: IDefaultPaging = {
  page: 0,
  pageSize: 25,
  orderType: 'desc',
  sortField: 'createdAt',
};
