import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as AuthenticationService from '../../../services/api/authenticate';
import { Dispatch } from 'redux';
import NavigationConfig from '../../../config/navigationConfig';
import _, { result } from 'lodash';
import { showToastAction } from 'components/atoms/ToastMessage';
import { saveToLocalStorage, loadFromLocalStorage } from '../../utils/storage';
import { ClientError } from 'utils/types';
import { useHistory } from 'react-router';
import ErrorCodes from 'constants/errorCodesConst';
import { IState as IForceChangePasswordState } from 'scenes/auth_ForceChangePasswordReset_page';
import { IState as IConfirmAccountState } from 'scenes/auth_ConfirmAccount_page';
import { IState } from './reducer';

/** Authenticate Action  */
interface IAutheticateViaPasswordInput {
  email: string;
  password: string;
  stayLogin: boolean;
  onFinish: (
    success: boolean,
    options?: {
      userEmail: string;
      errorCode: string;
    }
  ) => void;
}

interface IAutheticateOutput
  extends Omit<
    AuthenticationService.IAuthData,
    'firstName' | 'lastName' | 'phone'
  > {
  token: string;
  refreshToken: string;
  // roles: Array<string | number>;
  // id: string;
  stayLogin: boolean;
  // email: string;
  // username: string;
}

class Authenticate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(result: IAutheticateOutput): IAction {
    const data: Partial<IState> = {
      roles: result.roles,
      // token: result.token,
      // refreshToken: result.refreshToken,
      stayLogin: result.stayLogin,
      data: {
        username: result.username,
        email: result.email,
        twoStepVerification: result.twoStepVerification,
        id: result.userid,
        sessionIsVerified: result.sessionIsVerified,
        hasPassword: result.hasPassword,
        settings: result.settings,
        accessPortal: result.accessPortal,
      },
    };

    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IAutheticateViaPasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());

        let _input: IAutheticateViaPasswordInput = input as any;
        const result = await AuthenticationService.authenticateUser({
          username: _input.email,
          password: _input.password,
        });
        const token = result.data.accessToken;
        const refreshToken = result.data.refreshToken;
        const data: IAutheticateOutput = {
          token,
          roles: result.data.roles,
          userid: result.data.userid,
          refreshToken,
          email: result.data.email,
          username: result.data.username,
          stayLogin: _input.stayLogin,
          twoStepVerification: result.data.twoStepVerification,
          sessionIsVerified: result.data.sessionIsVerified,
          hasPassword: result.data.hasPassword,
          settings: result.data.settings,
          accessPortal: result.data.accessPortal,
        };
        saveToLocalStorage({
          token,
          refreshToken,
        });
        dispatch(this.onSuccess(data));
        input.onFinish(true);
      } catch (error) {
        const _error = error as ClientError;
        // console.log('Authenticate Error:', _error.message); // '<ClassName> Error: <error>'
        console.log('Login errorCode:', _error.errorCode);
        input.onFinish(false, {
          userEmail: input.email,
          errorCode: _error.errorCode || '',
        });
        dispatch(this.onFailed());
      }
    };
  }
}

/** Logout Action  */
class Logout implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(): IAction {
    saveToLocalStorage({});
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    saveToLocalStorage({});
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(history?: ReturnType<typeof useHistory>): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        await AuthenticationService.logout();
        dispatch(this.onSuccess());
        showToastAction('Logout', 'info');
      } catch (error) {
        console.log('Logout Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        if (history) {
          history.push(NavigationConfig.loginPage().path);
        }
      }
    };
  }
}

/** Refresh Token Action  */

interface IRefreshTokenInput {
  token: string;
  refreshToken: string;
}

interface IRefreshTokenOutput {
  token: string;
  refreshToken: string;
}

class RefreshToken implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: IRefreshTokenOutput): IAction {
    return {
      type: Types.REFRESH_TOKEN_TYPE,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: IRefreshTokenInput): IAction {
    saveToLocalStorage({
      token: data.token,
      refreshToken: data.refreshToken,
    });
    return this.onSuccess(data);
  }
}

/** Fetch user data */
interface IGetAuthDataInput {
  overrideAuth?: {
    token: string;
    refreshToken: string;
  };
}

class FetchUserData implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.USER_DETAILS_TYPE,
      status: StateStatus.Pending,
      data: {},
    };
  }

  onSuccess(data: AuthenticationService.IAuthData): IAction {
    const {
      roles,
      userid,
      username,
      email,
      twoStepVerification,
      sessionIsVerified,
      hasPassword,
      settings,
      accessPortal,
    } = data;
    return {
      type: Types.USER_DETAILS_TYPE,
      status: StateStatus.Success,
      data: {
        roles,
        data: {
          username,
          email,
          id: userid,
          twoStepVerification,
          sessionIsVerified,
          hasPassword,
          settings,
          accessPortal,
        },
      } as IState,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.USER_DETAILS_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IGetAuthDataInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        if (input.overrideAuth) {
          saveToLocalStorage({
            token: input.overrideAuth.token,
            refreshToken: input.overrideAuth.refreshToken,
          });
        }

        const { data } = await AuthenticationService.getUserData({});
        dispatch(this.onSuccess(data));
      } catch (error) {
        const _error = error as ClientError;
        if (_error.statusCode !== 401) {
          dispatch(new Logout().action());
        }
        console.log('GetAuthData Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Complete 2 step login */

class Login2StepComplete implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not completed');
  }

  onSuccess(): IAction {
    return {
      type: Types.LOGIN_TWO_STEP_COMPLETE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not completed');
  }

  action(): IAction {
    return this.onSuccess();
  }
}

export const handleAuthErrorCodes = (
  errorCode: string,
  options: {
    history: ReturnType<typeof useHistory>;
    email: string;
    password?: string;
  }
) => {
  const { history, email, password } = options;
  if (errorCode === ErrorCodes.ACCOUNT_NOT_VERIFIED.code) {
    history.push(NavigationConfig.confirmAccountPage({ email }).path, {
      password,
    } as IConfirmAccountState);
  } else if (errorCode === ErrorCodes.FORCE_CHANGE_PASSWORD.code) {
    history.push(
      NavigationConfig.forceChangePasswordVerifyPage({
        email,
      }).path,
      {
        password,
      } as IForceChangePasswordState
    );
  }
};

export default {
  authenticateAction: (data: IAutheticateViaPasswordInput) =>
    new Authenticate().action(data),
  logoutAction: (history?: ReturnType<typeof useHistory>) =>
    new Logout().action(history),
  refreshTokenAction: (refreshToken: string, token: string) =>
    new RefreshToken().action({ refreshToken, token }),
  fetchUserDataAction: (payload: IGetAuthDataInput) =>
    new FetchUserData().action(payload),
  login2StepCompleteAction: () => new Login2StepComplete().action(),
};
