import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    paddingTop: (props: any) => (props?.paddingTop ? 18 : 0),
    paddingBottom: (props: any) => (props?.paddingBottom ? 18 : 0),
    // [theme.breakpoints.down('lg')]: {
    //   marginLeft: 60,
    //   marginRight: 60,
    // },
  },
}));

export default styles;
