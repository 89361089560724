import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: '90vh',
    minHeight: '90vh',
  },
}));

export default useStyles;
