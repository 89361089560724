import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppAdvancedselect,
  AppTextField,
  AppSelect,
  IAdvancedSelectProps,
  AppCommon,
} from 'components/molecules/FormControls';
import { Control, Controller, useForm, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import GridLayout, { IElement } from 'components/molecules/GridLayout';
import { alpha, colors, Divider, Paper, Typography } from '@material-ui/core';
import TrickDetailsActions from '../../store/actions';
import * as TrickDetailsSelectors from '../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import { NoNullFields } from 'utils/generics';
import NavigationConfig from 'config/navigationConfig';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../configuration';
import { withVerification } from 'components/molecules/ModalVerifyAction';
import { downloadTrick } from 'services/api/items';
import IconButton from '@material-ui/core/IconButton';
import { ItemTypeEnum, ItemGroups } from 'constants/typesConst';
import ExamplesField from './ExamplesField';
import { IFormData } from '../../types';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import {
  openTagDetailsModal,
  closeTagDetailsModal,
} from 'components/compounds/TagDetailsModal';
import { TagLevel, TagTypes } from 'constants/tagsConst';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import {
  useSelectedPortalNamespace,
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  openTranslationModalFormModal,
  SelectedLanguages,
} from 'components/app_common/TranslationsModal';
import { DefaultLanguage, LanguagesEnum } from 'constants/languagesConst';
import {
  fixTranslations,
  getFieldTranslations,
  ITranslationType,
  translation,
} from 'utils/translation';
import { useNamespacesList } from 'redux/reducers/gl_common_reducer/selectors';
import { ItemStatusEnum, ItemStatusOptions } from 'constants/statusConst';
import { ItemTypeOptions, TrueFalseOptions } from 'constants/dropdownOptions';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ExerciseActions from './ExerciseActions';
import {
  openUploadedFilesModal,
  closeUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { getFileDetails, openFileToNewTabAsync } from 'services/api/file';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { FileType } from 'constants/filesConst';
import LanguageIcon from '@material-ui/icons/Language';
import HelpIcon from '@material-ui/icons/Help';
import { openWordReference } from 'utils/wordReference';
import {
  closePromptsModal,
  openPromptsModal,
} from 'components/app_common/Prompts';
import { getState } from 'redux/store';
import * as OpenAIService from 'services/api/openai';
import { IState } from '../../store/reducer';
import { formatCamelOrUnderscoreCaseToSentenceCase } from 'utils/strings';
import { Roles } from 'constants/authRolesConst';
import { IItemPromptData } from 'services/api/openai';
import { openPromptInputModalModal } from 'components/app_common/PromptInputModal';
import {
  addItemEtymologyEvolutionDataByPrompt,
  ICollectionPromptData,
} from 'services/api/openai';
import EtymologyEvolution from './EtymologyEvolution';
import ImageUrlSection from './ImageUrlSection';
import { arraysHasCommonElements } from 'utils/arrays';

// const getDefaultValues = (
//   mode: IItemDetailsProps['mode'],
//   type: ItemTypeEnum | null
// ): IFormData => ({
//   title: null,
//   description: null,
//   type,
//   link: null,
//   tags: null,
//   public: 'true',
//   difficulty: 3,
//   examples: mode === 'add' ? [] : [],
//   additionalInfo: null,
//   namespaces: null,
//   question: null,
//   status: ItemStatusEnum.Active,
//   imageId: null,
//   etymologyEvolution: [],
// });

interface IHandleTranslationOptions {
  name: string;
  title: string;
  type: 'textarea' | 'text';
  textToTranslate?: string;
  lanFrom: ITranslationType;
}

export type IBasicTemplateProps = IItemDetailsProps;

export default function AddEditTrickForm({
  trickId,
  mode,
  access,
  onAddItem,
  type,
  onDeleteItem,
}: IBasicTemplateProps) {
  // console.log('TYPE:::', type);
  const classes = useStyles({});
  const trickDetails = TrickDetailsSelectors.useTrickDetails();
  const defaultValues = React.useMemo(
    () => getDefaultValues(mode, type ?? null),
    [mode, type]
  );
  const {
    reset,
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    setValue,
    watch,
  } = useFormContext<IFormData>();
  const dispatch = useDispatch();
  const history = useHistory();
  const itemType = watch('type');
  const answerRef = React.useRef<HTMLInputElement | null>(null);
  const exerciseRef = React.useRef<HTMLInputElement | null>(null);
  const namespace = useSelectedPortalNamespace();
  const namespaces = useNamespacesList();
  const itemTypeIsExercise = React.useMemo(
    () => ItemGroups.Exercises.includes(itemType as ItemTypeEnum),
    [itemType]
  );
  const roles = useSelectUserRoles();

  const namespacesOptions = React.useMemo(
    () => namespaces.data?.map((el) => ({ id: el._id, label: el.name })) ?? [],
    [namespaces]
  );

  React.useEffect(() => {
    if (!trickId) {
      // itemType === ItemTypeEnum.Exercise
      if (ItemGroups.Exercises.includes(itemType as ItemTypeEnum)) {
        // setValue('public', 'false');
        setValue('tags', []);
      }
      if ([ItemTypeEnum.MultipleChoice].includes(itemType as ItemTypeEnum)) {
        setValue('examples', ['', '', '']);
      } else if ([ItemTypeEnum.Word].includes(itemType as ItemTypeEnum)) {
        setValue('examples', ['']);
      } else {
        setValue('examples', []);
      }
    }
  }, [itemType, setValue, trickId]);

  React.useEffect(() => {
    if (trickDetails.data) {
      const examples =
        trickDetails.data.examples?.map((example) => ({ example })) ?? [];

      const etymologyEvolution = trickDetails.data.etymologyEvolution ?? [];
      reset({
        ...defaultValues,
        title: trickDetails.data.title,
        description: trickDetails.data.description,
        type: trickDetails.data.type as ItemTypeEnum,
        link: trickDetails.data.link,
        tags: trickDetails.data.tags ?? [],
        public: trickDetails.data.public ? 'true' : 'false',
        difficulty: trickDetails.data.difficulty,
        additionalInfo: trickDetails.data.additionalInfo ?? null,
        examples,
        namespaces: trickDetails.data.namespaces ?? null,
        question: trickDetails.data.question ?? null,
        translations: trickDetails.data.translations,
        status: trickDetails.data.status,
        imageId: trickDetails.data.imageId?._id ?? null,
        etymologyEvolution,
      });
      register('translations');
      setValue('examples', examples);
      // setValue('etymologyEvolution', etymologyEvolution);
    } else {
      setValue('namespaces', [namespace]);
    }
  }, [trickDetails, namespace, reset]);

  const handleTranslation = React.useCallback(
    ({
      name,
      title,
      type,
      textToTranslate,
      lanFrom,
    }: IHandleTranslationOptions) => {
      openTranslationModalFormModal({
        onSubmit: (data) => {
          Object.keys(data).forEach((lan) => {
            const key = `translations.${lan}.${name}`;
            register(key);
            setValue(key, data[lan as LanguagesEnum]);
          });
        },
        // type: 'text',
        type,
        title,
        initialData: getFieldTranslations(name, getValues() as any) as any,
        modalSize: 'large',
        textinputProps: {
          rows: 1,
          maxRows: 5,
        },
        autoTranslation: {
          textToTranslate: textToTranslate ?? _.get(getValues(), name),
          execute: async (text, lanTo) => {
            return await translation(text, {
              lanTo,
              lanFrom: lanFrom ?? undefined,
            });
          },
        },
      });
    },
    []
  );

  const onSubmit = (_data: NoNullFields<IFormData>) => {
    if (mode === 'add') {
      dispatch(
        TrickDetailsActions.addTrickAction({
          payload: {
            ..._data,
            tags: _data.tags ?? [],
            description: _data.description ?? '',
            type: _data.type,
            link: _data.link ?? '',
            public: _data.public === 'false' ? false : true,
            examples:
              _data?.examples?.map(({ example }) => example ?? '') ?? [],
            additionalInfo: _data.additionalInfo ?? '',
            namespaces: _data.namespaces ?? [],
            question: _data.question ?? undefined,
            translations: fixTranslations(_data.translations),
            imageId: _data.imageId || undefined,
            difficulty: _data.difficulty
              ? parseInt(`${_data.difficulty}`, 10)
              : null,
            etymologyEvolution: _data.etymologyEvolution ?? [],
          },
          onSuccess: () => {
            if (access === 'page') {
              history.push(NavigationConfig.trickPage().path);
            }
            onAddItem && onAddItem();
          },
        })
      );
    } else if (mode === 'edit' && trickId) {
      dispatch(
        TrickDetailsActions.editTrickAction({
          payload: {
            ..._data,
            tags: _data.tags ?? [],
            description: _data.description ?? '',
            type: _data.type,
            link: _data.link ?? '',
            public: _data.public === 'false' ? false : true,
            examples:
              _data?.examples?.map(({ example }) => example ?? '') ?? [],
            additionalInfo: _data.additionalInfo ?? '',
            namespaces: _data.namespaces ?? [],
            question: _data.question ?? undefined,
            translations: fixTranslations(_data.translations),
            imageId: _data.imageId,
            difficulty: _data.difficulty
              ? parseInt(`${_data.difficulty}`, 10)
              : undefined,
            etymologyEvolution: _data.etymologyEvolution ?? [],
          },
          onSuccess: () => {
            dispatch(TrickDetailsActions.fetchTrickDetailsAction(trickId));
          },
          trickId: trickId as string,
        })
      );
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppCommon.FormContainer>
        <AppCommon.Section
          title={
            itemTypeIsExercise
              ? 'Exercise'
              : ItemTypeEnum.RearrangementAnswer === itemType
              ? 'Sentence'
              : 'Title'
          }
          iconLeft={
            ItemGroups.Vocabulary.includes(type as ItemTypeEnum) && (
              <IconButton
                onClick={() => {
                  openWordReference(getValues().title ?? '');
                }}
                size="small"
                style={{ marginRight: '0.3rem' }}
              >
                <HelpIcon />
              </IconButton>
            )
          }
        >
          <div style={{ marginTop: '1rem' }}>
            {itemTypeIsExercise && itemType !== ItemTypeEnum.Rearrangement ? (
              <ExerciseActions
                ref={exerciseRef as any}
                setValue={setValue}
                getValues={getValues}
                configuration={{
                  blank: itemType !== ItemTypeEnum.RearrangementSpelling,
                  bold: itemType !== ItemTypeEnum.RearrangementSpelling,
                  underline: itemType !== ItemTypeEnum.RearrangementSpelling,
                  pause: itemType === ItemTypeEnum.RearrangementSpelling,
                }}
              />
            ) : undefined}
            <GridLayout
              justify="flex-start"
              elements={[
                {
                  id: 'title',
                  element: (
                    <AppTextField
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      error={'title' in errors}
                      helperText={errors.title && errors.title.message}
                      rules={{ required: <AppCommon.RequiredField /> }}
                      required
                      fullWidth
                      inputRef={exerciseRef}
                      id="title"
                      control={control}
                      placeholder={
                        itemTypeIsExercise
                          ? 'Enter exercise..'
                          : 'Enter Title..'
                      }
                      autoComplete="title"
                      name="title"
                    />
                  ),
                  size: 12,
                },
              ]}
            />
          </div>
        </AppCommon.Section>

        {!itemTypeIsExercise && (
          <AppCommon.Section title="Categories">
            <TagsSelectorWrapper
              control={control}
              name="tags"
              style={{
                display: itemTypeIsExercise ? 'none' : undefined,
              }}
              placeholder="Select Categories.."
              error={'tags' in errors}
              id={'tags'}
              rules={{}}
              margin={'normal'}
              helperText={errors.tags && errors.tags.message}
            />
          </AppCommon.Section>
        )}

        {type === ItemTypeEnum.Word && (
          <AppCommon.Section title="Etymology Evolution">
            <EtymologyEvolution
              control={control}
              errors={errors}
              setValue={setValue}
              fieldName="etymologyEvolution"
              removeButtonEnabled
              addButtonEnabled
              itemType={itemType as ItemTypeEnum}
            />
          </AppCommon.Section>
        )}

        {itemTypeIsExercise && (
          <AppCommon.Section
            iconLeft={
              <IconButton
                onClick={() => {
                  // handleTranslation('question', 'Question', 'text');

                  handleTranslation({
                    title: 'Question',
                    type: 'text',
                    name: 'question',
                    lanFrom: 'defaultLan',
                  });
                }}
                size="small"
                style={{ marginRight: '0.3rem' }}
              >
                <LanguageIcon />
              </IconButton>
            }
            title={'Question'}
            footer={
              <SelectedLanguages fieldName="question" control={control} />
            }
          >
            <GridLayout
              justify="flex-start"
              elements={[
                {
                  id: 'question',
                  element: (
                    <AppTextField
                      variant="outlined"
                      margin="normal"
                      size="medium"
                      error={'question' in errors}
                      helperText={errors.question && errors.question.message}
                      rules={{}}
                      required
                      fullWidth
                      placeholder="Enter question.."
                      id="question"
                      control={control}
                      autoComplete="question"
                      name="question"
                      // icon={{
                      //   ComponentRight: (
                      //     <IconButton
                      //       onClick={() => {
                      //         handleTranslation('question', 'Question');
                      //       }}
                      //       size="small"
                      //     >
                      //       <TranslateIcon />
                      //     </IconButton>
                      //   ),
                      // }}
                    />
                  ),
                  size: 12,
                },
              ]}
            />
          </AppCommon.Section>
        )}

        {ItemGroups.Vocabulary.includes(itemType as ItemTypeEnum) ||
          (itemType === ItemTypeEnum.Trick && (
            <ImageUrlSection />
            // <AppCommon.Section title={'Image URL'}>
            //   <AppTextField
            //     variant="outlined"
            //     size="medium"
            //     margin="normal"
            //     error={'imageId' in errors}
            //     helperText={errors.imageId && errors.imageId.message}
            //     rules={{}}
            //     fullWidth
            //     id="imageId"
            //     disabled
            //     placeholder={'Enter Image Url'}
            //     control={control}
            //     autoComplete="imageId"
            //     icon={{
            //       ComponentRight: (
            //         <>
            //           <IconButton
            //             onClick={async () => {
            //               let initialValue;
            //               if (getValues().imageId) {
            //                 try {
            //                   const fileDetails = await getFileDetails(
            //                     getValues().imageId as string
            //                   );
            //                   initialValue = fileDetails.data.name;
            //                 } catch (error) {}
            //               }
            //               openUploadedFilesModal({
            //                 handleSelectedFile: (filePath) => {
            //                   setValue('imageId', filePath);
            //                   closeUploadedFilesModal();
            //                 },
            //                 selectItem: true,
            //                 initialSearch: initialValue,
            //                 type: FileType.Image,
            //               });
            //             }}
            //           >
            //             <CloudUploadOutlinedIcon />
            //           </IconButton>
            //           <IconButton
            //             onClick={() => {
            //               setValue('imageId', null);
            //             }}
            //           >
            //             <HighlightOffOutlinedIcon />
            //           </IconButton>
            //         </>
            //       ),
            //       ComponentLeft: (
            //         <IconButton
            //           onClick={async () => {
            //             getValues().imageId &&
            //               (await openFileToNewTabAsync(
            //                 getValues().imageId ?? ''
            //               ));
            //           }}
            //         >
            //           <VisibilityOutlinedIcon />
            //         </IconButton>
            //       ),
            //     }}
            //     name="imageId"
            //   />
            // </AppCommon.Section>
          ))}

        <AppCommon.Section
          contentIsVisible={mode === 'edit'}
          title="Basic Info"
        >
          <GridLayout
            justify="flex-start"
            elements={[
              {
                id: 'status',
                element: (
                  <AppSelect
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    error={'status' in errors}
                    helperText={errors.status && errors.status.message}
                    rules={{ required: <AppCommon.RequiredField /> }}
                    required
                    fullWidth
                    id="status"
                    label={<TranslatedText defaultText={'Status'} />}
                    control={control}
                    autoComplete="status"
                    name="status"
                    options={ItemStatusOptions}
                  />
                ),
                size: itemType === null ? 6 : 12,
              },
              {
                id: 'public',
                element: (
                  <AppSelect
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    error={'public' in errors}
                    helperText={errors.public && errors.public.message}
                    rules={{ required: <AppCommon.RequiredField /> }}
                    required
                    fullWidth
                    id="public"
                    label={<TranslatedText defaultText={'Access'} />}
                    control={control}
                    autoComplete="public"
                    name="public"
                    options={[
                      {
                        id: 'true',
                        label: 'Public',
                      },
                      {
                        id: 'false',
                        label: 'Private',
                      },
                    ]}
                  />
                ),
                size: 12,
              },
              // ...(ItemGroups.NotExercises.includes(itemType as ItemTypeEnum)
              //   ? [
              //       {
              //         id: 'imageId',
              //         element: (
              //           <AppTextField
              //             variant="outlined"
              //             size="medium"
              //             margin="normal"
              //             error={'imageId' in errors}
              //             helperText={errors.imageId && errors.imageId.message}
              //             rules={{}}
              //             fullWidth
              //             id="imageId"
              //             disabled
              //             label={<TranslatedText defaultText={'Image Url'} />}
              //             control={control}
              //             autoComplete="imageId"
              //             icon={{
              //               ComponentRight: (
              //                 <>
              //                   <IconButton
              //                     onClick={() => {
              //                       openUploadedFilesModal({
              //                         handleSelectedFile: (filePath) => {
              //                           setValue('imageId', filePath);
              //                           closeUploadedFilesModal();
              //                         },
              //                         selectItem: true,
              //                       });
              //                     }}
              //                   >
              //                     <CloudUploadOutlinedIcon />
              //                   </IconButton>
              //                   <IconButton
              //                     onClick={() => {
              //                       setValue('imageId', null);
              //                     }}
              //                   >
              //                     <HighlightOffOutlinedIcon />
              //                   </IconButton>
              //                 </>
              //               ),
              //               ComponentLeft: (
              //                 <IconButton
              //                   onClick={async () => {
              //                     getValues().imageId &&
              //                       (await openFileToNewTabAsync(
              //                         getValues().imageId ?? ''
              //                       ));
              //                   }}
              //                 >
              //                   <VisibilityOutlinedIcon />
              //                 </IconButton>
              //               ),
              //             }}
              //             name="imageId"
              //           />
              //         ),
              //         size: 12,
              //       } as IElement,
              //     ]
              //   : []),
              {
                id: 'link',
                element: (
                  <AppTextField
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    error={'link' in errors}
                    helperText={errors.link && errors.link.message}
                    rules={{}}
                    fullWidth
                    id="link"
                    label={<TranslatedText defaultText={'Video url'} />}
                    control={control}
                    autoComplete="link"
                    icon={{
                      ComponentRight: (
                        <IconButton
                          onClick={() =>
                            getValues().link &&
                            window.open(getValues().link ?? '', '_blank')
                          }
                        >
                          <VideocamOutlinedIcon />
                        </IconButton>
                      ),
                      // ComponentLeft: (
                      //   <IconButton onClick={() => {}}>
                      //     <FileCopyIcon />
                      //   </IconButton>
                      // ),
                    }}
                    name="link"
                  />
                ),
                size: 12,
              },
              {
                id: 'type',
                element: (
                  <AppSelect
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    error={'type' in errors}
                    helperText={errors.type && errors.type.message}
                    rules={{ required: <AppCommon.RequiredField /> }}
                    required
                    fullWidth
                    id="type"
                    style={{ display: mode === 'edit' ? 'block' : 'none' }}
                    label={<TranslatedText defaultText={'Type'} />}
                    control={control}
                    autoComplete="type"
                    name="type"
                    options={ItemTypeOptions}
                  />
                ),
                size: 12,
              },
              // ...(mode === 'edit'
              //   ? // itemType === null || true
              //     [
              //       {
              //         id: 'type',
              //         element: (
              //           <AppSelect
              //             variant="outlined"
              //             size="medium"
              //             margin="normal"
              //             error={'type' in errors}
              //             helperText={errors.type && errors.type.message}
              //             rules={{ required: <AppCommon.RequiredField /> }}
              //             required
              //             fullWidth
              //             id="type"
              //             label={<TranslatedText defaultText={'Type'} />}
              //             control={control}
              //             autoComplete="type"
              //             name="type"
              //             options={ItemTypeOptions}
              //           />
              //         ),
              //         size: 12,
              //       } as IElement,
              //     ]
              //   : []),
              {
                id: 'difficulty',
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    size="medium"
                    error={'difficulty' in errors}
                    helperText={errors.difficulty && errors.difficulty.message}
                    rules={{}}
                    fullWidth
                    id="difficulty"
                    control={control}
                    label="Difficulty"
                    autoComplete="difficulty"
                    name="difficulty"
                    type="number"
                  />
                ),
                size: 12,
              },
              {
                id: 'namespaces',
                element: (
                  <AppAdvancedselect
                    control={control}
                    disabled={
                      !arraysHasCommonElements(roles, [
                        Roles.Admin,
                        Roles.Supervisor,
                      ])
                    }
                    name="namespaces"
                    placeholder="Workspaces"
                    error={'namespaces' in errors}
                    id={'namespaces'}
                    rules={{}}
                    margin={'normal'}
                    isMulti
                    helperText={errors.namespaces && errors.namespaces.message}
                    options={namespacesOptions}
                  />
                ),
                size: 12,
                rowStyle: {
                  marginBottom: 0,
                },
              },
            ]}
          />
        </AppCommon.Section>
        {[
          ItemTypeEnum.Word,
          ItemTypeEnum.MultipleChoice,
          ItemTypeEnum.FillTheGapMultiple,
          ItemTypeEnum.FillTheGapMultipleSpelling,
          ItemTypeEnum.RearrangementAnswer,
          ItemTypeEnum.Rearrangement,
          ItemTypeEnum.RearrangementSpelling,
        ].includes(itemType as ItemTypeEnum) && (
          <AppCommon.Section
            iconLeft={
              <>
                {itemTypeIsExercise && (
                  <Tooltip
                    aria-label="add"
                    title={
                      'Add answers for multiple choice (except the correct answer)'
                    }
                    style={{ marginRight: '0.3rem' }}
                  >
                    <IconButton size="small">
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            }
            title={itemTypeIsExercise ? 'Choices' : 'Examples'}
          >
            <GridLayout
              justify="flex-start"
              elements={[
                {
                  id: 'examples',
                  element: (
                    <ExamplesField
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      fieldName="examples"
                      removeButtonEnabled
                      addButtonEnabled
                      itemType={itemType as ItemTypeEnum}
                    />
                  ),
                  size: 12,
                },
              ]}
            />
          </AppCommon.Section>
        )}

        {itemTypeIsExercise &&
          itemType !== ItemTypeEnum.Rearrangement &&
          itemType !== ItemTypeEnum.RearrangementSpelling && (
            <AppCommon.Section
              title={'Answer'}
              footer={
                <SelectedLanguages
                  fieldName="additionalInfo"
                  control={control}
                />
              }
              iconLeft={
                <>
                  {itemType === ItemTypeEnum.QuestionAnswer && (
                    <Tooltip
                      aria-label="add"
                      title={
                        'Click here to add new possible answer if you have'
                      }
                      style={{ marginRight: '0.3rem' }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          answerRef.current && answerRef.current.focus();
                          setValue(
                            'additionalInfo',
                            `${getValues().additionalInfo} ${AnswerSplitItem} `
                          );
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {itemType !== ItemTypeEnum.TrueOrFalse && (
                    <IconButton
                      onClick={() => {
                        // handleTranslation('additionalInfo', 'Answer', 'textarea');
                        handleTranslation({
                          name: 'additionalInfo',
                          lanFrom: 'defaultLan',
                          title: 'Answer',
                          type: 'textarea',
                        });
                      }}
                      size="small"
                      style={{ marginRight: '0.3rem' }}
                    >
                      <LanguageIcon />
                    </IconButton>
                  )}
                </>
              }
            >
              <GridLayout
                justify="flex-start"
                elements={[
                  {
                    id: 'additionalInfo',
                    element: (
                      <>
                        {itemType === ItemTypeEnum.TrueOrFalse ? (
                          <AppSelect
                            variant="outlined"
                            size="medium"
                            margin="normal"
                            error={'additionalInfo' in errors}
                            helperText={
                              errors.additionalInfo &&
                              errors.additionalInfo.message
                            }
                            rules={{ required: <AppCommon.RequiredField /> }}
                            fullWidth
                            options={TrueFalseOptions}
                            id="additionalInfo"
                            placeholder="Enter answer.."
                            control={control}
                            autoComplete="additionalInfo"
                            name="additionalInfo"
                          />
                        ) : (
                          <AppTextField
                            variant="outlined"
                            inputRef={answerRef}
                            size="medium"
                            margin="normal"
                            error={'additionalInfo' in errors}
                            helperText={
                              errors.additionalInfo &&
                              errors.additionalInfo.message
                            }
                            rules={{ required: <AppCommon.RequiredField /> }}
                            fullWidth
                            multiline
                            id="additionalInfo"
                            placeholder="Enter answer.."
                            control={control}
                            autoComplete="additionalInfo"
                            name="additionalInfo"
                          />
                        )}
                      </>
                    ),
                    size: 12,
                  },
                ]}
              />
            </AppCommon.Section>
          )}

        <AppCommon.Section
          iconLeft={
            <IconButton
              onClick={() => {
                // handleTranslation(
                //   'description',
                //   'Explanation',
                //   'textarea',
                //   getValues().title ?? ''
                // );
                handleTranslation({
                  title: 'Explanation',
                  name: 'description',
                  type: 'textarea',
                  textToTranslate: ItemGroups.Exercises.includes(
                    type as ItemTypeEnum
                  )
                    ? getValues().description ?? ''
                    : getValues().title ?? '',
                  lanFrom: 'namespaceLan',
                });
              }}
              size="small"
              style={{ marginRight: '0.3rem' }}
            >
              <LanguageIcon />
            </IconButton>
          }
          title={'Quick Explanation'}
          footer={
            <SelectedLanguages fieldName="description" control={control} />
          }
        >
          <GridLayout
            justify="flex-start"
            elements={[
              {
                id: 'description',
                element: (
                  <AppTextField
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    error={'description' in errors}
                    helperText={
                      errors.description && errors.description.message
                    }
                    rules={
                      [
                        ItemTypeEnum.Phrase,
                        ItemTypeEnum.RearrangementAnswer,
                        ItemTypeEnum.Word,
                      ].includes(itemType as ItemTypeEnum)
                        ? undefined
                        : { required: <AppCommon.RequiredField /> }
                    }
                    fullWidth
                    multiline
                    id="description"
                    placeholder={'Enter explanation..'}
                    control={control}
                    autoComplete="description"
                    name="description"
                    // icon={{
                    //   ComponentRight: (
                    //     <IconButton
                    //       onClick={() => {
                    //         handleTranslation('description', 'Explanation');
                    //       }}
                    //       size="small"
                    //     >
                    //       <TranslateIcon />
                    //     </IconButton>
                    //   ),
                    // }}
                  />
                ),
                size: 12,
              },
            ]}
          />
        </AppCommon.Section>

        <Box className={classes.submitContainer}>
          {mode === 'edit' && (
            <Button
              variant="contained"
              color="inherit"
              className={classes.submit}
              style={{
                background: colors.red['600'],
                color: colors.common.white,
              }}
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => {
                const removeImageId = 'includeImage';
                withVerification({
                  checkboxes: [
                    {
                      id: removeImageId,
                      content: 'Include Image',
                      checked: true,
                    },
                  ],
                  action1Event: (payload) => {
                    dispatch(
                      TrickDetailsActions.removeTrickAction({
                        payload: {
                          itemId: trickId as string,
                          removeImage: payload?.checkboxes.find(
                            (el) => el.id === removeImageId
                          )?.checked,
                        },
                        onSuccess: () => {
                          if (access === 'page') {
                            history.push(NavigationConfig.trickPage().path);
                          }
                          onDeleteItem && onDeleteItem();
                        },
                      })
                    );
                  },
                  title: `Remove ${type}`,
                  description: (
                    <span>
                      <span
                        style={{ fontSize: '1.15em' }}
                      >{`Are you sure you want to remove the ${type} `}</span>
                      <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                        {`${trickDetails.data?.title}?`}
                      </span>
                    </span>
                  ),
                });
              }}
            >
              <TranslatedText defaultText={'Remove'} />
            </Button>
          )}

          {trickId && roles.includes(Roles.Admin) && (
            <Button
              variant="contained"
              color="inherit"
              className={classes.submit}
              style={{
                background: colors.blueGrey['600'],
                color: colors.common.white,
              }}
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={async () => {
                const { data } = await OpenAIService.fetchItemPrompt(trickId);

                const mapData: Required<IItemPromptData> = {
                  sentence: '',
                  etymologyEvolution: ItemTypeEnum.Word,
                  explanationTranslation: '',
                  translation: '',
                  description: '',
                };

                openPromptsModal({
                  prompts: Object.keys(data ?? {}).map((el) => ({
                    title: formatCamelOrUnderscoreCaseToSentenceCase(el),
                    content: _.get(data, el),
                    handleAddData: _.get(mapData, el)
                      ? () => {
                          openPromptInputModalModal({
                            title: `${formatCamelOrUnderscoreCaseToSentenceCase(
                              el
                            )} Prompt`,
                            handleResult: async (data) => {
                              try {
                                await addItemEtymologyEvolutionDataByPrompt({
                                  itemId: trickId,
                                  promptResult: data,
                                });
                              } catch (error) {
                                console.log(
                                  'addItemEtymologyEvolutionDataByPrompt::',
                                  error.message
                                );
                              }

                              dispatch(
                                TrickDetailsActions.fetchTrickDetailsAction(
                                  trickId
                                )
                              );
                              closePromptsModal();
                            },
                          });
                        }
                      : undefined,
                  })),
                });
              }}
            >
              <TranslatedText defaultText={'View Prompt'} />
            </Button>
          )}
          {/* 
          {mode !== 'add' && (
            <Button
              variant="contained"
              className={classes.submit}
              color="primary"
              onClick={async () => await downloadTrick(trickId as string)}
            >
              <TranslatedText defaultText={'Download'} />
            </Button>
          )} */}

          {mode !== 'view' && (
            <SubmitWrapper
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
              startIcon={<EditOutlinedIcon />}
            >
              <TranslatedText
                defaultText={
                  mode === 'add' ? `Add` : mode === 'edit' ? `Edit` : `View`
                }
              />
            </SubmitWrapper>
          )}
        </Box>
      </AppCommon.FormContainer>
    </form>
  );
}

const TagsSelectorWrapper = ({
  style,
  ...props
}: Omit<IAdvancedSelectProps & { style?: React.CSSProperties }, 'options'>) => {
  const tagsList = TrickDetailsSelectors.useTagsList();
  const options =
    tagsList.data?.items.map((el) => ({
      id: el._id,
      label: `${el.description} (${TagLevel?.[el.level as never]})`,
    })) || [];
  const dispatch = useDispatch();

  return (
    <Box style={style} display="flex">
      <Button
        style={{
          textTransform: 'none',
          marginTop: 15,
          marginBottom: 9,
        }}
        variant="outlined"
        onClick={() =>
          openTagDetailsModal({
            mode: 'add',
            onSuccess: () => {
              closeTagDetailsModal();
              dispatch(TrickDetailsActions.fetchTagsListAction());
            },
            defaultValues: {
              // type: TagTypes.Item,
              description: '',
              name: '',
              namespaces: [],
              status: null,
              level: null,
              iconId: null,
              imageId: null,
              order: null,
              subType: null,
              iconImageId: null,
            },
            tagType: TagTypes.Item,
          })
        }
      >
        <AddOutlinedIcon />
      </Button>
      <div style={{ flex: 1, marginLeft: '0.5rem' }}>
        <AppAdvancedselect {...props} isMulti options={options} />
      </div>
    </Box>
  );
};

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const addTrickStatus = TrickDetailsSelectors.useAddTrickStatus();
  const editTrickStatus = TrickDetailsSelectors.useEditTrickStatus();

  return (
    <Button
      {...props}
      color="primary"
      disabled={
        addTrickStatus === StateStatus.Pending ||
        editTrickStatus === StateStatus.Pending
      }
    />
  );
};
