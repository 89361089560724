import { Typography } from '@material-ui/core';
import React from 'react';

interface PageTitleProps {
  children: React.ReactNode;
}

const PageTitle = (props: PageTitleProps) => {
  return (
    <div>
      <Typography variant="h6" component="div">
        {props.children}
      </Typography>
    </div>
  );
};

export default React.memo(PageTitle);
