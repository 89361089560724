import React from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import useStyles from './styles';
import { useModalData } from '../../../redux/reducers/gl_modal_reducer/selectors';
import { ModalIDs } from '../../../redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../redux/reducers/gl_modal_reducer/actions';
import { Snackbar } from '@material-ui/core';
import TranslatedText from '../TranslatedText';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from 'redux/store';
import Environment from 'config/environmentConfig';
import { ToastEnum } from 'constants/toastConst';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MODAL_ID = ModalIDs.TOAST;

export default function ToastMessage() {
  const classes = useStyles({});
  const { params, visible } = useModalData(MODAL_ID);
  const open = visible;
  const dispatch = useDispatch();
  const { message, type } = params;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      ModalActions.closeModalAction({
        modalID: MODAL_ID,
      })
    );
  };

  return (
    <React.Fragment>
      {Environment.TOAST_TYPE === ToastEnum.MaterialToast ? (
        <div className={classes.root}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type || 'success'}>
              <TranslatedText textMap={message} defaultText={message} />
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <ToastContainer />
      )}
    </React.Fragment>
  );
}

export const showToastAction = async (
  message: React.ReactNode,
  type: 'error' | 'warning' | 'info' | 'success' | null,
  options?: ToastOptions
) => {
  if (Environment.TOAST_TYPE === ToastEnum.MaterialToast) {
    store.dispatch(
      ModalActions.openModalAction({
        modalID: MODAL_ID,
        params: {
          message,
          type,
        },
      })
    );
  } else {
    toast(message, {
      type: type || options?.type,
      // hideProgressBar: true,
      position: 'bottom-center',
      ...options,
    });
  }
};

export const hideToastAction = () => {
  if (Environment.TOAST_TYPE === ToastEnum.MaterialToast) {
    store.dispatch(
      ModalActions.closeModalAction({
        modalID: MODAL_ID,
      })
    );
  } else {
  }
};
