import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const REMOVE_NAMESPACE = typeCreatorV2(
  'NamespaceDetailsReducer',
  'RemoveNamespace'
);

export const ADD_NAMESPACE = typeCreatorV2(
  'NamespaceDetailsReducer',
  'AddNamespace'
);

export const EDIT_NAMESPACE = typeCreatorV2(
  'NamespaceDetailsReducer',
  'EditNamespace'
);

export const SET_NAMESPACE_CONFIG_DROPDOWN = typeCreatorV2(
  'NamespaceDetailsReducer',
  'SetNamespaceConfigDropdown'
);
