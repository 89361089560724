import _axios from 'axios';
import { ReducerKeys } from '../../redux/config';
import store, { getState } from '../../redux/store';
import AuthActions from '../../redux/reducers/gl_auth_reducer/actions';
import { showToastAction } from '../../components/atoms/ToastMessage';
import { LanguagesEnum } from '../../constants/languagesConst';
import Configuration from './configuration';
import { saveToLocalStorage, loadFromLocalStorage } from 'redux/utils/storage';

const axios = _axios.create();
const { AUTH_HEADER_KEY, NAMESPACE_HEADER_KEY } = Configuration;

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// request pre execute -> add token in headers
axios.interceptors.request.use(
  async (config) => {
    // const token = getState().AuthReducer.token;
    const { token } = loadFromLocalStorage();
    // getState().AuthReducer.token;

    if (token) {
      config.headers[AUTH_HEADER_KEY] = `Bearer ${token}`;
    }
    config.headers[NAMESPACE_HEADER_KEY] =
      getState().AuthReducer.data.accessPortal?.selectedNamespace;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// request post execute
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers[AUTH_HEADER_KEY] = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        Configuration.executeRequestParser()
          .then(({ data }) => {
            const { refreshToken, token } = Configuration.authTokenParser(data);
            console.log(
              'Result: Token and refresh token:',
              refreshToken,
              token
            );
            store.dispatch(AuthActions.refreshTokenAction(refreshToken, token));
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            // console.log("Logout?", err);
            store.dispatch(AuthActions.logoutAction());
            showToastAction(
              {
                [LanguagesEnum.English]: 'Session Expired',
                [LanguagesEnum.Greek]: 'Η σύνδεση σας έληξε',
              },
              'info'
            );
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
