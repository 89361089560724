import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    flex: '1 1 100%',
  },
  titleContainer: {
    padding: '0.3rem 1rem 0.3rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
