export enum Roles {
  Guest = 'GUEST',
  Admin = 'ADMIN',
  User = 'USER',
  Supervisor = 'SUPERVISOR',
  Unknown = 'UNKNOWN',
  Tester = 'TESTER',
  Teacher = 'TEACHER',
  PREMIUM_USER_BASIC = 'PREMIUM_USER_BASIC',
  PREMIUM_USER_PLUS = 'PREMIUM_USER_PLUS',
}

const AllUsers = Object.values(Roles);
const RegisteredUsers = AllUsers.filter((user) => user !== Roles.Unknown);

export default {
  ...Roles,
  All: AllUsers,
  Registered: RegisteredUsers,
};
