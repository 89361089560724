import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import * as NamespacesService from 'services/api/namespaces';

export interface IState {
  namespacesList: {
    status: StateStatus | null;
    data: null | UserService.UserPortalDetailsData['assignNamespaces'];
  };
  portalAccessDetailsStatus: StateStatus | null;
  namespacesDetails: {
    status: StateStatus | null;
    data: null | NamespacesService.FetchnamespaceDetailsData;
  };
  loadingView: {
    options: {
      zIndex: number;
    } | null;
    visible: boolean;
  };
}

const initialState: IState = {
  namespacesList: {
    status: null,
    data: null,
  },
  portalAccessDetailsStatus: null,
  namespacesDetails: {
    status: null,
    data: null,
  },
  loadingView: {
    options: null,
    visible: false,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SHOW_LOADING_VIEW:
      return {
        ...state,
        loadingView: action.data,
      };
    case Types.SET_NAMESPACES_LIST:
      return {
        ...state,
        namespacesList: {
          ...state.namespacesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.namespacesList.data
              : action.data,
        },
      };
    case Types.UPDATE_PORTAL_ACCESS_DETAILS:
      return {
        ...state,
        portalAccessDetailsStatus: action.status || null,
      };
    case Types.SET_NAMESPACE_DETAILS:
      return {
        ...state,
        namespacesDetails: {
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
