import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import store from 'redux/store';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IEventPayload {
  checkboxes: Array<{
    id: string;
    content: React.ReactNode;
    checked?: boolean;
  }>;
}

interface IModalVerifyActionProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  action1?: React.ReactNode;
  action2?: React.ReactNode;
  action1Event?: (payload?: IEventPayload) => void;
  action2Event?: (payload?: IEventPayload) => void;
  action1Props?: Omit<ButtonProps, 'children' | 'onClick'>;
  action2Props?: Omit<ButtonProps, 'children' | 'onClick'>;
  checkboxes?: IEventPayload['checkboxes'];
}

interface IRefParams {
  checkboxes: IEventPayload['checkboxes'];
}

const ModalVerifyAction = () => {
  const selectionsRef = React.useRef<IEventPayload['checkboxes']>();

  // const handleCheckboxesChange = (
  //   add: boolean,
  //   checkbox: IEventPayload['checkboxes'][0]
  // ) => {
  //   // remove element
  //   selections.current = selections.current?.filter
  //     ? selections.current.filter((el) => el.id === checkbox.id)
  //     : undefined;

  //   if (add) {
  //     if (selections.current?.push)
  //       selections.current?.push && selections.current.push(checkbox);
  //   }
  // };

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_VERIFY_ACTION}
      showExitIcon={true}
      //   contentProps={{ style: { minHeight: '40vh' } }}
      modalProps={{ fullWidth: true }}
      renderHeader={(params: IModalVerifyActionProps) => (
        <div>
          {params.title ?? <TranslatedText defaultText="Execute Action" />}
        </div>
      )}
      renderContent={(params: IModalVerifyActionProps) => (
        <Content {...params} ref={selectionsRef} />
      )}
      renderFooter={(params: IModalVerifyActionProps) => (
        <Actions {...params} ref={selectionsRef} />
      )}
    />
  );
};

const Content = React.forwardRef(
  (params: IModalVerifyActionProps, ref: any) => {
    const classes = useStyles();
    const [selections, setSelections] = React.useState<
      IEventPayload['checkboxes']
    >([]);

    React.useEffect(() => {
      ref.current = {
        ...ref.current,
        checkboxes: params.checkboxes ?? [],
      } as IRefParams;
      setSelections(params.checkboxes ?? []);
    }, [params.checkboxes]);

    return (
      <>
        {params.description ?? (
          <Typography className={classes.description}>
            <TranslatedText defaultText="Are your sure to proceed to the action?" />
          </Typography>
        )}
        {selections && (
          <Box style={{ marginTop: '1rem' }}>
            {selections.map((el) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={el.checked}
                    onChange={(event) => {
                      const newSelections = selections.map((el) => {
                        if (el.id === event.target.name) {
                          el.checked = event.target.checked;
                        }

                        return el;
                      });
                      setSelections(newSelections);
                      // event.target.name]: event.target.checked
                    }}
                    name={el.id}
                    color="primary"
                  />
                }
                label={el.content}
              />
            ))}
          </Box>
        )}
      </>
    );
  }
);

const Actions = React.forwardRef(
  (params: IModalVerifyActionProps, ref: any) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
      <>
        <Button
          color="secondary"
          onClick={() => {
            params.action2Event && params.action2Event();
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_VERIFY_ACTION,
              })
            );
          }}
          className={classes.action2}
          {...params.action2Props}
        >
          {params.action2 ?? <TranslatedText defaultText="No" />}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            params.action1Event &&
              params.action1Event(ref.current as IRefParams);
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_VERIFY_ACTION,
              })
            );
          }}
          className={classes.action1}
          {...params.action1Props}
        >
          {params.action1 ?? <TranslatedText defaultText="Yes" />}
        </Button>
      </>
    );
  }
);

export const withVerification = (params?: IModalVerifyActionProps) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_VERIFY_ACTION,
      params,
    })
  );
};

export default React.memo(ModalVerifyAction);
