import { Box, Button, Checkbox, IconButton, Theme } from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import BaseModal from 'components/molecules/BaseModal';
import React from 'react';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { ItemTypeEnum } from 'constants/typesConst';
import { ItemTypeTitleMap } from 'components/compounds/ItemDetailsModal/configuration';
import Content from '../Content';

interface ITricksDetailsModalModalProps {}

export interface TricksDetailsModalParams {
  trickId: string | null;
  onAddItem?: () => void;
  type?: ItemTypeEnum;
  onDeleteItem?: () => void;
}

const TricksDetailsModal = (props: ITricksDetailsModalModalProps) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_TRICK_DETAILS}
      showExitIcon={true}
      modalProps={{ fullWidth: true, maxWidth: 'lg', fullScreen: matches }}
      renderHeader={(params: TricksDetailsModalParams) => (
        <Header {...params} />
      )}
      renderContent={(params: TricksDetailsModalParams) => {
        return (
          <Content
            mode={params.trickId ? 'edit' : 'add'}
            access="modal"
            trickId={params.trickId}
            onAddItem={params.onAddItem}
            onDeleteItem={params.onDeleteItem}
            type={params.type ?? null}
          />
        );
      }}
    />
  );
};

const Header = (props: TricksDetailsModalParams) => {
  const dispatch = useDispatch();
  const title = props.type ? ItemTypeTitleMap[props.type] : 'Item';

  return (
    <Box alignItems="center" display="flex">
      <TranslatedText
        defaultText={`${props.trickId ? 'Edit' : 'Add'} ${title}`}
      />
    </Box>
  );
};

export default React.memo(TricksDetailsModal);
