import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { IState } from './reducer';

/* selector: useUserSettingsDetails */
export const useUserSettingsDetails = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.USER_NAMESPACE_SETTINGS_REDUCER] as IState)
        .userSettingsDetails
  );
};

/* selector: useUpdateSettingsStatus */
export const useUpdateSettingsStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.USER_NAMESPACE_SETTINGS_REDUCER] as IState)
        .updateUserSettingsStatus
  );
};
