import { BlankSplitItem } from './configuration';

export function validateFillTheBlankMultiple(
  exercise: string,
  answer: string
): {
  correct: boolean;
  message: string;
} {
  // Split exercise and answer into arrays of words
  const exerciseWords = exercise.split(' ');
  const answerWords = answer.split(' ');

  for (let index in exerciseWords) {
    if (exerciseWords[index] === BlankSplitItem) {
      continue;
    }

    if (exerciseWords[index] !== answerWords[index]) {
      return {
        correct: false,
        message: `Invalid match: Exercise: ${exerciseWords[index]} & Answer: ${answerWords[index]}`,
      };
    }
  }

  if (exerciseWords.length !== answerWords.length) {
    return {
      correct: false,
      message: `Invalid number of words`,
    };
  }

  return {
    correct: true,
    message: '',
  };
}
