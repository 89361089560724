import React from 'react';
import TranslatedText from 'components/atoms/TranslatedText';
import Translations from './translations';
import Section from './Section';
import FormContainer from './FormContainer';

const RequiredField = () => {
  return <TranslatedText textMap={Translations.requiredField} />;
};

export default { RequiredField, Section, FormContainer };
