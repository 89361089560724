import React from 'react';
import TranslatedText from 'components/atoms/TranslatedText';
import NavigationConfig from 'config/navigationConfig';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RateReviewIcon from '@material-ui/icons/RateReview';
import * as Types from '../types';
import { ReviewEntityTypeEnum } from 'constants/reviewEntityConst';
import { TagTypes } from 'constants/tagsConst';
import AuthRoles, { Roles } from 'constants/authRolesConst';

const SideBarConfig: Types.ISidebarConfig[] = [
  {
    title: <TranslatedText defaultText="Statistics" />,
    path: null,
    requiredRoles: [Roles.Supervisor],
    id: 'statistics',
    component: null,
    Icon: () => <DashboardOutlinedIcon />,
    subItems: [
      {
        title: <TranslatedText defaultText="Dashboard" />,
        path: NavigationConfig.dashboardPage().path,
        requiredRoles: NavigationConfig.dashboardPage().roles,
        id: 1,
        component: NavigationConfig.dashboardPage().component,
      },
      {
        title: <TranslatedText defaultText="Ranking" />,
        path: NavigationConfig.rankingPage().path,
        requiredRoles: NavigationConfig.rankingPage().roles,
        id: 2,
        component: NavigationConfig.rankingPage().component,
      },
    ],
  },
  {
    title: <TranslatedText defaultText="Items" />,
    path: NavigationConfig.trickPage().path,
    requiredRoles: NavigationConfig.trickPage().roles,
    id: 'items',
    component: NavigationConfig.trickPage().component,
    Icon: () => <DescriptionOutlinedIcon />,
  },
  {
    title: <TranslatedText defaultText="Collections" />,
    path: NavigationConfig.routinesPage().path,
    requiredRoles: NavigationConfig.routinesPage().roles,
    id: 'collections',
    component: NavigationConfig.routinesPage().component,
    Icon: () => <LibraryBooksOutlinedIcon />,
  },
  {
    title: <TranslatedText defaultText="Tags" />,
    path: null,
    requiredRoles: NavigationConfig.tagsPage().roles,
    id: 'tags',
    component: null,
    //  NavigationConfig.tagsPage().component,
    Icon: () => <LocalOfferOutlinedIcon />,
    subItems: Object.values(TagTypes).map((el, index) => ({
      title: <TranslatedText defaultText={`Tag - ${el}`} />,
      path: NavigationConfig.tagsPage({ type: el }).path,
      requiredRoles: NavigationConfig.tagsPage().roles,
      id: index,
      component: NavigationConfig.tagsPage().component,
    })),
  },
  {
    title: <TranslatedText defaultText="Files" />,
    path: NavigationConfig.uploadedFilesPage().path,
    requiredRoles: NavigationConfig.uploadedFilesPage().roles,
    id: 'uploaded-files',
    component: NavigationConfig.uploadedFilesPage().component,
    Icon: () => <CropOriginalIcon />,
  },
  {
    title: <TranslatedText defaultText="Workspaces" />,
    path: NavigationConfig.namespacesPage().path,
    requiredRoles: NavigationConfig.namespacesPage().roles,
    id: 'namespaces',
    component: NavigationConfig.namespacesPage().component,
    Icon: () => <FeaturedPlayListOutlinedIcon />,
  },
  {
    title: <TranslatedText defaultText="Notification" />,
    path: NavigationConfig.notificationPage().path,
    requiredRoles: NavigationConfig.notificationPage().roles,
    id: 'notification',
    component: NavigationConfig.notificationPage().component,
    Icon: () => <NotificationsIcon />,
  },
  {
    title: <TranslatedText defaultText="Users" />,
    path: NavigationConfig.usersPage().path,
    requiredRoles: NavigationConfig.usersPage().roles,
    Icon: () => <PeopleAltIcon />,
    id: 'users',
    component: NavigationConfig.usersPage().component,
  },
  {
    title: <TranslatedText defaultText="Review" />,
    path: null,
    requiredRoles: NavigationConfig.reviewEntityPage().roles,
    id: 'review',
    component: null,
    Icon: () => <RateReviewIcon />,
    subItems: Object.values(ReviewEntityTypeEnum).map((el, index) => ({
      title: <TranslatedText defaultText={`Review - ${el}`} />,
      path: NavigationConfig.reviewEntityPage({ entityType: el }).path,
      requiredRoles: NavigationConfig.reviewEntityPage().roles,
      id: index,
      component: NavigationConfig.reviewEntityPage().component,
    })),
  },
  {
    title: <TranslatedText defaultText="System" />,
    path: null,
    Icon: () => <SettingsApplicationsOutlinedIcon />,
    requiredRoles: [AuthRoles.Admin],
    id: 'app-system',
    component: null,
    subItems: [
      {
        title: <TranslatedText defaultText="Logging" />,
        path: NavigationConfig.logsPage().path,
        requiredRoles: NavigationConfig.logsPage().roles,
        // Icon: () => <AssignmentOutlinedIcon />,
        id: 2,
        component: NavigationConfig.logsPage().component,
      },
      {
        title: <TranslatedText defaultText="Sessions" />,
        path: NavigationConfig.sessionPage().path,
        requiredRoles: NavigationConfig.sessionPage().roles,
        // Icon: () => <DevicesOtherOutlinedIcon />,
        id: 3,
        component: NavigationConfig.sessionPage().component,
      },
      {
        title: <TranslatedText defaultText="Activities" />,
        path: NavigationConfig.ativitiesPage().path,
        requiredRoles: NavigationConfig.ativitiesPage().roles,
        // Icon: () => <HistoryIcon />,
        id: 4,
        component: NavigationConfig.ativitiesPage().component,
      },
      {
        title: <TranslatedText defaultText="Settings" />,
        path: NavigationConfig.systemSettingsPage().path,
        requiredRoles: NavigationConfig.systemSettingsPage().roles,
        id: 5,
        component: NavigationConfig.systemSettingsPage().component,
      },
      {
        title: <TranslatedText defaultText="Payments" />,
        path: NavigationConfig.paymentsPage().path,
        requiredRoles: NavigationConfig.paymentsPage().roles,
        id: 6,
        component: NavigationConfig.paymentsPage().component,
      },
      {
        title: <TranslatedText defaultText="Url Alias" />,
        path: NavigationConfig.urlAliasPage().path,
        requiredRoles: NavigationConfig.urlAliasPage().roles,
        id: 7,
        component: NavigationConfig.urlAliasPage().component,
      },
    ],
  },
];

export default SideBarConfig;
