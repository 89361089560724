import { ThemesModeEnum } from 'constants/themeConst';
import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import Environment from 'config/environmentConfig';
import { PalletesEnum } from 'constants/palletesConst';

export interface IState {
  themeMode: ThemesModeEnum;
  accessFromMobileApp: boolean;
  palleteId: PalletesEnum;
  themeSettingsPanelIsOpen: boolean;
}

const initialState: IState = {
  themeMode: Environment.DEFAULT_THEME,
  accessFromMobileApp: false,
  palleteId: Environment.DEFAULT_PALLET,
  themeSettingsPanelIsOpen: false,
};

const ThemeReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_THEME:
      return {
        ...state,
        themeMode: action.data,
      };
    case Types.SET_ACCESS:
      return {
        ...state,
        accessFromMobileApp: action.data,
      };
    case Types.SET_PALLETE_ID:
      return {
        ...state,
        palleteId: action.data,
      };
    case Types.SET_THEME_SETTINGS_PANEL_OPEN:
      return {
        ...state,
        themeSettingsPanelIsOpen: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ThemeReducer;
