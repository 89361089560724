// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request from 'utils/request';
import * as PathUtils from 'utils/path';

/** fetch tricks libary sets */
export interface IWordInfoData {
  [key: string]: any;
}

export interface IWordInfoPayload {
  text: string;
  lanFrom?: string;
  lanTo: string;
  type: 'examples' | 'explanation';
}

export const getWordInfo = async (
  payload: IWordInfoPayload
): Promise<Types.ResponseOutputV2<IWordInfoData>> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/utils/word-info`,
    {
      body: payload,
      toastMessageVisibility: {
        success: false,
        error: true,
      },
    }
  );

  return data;
};

/** fetch tricks libary sets */
export interface ITranslateData {
  text: string;
  // pronunciation: string;
  // from: {
  //   language: {
  //     didYouMean: boolean;
  //     iso: string;
  //   };
  //   text: {
  //     autoCorrected: boolean;
  //     value: string;
  //     didYouMean: boolean;
  //   };
  // };
  // raw: any[];
}

interface ITranslatePayload {
  text: string;
  lanFrom?: string;
  lanTo?: string;
}

export const translateText = async (
  payload: ITranslatePayload
): Promise<Types.ResponseOutputV2<ITranslateData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/utils/translate`,
    {
      body: payload,
      toastMessageVisibility: {
        success: false,
        error: false,
      },
    }
  );

  return data;
};

/** fetch Images From External Providers */
export interface IFetchImagesFromExternalProvidersData {
  items: Array<{
    imageUrl: string;
    id: number;
    name: string;
    type: IFetchImagesFromExternalProvidersPayload['type'];
  }>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
}

export interface IFetchImagesFromExternalProvidersPayload {
  type: 'pexels';
  topic: string;
  page?: number;
  pageSize?: number;
}

export const fetchImagesFromExternalProviders = async (
  payload: IFetchImagesFromExternalProvidersPayload
): Promise<Types.ResponseOutputV2<IFetchImagesFromExternalProvidersData>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'type',
        value: payload.type,
      },
      {
        key: 'topic',
        value: payload.topic,
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
    ],
  });

  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/utils/external-images/list${query}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: false,
        error: false,
      },
    }
  );

  return data;
};

/** Upload image from external */
export interface IUploadImageFromExternalProvidersData {
  _id: string;
  fileName: string;
  path: string;
  fileType: string;
  attachmentType: string;
  metadata: {
    dirName: string;
    fieldname: string;
    originalname: string;
    mimetype: string;
    id: string;
    fullPath: string;
  };
  name: string;
  size: number;
  createdAt: string;
  updatedAt: string;
}

export interface IUploadImageFromExternalProvidersPayload {
  imageUrl: string;
  name: string;
}

export const uploadImageFromExternalProvider = async (
  payload: IUploadImageFromExternalProvidersPayload
): Promise<Types.ResponseOutputV2<IUploadImageFromExternalProvidersData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/utils/upload/external-image`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Transcript Youtube Video */
export interface ITranscriptYoutubeVideoPayloadData {
  transcript: Array<{
    text: string;
    translation: string;
    timestamp: {
      secondsDuration: number;
      secondsStart: number;
    };
  }>;
}

/** Transcript Youtube Video */
export interface ITranscriptYoutubeVideoPayload {
  videoUrl: string;
}

export const transcriptYoutubeVideo = async (
  payload: ITranscriptYoutubeVideoPayload
): Promise<Types.ResponseOutputV2<ITranscriptYoutubeVideoPayloadData>> => {
  // const query = PathUtils.varToStringParams({
  //   variablesArray: [
  //     {
  //       key: 'videoUrl',
  //       value: payload?.videoUrl?.toString(),
  //     },
  //   ],
  // });

  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/utils/transcript-youtube-video`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
      config: {
        params: {
          videoUrl: payload?.videoUrl?.toString(),
        },
      },
    }
  );

  return data;
};

/** Convert Text To Audio */
export interface IConvertTextToAudioPayload {
  text: string;
  title?: string;
  dialogue?: {
    speakers: Array<{
      name: string;
      gender: 'male' | 'female';
    }>;
  };
}

export type IConvertTextToAudioData = {
  _id: string;
  fileName: string;
  path: string;
  fileType: string;
  attachmentType: string;
  metadata: {
    dirName: string;
    fieldname: string;
    originalname: string;
    mimetype: string;
    id: string;
    fullPath: string;
  };
  name: string;
  size: number;
  createdAt: string;
  updatedAt: string;
};

export const convertTextToAudio = async (
  payload: IConvertTextToAudioPayload
): Promise<Types.ResponseOutputV2<IConvertTextToAudioData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/utils/text-to-audio`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};
