//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_THEME: string = typeCreatorV2('ThemeReducer', 'SetTheme');
export const SET_ACCESS: string = typeCreatorV2('ThemeReducer', 'SetAccess');
export const SET_PALLETE_ID: string = typeCreatorV2(
  'ThemeReducer',
  'SetPalleteId'
);

export const SET_THEME_SETTINGS_PANEL_OPEN: string = typeCreatorV2(
  'ThemeReducer',
  'SetThemeSettingsPanelOpen'
);
