import React, { CSSProperties } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { KnowledgeLevelEnum } from 'constants/typesConst';
import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import clsx from 'clsx';
import { colors } from '@material-ui/core';

// Define color mappings using Material-UI colors
export const KnowledgeLevelColors = {
  [KnowledgeLevelEnum.NoLevel]: grey[700],
  [KnowledgeLevelEnum.Weak]: red[800],
  [KnowledgeLevelEnum.Medium]: amber[500],
  [KnowledgeLevelEnum.Strong]: green[400],
  [KnowledgeLevelEnum.VeryStrong]: green[900],
  all: colors['blueGrey']['400'], // Replace with your app's light primary color
  'known-items': blue[400],
};

// Define styles using Material-UI makeStyles hook
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  bar: {
    width: 3,
  },
  bar1: {
    height: 4,
  },
  bar2: {
    height: 8,
    marginLeft: theme.spacing(0.5),
  },
  bar3: {
    height: 11,
    marginLeft: theme.spacing(0.5),
  },
}));

const KnowledgeLevel = ({
  level,
  style,
}: {
  level: KnowledgeLevelEnum;
  style?: CSSProperties;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const noLevelColor = theme.palette.type === 'dark' ? grey[800] : grey[300];

  return (
    <div className={clsx(classes.container, style)}>
      <div
        className={clsx(classes.bar, classes.bar1)}
        style={{
          backgroundColor:
            level === KnowledgeLevelEnum.NoLevel
              ? noLevelColor
              : level === KnowledgeLevelEnum.Weak
              ? KnowledgeLevelColors.Weak
              : level === KnowledgeLevelEnum.Strong
              ? KnowledgeLevelColors.Strong
              : level === KnowledgeLevelEnum.VeryStrong
              ? KnowledgeLevelColors.VeryStrong
              : KnowledgeLevelColors.Medium,
        }}
      />
      <div
        className={clsx(classes.bar, classes.bar2)}
        style={{
          backgroundColor:
            level === KnowledgeLevelEnum.Weak ||
            level === KnowledgeLevelEnum.NoLevel
              ? noLevelColor
              : level === KnowledgeLevelEnum.Strong
              ? KnowledgeLevelColors.Strong
              : level === KnowledgeLevelEnum.VeryStrong
              ? KnowledgeLevelColors.VeryStrong
              : KnowledgeLevelColors.Medium,
        }}
      />
      <div
        className={clsx(classes.bar, classes.bar3)}
        style={{
          backgroundColor:
            level === KnowledgeLevelEnum.Weak ||
            level === KnowledgeLevelEnum.NoLevel
              ? noLevelColor
              : level === KnowledgeLevelEnum.Strong
              ? KnowledgeLevelColors.Strong
              : level === KnowledgeLevelEnum.VeryStrong
              ? KnowledgeLevelColors.VeryStrong
              : noLevelColor,
        }}
      />
    </div>
  );
};

export default KnowledgeLevel;
