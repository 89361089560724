import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_REVIEW_ENTITY_COLLECTIONS_LIST = typeCreatorV2(
  'ReviewEntityReducer',
  'SetReviewEntityCollectionsList'
);

export const SET_REVIEW_ENTITY_ITEMS_LIST = typeCreatorV2(
  'ReviewEntityReducer',
  'SetReviewEntityItemsList'
);

export const UPDATE_REVIEW_ENTITY_DETAILS = typeCreatorV2(
  'ReviewEntityReducer',
  'UpdateReviewEntityDetails'
);

export const DELETE_REVIEW_ENTITY_DETAILS = typeCreatorV2(
  'ReviewEntityReducer',
  'DeleteReviewEntityDetails'
);

export const RESET = typeCreatorV2('ReviewEntityReducer', 'Reset');
