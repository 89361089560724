export enum ItemStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  OnReview = 'OnReview',
  AIReview = 'AIReview',
}

export enum CollectionStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  OnReview = 'OnReview',
  AIReview = 'AIReview',
}

export const CollectionStatusOptions = Object.values(CollectionStatusEnum).map(
  (el) => ({
    id: el,
    label: el,
  })
);

export const ItemStatusOptions = Object.values(ItemStatusEnum).map((el) => ({
  id: el,
  label: el,
}));
