import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_COLLECTION_TAGS = typeCreatorV2(
  'RoutineDetailsReducer',
  'SetCollectionTags'
);

export const SET_PLACES = typeCreatorV2('RoutineDetailsReducer', 'SetPlaces');

export const ADD_ROUTINE = typeCreatorV2('RoutineDetailsReducer', 'AddRoutine');

export const SET_ROUTINE_DETAILS = typeCreatorV2(
  'RoutineDetailsReducer',
  'SetRoutineDetails'
);

export const EDIT_ROUTINE = typeCreatorV2(
  'RoutineDetailsReducer',
  'EditRoutine'
);

export const SET_TRICKS = typeCreatorV2('RoutineDetailsReducer', 'SetTricks');

export const SET_SELECTED_ITEMS = typeCreatorV2(
  'RoutineDetailsReducer',
  'SetSelectedItems'
);

export const SET_SELECTED_TAGS = typeCreatorV2(
  'RoutineDetailsReducer',
  'SetSelectedTags'
);

export const REMOVE_ROUTINE = typeCreatorV2(
  'RoutineDetailsReducer',
  'RemoveRoutine'
);

export const UPLOAD_CSV_FILE = typeCreatorV2(
  'RoutineDetailsReducer',
  'UploadCsvFile'
);

export const COLLECTION_BULK_ACTIONS = typeCreatorV2(
  'RoutineDetailsReducer',
  'CollectinBulkActions'
);

export const SET_ITEM_TAGS = typeCreatorV2(
  'RoutineDetailsReducer',
  'SetItemTags'
);

export const INIT_BOOK_ITEMS = typeCreatorV2(
  'RoutineDetailsReducer',
  'InitBookItems'
);

export const GENERATE_AUDIO_FILE = typeCreatorV2(
  'RoutineDetailsReducer',
  'GenerateAudioFile'
);

export const RESET = typeCreatorV2('RoutineDetailsReducer', 'Reset');
