import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppAdvancedselect,
  AppTextField,
  AppSelect,
  IAdvancedSelectProps,
  AppCommon,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ExerciseActions from './ExerciseActions';
import {
  openUploadedFilesModal,
  closeUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { FileType } from 'constants/filesConst';
import { getState } from 'redux/store';
import * as OpenAIService from 'services/api/openai';
import { IState } from '../../store/reducer';
import { formatCamelOrUnderscoreCaseToSentenceCase } from 'utils/strings';
import TranslatedText from 'components/atoms/TranslatedText';
import { IItemPromptData } from 'services/api/openai';
import { openPromptInputModalModal } from 'components/app_common/PromptInputModal';
import {
  addItemEtymologyEvolutionDataByPrompt,
  ICollectionPromptData,
} from 'services/api/openai';
import EtymologyEvolution from './EtymologyEvolution';
import type { IFormData } from '../../types';
import Environment from 'config/environmentConfig';
import { colors } from '@material-ui/core';
import ImageWrapper from 'components/app_common/FormFileWrapper';

const LinkSection = () => {
  const { errors, control, setValue, getValues } = useFormContext<IFormData>();

  return (
    <AppCommon.Section title={'Video URL'}>
      <div>
        <AppTextField
          variant="outlined"
          size="medium"
          margin="normal"
          error={'link' in errors}
          helperText={errors.link && errors.link.message}
          rules={{}}
          fullWidth
          id="link"
          placeholder={'Enter video url'}
          control={control}
          autoComplete="link"
          icon={{
            ComponentRight: (
              <IconButton
                onClick={() =>
                  getValues().link &&
                  window.open(getValues().link ?? '', '_blank')
                }
              >
                <VideocamOutlinedIcon />
              </IconButton>
            ),
          }}
          name="link"
        />
      </div>
    </AppCommon.Section>
  );
};

export default React.memo(LinkSection);
