// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request, { nativeAxios } from 'utils/request';
import * as PathUtils from 'utils/path';
import {
  AppTypes,
  FeaturesTypes,
  Namespacestatus,
  NamespaceType,
} from 'constants/namespaceConst';
import { LanguagesEnum } from 'constants/languagesConst';
import { TagLevel } from 'constants/tagsConst';
import { Roles } from 'constants/authRolesConst';

/** fetch namespaces list */
export type FetchnamespaceListData = {
  items: Array<{
    _id: string;
    name: string;
    description: string;
    type?: NamespaceType;
    status?: Namespacestatus;
    translations?: {
      [key in LanguagesEnum]: Partial<{
        description: string;
      }>;
    };
    imageId?: {
      _id: string;
      path: string;
    };
    appType?: AppTypes;
    levels?: Array<TagLevel>;
    features?: FeaturesTypes[];
    public?: boolean;
    code?: string;
    members?: string[];
    autoPracticeModeCollection?: boolean;
    config?: string;
  }>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface IFetchnamespaceListInput {
  page?: number;
  pageSize?: number;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  globalSearch?: string;
  disableTranslation?: 'true' | 'false';
  showTranslationsDictionary?: 'true' | 'false';
  public?: 'true' | 'false';
  type?: NamespaceType;
  status?: Namespacestatus;
}

export const fetchnamespaceList = async (
  payload: IFetchnamespaceListInput
): Promise<Types.ResponseOutputV2<FetchnamespaceListData>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },
      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'globalSearch',
        value: payload?.globalSearch?.toString(),
      },
      {
        key: 'disableTranslation',
        value: payload.disableTranslation,
      },
      {
        key: 'showTranslationsDictionary',
        value: payload.showTranslationsDictionary,
      },
      {
        key: 'public',
        value: payload.public,
      },
      {
        key: 'type',
        value: payload.type,
      },
      {
        key: 'status',
        value: payload.status,
      },
    ],
  });
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/namespace/list${query}`
  );

  return data;
};

/** Add namespace */
export interface IAddnamespaceInput {
  name: string;
  description: string;
  status: Namespacestatus;
  imageId: string;
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string | null;
      }>;
    }
  >;
  type: NamespaceType;
  appType: AppTypes;
  levels?: Array<TagLevel>;
  features: FeaturesTypes[];
  public: boolean;
  members?: string[];
  autoPracticeModeCollection?: boolean;
  config?: string;
}

export const addnamespace = async (
  payload: IAddnamespaceInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/namespace/add`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Edit namespace */
export interface IEditnamespaceInput extends Partial<IAddnamespaceInput> {}

export const editnamespace = async (
  id: string,
  payload: IEditnamespaceInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/namespace/details/${id}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Remove namespace */

export const removenamespace = async (
  id: string
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/namespace/details/${id}`,
    {
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** fetch namespace details */
export type FetchnamespaceDetailsData = {
  _id: string;
  name: string;
  description: string;
  type: NamespaceType;
  status?: Namespacestatus;
  appType?: AppTypes;
  levels?: Array<TagLevel>;
  public?: boolean;
  code?: string;
};

export interface IFetchnamespaceDetailsInput {
  id: string;
}

export const fetchnamespaceDetails = async (
  payload: IFetchnamespaceDetailsInput
): Promise<Types.ResponseOutputV2<FetchnamespaceDetailsData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/namespace/details/${payload.id}`
  );

  return data;
};

/** fetch namespace members */
export type FetchnamespaceMembersData = Array<{
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
}>;

export interface IFetchnamespaceMembersInput {
  id: string;
}

export const fetchNamespaceMembers = async (
  payload: IFetchnamespaceMembersInput
): Promise<Types.ResponseOutputV2<FetchnamespaceMembersData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/namespace/members/${payload.id}`
  );

  return data;
};

/** fetch namespace students list */
export type FetchnamespaceStudentsListData = {
  items: Array<{
    _id: string;
    roles: Roles[];
    isVerified: boolean;
    username: string;
    email?: string;
    createdAt: string;
    lastName?: string;
    firstName?: string;
  }>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface IFetchnamespaceStudentsListInput {
  // namespaceId?: string;
  sortField: string;
  page: string;
  pageSize: string;
  orderType: 'desc' | 'asc';
  globalSearch?: string;
  level?: string;
}

export const fetchNamespaceStudentsList = async (
  payload: IFetchnamespaceStudentsListInput
): Promise<Types.ResponseOutputV2<FetchnamespaceStudentsListData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/namespace/students/list`,
    {
      config: {
        params: payload,
      },
    }
  );

  return data;
};

/** Remove student from class */
export interface IRemoveStudentFromClassInput {
  namespaceId?: string;
  studentId: string;
}

export const removeStudentFromClass = async (
  payload: IRemoveStudentFromClassInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/namespace/remove-student-from-class`,
    {
      body: payload,
    }
  );

  return data;
};

/** Add student to class */

export interface IAddStudentToClassInput {
  namespaceId?: string;
  email: string;
}

export const addStudentToClass = async (
  payload: IAddStudentToClassInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/namespace/add-student-to-class`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** Fetch namespace configs */
export type IFetchNamespaceConfigsData = Array<{ id: string; label: string }>;

export const fetchNamespaceConfigs = async (): Promise<
  Types.ResponseOutputV2<IFetchNamespaceConfigsData>
> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/namespace/configs`,
    {
      toastMessageVisibility: {
        error: true,
        success: false,
      },
    }
  );

  return data;
};
