import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TricksService from 'services/api/items';

export interface IState {
  tricksList: {
    status: StateStatus | null;
    data: TricksService.FetchTrickListData | null;
  };
  hideCollectionStatus: StateStatus | null;
  updateItemStatus: StateStatus | null;
}

const initialState: IState = {
  tricksList: {
    status: null,
    data: null,
  },
  hideCollectionStatus: null,
  updateItemStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.UPDATE_ITEM:
      return {
        ...state,
        updateItemStatus: action.status || null,
      };
    case Types.SET_TRICKS_LIST:
      return {
        ...state,
        tricksList: {
          ...state.tricksList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.tricksList.data : action.data,
        },
      };
    case Types.HIDE_COLLECTION:
      return {
        ...state,
        hideCollectionStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
