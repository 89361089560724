import React from 'react';
import { CollectionTypeEnum, ItemTypeEnum } from 'constants/typesConst';
import { useFormContext, useWatch } from 'react-hook-form';
import { CollectionDetailsProps } from '../../types';
import { IFormData } from '../../configuration';
import DefaultItemForm from '../templates/DefaultCollectionForm';
import BookForm from '../templates/BookForm';
import ReadingCollectionForm from '../templates/ReadingCollectionForm';
import SpeakingCollectionForm from '../templates/SpeakingCollectionForm';
import ListeningCollectionForm from '../templates/ListeningCollectionForm';
import GrammarCollectionForm from '../templates/GrammarCollectionForm';
import VocabularyCollectionForm from '../templates/VocabularyCollectionForm';
import PlacementTestCollectionForm from '../templates/PlacementTestCollectionForm';
import WizdomCollectionForm from '../templates/WizdomCollectionForm';
import TextFragmentCollectionForm from '../templates/TextFragmentCollectionForm';
import BundleExercisesCollectionForm from '../templates/BundleExercisesCollectionForm';

const Collectionelector: {
  [key in CollectionTypeEnum]: React.FC<CollectionDetailsProps>;
} = {
  [CollectionTypeEnum.Book]: BookForm,
  [CollectionTypeEnum.VocabText]: VocabularyCollectionForm,
  [CollectionTypeEnum.GenericCollection]: DefaultItemForm,
  [CollectionTypeEnum.Grammar]: GrammarCollectionForm,
  [CollectionTypeEnum.Listening]: ListeningCollectionForm,
  // [CollectionTypeEnum.PlacementTest]: DefaultItemForm,
  [CollectionTypeEnum.Reading]: ReadingCollectionForm,
  [CollectionTypeEnum.Speaking]: SpeakingCollectionForm,
  [CollectionTypeEnum.TricksRoutine]: DefaultItemForm,
  [CollectionTypeEnum.TextFragm]: TextFragmentCollectionForm,
  [CollectionTypeEnum.Vocabulary]: VocabularyCollectionForm,
  [CollectionTypeEnum.PlacementTest]: PlacementTestCollectionForm,
  [CollectionTypeEnum.EvaluationTest]: PlacementTestCollectionForm,
  [CollectionTypeEnum.Wizdom]: WizdomCollectionForm,
  [CollectionTypeEnum.BundleExercises]: BundleExercisesCollectionForm,
};

const TemplateSelector = (props: CollectionDetailsProps) => {
  // const { control } = useFormContext<IFormData>();
  // const type = useWatch({
  //   control,
  //   name: 'type',
  // });
  const Node = Collectionelector[props.type as CollectionTypeEnum];

  return <Node {...props} />;
};

export default TemplateSelector;
