import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';

export interface IState {
  resetPasswordStatus: StateStatus | null;
}

const initialState: IState = {
  resetPasswordStatus: null,
};

const ForceChangePasswordResetReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // FORGOT_PASSOWRD
    case Types.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ForceChangePasswordResetReducer;
