import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]

export const ADD_TASK = typeCreatorV2('TaskDetailsReducer', 'AddTask');

export const EDIT_TASK = typeCreatorV2('TaskDetailsReducer', 'EditTask');

export const SET_TASK_DETAILS = typeCreatorV2(
  'TaskDetailsReducer',
  'SetTaskDetails'
);

export const SET_CURRENT_STEP = typeCreatorV2(
  'TaskDetailsReducer',
  'SetCurrentStep'
);

export const RESET = typeCreatorV2('TaskDetailsReducer', 'Reset');
