import { colors } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gab: 0,
    },
  },
  // timepicker: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: 200,
  // },
}));

export default useStyles;
