import React, { ReactElement } from 'react';
import useStyles from './styles';
import List from '@material-ui/core/List';
import { Button, Divider, ListItem, ListItemProps } from '@material-ui/core';
import clsx from 'clsx';

interface IProps<T> {
  data: T[];
  renderElement: (_data: T, index: number) => React.ReactNode;
  itemContainerProps?: Omit<ListItemProps<any>, 'children'>;
  onElementClick?: (_data: T, index: number) => void;
  divider?: boolean;
  keyField?: string;
  fetchMore?: {
    handleMore: () => void;
    totalItems: number;
  };
  horizontal?: boolean;
}

export default function DataList<T>(props: IProps<T>): ReactElement {
  const classes = useStyles({});
  const ref: any = React.useRef<HTMLDivElement>();

  const list = props.data.map((_data, index) => (
    <div
      key={
        props.keyField
          ? (_data as any)?.[props.keyField]
          : (_data as any)?._id ?? index
      }
    >
      <ListItem
        {...props.itemContainerProps}
        className={clsx(classes.listItem, props?.itemContainerProps?.className)}
        onClick={() =>
          props?.onElementClick && props.onElementClick(_data, index)
        }
      >
        {props.renderElement(_data, index)}
      </ListItem>
      {props.divider && <Divider style={{ flex: 1, width: '100%' }} />}
    </div>
  ));

  return (
    <div
      style={{
        overflow: props.horizontal ? 'auto' : undefined,
      }}
      ref={ref}
      className={classes.root}
    >
      <List
        style={{
          display: props.horizontal ? 'flex' : undefined,
        }}
      >
        {list}
      </List>
      <TargetElement {...props} ref={ref} />
    </div>
  );
}

const TargetElement = React.forwardRef((props: IProps<any>, ref: any) => {
  const hasMore =
    (props.data.length ?? 0) < (props?.fetchMore?.totalItems ?? 0);
  return (
    <>
      {props.fetchMore && hasMore && (
        <Button onClick={() => props.fetchMore?.handleMore()}>
          {'Load More'}
        </Button>
      )}
    </>
  );
});
