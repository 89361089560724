import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_NAMESPACES_LIST = typeCreatorV2(
  'NamespacesReducer',
  'SetNamespacesList'
);

export const REMOVE_NAMESPACE = typeCreatorV2(
  'NamespacesReducer',
  'RemoveNamespace'
);
