import React from 'react';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import AuthActions, {
  handleAuthErrorCodes,
} from 'redux/reducers/gl_auth_reducer/actions';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import { StateStatus } from 'redux/utils/common';
import { loadFromLocalStorage } from 'redux/utils/storage';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useHistory } from 'react-router';
import NavigationConfig from 'config/navigationConfig';
import { showToastAction } from 'components/atoms/ToastMessage';
import useQuery from 'hooks/useQuery';
import LandingFragment from 'components/app_common/LandingFragment';

interface IAuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const getDataStatus = AuthSelectors.useSelectUserDataStatus();
  reactLogger.renderComponent('AuthProvider' + getDataStatus);

  const dispatch = useDispatch();
  const query = useQuery();
  const { token, refreshToken } = loadFromLocalStorage();
  const cookiesToken = Cookies.get('token') ?? query.get('token') ?? '';
  const cookiesRefreshToken =
    Cookies.get('refreshToken') ?? query.get('refreshToken') ?? '';
  const error = Cookies.get('error');
  const verifySession = AuthSelectors.useVerifySession();
  const history = useHistory();

  React.useLayoutEffect(() => {
    if (verifySession) {
      history.push(NavigationConfig.confirmSessionPage().path);
    }
  }, [verifySession, history]);

  React.useEffect(() => {
    if (error) {
      try {
        const errorData = JSON.parse(error);
        showToastAction(_.get(errorData, 'message'), 'error');
      } catch (error) {}
    }
  }, [error, dispatch]);

  const reLogin = Boolean(
    (token && refreshToken) || (cookiesToken && cookiesRefreshToken)
  );

  React.useEffect(() => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    Cookies.remove('error');
  }, []);

  React.useEffect(() => {
    if (reLogin) {
      dispatch(
        AuthActions.fetchUserDataAction({
          overrideAuth:
            cookiesToken && cookiesRefreshToken
              ? {
                  token: cookiesToken,
                  refreshToken: cookiesRefreshToken,
                }
              : undefined,
        })
      );
    }
  }, [dispatch, reLogin]);

  if (!reLogin || StateStatus.Success === getDataStatus) {
    return <>{children}</>;
  }

  // return <div>Loading...</div>;
  return <LandingFragment />;
};

// const IconComponent = () => {
//   return (
//     <div
//       style={{
//         position: 'absolute',
//         top: 0,
//         right: 0,
//         left: 0,
//         bottom: 0,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         paddingBottom: '4rem',
//       }}
//     >
//       <img
//         src={require('assets/images/logos/app-logo.png')}
//         style={{ width: 80, height: 80 }}
//       />
//     </div>
//   );
// };

// const IcognitoProvider = (props: IAuthProviderProps) => {
//   const query = useQuery();
//   const icognito = query.get('icognito') === 'true';
//   const [proceed, setProceed] = React.useState(icognito ? false : true);

//   React.useEffect(() => {
//     if (!proceed) {
//       if (icognito) {
//         localStorage.clear();
//       }
//       setProceed(true);
//     }
//   }, [proceed, setProceed, icognito]);

//   if (!proceed) {
//     return <IconComponent />;
//   }

//   return <>{props.children}</>;
// };

// const AuthProvider = ({ children }: IAuthProviderProps) => {
//   return (
//     <IcognitoProvider>
//       <AuthProviderNested>{children}</AuthProviderNested>
//     </IcognitoProvider>
//   );
// };

export default React.memo(AuthProvider);
