import React from 'react';
import CommonActions from 'redux/reducers/gl_common_reducer/actions';
import * as CommonSelectors from 'redux/reducers/gl_common_reducer/selectors';
import { IState } from 'redux/reducers/gl_common_reducer/reducer';
import LoadingView from 'components/atoms/LoadingView';
import store from 'redux/store';

const FullScreenLoading = () => {
  const { visible, options } = CommonSelectors.useLoadingView();

  return (
    <>
      {visible && (
        <LoadingView
          fullScreen
          //   backdropSx={{
          //     ...ObjectsUtils.cleanObject({
          //       zIndex: options?.zIndex,
          //     }),
          //   }}
        />
      )}
    </>
  );
};

export const showLoadingView = (
  options?: NonNullable<IState['loadingView']['options']>
) => {
  store.dispatch(
    CommonActions.showLoadingViewAction({
      data: {
        visible: true,
        options: options ?? null,
      },
    })
  );
};

export const hideLoadingView = () => {
  store.dispatch(
    CommonActions.showLoadingViewAction({
      data: {
        visible: false,
        options: null,
      },
    })
  );
};

export default React.memo(FullScreenLoading);
