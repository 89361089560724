import { CollectionTypeEnum, ItemGroups, ItemTypeEnum } from './typesConst';

export enum ReviewEntityTypeEnum {
  Collection = 'Collection',
  Item = 'Item',
}

export enum ReviewEntityStatusEnum {
  OnReview = 'OnReview',
  Completed = 'Completed',
}

export const SEPERATOR = '|';

type IOptionsMap = Array<{
  title: string;
  description: string;
  type: (ItemTypeEnum | CollectionTypeEnum)[];
  id: string;
}>;

export const OptionsMap: IOptionsMap = [
  {
    title: 'Grammar',
    description: 'Incorrect description',
    type: [CollectionTypeEnum.Grammar],
    id: 'incorrect_grammar_description',
  },
  {
    title: 'Reading',
    description: 'Incorrect text',
    type: [CollectionTypeEnum.Reading, CollectionTypeEnum.Listening],
    id: 'incorrect_reading_text',
  },
  {
    title: 'Dialogue',
    description: 'Incorrect dialogue',
    type: [CollectionTypeEnum.Speaking],
    id: 'incorrect_speaking_dialogue',
  },
  {
    title: 'Exercises',
    description: 'Incorrect exercises/content',
    type: Object.values(CollectionTypeEnum),
    id: 'incorrect_exercises_content',
  },
  {
    title: 'Image',
    description: 'Incorrect image course',
    type: Object.values(CollectionTypeEnum),
    id: 'incorrect_image_course',
  },
  {
    title: 'Other',
    description: 'Other reason',
    type: Object.values(CollectionTypeEnum),
    id: 'incorrect_course_other_reason',
  },
  {
    title: 'Image',
    description: 'Incorrect image',
    type: ItemGroups.Vocabulary,
    id: 'incorrect_vocabulary_image',
  },
  {
    title: 'Example',
    description: 'Incorrect example',
    type: ItemGroups.Vocabulary,
    id: 'incorrect_vocabulary_example',
  },
  {
    title: 'Exercise',
    description: 'Incorrect exercise',
    type: ItemGroups.Exercises,
    id: 'incorrect_exercise',
  },
  // {
  //   title: 'Video',
  //   description: 'Incorrect video',
  //   type: ItemGroups.Video,
  //   id: 'incorrect_video',
  // },
  {
    title: 'Translation',
    description: 'Incorrect translation',
    type: Object.values(ItemTypeEnum),
    id: 'incorrect_translation',
  },
  {
    title: 'Explanation',
    description: 'Incorrect explanation',
    type: Object.values(ItemTypeEnum),
    id: 'incorrect_explanation',
  },
];
