import UploadFileButton from 'components/atoms/UploadFileButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CollectionDetailsActions from '../../store/actions';
import { ContextProvider, useContextProps } from '../../provider';
import { Button } from '@material-ui/core';
import { openUploadPdfModal } from '../UploadPdfModal';

export const UploadPdfButton = () => {
  const dispatch = useDispatch();
  const props = useContextProps();

  return (
    <div>
      {props.collectionId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            openUploadPdfModal({});
          }}
        >
          {'Add Book'}
        </Button>
        // <UploadFileButton
        //   type="button"
        //   handleChange={(files) => {
        //     dispatch(
        //       CollectionDetailsActions.initBookItemsAction({
        //         pdfFile: files?.[0],
        //         collectionId: props.collectionId as string,
        //         onFinish: () => {
        //           dispatch(
        //             CollectionDetailsActions.fetchRoutineDetailsAction(
        //               props.collectionId as string
        //             )
        //           );
        //         },
        //       })
        //     );
        //   }}
        //   accept=".pdf"
        //   componentProps={{
        //     color: 'primary',

        //     startIcon: <PictureAsPdfIcon />,
        //     variant: 'contained',
        //     fullWidth: true,
        //   }}
        // >
        //   <div>{'Upload Book PDF'}</div>
        // </UploadFileButton>
      )}
    </div>
  );
};

export default UploadPdfButton;
