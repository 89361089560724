import React, { ReactElement } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
  Box,
  ListSubheader,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

interface IGroup {
  id: string | number;
  title: string | ReactElement;
}

interface IOptions {
  id: string | number;
  label: string | ReactElement;
  iconLeft?: React.ReactNode;
  group?: string | number; // Group identifier
}

interface Iprops
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'value' | 'variant' | 'name'
  > {
  includeNone?: boolean;
  options: IOptions[];
  groups: IGroup[]; // New property for group headers
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  name: string;
  handleChange?: (data: string) => void;
  controllerExtras?: any;
}

export default function GroupSelect(props: Iprops) {
  const {
    control,
    rules,
    includeNone,
    options,
    groups,
    icon,
    handleChange,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={({ onChange, value }) => (
        <TextField
          select
          value={value || ''}
          onChange={(event) => {
            handleChange && handleChange(event.target.value);
            onChange(event);
          }}
          {...other}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {icon && icon.position === 'right' && (
                  <InputAdornment position="start">
                    {icon.Component}
                  </InputAdornment>
                )}
              </React.Fragment>
            ),
          }}
        >
          {includeNone && <MenuItem value="">None</MenuItem>}
          {/* {groups.map((group) => (
            <>
              <ListSubheader key={group.id}>{group.title}</ListSubheader>
              {options
                .filter((option) => option.group === group.id)
                .map((filteredOption) => (
                  <MenuItem key={filteredOption.id} value={filteredOption.id}>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {filteredOption.iconLeft}
                      {filteredOption.label}
                    </Box>
                  </MenuItem>
                ))}
            </>
          ))} */}

          {groups?.map((group, index) => {
            // const dataItem = item?.options?.map((itemOption, index) => {
            //   return (
            //     <MenuItem key={index + 1} value={itemOption?.id}>
            //       {itemOption?.label}
            //     </MenuItem>
            //   );
            // });
            const dataItem = options
              .filter((option) => option.group === group.id)
              .map((filteredOption) => (
                <MenuItem key={filteredOption.id} value={filteredOption.id}>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {filteredOption.iconLeft}
                    {filteredOption.label}
                  </Box>
                </MenuItem>
              ));

            return [
              <ListSubheader key={index}>{group?.title}</ListSubheader>,
              ...dataItem,
            ];
          })}
        </TextField>
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
