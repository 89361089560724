import React from 'react';
import TableActions from './components/TableAction';
import { IState } from './store/reducer';
import { viewFileUrl, downloadFile, openFileToNewTab } from 'services/api/file';
import { FilePathTypes, FileType } from 'constants/filesConst';
import { formatDate } from 'utils/dates';
import { IModalParams } from './types';
import { FileTypeOptions } from 'constants/dropdownOptions';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { alpha, Box, colors, IconButton, Tooltip } from '@material-ui/core';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import UploadFileButton from './components/UploadFileButton';
import ColumnName from './components/ColumnName';
import { formatBytes } from 'utils/sizes';
import {
  closeImageEditorModal,
  openImageEditorModal,
} from 'components/molecules/ImageEditor';
import store from 'redux/store';
import UploadedFilesAction from './store/actions';
import { IColumnProps } from 'components/molecules/Datatable/configuration';
import PageviewIcon from '@material-ui/icons/Pageview';

type ListDataType = NonNullable<IState['imagesList']['data']>['items'];

export const TableColumns = (params: IModalParams): IColumnProps[] => [
  {
    label: 'File',
    id: 'imageUrl',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => (
      <img
        style={{ borderRadius: 8, cursor: 'pointer' }}
        width={90}
        height={80}
        src={cellValue}
      />
    ),
    inData: false,
  },
  {
    label: 'Preview',
    id: 'imageUrl',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Tooltip title={cellValue}>
        <IconButton
          onClick={() => {
            window.open(cellValue, '_blank');
          }}
        >
          <PageviewIcon />
        </IconButton>
      </Tooltip>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'name',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => <ColumnName name={cellValue ?? null} />,
    inData: true,
  },
  // {
  //   label: 'Size',
  //   id: 'size',
  //   align: 'left',
  //   render: (rowIndex: number, cellValue: any, columnIndex: number) => (
  //     <React.Fragment>{formatBytes(cellValue || 0)}</React.Fragment>
  //   ),
  //   inData: true,
  // },

  {
    label: 'Provider',
    id: 'type',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => (
      <Box display={'flex'} alignItems="center">
        {cellValue}
      </Box>
    ),
    inData: true,
    filtering: {
      options: [
        {
          label: 'Pexels',
          id: 'pexels',
        },
      ],
      defaultValue: params.type,
      type: 'dropdown',
    },
  },
  {
    label: '',
    id: 'actions',
    align: 'center',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ListDataType
    ) => <TableActions {...params} params={data[rowIndex]} />,
    inData: false,
    sticky: 'right',
  },
];
