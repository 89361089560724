import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as NotificationService from 'services/api/notification';
import * as UsersService from 'services/api/user';

export interface IState {
  notificationList: {
    status: StateStatus | null;
    data: NotificationService.FetchNotificationData | null;
  };
  sendNotification: {
    status: StateStatus | null;
    data: null;
  };
  usersList: {
    status: StateStatus | null;
    data: UsersService.IFetchUsersData | null;
  };
  removeNotificationStatus: StateStatus | null;
}

const initialState: IState = {
  notificationList: {
    status: null,
    data: null,
  },
  sendNotification: {
    status: null,
    data: null,
  },
  usersList: {
    status: null,
    data: null,
  },
  removeNotificationStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.notificationList.data
              : action.data,
        },
      };
    case Types.SEND_NOTIFICATION:
      return {
        ...state,
        sendNotification: {
          ...state.sendNotification,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.SET_USERS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.REMOVE_NOTIFICATION:
      return {
        ...state,
        removeNotificationStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
