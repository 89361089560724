import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import reactLogger from 'utils/logger';
import * as UtilsService from 'services/api/utils';

/** Imag */
interface IUploadImageFromExternalPayload {
  payload: UtilsService.IUploadImageFromExternalProvidersPayload;
  onSuccess: () => void;
}

class UploadImageFromExternal implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPLOAD_IMAGE_FROM_EXTERNAL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPLOAD_IMAGE_FROM_EXTERNAL,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPLOAD_IMAGE_FROM_EXTERNAL,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IUploadImageFromExternalPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await UtilsService.uploadImageFromExternalProvider(payload.payload);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('UploadImageFromExternal:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** FetchImagesFromExternal  */
interface IFetchImagesFromExternalPayload {
  data: UtilsService.IFetchImagesFromExternalProvidersPayload;
  onSuccess: () => void;
}

class FetchImagesFromExternal implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_IMAGES_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: UtilsService.IFetchImagesFromExternalProvidersData): IAction {
    return {
      type: Types.SET_IMAGES_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_IMAGES_LIST,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IFetchImagesFromExternalPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const result = await UtilsService.fetchImagesFromExternalProviders({
          ...payload.data,
          topic: payload.data.topic || 'people',
        });
        payload.onSuccess();
        dispatch(this.onSuccess(result.data));
      } catch (error) {
        reactLogger.error('FetchImagesFromExternal:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  uploadImageFromExternalAction: (payload: IUploadImageFromExternalPayload) =>
    new UploadImageFromExternal().action(payload),
  fetchImagesFromExternalAction: (payload: IFetchImagesFromExternalPayload) =>
    new FetchImagesFromExternal().action(payload),
};
