import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
}));

export default useStyles;
