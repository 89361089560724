import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    paper: {
      // marginRight: theme.spacing(0.5),
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.short,
      }),
      // color: Configuration.sidebar.config?.primaryTextColor,
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  })
);

export default useStyles;
