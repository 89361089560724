import React from 'react';
import MaterialIcons from 'assets/vectors/glyphmaps/MaterialIcons.json';
import Vector from 'components/atoms/Vector';

export const VectorIconsList = Object.entries(MaterialIcons).map(
  ([key, value], index) => ({
    id: key,
    label: key,
    iconLeft: <Vector style={{ marginRight: '0.8rem' }} code={value} />,
  })
);
