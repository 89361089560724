import { Box, Button } from '@material-ui/core';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useCollectionsList } from '../../store/selectors';
import { useDispatch } from 'react-redux';
import StudentsActions from '../../store/actions';
import { useParams } from '../../common';
import { useContextProps } from '../../provider';

const ActionHeader = () => {
  // const totalSelections = useTotalSelections();
  const list = useCollectionsList();
  // const dispatch = useDispatch();
  // const params = useParams();
  // const selectAll = totalSelections === (list.data?.items.length ?? 0);
  // const props = useContextProps();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '0.5rem',
      }}
    ></Box>
  );
};

export default ActionHeader;
