//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_MODAL_VISIBILITY: string = typeCreatorV2(
  'ModalReducer',
  'SetModalVisibility'
);

export enum ModalIDs {
  TOAST = 'TOAST',
  MODAL_DASHBOARD_ADD_GRAPH = 'MODAL_DASHBOARD_ADD_GRAPH',
  MODAL_FIXED_ESTABLISHMENT = 'MODAL_FIXED_ESTABLISHMENT',
  MODAL_IDENTIFICATION_IN_OTHER_EU_COUNTRIES = 'MODAL_IDENTIFICATION_IN_OTHER_EU_COUNTRIES',
  MODAL_PREVIOUS_USE_OF_SPECIAL_SCHEMES = 'MODAL_PREVIOUS_USE_OF_SPECIAL_SCHEMES',
  MODAL_DASHBOARD_ELEMENT_CONFIG = 'MODAL_DASHBOARD_ELEMENT_CONFIG', // dashboardbuilder_page
  MODAL_DASHBOARD_ELEMENT_REMOVE = 'MODAL_DASHBOARD_ELEMENT_REMOVE', // dashboardbuilder_page
  MODAL_DASHBOARD_BUILDER_SETTINGS = 'MODAL_DASHBOARD_BUILDER_SETTINGS', // dashboardbuilder_page
  MODAL_DASHBOARD_SAVE = 'MODAL_DASHBOARD_SAVE', // dashboardbuilder_page
  MODAL_CONFIG_WRAPPER = 'MODAL_CONFIG_WRAPPER',
  MODAL_FORM_WRAPPER = 'MODAL_FORM_WRAPPER', // forms_page

  MODAL_ADD_EDIT_USER = 'MODAL_ADD_EDIT_USER',
  MODAL_ADD_EDIT_USER_SETTINGS = 'MODAL_ADD_EDIT_USER_SETTINGS',
  MODAL_LOGS_CONTENT = 'MODAL_LOGS_CONTENT',
  MODAL_ACTIVITIES_CONTENT = 'MODAL_ACTIVITIES_CONTENT',
  MODAL_REQUEST_ACTIVITIES = 'MODAL_REQUEST_ACTIVITIES',
  MODAL_NOTIFICATION_CONTENT = 'MODAL_NOTIFICATION_CONTENT',
  MODAL_PORTAL_ACCESS_DETAILS = 'MODAL_PORTAL_ACCESS_DETAILS',

  // app modals
  MODAL_TRICKS_LIST = 'MODAL_TRICKS_LIST',
  MODAL_VERIFY_ACTION = 'MODAL_VERIFY_ACTION',
  MODAL_TRICK_DETAILS = 'MODAL_TRICK_DETAILS',
  MODAL_ADD_EDIT_TAG = 'MODAL_ADD_EDIT_TAG',
  MODAL_WORD_INFO = 'MODAL_WORD_INFO',
  MODAL_ADD_EDIT_NAMESPACE = 'MODAL_ADD_EDIT_NAMESPACE',
  MODAL_MEMBERS_NAMESPACE = 'MODAL_MEMBERS_NAMESPACE',
  MODAL_TRANSLATIONS_FORM = 'MODAL_TRANSLATIONS_FORM',
  MODAL_UPLOADED_FILES_LIST = 'MODAL_UPLOADED_FILES_LIST',
  MODAL_EDIT_FILE_DATA = 'MODAL_EDIT_FILE_DATA',
  MODAL_UPLOAD_AND_RESTORE_DATABASE = 'MODAL_UPLOAD_AND_RESTORE_DATABASE',
  MODAL_RESTORE_DATABASE = 'MODAL_RESTORE_DATABASE',
  MODAL_EDIT_IMAGE = 'MODAL_EDIT_IMAGE',
  MODAL_PURCHASE_DETAILS = 'MODAL_PURCHASE_DETAILS',
  MODAL_CSV_STATUS_DETAILS = 'MODAL_CSV_STATUS_DETAILS',
  MODAL_PROMPTS = 'MODAL_PROMPTS',
  MODAL_PROMPT_INPUT = 'MODAL_PROMPT_INPUT',
  MODAL_MODIFY_COLLECTION_DIFFICULTY = 'MODAL_MODIFY_COLLECTION_DIFFICULTY',
  MODAL_DISCOVER_IMAGES = 'MODAL_DISCOVER_IMAGES',
  MODAL_TRANSCRIPT_TEXT = 'MODAL_TRANSCRIPT_TEXT',
  MODAL_REVIEW_ENTITY_COMMENTS = 'MODAL_REVIEW_ENTITY_COMMENTS',
  MODAL_COLLECTION_DETAILS = 'MODAL_COLLECTION_DETAILS',
  MODAL_COLLECTION_DETAILS_UPLOAD_PDF = 'MODAL_COLLECTION_DETAILS_UPLOAD_PDF',
  MODAL_TEXT_TO_AUDIO = 'MODAL_TEXT_TO_AUDIO',
  MODAL_USER_NAMESPACE_SETTINGS_MODAL = 'MODAL_USER_NAMESPACE_SETTINGS_MODAL',
  MODAL_ADD_EDIT_TASK = 'MODAL_ADD_EDIT_TASK',

  MODAL_STUDENT_SELECTOR = 'MODAL_STUDENT_SELECTOR',
  MODAL_COLLECTION_SELECTOR = 'MODAL_COLLECTION_SELECTOR',

  MODAL_COLLECTION_LIBRARIES_PER_COLLECTION = 'MODAL_COLLECTION_LIBRARIES_PER_COLLECTION',
  MODAL_COLLECTION_ITEMS_PER_STUDENT = 'MODAL_COLLECTION_ITEMS_PER_STUDENT',

  MODAL_ADD_EDIT_URL_ALIAS = 'MODAL_ADD_EDIT_URL_ALIAS',

  MODAL_ADD_STUDENT = 'MODAL_ADD_STUDENT',
}
