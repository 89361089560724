//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const VERIFY_VERIFICATION_CODE: string = typeCreatorV2(
  'ForceChangePasswordVerifyReducer',
  'VerifyVerificationCode'
);

export const RESEND_EMAIL: string = typeCreatorV2(
  'ForceChangePasswordVerifyReducer',
  'ResendEmail'
);
