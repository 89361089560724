import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles';
import Configuration from './configuration';
import Environment from 'config/environmentConfig';
import { NAVBAR_ITEM_SELECTED, SIDE_BAR_COLOR } from 'styles/colors';
import { alpha } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const drawerWidth = Environment.SIDE_MENU_WIDTH;
const drawerMiniWidth = 70;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  appBar: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.05)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1, //temp
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  miniDrawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  miniDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    background: theme.palette.secondary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    color: 'white',
  },
  content: {
    flexGrow: 1,
    // overflow: 'auto',
    // maxWidth: '100%',
    overflow: 'clip',
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    // [theme.breakpoints.down('md')]: {
    //   overflow: 'auto',
    // },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    overflow: 'auto',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      // backgroundColor:
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  title: {
    display: 'none',
    // fontFamily: 'Sansita',
    // fontStyle: 'italic',
    // fontWeight: '',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  titleContainer: {
    border: '1px solid #FFFFFF',
    borderRadius: 5,
    cursor: 'pointer',
    display: 'none',
    boxSizing: 'border-box',
    padding: '1px 10px 1px 10px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  avatar: {
    cursor: 'pointer',
    // width: '80%',
    width: 50,
    // height: 150,
    maxWidth: '100%',
    alignSelf: 'center',
    // height: '100px',
    // background: 'red',
    height: 'auto',
    // maxHeight: 130,
    marginTop: '1rem',
  },
  drawerListItemText: {
    fontWeight: 'bolder',
  },
  drawerListSubItemText: {
    fontWeight: 600,
    opacity: 0.9,
  },
  drawerPaper: {
    background:
      theme.palette.type === 'dark' ? 'rgba(20, 20, 20, 1)' : undefined,
    color: theme.palette.type === 'dark' ? 'white' : 'dark',
    width: drawerWidth,
  },
  drawerPaperMini: {
    background:
      theme.palette.type === 'dark' ? 'rgba(20, 20, 20, 1)' : undefined,
    color: theme.palette.type === 'dark' ? 'white' : 'dark',
    // width: drawerMiniWidth,
  },
  drawerItem: {
    width: '97%',
    backgroundColor: (props: any) =>
      props.isSelected ? 'rgba(207, 207, 207, .6)' : 'transparent',
    '&:hover': {
      backgroundColor: (props: any) =>
        !props.isSelected ? 'rgba(207, 207, 207, .3)' : undefined,
    },
    fontWeight: (props: any) => (props.isSelected ? 'bold' : 'initial'),
    // borderBottomRightRadius: 50,
    // borderTopRightRadius: 50,
    marginBottom: '0.3rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    // color: Configuration.sidebar.config?.textColor1,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  subElement: {
    cursor: 'pointer',
    marginBottom: '0.1rem',
    // paddingLeft: props.includePadding ? 20 : 0,
  },
  buttonText: (props) => ({
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      ...(props.enableHidden ? { display: 'none' } : undefined),
    },
  }),
  menuItemSelected: {
    color: NAVBAR_ITEM_SELECTED(theme),
    fontWeight: 'bold',
  },
}));

export default useStyles;
