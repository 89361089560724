import React from 'react';
import { LanguagesEnum } from '../../../constants/languagesConst';
import * as LanguageSelectors from '../../../redux/reducers/gl_translations_reducer/selectors';

type ITextMap = Partial<
  {
    [key in LanguagesEnum]: string;
  }
>;

interface IProps {
  textMap?: ITextMap | null;
  defaultText?: string;
}

const TranslatedText = ({
  textMap,
  defaultText = '',
}: IProps): React.ReactElement => {
  const languageID: LanguagesEnum = LanguageSelectors.useSelectLanguageID();

  const renderTranslatedText = () => {
    // return textMap && typeof textMap === 'object' && textMap?.[languageID]
    //   ? textMap[languageID]
    //   : defaultText;
    return textMap?.[languageID] ? textMap[languageID] : defaultText;
  };
  return <React.Fragment>{renderTranslatedText()}</React.Fragment>;
};

export default TranslatedText;
