import _ from 'lodash';

export const arraysHasCommonElements = (
  arr1: Array<string | number>,
  arr2: Array<string | number>
): boolean => {
  return arr1.some((item) => arr2.includes(item));
};

export function arrayToObject<T>(
  arr: Array<T>,
  key: string
): { [s: string]: T } {
  return arr.reduce((obj, item) => {
    _.set(obj, _.get(item, key), item);
    return obj;
  }, {});
}

export const removeDuplicates = (arr: Array<number | string>) =>
  arr.filter(function (elem: number | string, index: number, self) {
    return index === self.indexOf(elem);
  });

// interface Option {
//   value: string;
//   label: string;
//   group: string;
// }

interface GroupedOption {
  label: string;
  options: Array<{ id: string; label: string }>;
}

export function groupOptions(
  options: {
    id: string;
    label: string;
    group: string;
  }[]
): GroupedOption[] {
  // Group options by the 'group' property using lodash
  const groupedByGroup = _.groupBy(options, 'group');

  // Transform the grouped object into the desired array format
  const groupedOptions: GroupedOption[] = _.map(
    groupedByGroup,
    (options, group) => ({
      label: group,
      options: options.map(({ id, label }) => ({ id, label })),
    })
  );

  return groupedOptions;
}
