import { makeStyles, Theme } from '@material-ui/core/styles';

const zIndex = 2000;
const useStyles = makeStyles((theme: Theme) => ({
  fullScreen: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 2000,
    backgroundColor: 'rgba(18, 18, 18, .2)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  loadingView: {
    zIndex: zIndex + 1,
  },
}));

export default useStyles;
