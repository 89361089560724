import React from 'react';
import { inspect } from 'util'; // or directly

interface IPrettyPrintJsonProps {
  data: JSON;
  spaces?: number;
  jsonData?: boolean;
  style?: React.CSSProperties;
}

export default function PrettyPrintJson(props: IPrettyPrintJsonProps) {
  const { jsonData = true, spaces = 4, data, style } = props;
  return (
    <div style={style}>
      <pre>
        {jsonData
          ? JSON.stringify(data || {}, null, spaces)
          : inspect(data || {}, false, 2, true)}
      </pre>
    </div>
  );
}
