import React, { ReactElement } from 'react';
import useStyles from './styles';
import MobileList from './components/MobileList';
import { IProps, Order, IFilteringData } from './configuration';
import AppTable from './components/TableList';
import { DatatableProvider } from './provider';
import { Hidden } from '@material-ui/core';

export default function Datatable<T>(props: IProps<T>): ReactElement {
  const classes = useStyles({});
  let page = props.paging?.page || 0;
  let rowsPerPage = props.paging?.pageSize || 10;
  const sortType = React.useRef<Order | undefined>(props.sorting?.order);
  const sortField = React.useRef<string | undefined>(props.sorting?.sortField);
  const filteringData = React.useRef<IFilteringData>({});

  const handleChangePage = (event: unknown, newPage: number) => {
    if (props.handleDataChange) {
      const order = sortType.current || 'asc';
      props.handleDataChange(
        newPage,
        rowsPerPage,
        order,
        props.sorting?.sortField || '',
        order,
        filteringData.current
      );
    }
  };

  const handleFiltering = React.useCallback(
    (resetForm: boolean) => {
      console.log('Form Data::', filteringData.current);
      const order = sortType.current || 'asc';
      if (props.handleDataChange) {
        props.handleDataChange(
          page,
          rowsPerPage,
          order,
          sortField.current || '',
          order,
          filteringData.current
        );
      }
    },
    [props.handleDataChange]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string //keyof IData
  ) => {
    if (props.handleDataChange) {
      if (sortField.current !== property) {
        sortType.current = 'asc';
      } else {
        sortType.current = sortType.current === 'asc' ? 'desc' : 'asc';
      }
      sortField.current = property;
      props.handleDataChange(
        page,
        rowsPerPage,
        sortType.current,
        property,
        sortType.current,
        filteringData.current
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (props.handleDataChange && props.paging) {
      const order = sortType.current || 'asc';

      props.handleDataChange(
        props.paging.page,
        +event.target.value,
        order,
        props.sorting?.sortField || '',
        order,
        filteringData.current
      );
    }
  };

  // keyof Data
  const createSortHandler =
    (property: string, inData: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (inData) {
        handleRequestSort(event, property);
      }
    };

  const TableDataElement = (
    <AppTable
      {...props}
      filteringData={filteringData}
      createSortHandler={createSortHandler}
      handleChangePage={handleChangePage}
      order={sortType.current || ('asc' as any)}
      rowsPerPage={rowsPerPage}
      handleFiltering={handleFiltering}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      orderBy={props.sorting?.sortField || ''}
      page={page}
    />
  );

  return (
    <DatatableProvider filteringIsVisible={Boolean(props.filtering?.visible)}>
      <React.Fragment>
        {props.renderItemInSmallDevices ? (
          <>
            <Hidden xsDown>{TableDataElement}</Hidden>

            <Hidden smUp>
              {/* hidden up to small */}
              <MobileList
                {...props}
                handleFiltering={handleFiltering}
                createSortHandler={createSortHandler}
                handleChangePage={handleChangePage}
                filteringData={filteringData}
                // order={order}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                orderBy={props.sorting?.sortField || ''}
                page={page}
              />
            </Hidden>
          </>
        ) : (
          TableDataElement
        )}
      </React.Fragment>
    </DatatableProvider>
  );
}
