import React from 'react';
import createReducer, { asyncReducers, staticReducers } from './reducers';
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { ReducerKeys } from './config';
import { saveToLocalStorage, loadFromLocalStorage } from './utils/storage';
import { objectWithoutProperties } from 'utils/objects';

// Middleware: Redux Persist Persisted Reducer
const middlewares = [applyMiddleware(thunk)];
const store = createStore(
  createReducer(),
  // loadFromLocalStorage(),
  composeWithDevTools(compose(...middlewares) as any)
);

export type RootState = ReturnType<typeof store.getState>;
export const getState = store.getState;

let _store: any = store;
_store.asyncReducers = {};
export const injectReducer = (
  reducerKey: ReducerKeys,
  action: 'add' | 'remove'
) => {
  try {
    if (reducerKey in _store.asyncReducers && action === 'remove') {
      delete _store.asyncReducers[reducerKey];
      let reducer = createReducer(_store.asyncReducers);
      store.replaceReducer(reducer as any);
      if (_store.dispatch) {
        _store?.persistor && _store.persistor.persist();
      }
    } else if (reducerKey in asyncReducers && action === 'add') {
      _store.asyncReducers[reducerKey] = (asyncReducers as any)[reducerKey];
      let reducer = createReducer(_store.asyncReducers);
      store.replaceReducer(reducer as any);
      if (_store.dispatch) {
        _store?.persistor && _store.persistor.persist();
      }
    } else {
      console.log('Reducer Key not found...');
    }
  } catch (error) {
    console.log('Inject Reducer Error:', error.message);
  }
};

// store.subscribe(async () => {
//   saveToLocalStorage({
//     [ReducerKeys.AUTH_REDUCER]: {
//       token: store.getState().AuthReducer.token,
//       refreshToken: store.getState().AuthReducer.refreshToken,
//     },
//     // objectWithoutProperties(
//     //   getState(ReducerKeys.AUTH_REDUCER),
//     //   ['status', 'fetchUserDataStatus', 'roles', 'login'] //'data', 'roles'
//     // ),
//   });
// });

export const persistor = persistStore(store as any);
export default store;
