import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { IState } from './reducer';

/* selector: useCollectionsList */
export const useCollectionsList = (): IState['collectionsList'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER] as IState)
        .collectionsList
  );
};

// /* selector: useSelectedCollectionsMapping */
// export const useSelectedCollectionsMapping = () => {
//   return useSelector(
//     (state: any) =>
//       (state[ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER] as IState)
//         .selectedCollectionsMapping
//   );
// };

// /* selector: useElementIsSelected */
// export const useElementIsSelected = (elementId: string) => {
//   return useSelector(
//     (state: any) =>
//       !!(
//         state[ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER] as IState
//       ).selectedCollectionsMapping?.[elementId]?.id
//   );
// };

// /* selector: useTotalSelections */
// export const useTotalSelections = () => {
//   return useSelector(
//     (state: any) =>
//       Object.values(
//         (
//           state[
//             ReducerKeys.COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER
//           ] as IState
//         ).selectedCollectionsMapping ?? {}
//       ).length
//   );
// };
