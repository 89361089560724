//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';
export const FORGOT_PASSOWRD: string = typeCreatorV2(
  'ForgotPasswordReducer',
  'ForgotPassword'
);

export const VERIFICATION_CODE: string = typeCreatorV2(
  'ForgotPasswordReducer',
  'ResetPassword'
);

export const SET_SELECTED_FORM: string = typeCreatorV2(
  'ForgotPasswordReducer',
  'SetSelectedForm'
);

export const RESEND_EMAIL: string = typeCreatorV2(
  'ForgotPasswordReducer',
  'ResendEmail'
);
