import PageLayout from 'components/app_common/PageLayout';
import React from 'react';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { useHistory, useParams, withRouter } from 'react-router';
import AddEditTrickForm from '../BasicTemplate';
import PageTitle from 'components/app_common/PageTitle';
import TranslatedText from 'components/atoms/TranslatedText';
import TrickDetailsActions from '../../store/actions';
import useQuery from 'hooks/useQuery';
import { CollectionTypeTitleMap } from '../../configuration';
import ModalVerifyAction from 'components/molecules/ModalVerifyAction';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigationConfig from 'config/navigationConfig';
import { Box, IconButton, colors } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
// import { useQueryParams } from '../../common';
import LoadingView from 'components/atoms/LoadingView';
import {
  useCollectionBulkActionsStatus,
  useInitBookItemsStatus,
  useUploadCsvFile,
} from '../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import CsvStatusModal from '../../components/CsvStatusModal';
import BulkActionsMenu from '../../components/BulkActionsMenu';
import PromptInputModal from 'components/app_common/PromptInputModal';
import FormProviderWrapper from '../../components/FormProvider';
import { CollectionTypeEnum } from 'constants/typesConst';
import { CollectionDetailsProps } from '../../types';
import { ContextProvider, useContextProps } from '../../provider';
import TemplateSelector from '../TemplateSelector';
import UploadPdfModal from '../UploadPdfModal';
import TextToAudioModal from 'components/app_common/TextToAudioModal';

const Content = () => {
  const classes = useStyles();
  const props = useContextProps();
  const dispatch = useDispatch();

  const { collectionId, type, ...params } = props;
  const title = type ? CollectionTypeTitleMap[type] : 'Collection';

  React.useEffect(() => {
    dispatch(TrickDetailsActions.fetchTagsListAction());
    dispatch(TrickDetailsActions.fetchItemTagsAction({}));

    return () => {
      dispatch(TrickDetailsActions.resetAction());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (collectionId && (params.mode === 'edit' || params.mode === 'view')) {
      dispatch(TrickDetailsActions.fetchRoutineDetailsAction(collectionId));
    }
  }, [collectionId, dispatch]);

  return (
    <FormProviderWrapper type={type as CollectionTypeEnum}>
      <LoadingViewWrapper />
      <PromptInputModal />
      <CsvStatusModal />
      <UploadPdfModal />
      <PageLayout className={classes.root}>
        <Box className={classes.subContainer}>
          <PageTitle>
            <Box
              className={classes.header}
              // style={{
              //   display: 'flex',
              //   alignItems: 'center',
              // }}
            >
              {params.navigateBack ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <div style={{ marginRight: '1rem' }}>
                    <IconButton onClick={params.navigateBack}>
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', flex: 1 }}
                  >
                    <p
                      style={{
                        margin: 0,
                        marginRight: '0.4rem',
                        fontWeight: 500,
                        color: colors.grey['700'],
                      }}
                      className={classes.title}
                    >
                      {params.mode === 'view'
                        ? `View`
                        : params.mode === 'add'
                        ? `Create`
                        : `Edit`}
                    </p>
                    <h4 className={classes.title} style={{ margin: 0 }}>
                      {title}
                    </h4>
                  </div>
                </div>
              ) : (
                <div style={{ display: 'flex' }} />
              )}

              <div className={classes.actionsMenuContainer}>
                <BulkActionsMenu />
              </div>
            </Box>
          </PageTitle>
          <TemplateSelector {...props} />
        </Box>
      </PageLayout>
    </FormProviderWrapper>
  );
};

const LoadingViewWrapper = React.memo(() => {
  const { status } = useUploadCsvFile();
  const bulkActionStatus = useCollectionBulkActionsStatus();
  const bookItemsStatus = useInitBookItemsStatus();

  return (
    <>
      {(status === StateStatus.Pending ||
        bookItemsStatus === StateStatus.Pending ||
        bulkActionStatus === StateStatus.Pending) && <LoadingView fullScreen />}
    </>
  );
});

const Page = (props: CollectionDetailsProps) => {
  return (
    <>
      <TextToAudioModal />
      <ContextProvider props={props}>
        <Content />
      </ContextProvider>
    </>
  );
};

export default withReducer<CollectionDetailsProps>(
  ReducerKeys.ROUTINE_DETAILS_REDUCER
)(Page);
