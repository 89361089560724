import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import store from 'redux/store';
import { Control, Controller, useWatch, useForm } from 'react-hook-form';
import _ from 'lodash';
import { AppTextField, AppCommon } from 'components/molecules/FormControls';

interface IPromptInputModalParams {
  title: string;
  handleResult: (data: string) => void;
}

const PromptInputModal = () => {
  const submitRef = React.useRef<any>();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_PROMPT_INPUT}
      showExitIcon={true}
      modalProps={{ fullWidth: true }}
      renderHeader={(params: IPromptInputModalParams) => (
        <div>
          {
            <TranslatedText
              defaultText={params.title ?? 'Add data by Prompt Result'}
            />
          }
        </div>
      )}
      renderContent={(params: IPromptInputModalParams) => (
        <Content {...params} ref={submitRef} />
      )}
      renderFooter={(params: IPromptInputModalParams) => {
        return (
          <div>
            <Box
              style={{
                flexDirection: 'row-reverse',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Button onClick={() => submitRef.current && submitRef.current()}>
                {'Save'}
              </Button>
              <Button
                style={{ marginRight: '0.5rem' }}
                onClick={closePromptInputModalModal}
              >
                {'Cancel'}
              </Button>
            </Box>
          </div>
        );
      }}
    />
  );
};

interface IFormData {
  promptInput: string | null;
}

const defaultValues: IFormData = { promptInput: null };

const Content = React.forwardRef(
  ({ handleResult }: IPromptInputModalParams, ref: any) => {
    const {
      reset,
      handleSubmit,
      errors,
      control,
      register,
      setValue,
      getValues,
    } = useForm<IFormData>({
      defaultValues,
    });

    ref.current = handleSubmit((data) => {
      handleResult(data.promptInput ?? '');
      closePromptInputModalModal();
    });

    return (
      <Box style={{ padding: '1rem' }}>
        <AppTextField
          variant="outlined"
          margin="normal"
          error={'promptInput' in errors}
          helperText={errors.promptInput && errors.promptInput.message}
          rules={{
            required: <AppCommon.RequiredField />,
          }}
          required
          fullWidth
          id="promptInput"
          control={control}
          label={<TranslatedText defaultText="Prompt Input" />}
          autoComplete="promptInput"
          name="promptInput"
          multiline
          rows={60}
        />
      </Box>
    );
  }
);

export const openPromptInputModalModal = (params: IPromptInputModalParams) => {
  store.dispatch(
    ModalActions.openModalAction({
      modalID: ModalIDs.MODAL_PROMPT_INPUT,
      params,
    })
  );
};

export const closePromptInputModalModal = () => {
  store.dispatch(
    ModalActions.closeModalAction({
      modalID: ModalIDs.MODAL_PROMPT_INPUT,
    })
  );
};

export default React.memo(PromptInputModal);
