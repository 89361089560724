// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request, { nativeAxios } from 'utils/request';
import * as PathUtils from 'utils/path';
import {
  TagLevel,
  TagStatus,
  TagSubTypes,
  TagTypes,
} from 'constants/tagsConst';
import { LanguagesEnum } from 'constants/languagesConst';
import { ItemTypeEnum } from 'constants/typesConst';

/** fetch Tags list */
export type FetchTagListData = {
  items: Array<{
    _id: string;
    name: string;
    description: string;
    type: string;
    status?: TagStatus;
    namespaces?: string[];
    iconId?: string;
    imageId?: {
      _id: string;
      path: string;
    };
    iconImageId?: {
      _id: string;
      path: string;
    };
    level?: TagLevel[];
    translations?: {
      [key in LanguagesEnum]: Partial<{
        description: string;
      }>;
    };
    updatedAt: string;
    createdAt: string;
    order?: number;
    subType?: TagSubTypes[];
    item_vocabCategories?: ItemTypeEnum[];
  }>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface IFetchTagListInput {
  page?: number;
  pageSize?: number;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  type?: TagTypes;
  globalSearch?: string;
  status?: TagStatus;
  level?: TagLevel;
  subType?: TagSubTypes;
}

export const fetchTagList = async (
  payload: IFetchTagListInput
): Promise<Types.ResponseOutputV2<FetchTagListData>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },
      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'type',
        value: payload.type,
      },
      {
        key: 'subType',
        value: payload.subType,
      },
      {
        key: 'status',
        value: payload.status,
      },
      {
        key: 'globalSearch',
        value: payload?.globalSearch?.toString(),
      },
      {
        key: 'disableTranslation',
        value: 'true',
      },
      {
        key: 'showTranslationsDictionary',
        value: 'true',
      },
      {
        key: 'level',
        value: payload?.level?.toString(),
      },
    ],
  });
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/tag/list${query}`
  );

  return data;
};

/** Add Tag */

export interface IAddTagInput {
  name: string;
  description: string;
  type: TagTypes;
  status: TagStatus;
  namespaces: string[];
  iconId?: string;
  imageId?: string;
  iconImageId?: string;
  level?: TagLevel[];
  order?: number;
  subType?: TagSubTypes[];
  item_vocabCategories?: ItemTypeEnum[] | null;
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string | null;
      }>;
    }
  >;
}

export const addTag = async (
  payload: IAddTagInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/tag/add`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Edit Tag */
export interface IEditTagInput extends Partial<IAddTagInput> {}

export const editTag = async (
  id: string,
  payload: IEditTagInput
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/tag/details/${id}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Remove Tag */

export const removeTag = async (
  id: string,
  type: TagTypes
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/tag/details/${id}?type=${type}`,
    {
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** fetch Levels list */
export type FetchLevelsListData = Array<{
  name: string;
  icon: string;
  id: number;
  description: string;
  active: boolean;
}>;
export interface IFetchLevelsListInput {}
export const fetchLevelsList = async (
  payload: IFetchLevelsListInput
): Promise<Types.ResponseOutputV2<FetchLevelsListData>> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [],
  });
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/tag/levels/list${query}`
  );

  return data;
};
