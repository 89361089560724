import React, { ReactElement } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;

export interface IMultipleAutoCompleteSelectProps
  extends StandardTextFieldProps {
  options: Array<any>;
  control: Control;
  rules: any;
  selectedKey: string;
  name: string;
  handleChange?: (data: string) => void;
  controllerExtras?: any;
  style?: React.CSSProperties;
}

export default function MultipleAutoCompleteSelect(
  props: IMultipleAutoCompleteSelectProps
) {
  const {
    control,
    rules,
    options,
    handleChange,
    selectedKey,
    style,
    id,
    name,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <div>
      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            multiple
            limitTags={4}
            id={id}
            disableCloseOnSelect
            options={options}
            getOptionLabel={(option) =>
              option?.[selectedKey] ?? 'Label not found'
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                // label="limitTags"
                // placeholder="Favorites"
                {...other}
              />
            )}
            onChange={(res, result) => {
              props.onChange(result);
            }}
          />
        )}
        name={name}
        control={control}
        rules={rules}
        {...controllerExtras}
      />
    </div>
  );
}
