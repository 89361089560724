import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import type { IConvertTextToAudioData } from 'services/api/utils';

export interface IState {
  generateAudioFile: {
    status: StateStatus | null;
    data: IConvertTextToAudioData | null;
  };
}

const initialState: IState = {
  generateAudioFile: {
    status: null,
    data: null,
  },
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.GENERATE_AUDIO_FILE:
      return {
        ...state,
        generateAudioFile: {
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
