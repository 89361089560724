import { showToastAction } from 'components/atoms/ToastMessage';
import {
  DefaultLanguage,
  GoogleTranslateLans,
  LanguagesEnum,
} from 'constants/languagesConst';
import {
  GoogleTranslateNamespaceTypeMap,
  NamespaceType,
} from 'constants/namespaceConst';
import { getState } from 'redux/store';
import { translateText } from 'services/api/utils';
import { removeEmptyFields } from 'utils/objects';

type IFormData = {
  translations: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        [key: string]: string;
      }>;
    }
  >;
};

export const getFieldTranslations = <T extends IFormData>(
  name: string,
  formData: T
) => {
  const translations: Partial<
    {
      [key in LanguagesEnum]: string | null;
    }
  > = {};
  Object.keys(formData.translations ?? {}).forEach((lan) => {
    translations[lan as LanguagesEnum] =
      formData.translations?.[lan as LanguagesEnum]?.[name as never];
  });
  return translations;
};

const getLanByType = (lan: 'namespaceLan' | 'defaultLan' | LanguagesEnum) => {
  if (lan === 'defaultLan') {
    return GoogleTranslateLans[DefaultLanguage];
  } else if (lan === 'namespaceLan') {
    return GoogleTranslateNamespaceTypeMap[
      getState().CommonReducer.namespacesDetails.data?.type as NamespaceType
    ];
  } else {
    return GoogleTranslateLans[lan];
  }
};

export type ITranslationType =
  | 'namespaceLan'
  | 'defaultLan'
  | 'userLan'
  | LanguagesEnum;

interface ITranslationOptions {
  lanFrom: ITranslationType;
  lanTo: ITranslationType;
}

export const translation = async (
  text: string,
  { lanFrom, lanTo }: ITranslationOptions
) => {
  try {
    const result = await translateText({
      text,
      lanFrom: lanFrom === 'userLan' ? undefined : getLanByType(lanFrom),
      lanTo: lanTo === 'userLan' ? undefined : getLanByType(lanTo),
    });
    return result.data.text;
  } catch (error) {
    showToastAction('Error:' + error.message, 'error', {
      position: 'bottom-left',
    });
    return null;
  }
};

export const fixTranslations = <
  T extends Partial<
    {
      [key in LanguagesEnum]: Partial<{
        [key: string]: string;
      }>;
    }
  >
>(
  translations?: T
): T | undefined => {
  const data = { ...translations };
  // const languages = Object.values()

  Object.keys(data ?? {}).forEach((key) => {
    if (!(key in LanguagesEnum)) {
      delete data?.[key as LanguagesEnum];
    }
  });

  // delete DefaultLanguage
  delete data?.[DefaultLanguage];

  return (removeEmptyFields(data as any) ?? {}) as T;
};
