import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as UsersService from 'services/api/user';

export interface IState {
  usersList: {
    status: StateStatus | null;
    data: UsersService.IFetchUsersData | null;
  };
  editUserStatus: StateStatus | null;
  addUserStatus: StateStatus | null;
  userSettingsDetails: {
    status: StateStatus | null;
    data: UsersService.GetUserSettingsByAdminData | null;
  };
  updateUserSettingsStatus: StateStatus | null;
  portalAccessDetails: {
    status: StateStatus | null;
    data: UsersService.UserPortalDetailsData | null;
  };
  removeUserStatus: StateStatus | null;
}

const initialState: IState = {
  usersList: {
    status: null,
    data: null,
  },
  editUserStatus: null,
  addUserStatus: null,
  userSettingsDetails: {
    status: null,
    data: null,
  },
  updateUserSettingsStatus: null,
  portalAccessDetails: {
    status: null,
    data: null,
  },
  removeUserStatus: null,
};

const UsersReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.REMOVE_USER:
      return {
        ...state,
        removeUserStatus: action.status || null,
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.usersList.data : action.data,
        },
      };
    case Types.ADD_USER:
      return {
        ...state,
        addUserStatus: action.status || null,
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        editUserStatus: action.status || null,
      };
    case Types.UPDATE_USER_SETTINGS:
      return {
        ...state,
        updateUserSettingsStatus: action.status || null,
      };
    case Types.SET_USER_SETTINGS_DETAILS:
      return {
        ...state,
        userSettingsDetails: {
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.SET_PORTAL_ACCESS_DETAILS:
      return {
        ...state,
        portalAccessDetails: {
          ...state.portalAccessDetails,
          status: action.status || null,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UsersReducer;
