// https://stackoverflow.com/questions/35305661/where-to-write-to-localstorage-in-a-redux-app
// https://www.geeksforgeeks.org/how-to-persist-redux-state-in-local-storage-without-any-external-library/

interface ILocalStorageData {
  token?: string;
  refreshToken?: string;
}

export function saveToLocalStorage(payload: ILocalStorageData) {
  try {
    const serializedState = JSON.stringify(payload);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
}

export function loadFromLocalStorage(): ILocalStorageData {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) throw new Error('LocalStorage State');
    return JSON.parse(serializedState);
  } catch (error) {
    console.log(error);
    return {};
  }
}
