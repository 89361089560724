import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';

interface ICommonMenuProps extends Partial<Omit<MenuItemProps, 'onClick'>> {
  children: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick?: MenuItemProps['onClick'];
}

const CommonMenu = React.forwardRef(
  (
    {
      children,
      iconLeft,
      iconRight,
      onClick,
      button,
      ...rest
    }: ICommonMenuProps,
    ref: any
  ) => {
    return (
      <MenuItem
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
        ref={ref}
        onClick={onClick}
        {...rest}
      >
        {iconLeft && <Box marginRight={'0.5rem'}>{iconLeft}</Box>}
        <ListItemText>{children}</ListItemText>
        {iconRight && <Box marginLeft={'0.5rem'}>{iconRight}</Box>}
      </MenuItem>
    );
  }
);

CommonMenu.displayName = 'CommonMenu';

export default CommonMenu;
