import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import reactLogger from 'utils/logger';
import * as TagsService from 'services/api/tags';
import { TagTypes } from 'constants/tagsConst';

/** Remove Tag  */
interface IRemoveTagPayload {
  tagId: string;
  tagType: TagTypes;
  onSuccess: () => void;
}

class RemoveTag implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_TAG,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_TAG,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_TAG,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IRemoveTagPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await TagsService.removeTag(payload.tagId, payload.tagType);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('RemoveTag:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add Tag  */
interface IAddTagPayload {
  data: TagsService.IAddTagInput;
  onSuccess: () => void;
}

class AddTag implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_TAG,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_TAG,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_TAG,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IAddTagPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await TagsService.addTag(payload.data);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('AddTag:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Edit Tag  */
interface IEditTagPayload {
  data: TagsService.IEditTagInput;
  onSuccess: () => void;
  tagId: string;
}

class EditTag implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_TAG,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.EDIT_TAG,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_TAG,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(payload: IEditTagPayload): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await TagsService.editTag(payload.tagId, payload.data);
        payload.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('EditTag:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  removeTagAction: (payload: IRemoveTagPayload) =>
    new RemoveTag().action(payload),
  addTagAction: (payload: IAddTagPayload) => new AddTag().action(payload),
  editTagAction: (payload: IEditTagPayload) => new EditTag().action(payload),
};
