import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import * as UsersService from 'services/api/user';
import reactLogger from 'utils/logger';
import { IState } from './reducer';

/** Fetch User Settings Details  */
interface IFetchUserSettingsDetailsInput {
  userId: string;
}

class FetchUserSettingsDetails implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_SETTINGS_DETAILS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: IState['userSettingsDetails']['data']): IAction {
    return {
      type: Types.SET_USER_SETTINGS_DETAILS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_SETTINGS_DETAILS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IFetchUserSettingsDetailsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const response = await UsersService.getUserSettingsDetailsByAdmin(
          input.userId
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchUserSettingsDetails:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Update User Settings    */
interface IUpdateUserSettingsInput {
  payload: UsersService.EditUserSettingsPayload;
  userId: string;
  onSuccess: () => void;
}

class UpdateUserSettings implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_USER_SETTINGS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPDATE_USER_SETTINGS,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_USER_SETTINGS,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IUpdateUserSettingsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await UsersService.editUserSettingsByAdmin(input.userId, input.payload);
        input.onSuccess();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('Update User Settings:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchUserSettingsDetailsAction: (payload: IFetchUserSettingsDetailsInput) =>
    new FetchUserSettingsDetails().action(payload),
  updateUserSettingsAction: (payload: IUpdateUserSettingsInput) =>
    new UpdateUserSettings().action(payload),
};
