// import React from 'react';
// import { injectReducer } from './store';

// export const withReducer =
//   (key: any, { unmount = false } = {}) =>
//   (WrappedComponent: any) =>
//     class extends React.PureComponent {
//       constructor(props: any) {
//         super(props);
//         injectReducer(key, 'add');
//       }
//       componentWillUnmount() {
//         if (unmount) {
//           injectReducer(key, 'remove');
//         }
//       }

//       render() {
//         return <WrappedComponent {...this.props} />;
//       }
//     };

import React from 'react';
import { injectReducer } from './store';

export function withReducer<T extends {}>(key: any, { unmount = false } = {}) {
  return (WrappedComponent: React.FC<T> | React.ComponentClass<T>) =>
    class ComposedComponent extends React.PureComponent<T, any> {
      constructor(props: T) {
        super(props);
        injectReducer(key, 'add');
      }
      componentWillUnmount() {
        if (unmount) {
          injectReducer(key, 'remove');
        }
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    };
}

export default withReducer;
