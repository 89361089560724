import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import { IReviewEntityData } from 'services/api/reviewEntity';

export interface IState {
  reviewEntityCollectionData: {
    data: IReviewEntityData | null;
    status: StateStatus | null;
  };
  reviewEntityItemsData: {
    data: IReviewEntityData | null;
    status: StateStatus | null;
  };
  updateDetailsStatus: StateStatus | null;
  removeDetailsStatus: StateStatus | null;
}

export const initialState: IState = {
  reviewEntityCollectionData: {
    data: null,
    status: null,
  },
  reviewEntityItemsData: {
    data: null,
    status: null,
  },
  updateDetailsStatus: null,
  removeDetailsStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_REVIEW_ENTITY_COLLECTIONS_LIST:
      return {
        ...state,
        reviewEntityCollectionData: {
          status: action.status ?? null,
          data:
            action.data === KEEP_THE_SAME
              ? state.reviewEntityCollectionData.data
              : action.data,
        },
      };
    case Types.SET_REVIEW_ENTITY_ITEMS_LIST:
      return {
        ...state,
        reviewEntityItemsData: {
          status: action.status ?? null,
          data:
            action.data === KEEP_THE_SAME
              ? state.reviewEntityItemsData.data
              : action.data,
        },
      };
    case Types.UPDATE_REVIEW_ENTITY_DETAILS:
      return {
        ...state,
        updateDetailsStatus: action.status ?? null,
      };
    case Types.DELETE_REVIEW_ENTITY_DETAILS:
      return {
        ...state,
        removeDetailsStatus: action.status ?? null,
      };
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
