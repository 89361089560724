import PageLayout from 'components/app_common/PageLayout';
import React from 'react';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import ModalVerifyAction from 'components/molecules/ModalVerifyAction';
// import AddEditTrickForm from '../BasicTemplate';
import TrickDetailsActions from '../../store/actions';
import { IItemDetailsProps } from '../../configuration';
import TagDetailsModal from 'components/compounds/TagDetailsModal';
import WordInfoModal from '../../components/WordInfoModal';
import PromptInputModal from 'components/app_common/PromptInputModal';
import FormProvider from '../../components/FormProvider';
import TemplateSelector from '../TemplateSelector';

const TrickDetails = (props: IItemDetailsProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { trickId, mode } = props;

  React.useEffect(() => {
    dispatch(TrickDetailsActions.fetchTagsListAction());

    return () => {
      dispatch(TrickDetailsActions.resetAction());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (trickId && (mode === 'edit' || mode === 'view')) {
      dispatch(TrickDetailsActions.fetchTrickDetailsAction(trickId));
    }
  }, [trickId, mode, dispatch]);

  return (
    <>
      <PromptInputModal />
      <WordInfoModal />
      <TagDetailsModal />
      <FormProvider {...props}>
        <TemplateSelector {...props} />
      </FormProvider>
    </>
  );
};

// const Page = withRouter(TrickDetailsPage);
export default withReducer<IItemDetailsProps>(
  ReducerKeys.TRICK_DETAILS_REDUCER
)(TrickDetails) as any as React.FC<IItemDetailsProps>;
