import React from 'react';
import TranslatedText from 'components/atoms/TranslatedText';
import NavigationConfig from 'config/navigationConfig';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssessmentIcon from '@material-ui/icons/Assessment';
import RateReviewIcon from '@material-ui/icons/RateReview';
import * as Types from '../types';
import { ReviewEntityTypeEnum } from 'constants/reviewEntityConst';
import { TagTypes } from 'constants/tagsConst';
import AuthRoles, { Roles } from 'constants/authRolesConst';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PageviewIcon from '@material-ui/icons/Pageview';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';

const SideBarConfig: Types.ISidebarConfig[] = [
  {
    title: <TranslatedText defaultText="Learning" />,
    path: NavigationConfig.routinesPage().path,
    requiredRoles: NavigationConfig.routinesPage().roles,
    id: 'learning',
    component: NavigationConfig.routinesPage().component,
    Icon: () => <MenuBookIcon />,
  },
  {
    title: <TranslatedText defaultText="Entries" />,
    path: NavigationConfig.trickPage().path,
    requiredRoles: NavigationConfig.trickPage().roles,
    id: 'entries',
    component: NavigationConfig.trickPage().component,
    Icon: () => <DescriptionOutlinedIcon />,
    // initial params ...
  },
  {
    title: <TranslatedText defaultText="Units-Learning" />,
    path: NavigationConfig.tagsPage({ type: TagTypes.Collection }).path,
    requiredRoles: NavigationConfig.tagsPage().roles,
    id: 'unites-learning',
    component: NavigationConfig.tagsPage().component,
    Icon: () => <ViewCarouselIcon />,
  },
  {
    title: <TranslatedText defaultText="Units-Entries" />,
    path: NavigationConfig.tagsPage({ type: TagTypes.Item }).path,
    requiredRoles: NavigationConfig.tagsPage().roles,
    id: 'unites-entries',
    component: NavigationConfig.tagsPage().component,
    Icon: () => <LibraryBooksOutlinedIcon />,
  },

  {
    title: <TranslatedText defaultText="Classes" />,
    path: NavigationConfig.namespacesPage().path,
    requiredRoles: NavigationConfig.namespacesPage().roles,
    id: 'classes',
    component: NavigationConfig.namespacesPage().component,
    Icon: () => <FeaturedPlayListOutlinedIcon />,
  },
  {
    title: <TranslatedText defaultText="Students" />,
    path: NavigationConfig.studentsPage().path,
    requiredRoles: NavigationConfig.studentsPage().roles,
    id: 'students',
    component: NavigationConfig.studentsPage().component,
    Icon: () => <SupervisorAccountIcon />,
  },
  {
    title: <TranslatedText defaultText="Tasks" />,
    path: NavigationConfig.tasksPage().path,
    requiredRoles: NavigationConfig.tasksPage().roles,
    id: 'tasks',
    component: NavigationConfig.tasksPage().component,
    Icon: () => <FormatListNumberedRtlIcon />,
  },
  {
    title: <TranslatedText defaultText="Statistics" />,
    path: null,
    requiredRoles: [Roles.Supervisor],
    id: 'statistics',
    component: null,
    Icon: () => <AssessmentIcon />,
    subItems: [
      {
        title: <TranslatedText defaultText="Dashboard" />,
        path: NavigationConfig.dashboardPage().path,
        requiredRoles: NavigationConfig.dashboardPage().roles,
        id: 1,
        component: NavigationConfig.dashboardPage().component,
      },
      {
        title: <TranslatedText defaultText="Ranking" />,
        path: NavigationConfig.rankingPage().path,
        requiredRoles: NavigationConfig.rankingPage().roles,
        id: 2,
        component: NavigationConfig.rankingPage().component,
      },
    ],
  },
  {
    title: <TranslatedText defaultText="Review" />,
    path: null,
    requiredRoles: NavigationConfig.reviewEntityPage().roles,
    id: 'review',
    component: null,
    Icon: () => <RateReviewIcon />,
    subItems: [
      {
        title: <TranslatedText defaultText="Courses" />,
        path: NavigationConfig.reviewEntityPage({
          entityType: ReviewEntityTypeEnum.Collection,
        }).path,
        requiredRoles: NavigationConfig.reviewEntityPage().roles,
        id: 1,
        component: NavigationConfig.reviewEntityPage().component,
      },
      {
        title: <TranslatedText defaultText="Words/Exercises" />,
        path: NavigationConfig.reviewEntityPage({
          entityType: ReviewEntityTypeEnum.Item,
        }).path,
        requiredRoles: NavigationConfig.reviewEntityPage().roles,
        id: 1,
        component: NavigationConfig.reviewEntityPage().component,
      },
    ],
  },
  {
    title: <TranslatedText defaultText="Manage" />,
    path: null,
    requiredRoles: [Roles.Admin, Roles.Supervisor],
    id: 'manage',
    component: null,
    Icon: () => <PageviewIcon />,
    subItems: [
      {
        title: <TranslatedText defaultText="Notification" />,
        path: NavigationConfig.notificationPage().path,
        requiredRoles: NavigationConfig.notificationPage().roles,
        id: 'notification',
        component: NavigationConfig.notificationPage().component,
      },
      {
        title: <TranslatedText defaultText="Users" />,
        path: NavigationConfig.usersPage().path,
        requiredRoles: NavigationConfig.usersPage().roles,
        id: 'users',
        component: NavigationConfig.usersPage().component,
      },
      {
        title: <TranslatedText defaultText="Files" />,
        path: NavigationConfig.uploadedFilesPage().path,
        requiredRoles: NavigationConfig.uploadedFilesPage().roles,
        id: 'uploaded-files',
        component: NavigationConfig.uploadedFilesPage().component,
      },
      {
        title: <TranslatedText defaultText="Url Alias" />,
        path: NavigationConfig.urlAliasPage().path,
        requiredRoles: NavigationConfig.urlAliasPage().roles,
        id: 'url-alias',
        component: NavigationConfig.urlAliasPage().component,
      },
    ],
  },
  {
    title: <TranslatedText defaultText="System" />,
    path: null,
    Icon: () => <SettingsApplicationsOutlinedIcon />,
    requiredRoles: [AuthRoles.Admin],
    id: 'app-system',
    component: null,
    subItems: [
      {
        title: <TranslatedText defaultText="Logging" />,
        path: NavigationConfig.logsPage().path,
        requiredRoles: NavigationConfig.logsPage().roles,
        // Icon: () => <AssignmentOutlinedIcon />,
        id: 2,
        component: NavigationConfig.logsPage().component,
      },
      {
        title: <TranslatedText defaultText="Sessions" />,
        path: NavigationConfig.sessionPage().path,
        requiredRoles: NavigationConfig.sessionPage().roles,
        // Icon: () => <DevicesOtherOutlinedIcon />,
        id: 3,
        component: NavigationConfig.sessionPage().component,
      },
      {
        title: <TranslatedText defaultText="Activities" />,
        path: NavigationConfig.ativitiesPage().path,
        requiredRoles: NavigationConfig.ativitiesPage().roles,
        // Icon: () => <HistoryIcon />,
        id: 4,
        component: NavigationConfig.ativitiesPage().component,
      },
      {
        title: <TranslatedText defaultText="Settings" />,
        path: NavigationConfig.systemSettingsPage().path,
        requiredRoles: NavigationConfig.systemSettingsPage().roles,
        id: 5,
        component: NavigationConfig.systemSettingsPage().component,
      },
      {
        title: <TranslatedText defaultText="Payments" />,
        path: NavigationConfig.paymentsPage().path,
        requiredRoles: NavigationConfig.paymentsPage().roles,
        id: 6,
        component: NavigationConfig.paymentsPage().component,
      },
    ],
  },
];

export default SideBarConfig;
