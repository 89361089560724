import useQuery from 'hooks/useQuery';
import React from 'react';
import { useContextProps } from './provider';
import { IColumnProps } from 'components/molecules/Datatable/configuration';
import TableActions from './components/TableAction';
import * as DateUtils from 'utils/dates';
import Chip from '@material-ui/core/Chip';
import { IState } from './store/reducer';
import { PaymentTypeEnum } from 'constants/paymentsConst';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { colors, Tooltip, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import NavigationConfig from 'config/navigationConfig';
import { TagLevel } from 'constants/tagsConst';
import PublicIcon from '@material-ui/icons/Public';
import BigText from 'components/atoms/BigText';
import { CollectionStatusOptions } from 'constants/statusConst';
import { CollectionTypeOptions } from 'constants/dropdownOptions';
import _ from 'lodash';
import useTextSelector from 'hooks/useTextSelector';
import { AppTypes } from 'constants/namespaceConst';
import PreviewCollectionMob from 'components/app_common/PreviewCollectionMob';
import { viewFileUrl } from 'services/api/file';

interface IDefaultPaging {
  page: number;
  pageSize: number;
  sortField: string;
  orderType: 'asc' | 'desc';
}

export const DefaultPaging: IDefaultPaging = {
  page: 0,
  pageSize: 25,
  sortField: 'updatedAt',
  orderType: 'desc',
};

export const useParams = () => {
  const props = useContextProps();
  const page = props.page ?? DefaultPaging.page;
  const pageSize = props.pageSize ?? DefaultPaging.pageSize;
  const sortField = props.sortField ?? DefaultPaging.sortField;
  const orderType = props.orderType ?? DefaultPaging.orderType;
  const collectionId = props.collectionId ?? undefined;
  const title = props.title ?? undefined;

  return React.useMemo(
    () => ({
      page,
      pageSize,
      sortField,
      orderType: orderType as 'asc' | 'desc',
      collectionId,
      title,
    }),
    [page, pageSize, title, sortField, orderType, collectionId]
  );
};

export const useUpdateState = () => {
  const props = useContextProps();

  return props.setProps;
};

export const useTableColumns = () => {
  const props = useContextProps();
  const params = useParams();
  const { textSelector } = useTextSelector();

  const TableColumns: Array<IColumnProps> = [
    {
      label: '#',
      id: 'number',
      render: (rowIndex: number, cellValue: any, columnIndex: number) => (
        <Typography>{params.page * params.pageSize + rowIndex + 1}</Typography>
      ),
      inData: false,
    },
    {
      label: 'Student',
      id: 'user.username',
      render: (rowIndex: number, cellValue: any, columnIndex: number, data) => (
        <>{`${cellValue} (${data[rowIndex]?.user?.firstName} ${data[rowIndex]?.user?.lastName})`}</>
      ),
      inData: true,
    },
    {
      label: 'Email',
      id: 'user.email',
      render: (rowIndex: number, cellValue: any, columnIndex: number, data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>{cellValue}</div>
      ),
      inData: true,
    },
    {
      label: 'Score',
      id: 'process',
      render: (
        rowIndex: number,
        cellValue: number,
        columnIndex: number,
        data
      ) => (
        <Chip
          style={{
            background:
              cellValue > 80
                ? colors['green']['500']
                : cellValue > 50
                ? colors['orange']['500']
                : colors['red']['500'],
            color: colors.common.white,
            fontWeight: 500,
          }}
          label={`${cellValue ?? 0}%`}
        />
      ),
      inData: true,
    },

    {
      label: '',
      id: 'actions',
      align: 'center',
      render: (
        rowIndex: number,
        cellValue: any,
        columnIndex: number,
        data: any
      ) => <TableActions params={data[rowIndex]} />,
      inData: false,
      sticky: 'right',
    },
  ];

  return TableColumns;
};
