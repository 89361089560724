// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request from 'utils/request';
import * as PathUtils from 'utils/path';
import { CollectionTypeEnum } from 'constants/typesConst';
import { LanguagesEnum } from 'constants/languagesConst';
import { CollectionStatusEnum } from 'constants/statusConst';
import { PaymentTypeEnum } from 'constants/paymentsConst';
import { BulkAccessEnum, BulkStatusEnum } from 'constants/collectionConst';

/** fetch Routines list */
interface IFetchRoutineListOutput extends Types.ResponseOutput {
  data: FetchRoutineListData;
}

export type FetchRoutineListData = {
  items: Array<{
    _id: string;
    tags: Array<string>;
    public: boolean;
    examples: Array<string>;
    items: Array<string>;
    title: string;
    description: string;
    type: string;
    difficulty: number;
    link: string;
    finalRate: number;
    createdBy: {
      _id: string;
      firstName: string;
      lastName: string;
    };
    rates: Array<{}>;
    createdAt: string;
    updatedAt: string;
    // imageId?: string;
    imageId?: {
      _id: string;
      path: string;
    };
    status?: CollectionTypeEnum;
    maskAnswerss?: boolean;
  }>;
  paging: {
    pageSize: number;
    page: number;
    currentSize: number;
    total: number;
  };
};

export interface IFetchRoutineListInput {
  public?: boolean;
  page?: number;
  pageSize?: number;
  orderType?: 'asc' | 'desc';
  sortField?: string;
  globalSearch?: string;
  createdAt_Start?: Date;
  createdAt_End?: Date;
  updatedAt_Start?: Date;
  updatedAt_End?: Date;
  type?: CollectionTypeEnum;
  status?: CollectionStatusEnum;
  paymentType?: PaymentTypeEnum;
  createdBy?: string;
  tag?: string;
  isTemplate?: boolean;
}

export const fetchRoutineListList = async (
  payload: IFetchRoutineListInput
): Promise<IFetchRoutineListOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'isTemplate',
        value:
          payload.isTemplate === true
            ? 'true'
            : payload.isTemplate === false
            ? 'false'
            : undefined,
      },
      {
        key: 'public',
        value:
          payload.public === true
            ? 'true'
            : payload.public === false
            ? 'false'
            : undefined,
      },
      {
        key: 'pageSize',
        value: payload?.pageSize?.toString(),
      },
      {
        key: 'page',
        value: payload?.page?.toString(),
      },
      {
        key: 'sortField',
        value: payload.sortField,
      },
      {
        key: 'globalSearch',
        value: payload.globalSearch,
      },
      {
        key: 'orderType',
        value: payload.orderType,
      },
      {
        key: 'createdAt_Start',
        value: payload.createdAt_Start
          ? new Date(payload.createdAt_Start).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'createdAt_End',
        value: payload.createdAt_End
          ? new Date(payload.createdAt_End).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'updatedAt_Start',
        value: payload.updatedAt_Start
          ? new Date(payload.updatedAt_Start).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'updatedAt_End',
        value: payload.updatedAt_End
          ? new Date(payload.updatedAt_End).toISOString().split('T')[0]
          : undefined,
      },
      {
        key: 'type',
        value: payload?.type,
      },
      {
        key: 'disableTranslation',
        value: 'true',
      },
      {
        key: 'status',
        value: payload.status,
      },
      {
        key: 'paymentType',
        value: payload.paymentType,
      },
      {
        key: 'createdBy',
        value: payload.createdBy,
      },
      {
        key: 'tag',
        value: payload.tag,
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/list${query}`
  );

  return data;
};

/** fetch Routine Details */
interface IFetchRoutineDetailsOutput extends Types.ResponseOutput {
  data: FetchRoutineDetailsData;
}

export type FetchRoutineDetailsData = {
  _id: string;
  tags: Array<string>;
  public: boolean;
  examples: Array<string>;
  items: Array<Types.ITrickData>;
  title: string;
  description: string;
  type: string;
  difficulty: number;
  link: string;
  finalRate: number;
  createdBy: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  rates: Array<{}>;
  createdAt: string;
  quizTypes?: Array<string>;
  updatedAt: string;
  namespaces?: string[];
  // imageId?: string;
  imageId?: {
    _id: string;
    path: string;
  };
  audioId?: {
    _id: string;
    path: string;
  };
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string;
        question: string;
      }>;
    }
  >;
  status: CollectionStatusEnum;
  paymentType: PaymentTypeEnum;
  maskAnswers?: boolean;
  isTemplate?: boolean;
  clonedBy?: string;
  singleAttemptMode?: boolean;
  countDownDuration?: number;
};

export const fetchRoutineDetails = async (
  routineId: string
): Promise<IFetchRoutineDetailsOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'disableTranslation',
        value: 'true',
      },
      {
        key: 'showTranslationsDictionary',
        value: 'true',
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/details/${routineId}${query}`
  );

  return data;
};

/** Add Routine */
interface IAddRoutineOutput extends Types.ResponseOutput {
  data: null;
}

export interface IAddRoutineInput {
  title: string;
  description: string;
  type: string;
  link: string;
  tags: string[];
  public: boolean;
  items: string[];
  quizTypes?: string[];
  namespaces?: string[];
  imageId?: string;
  audioId?: string;
  translations?: Partial<
    {
      [key in LanguagesEnum]: Partial<{
        description: string;
        question: string;
      }>;
    }
  >;
  status: CollectionStatusEnum;
  paymentType: PaymentTypeEnum;
  difficulty?: number | null;
  maskAnswers?: boolean;
  isTemplate?: boolean;
  singleAttemptMode?: boolean;
  countDownDuration?: number | null;
}

export const addRoutine = async (
  payload: IAddRoutineInput
): Promise<IAddRoutineOutput> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/collection/add`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Edit Routine */
interface IEditRoutineOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditRoutineInput
  extends Omit<IAddRoutineInput, 'imageId' | 'audioId'> {
  imageId: string | null;
  audioId: string | null;
}

export const editRoutine = async (
  trickId: string,
  payload: Partial<IEditRoutineInput>
): Promise<IEditRoutineOutput> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/collection/details/${trickId}`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Remove Routine */
interface IRemoveRoutineOutput extends Types.ResponseOutput {
  data: null;
}

export interface IRemoveRoutineInput extends IAddRoutineInput {}

export interface IRemoveCollectionPayload {
  removeContent?: boolean;
  removeCollectionImage?: boolean;
  removeItemImage?: boolean;
  collectionId: string;
}

export const removeRoutine = async (
  payload: IRemoveCollectionPayload
): Promise<IRemoveRoutineOutput> => {
  const query = PathUtils.varToStringParams({
    variablesArray: [
      {
        key: 'removeContent',
        value: payload.removeContent ? 'true' : 'false',
      },
      {
        key: 'removeCollectionImage',
        value: payload.removeCollectionImage ? 'true' : 'false',
      },
      {
        key: 'removeItemImage',
        value: payload.removeItemImage ? 'true' : 'false',
      },
    ],
  });

  const { data } = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/collection/details/${payload.collectionId}${query}`,
    {
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Export Collection Exercises */
export const exportCollectionExercises = async (
  routineId: string,
  title: string
) => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/export-exercises/${routineId}`,
    {
      config: {
        responseType: 'blob',
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  const url = window.URL.createObjectURL(new Blob([data]));
  var a = document.createElement('a');
  a.href = url;
  a.download = `${title}.docx`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

/** Export collection text */
export const exportCollectionText = async (
  routineId: string,
  title: string
) => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/export-text/${routineId}`,
    {
      config: {
        responseType: 'blob',
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  const url = window.URL.createObjectURL(new Blob([data]));
  var a = document.createElement('a');
  a.href = url;
  a.download = `${title}.docx`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

/** fetch collection tags list */
export type FetchCollectionTagsData = Array<{
  id: string;
  label: string;
}>;

export const fetchCollectionTagsList = async (): Promise<
  Types.ResponseOutputV2<FetchCollectionTagsData>
> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/tags`
  );

  return data;
};

/** fetch collection tags list */
export type IInsertCsvCollectionItemsPayload = {
  fileName: string;
  collectionId: string;
};

export type IInsertCsvCollectionItemsData = {
  insertedItems: Array<{
    id: string;
    title: string;
  }>;
};

export const insertCsvCollectionItems = async (
  payload: IInsertCsvCollectionItemsPayload
): Promise<Types.ResponseOutputV2<IInsertCsvCollectionItemsData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/collection/add/csv-items`,
    {
      body: payload,
    }
  );

  return data;
};

/** Download CSV Collection */
export const downloadCsvCollection = async (
  collectionId: string,
  title: string
) => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/download/csv-items/${collectionId}`,
    {
      config: {
        responseType: 'blob',
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  const url = window.URL.createObjectURL(new Blob([data]));
  var a = document.createElement('a');
  a.href = url;
  a.download = `${title}.csv`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

/** collection bulk actions */
export type CollectionsBulkActionsPayload = {
  status?: BulkStatusEnum;
  namespaces?: string[];
  access?: BulkAccessEnum;
  collectionId: string;
  itemTag?: string;
};

export const collectionBulkActions = async (
  payload: CollectionsBulkActionsPayload
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/collection/bulk-actions`,
    {
      body: payload,
    }
  );

  return data;
};

/**  Clone Collection Full */

export interface ICloneCollectionFullPayload {
  collectionId: string;
}

export const cloneCollectionFull = async (
  payload: ICloneCollectionFullPayload
): Promise<Types.ResponseOutputV2<null>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/collection/clone-collection/full`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

/** Export Collection Book */
export const exportCollectionBook = async (
  collectionId: string,
  title: string,
  language: LanguagesEnum | null
) => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/collection/export-book/${collectionId}`,
    {
      config: {
        responseType: 'blob',
        params: {
          language,
        },
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  const url = window.URL.createObjectURL(new Blob([data]));
  var a = document.createElement('a');
  a.href = url;
  a.download = `${title}.docx`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};
