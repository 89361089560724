import React from 'react';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { MainRoutes } from './navigation/config';
import ToastMessage from './components/atoms/ToastMessage';
import { PersistGate } from 'redux-persist/integration/react';
import ThemeProvider from 'providers/ThemeProvider';
import AuthProvider from 'providers/AuthProvider';
import ThemeSettings from 'components/app_common/ThemeSettings';
import ModalVerifyAction from 'components/molecules/ModalVerifyAction';
import WebviewProvider from 'providers/WebviewProvider';
import './styles/scrollbar.css';
import TranslationsModal from 'components/app_common/TranslationsModal';
import CommonProvider from 'providers//CommonProvider';
import UploadedFilesModal from 'components/compounds/UploadedFilesModal';
import PromptsModal from 'components/app_common/Prompts';
import DiscoverImagesModal from 'components/compounds/DiscoverImagesModal';
import CollectionDetailsModal from 'components/compounds/CollectionDetailsModal';
import ItemDetailsModal from 'components/compounds/ItemDetailsModal';
import IcognitoProvider from 'providers/IcognitoProvider';
import UserNamespaceSettingsModal from 'components/compounds/UserNamespaceSettingsModal';
import ColLibrariesPerCollectionModal from 'components/compounds/ColLibrariesPerCollectionModal';
import CollectionItemsPerStudentModal from 'components/compounds/CollectionItemsPerStudentModal';
import FullScreenLoading from 'components/compounds/FullScreenLoading';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IcognitoProvider>
          <ThemeProvider>
            <AuthProvider>
              <WebviewProvider>
                <CommonProvider />
                <MainRoutes />
                <CollectionDetailsModal />
                <ItemDetailsModal />
                <UploadedFilesModal />
                <DiscoverImagesModal />
                <TranslationsModal />
                <ColLibrariesPerCollectionModal />
                <CollectionItemsPerStudentModal />
                <UserNamespaceSettingsModal />
                <FullScreenLoading />
              </WebviewProvider>
              <ThemeSettings />
            </AuthProvider>
            <ModalVerifyAction />
            <ToastMessage />
            <PromptsModal />
          </ThemeProvider>
        </IcognitoProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
