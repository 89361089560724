export enum PaymentTypeEnum {
  Free = 'Free',
  PaidBasicPacket = 'PaidBasicPacket',
  PaidPlusPacket = 'PaidPlusPacket',
}

export const PaymentTypeOptions = Object.values(PaymentTypeEnum).map((el) => ({
  id: el,
  label: el,
}));
