import React from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import GTranslateIcon from '@material-ui/icons/GTranslate';

interface ITranslateButtonProps {
  execute: () => Promise<string | null>;
  size?: 'small' | 'medium';
}

const TranslateButton = (props: ITranslateButtonProps) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      {!loading ? (
        <IconButton
          onClick={async () => {
            setLoading(true);
            try {
              await props.execute();
            } catch (error) {}
            setLoading(false);
          }}
          color="primary"
          size={props.size ?? 'small'}
        >
          <GTranslateIcon fontSize={props.size ?? 'small'} />
        </IconButton>
      ) : (
        <CircularProgress size={'1rem'} color="primary" />
      )}
    </>
  );
};

export default TranslateButton;
