export const formatDate = (datetime: string): string | null => {
  try {
    const date = new Date(datetime);
    const daysTemp = date.getDate();
    const monthTemp = date.getMonth() + 1;

    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const hours =
      date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const day = daysTemp < 10 ? `0${daysTemp}` : daysTemp;
    const month = monthTemp < 10 ? `0${monthTemp}` : monthTemp;

    return `${day}-${month}-${date.getFullYear()} ${hours}:${minutes}`;
  } catch (error) {
    return null;
  }
};

export const datetimeToString = (
  datetime: string | Date,
  includeDate: boolean = true,
  includeTime: boolean = true
): string | null => {
  try {
    const date = new Date(datetime);
    let result = '';

    // DATE
    var datePart = `${date.getDate()}`;
    if (Number.parseInt(datePart) < 10) datePart = `0${datePart}`;
    var monthPart = `${date.getMonth() + 1}`;
    if (Number.parseInt(monthPart) < 10) monthPart = `0${monthPart}`;
    var yearPart = `${date.getFullYear()}`;
    if (includeDate) {
      result += `${datePart}/${monthPart}/${yearPart}`;
    }

    // TIME
    var hoursPart = `${date.getHours()}`;
    if (Number.parseInt(hoursPart) < 10) hoursPart = `0${hoursPart}`;
    var minutesPart = `${date.getMinutes()}`;
    if (Number.parseInt(minutesPart) < 10) minutesPart = `0${minutesPart}`;

    if (includeDate && includeTime) {
      result += ' ';
    }

    if (includeTime) {
      result += `${hoursPart}:${minutesPart}`;
    }

    return result;
  } catch (error) {
    return null;
  }
};

export const datesDifference = (
  dateFrom: Date,
  dateTo: Date,
  type: 'Minutes' | 'Hours' | 'Days' | 'Milli' = 'Minutes'
): number => {
  const diffMs: number = dateTo.getTime() - dateFrom.getTime(); // milliseconds
  switch (type) {
    case 'Days':
      return Math.floor(diffMs / 86400000); // days
    case 'Hours':
      return Math.floor((diffMs % 86400000) / 3600000); // hours
    case 'Milli':
      return diffMs;
    default:
      return Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  }
};

export const isSameDates = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
