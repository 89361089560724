import React from 'react';
import { Dispatch } from 'redux';
import { AuthRoles, Languages } from '../../../constants';
import TranslationActions from '../../../redux/reducers/gl_translations_reducer/actions';

interface IMenuConfigurationElement {
  label: string;
  id: string;
  action: (props: Dispatch<any>) => void;
  submenu: boolean;
  roles: Array<number | string> | number | string;
  icon: any;
}

export const LanguageConfiguration: IMenuConfigurationElement[] = [
  {
    label: 'English',
    id: Languages.English,
    action: (dispatch: Dispatch<any>) =>
      dispatch(TranslationActions.changeLanguageAction(Languages.English)),
    submenu: true,
    roles: AuthRoles.All,
    icon: (
      <img
        src={require('assets/images/icons/uk_flag.png')}
        alt="English"
        width="20"
        height="20"
      />
    ),
  },
  {
    label: 'Greek',
    id: Languages.Greek,
    action: (dispatch: Dispatch<any>) =>
      dispatch(TranslationActions.changeLanguageAction(Languages.Greek)),
    submenu: true,
    roles: AuthRoles.All,
    icon: (
      <img
        src={require('assets/images/icons/greece_flag.png')}
        alt="Greece"
        width="20"
        height="20"
      />
    ),
  },
];
