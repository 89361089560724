import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as FileService from 'services/api/file';

export interface IState {
  removeFileStatus: StateStatus | null;
  uploadFileStatus: StateStatus | null;
  editFileStatus: StateStatus | null;
  filesList: {
    data: FileService.IFetchFilesData | null;
    status: StateStatus | null;
  };
  uploadFileFromURLStatus: StateStatus | null;
}

const initialState: IState = {
  removeFileStatus: null,
  uploadFileStatus: null,
  editFileStatus: null,
  filesList: {
    data: null,
    status: null,
  },
  uploadFileFromURLStatus: null,
};

const Reducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_FILES:
      return {
        ...state,
        filesList: {
          ...state.filesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.filesList.data : action.data,
        },
      };
    case Types.REMOVE_FILE:
      return {
        ...state,
        removeFileStatus: action.status || null,
      };
    case Types.UPLOAD_FILE:
      return {
        ...state,
        uploadFileStatus: action.status || null,
      };
    case Types.EDIT_FILE:
      return {
        ...state,
        editFileStatus: action.status || null,
      };
    case Types.UPLOAD_FILE_FROM_URL:
      return {
        ...state,
        uploadFileFromURLStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
