import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  action1: {
    marginRight: '1rem',
    marginLeft: '1rem',
  },
  action2: {
    marginRight: '1rem',
    marginLeft: '1rem',
  },
  description: {
    fontSize: 18,
  },
}));

export default useStyles;
