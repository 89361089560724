import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useAddTrickStatus */
export const useAddTrickStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).addTrickStatus
  );
};

/* selector: useTagsList */
export const useTagsList = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).tagsList
  );
};

/* selector: useTrickDetails */
export const useTrickDetails = (): IState['trickDetails'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).trickDetails
  );
};

/* selector: useEditTrickStatus */
export const useEditTrickStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).editTrickStatus
  );
};

/* selector: useRemoveTrickStatus */
export const useRemoveTrickStatus = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).removeTrickStatus
  );
};

/* selector: usePlacesList */
export const useWordInfo = (): IState['wordInfo'] => {
  return useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).wordInfo
  );
};

// /* selector: useItemPrompts */
// export const useItemPrompts = () => {
//   return useShallowEqualSelector(
//     (state: any) =>
//       (state[ReducerKeys.TRICK_DETAILS_REDUCER] as IState).itemPrompts
//   );
// };
