import React from 'react';
import { Box, Paper, Tooltip, Typography } from '@material-ui/core';
import { Palletes } from 'providers/ThemeProvider/configuration';
import { useDispatch } from 'react-redux';
import ThemeActions from 'redux/reducers/gl_theme_reducer/actions';
import useStyles from './styles';
import { ThemesModeEnum } from 'constants/themeConst';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import clsx from 'clsx';
import _ from 'lodash';
import { PalletesEnum } from 'constants/palletesConst';

const ThemeSelector = () => {
  const palletesArr = Object.values(PalletesEnum);
  const dispatch = useDispatch();
  const classes = useStyles({});
  const palette = ThemeSelectors.usePalleteId();

  return (
    <Box display="flex" flexDirection="column">
      {palletesArr.map((id, index) => {
        return (
          <Paper
            variant="outlined"
            className={clsx({
              [classes.element]: true,
              [classes.selected]: palette === id,
            })}
            onClick={() => dispatch(ThemeActions.setPalleteIdAction(id))}
            key={index}
          >
            <Typography>{`Palette ${index + 1}`}</Typography>
            <Box display="flex" flexDirection="row">
              <Palette palette={id} theme={ThemesModeEnum.Light} />
              <Palette palette={id} theme={ThemesModeEnum.Dark} />
            </Box>
          </Paper>
        );
      })}
    </Box>
  );
};

interface IPaletteProps {
  palette: PalletesEnum;
  theme: ThemesModeEnum;
}
const Palette = ({ theme, palette }: IPaletteProps) => {
  const classes = useStyles({
    primary: _.get(
      Palletes[palette][theme]?.palette?.primary,
      'main',
      undefined
    ),
    secondary: _.get(
      Palletes[palette][theme]?.palette?.secondary,
      'main',
      undefined
    ),
  });
  return (
    <Tooltip title={theme === ThemesModeEnum.Light ? 'Light' : 'Dark'}>
      <div
        className={clsx({
          [classes.palette]: true,
        })}
      ></div>
    </Tooltip>
  );
};

export default React.memo(ThemeSelector);
