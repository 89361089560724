import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppAdvancedselect,
  AppTextField,
  AppSelect,
  IAdvancedSelectProps,
  AppCommon,
} from 'components/molecules/FormControls';
import {
  Control,
  Controller,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ExerciseActions from './ExerciseActions';
import {
  openUploadedFilesModal,
  closeUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { getFileDetails, openFileToNewTabAsync } from 'services/api/file';
import { FileType } from 'constants/filesConst';
import { getState } from 'redux/store';
import * as OpenAIService from 'services/api/openai';
import { IState } from '../../store/reducer';
import { formatCamelOrUnderscoreCaseToSentenceCase } from 'utils/strings';
import { Roles } from 'constants/authRolesConst';
import { IItemPromptData } from 'services/api/openai';
import { openPromptInputModalModal } from 'components/app_common/PromptInputModal';
import {
  addItemEtymologyEvolutionDataByPrompt,
  ICollectionPromptData,
} from 'services/api/openai';
import EtymologyEvolution from './EtymologyEvolution';
import type { IFormData } from '../../types';
import Environment from 'config/environmentConfig';
import { colors } from '@material-ui/core';
import ImageWrapper from 'components/app_common/FormFileWrapper';

const ImageUrlSection = () => {
  const { errors, control, setValue, getValues } = useFormContext<IFormData>();

  return (
    <AppCommon.Section title={'Image URL'}>
      <div>
        <AppTextField
          variant="outlined"
          size="medium"
          margin="normal"
          error={'imageId' in errors}
          helperText={errors.imageId && errors.imageId.message}
          rules={{}}
          fullWidth
          id="imageId"
          disabled
          placeholder={'Enter Image Url'}
          control={control}
          autoComplete="imageId"
          icon={{
            ComponentRight: (
              <>
                <IconButton
                  onClick={async () => {
                    let initialValue = getValues().title ?? undefined;
                    if (getValues().imageId) {
                      try {
                        const fileDetails = await getFileDetails(
                          getValues().imageId as string
                        );
                        initialValue = fileDetails.data.name;
                      } catch (error) {}
                    }
                    openUploadedFilesModal({
                      handleSelectedFile: (filePath) => {
                        setValue('imageId', filePath);
                        closeUploadedFilesModal();
                      },
                      selectItem: true,
                      initialSearch: initialValue,
                      type: FileType.Image,
                    });
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setValue('imageId', null);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </>
            ),
            ComponentLeft: (
              <IconButton
                onClick={async () => {
                  getValues().imageId &&
                    (await openFileToNewTabAsync(getValues().imageId ?? ''));
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            ),
          }}
          name="imageId"
        />
        <ImageWrapper type="img" fieldId="imageId" />
      </div>
    </AppCommon.Section>
  );
};

// const ImageContainer = React.memo(() => {
//   const { getValues, errors, control, setValue } = useFormContext<IFormData>();
//   const imageId = useWatch({
//     control,
//     name: 'imageId',
//   }) as string | null;
//   const [imageUrl, setImageUrl] = React.useState<string>();

//   React.useEffect(() => {
//     (async () => {
//       if (imageId) {
//         const details = await getFileDetails(imageId);
//         setImageUrl(`${Environment.API_BASE_URL}${details.data.path}`);
//       }
//     })();
//   }, [imageId]);

//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         background: colors.grey['100'],
//         cursor: 'pointer',
//       }}
//       onClick={async () => {
//         getValues().imageId &&
//           (await openFileToNewTabAsync(getValues().imageId ?? ''));
//       }}
//     >
//       {imageUrl && (
//         <img
//           src={imageUrl}
//           style={{ width: 'auto', height: 'auto', maxHeight: 200 }}
//         />
//       )}
//     </div>
//   );
// });

export default React.memo(ImageUrlSection);
