export enum ReducerKeys {
  AUTH_REDUCER = 'AuthReducer',
  TRANSL_REDUCER = 'TranslationReducer',
  NAVIGATION_REDUCER = 'NavigationReducer',
  MODAL_REDUCER = 'ModalReducer',
  THEME_REDUCER = 'ThemeReducer',
  RESET_PASSOWRD_REDUCER = 'ResetPasswordReducer',
  REGISTER_REDUCER = 'RegisterReducer',
  CHANGE_PASSWORD_REDUCER = 'ChangePasswordReducer',
  USER_PROFILE_REDUCER = 'UserProfileReducer',
  FORGOT_PASSWORD_REDUCER = 'ForgotPasswordReducer',
  CONFIRM_ACCOUNT_REDUCER = 'ConfirmAccountReducer',
  LOGS_REDUCER = 'LogsReducer',
  USERS_REDUCER = 'UsersReducer',
  ACTIVITIES_REDUCER = 'ActivitiesReducer',
  NOTIFICATION_REDUCER = 'NotificationReducer',
  CONFIRM_SESSION_REDUCER = 'ConfirmSessionReducer',
  SESSION_REDUCER = 'SessionReducer',
  FORCE_CHANGE_PASSWORD_VERIFY_REDUCER = 'ForceChangePasswordVerifyReducer',
  FORCE_CHANGE_PASSWORD_RESET_REDUCER = 'ForceChangePasswordResetReducer',
  COMMON_REDUCER = 'CommonReducer',

  // new reducers
  TRICKS_REDUCER = 'TricksReducer',
  TRICK_DETAILS_REDUCER = 'TrickDetailsReducer',
  ROUTINES_REDUCER = 'RoutinesReducer',
  ROUTINE_DETAILS_REDUCER = 'RoutineDetailsReducer',
  TAGS_REDUCER = 'TagsReducer',
  TAG_DETAILS_REDUCER = 'TagDetailsReducer',
  NAMESPACES_REDUCER = 'NamespacesReducer',
  NAMESPACE_DETAILS_REDUCER = 'NamespaceDetailsReducer',
  NAMESPACE_MEMBERS_REDUCER = 'NamespaceMembersReducer',
  SYSTEM_SETTINGS_REDUCER = 'SystemSettingsReducer',
  UPLOADED_FILES_REDUCER = 'UploadedFilesReducer',
  DASHBOARD_REDUCER = 'DashboardReducer',
  PAYMENTS_REDUCER = 'PaymentsReducer',
  DISCOVER_IMAGES_REDUCER = 'DiscoverImagesReducer',
  REVIEW_ENTITY_REDUCER = 'ReviewEntityReducer',
  TEXT_TO_AUDIO_REDUCER = 'TextToAudioReducer',
  RANKING_REDUCER = 'RankingReducer',
  STUDENTS_REDUCER = 'StudentsReducer',
  USER_NAMESPACE_SETTINGS_REDUCER = 'UserNamespaceSettingsReducer',
  ASSIGNED_TASKS_REDUCER = 'AssignedTasksReducer',
  TASK_DETAILS_REDUCER = 'TaskDetailsReducer',
  STUDENTS_SELECTOR_REDUCER = 'StudentsSelectorReducer',
  COLLECTION_SELECTOR_REDUCER = 'CollectionSelectorReducer',
  COLLECTION_LIBRARIES_PER_COLLECTION_REDUCER = 'CollectionLibrariesPerCollectionReducer',
  COLLECTION_ITEMS_PER_STUDENT_REDUCER = 'CollectionItemsPerStudentReducer',
  URL_ALIAS_REDUCER = 'UrlAliasReducer',
  URL_ALIAS_DETAILS_REDUCER = 'UrlAliaDetailsReducer',
}
