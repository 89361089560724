import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as LogsService from 'services/api/logs';
import * as ActivitiesService from 'services/api/activities';

export interface IState {
  logsList: {
    status: StateStatus | null;
    data: LogsService.FetchLogsData | null;
  };
  requestActivities: {
    status: StateStatus | null;
    data: ActivitiesService.FetchActivitiesData | null;
  };
}

const initialState: IState = {
  logsList: {
    status: null,
    data: null,
  },
  requestActivities: {
    status: null,
    data: null,
  },
};

const LogsReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_LOGS_LIST:
      return {
        ...state,
        logsList: {
          ...state.logsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.logsList.data : action.data,
        },
      };
    case Types.SET_REQUEST_ACTIVITIES_LIST:
      return {
        ...state,
        requestActivities: {
          ...state.requestActivities,
          status: action.status || null,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default LogsReducer;
