import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginLeft: 5,
    marginRight: 5,
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: 160,
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '0.8rem',
      // margin: '0px',
    },
  },
}));

export default useStyles;
