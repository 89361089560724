import { typeCreatorV2 } from 'redux/utils/common';

export const SET_SESSION_LIST: string = typeCreatorV2(
  'SessionReducer',
  'SetSessionList'
);

export const TOGGLE_SESSIONS_SELECTION: string = typeCreatorV2(
  'SessionReducer',
  'ToggleSessionsSelection'
);

export const SET_SELECTED_SESSIONS: string = typeCreatorV2(
  'SessionReducer',
  'SetSelectedSessions'
);

export const FORCE_LOGOUT: string = typeCreatorV2(
  'SessionReducer',
  'ForceLogout'
);
