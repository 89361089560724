import React from 'react';
import BaseModal from 'components/molecules/BaseModal';
import TranslatedText from 'components/atoms/TranslatedText';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppSelect,
  AppCommon,
  AppAdvancedselect,
  IAdvancedSelectProps,
} from 'components/molecules/FormControls';
import {
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Paper,
} from '@material-ui/core';
import * as TagSelectors from './store/selectors';
import TagActions from './store/actions';
import { StateStatus } from 'redux/utils/common';
import {
  TagLevel,
  TagStatus,
  TagTypes,
  TagTypesNameMap,
} from 'constants/tagsConst';
import withReducer from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import store from 'redux/store';
import ModalAction from 'redux/reducers/gl_modal_reducer/actions';
import {
  openUploadedFilesModal,
  closeUploadedFilesModal,
} from 'components/compounds/UploadedFilesModal';
import { FileType } from 'constants/filesConst';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import _ from 'lodash';
import { IModalParams } from './types';
import Content from './components/Content';

const ModalDiscoverImages = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_DISCOVER_IMAGES}
      renderContent={(props: IModalParams) => <Content {...props} />}
      modalProps={{
        fullWidth: true,
        fullScreen: matches,
      }}
      renderHeader={(props: IModalParams) => (
        <TranslatedText
          defaultText={'Discover Images From External Providers'}
        />
      )}
      renderFooter={(props: IModalParams) => (
        <React.Fragment>
          <Button variant="outlined" onClick={closeDiscoverImagesModal}>
            <TranslatedText defaultText={`Dismiss`} />
          </Button>
        </React.Fragment>
      )}
    />
  );
};

export const openDiscoverImagesModal = (params: IModalParams) => {
  store.dispatch(
    ModalAction.openModalAction({
      modalID: ModalIDs.MODAL_DISCOVER_IMAGES,
      params,
    })
  );
};

export const closeDiscoverImagesModal = () => {
  store.dispatch(
    ModalAction.closeModalAction({
      modalID: ModalIDs.MODAL_DISCOVER_IMAGES,
    })
  );
};

export default withReducer(ReducerKeys.DISCOVER_IMAGES_REDUCER)(
  ModalDiscoverImages
);
