import React from 'react';
import * as Config from '../constants';
import AppbarMenu from '../navigation/components/Appbar';
import * as PathUtils from 'utils/path';
import Test from 'components/atoms/LoadingView';

import MainPage from '../scenes/Main_page';
import RootPage from '../scenes/Root_page';
import { CollectionTypeEnum, ItemTypeEnum } from 'constants/typesConst';
import { CollectionStatusEnum, ItemStatusEnum } from 'constants/statusConst';
import { PaymentTypeEnum } from 'constants/paymentsConst';
import {
  TagLevel,
  TagStatus,
  TagSubTypes,
  TagTypes,
} from 'constants/tagsConst';
import {
  ReviewEntityStatusEnum,
  ReviewEntityTypeEnum,
} from 'constants/reviewEntityConst';
import {
  AppTypes,
  NamespaceType,
  Namespacestatus,
} from 'constants/namespaceConst';
import { ScoreRankingTypes } from 'constants/dailyActivityConst';
import { TaskStatusEnum, TaskTypeEnum } from 'constants/tasksConst';
import { UrlAliasStatusEnum } from 'constants/urlAliasConst';

const UsersPage = React.lazy(() => import('scenes/Users_page'));
const ConfirmAccountPage = React.lazy(
  () => import('scenes/auth_ConfirmAccount_page')
);
const ProfilePage = React.lazy(() => import('scenes/Profile_page'));
const ResetPasswordPage = React.lazy(
  () => import('scenes/auth_ResetPassword_page')
);
const ForgotPasswordPage = React.lazy(
  () => import('scenes/auth_ForgotPassword_page')
);
// const RegisterPage = React.lazy(() => import('scenes/auth_Register_page'));
const LoginPage = React.lazy(() => import('scenes/auth_Login_page'));
const LogsPage = React.lazy(() => import('scenes/Logs_page'));
const ActivitiesPage = React.lazy(() => import('scenes/Activities_page'));
const NotificationPage = React.lazy(() => import('scenes/Notification_page'));
const ConfirmSessionPage = React.lazy(
  () => import('scenes/auth_ConfirmSession_page')
);
const SessionPage = React.lazy(() => import('scenes/Session_page'));
const ForceChangePasswordResetPage = React.lazy(
  () => import('scenes/auth_ForceChangePasswordReset_page')
);
const ForceChangePasswordVerifyPage = React.lazy(
  () => import('scenes/auth_ForceChangePasswordVerify_page')
);
const TrickPage = React.lazy(() => import('scenes/Items_page'));
const TrickDetailsPage = React.lazy(() => import('scenes/ItemDetails_page'));
const RoutinesPage = React.lazy(() => import('scenes/Collections_page'));
const RoutineDetailsPage = React.lazy(
  () => import('scenes/CollectionDetails_page')
);
const TagsPage = React.lazy(() => import('scenes/Tags_page'));
const NamespacesPage = React.lazy(() => import('scenes/Namespaces_page'));
const SupportPage = React.lazy(() => import('scenes/Support_page'));
const PrivacyPolicyPage = React.lazy(() => import('scenes/PrivacyPolicy_page'));
const TermsOfUsePagePage = React.lazy(() => import('scenes/TermsOfUse_page'));
const MobAdsPage = React.lazy(() => import('scenes/MobAds_page'));
const SystemSettingsPage = React.lazy(
  () => import('scenes/SystemSettings_page')
);
const UploadedFilesPage = React.lazy(() => import('scenes/UploadedFiles_page'));
const DashboardPage = React.lazy(() => import('scenes/Dashboard_page'));
const PaymentsPage = React.lazy(() => import('scenes/Payments_page'));
const ReviewEntityPage = React.lazy(() => import('scenes/ReviewEntity_page'));
const DeleteAccountPage = React.lazy(() => import('scenes/DeleteAccount_page'));
const RankingPage = React.lazy(() => import('scenes/Ranking_page'));
const StudentsPage = React.lazy(() => import('scenes/Students_page'));
const TasksPage = React.lazy(() => import('scenes/Tasks_page'));
const UrlAliasPage = React.lazy(() => import('scenes/UrlAlias_page'));
const AppLoaderViewPage = React.lazy(
  () => import('scenes/AppEntrypointView_page')
);

export interface IPage {
  path: string;
  component: any;
  roles: Array<number | string>;
  // [key: string]: any;
  // state?: (params: unknown) => unknown;
}

interface INavigationConfig {
  rootPage: () => IPage;
  mainPage: () => IPage;
  loginPage: () => IPage;
  dashboardPage: () => IPage;
  tasksPage: (params?: {
    page?: number;
    pageSize?: number;
    globalSearch?: string;
    status?: TaskStatusEnum;
    type?: TaskTypeEnum;
    sortField?: string;
    orderType?: string;
  }) => IPage;
  appLoaderPage: (params?: { name?: string; suffix?: string }) => IPage;
  urlAliasPage: (params?: {
    page?: number;
    pageSize?: number;
    globalSearch?: string;
    status?: UrlAliasStatusEnum;
    collectionId?: string;
    sortField?: string;
    orderType?: string;
  }) => IPage;
  rankingPage: (params?: {
    page?: number;
    pageSize?: number;
    type: ScoreRankingTypes;
  }) => IPage;
  supportPage: () => IPage;
  privacyPolicyPage: () => IPage;
  termsOfUsePage: () => IPage;
  forgotPasswordPage: () => IPage;
  systemSettingsPage: () => IPage;
  uploadedFilesPage: (params?: {
    page?: number;
    pageSize?: number;
    orderType?: string;
  }) => IPage;

  resetPasswordPage: (params?: { token?: string }) => IPage;
  profilePage: () => IPage;
  reviewEntityPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    entityType?: ReviewEntityTypeEnum;
    status?: ReviewEntityStatusEnum;
    createdAt?: string;
    name?: string;
  }) => IPage;
  tagsPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    globalSearch?: string;
    type?: TagTypes;
    status?: TagStatus;
    level?: TagLevel;
    subType?: TagSubTypes;
  }) => IPage;
  namespacesPage: (params?: {
    public?: 'true' | 'false';
    type?: NamespaceType;
    status?: Namespacestatus;
  }) => IPage;
  confirmAccountPage: (params?: { email?: string }) => IPage;
  usersPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    globalSearch?: string;
  }) => IPage;
  studentsPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    globalSearch?: string;
    level?: string;
  }) => IPage;
  logsPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    email?: string;
    statusCode?: number;
    httpMethod?: string;
    createdAt?: Date;
    requestId?: string;
    path?: string;
    runTime?: string;
  }) => IPage;
  ativitiesPage: (params?: {
    requestId?: string;
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    createdAt?: Date;
    type?: string;
    success?: string;
    userId?: string;
  }) => IPage;
  notificationPage: () => IPage;
  confirmSessionPage: () => IPage;
  sessionPage: () => IPage;
  forceChangePasswordVerifyPage: (params?: { email?: string }) => IPage;
  forceChangePasswordResetPage: (params?: { token?: string }) => IPage;
  trickPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    globalSearch?: string;
    updatedAt_End?: Date;
    updatedAt_Start?: Date;
    createdAt_End?: Date;
    createdAt_Start?: Date;
    type?: ItemTypeEnum;
    status?: ItemStatusEnum;
    public?: 'true' | 'false';
  }) => IPage;
  trickDetailsPage: (params?: {
    mode?: 'add' | 'edit' | 'view';
    trickId?: string;
    type?: ItemTypeEnum;
    public?: 'true' | 'false';
  }) => IPage;
  routinesPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    globalSearch?: string;
    updatedAt_End?: Date;
    updatedAt_Start?: Date;
    type?: CollectionTypeEnum;
    status?: CollectionStatusEnum;
    paymentType?: PaymentTypeEnum;
    public?: 'true' | 'false';
    tag?: string;
  }) => IPage;
  routineDetailsPage: (params?: {
    mode?: 'add' | 'edit' | 'view';
    routineId?: string;
    type?: CollectionTypeEnum;
    // public?: 'true' | 'false';
  }) => IPage;
  mobAdsPage: () => IPage;
  paymentsPage: (params?: {
    page?: number;
    pageSize?: number;
    sortField?: string;
    orderType?: string;
    userId?: string;
    globalSearch?: string;
  }) => IPage;
  deleteAccountPage: (params?: { app?: AppTypes }) => IPage;
}

interface IAppbarsConfig {
  primaryNav: INav;
  // secondaryNav: INav;
}

interface INav {
  component: any;
  include: 'all' | string[] | null;
  exclude: 'all' | string[] | null;
  select: 'include' | 'exclude';
  path: string;
  // roles: Array<string>;
}

const NavigationConfig: INavigationConfig = {
  // default page first
  dashboardPage: () => ({
    path: '/dashboard',
    component: DashboardPage,
    roles: [Config.AuthRoles.Supervisor],
  }),
  tasksPage: (params) => ({
    path: `/tasks${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
        {
          key: 'type',
          value: params?.type,
        },
        {
          key: 'status',
          value: params?.status,
        },
      ],
    })}`,
    component: TasksPage,
    roles: [
      Config.AuthRoles.Supervisor,
      Config.AuthRoles.Admin,
      Config.AuthRoles.Teacher,
    ],
  }),
  appLoaderPage: ({ name = ':name', suffix = ':suffix?' } = {}) => ({
    path: `/app/${name}/${suffix}`,
    component: AppLoaderViewPage,
    roles: Config.AuthRoles.All,
  }),
  urlAliasPage: (params) => ({
    path: `/urlAlias${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
        {
          key: 'collectionId',
          value: params?.collectionId,
        },
        {
          key: 'status',
          value: params?.status,
        },
      ],
    })}`,
    component: UrlAliasPage,
    roles: [Config.AuthRoles.Admin],
  }),
  trickPage: (params): IPage => ({
    path: `/admin/items${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
        {
          key: 'updatedAt_End',
          value: params?.updatedAt_End?.toISOString(),
        },
        {
          key: 'updatedAt_Start',
          value: params?.updatedAt_Start?.toISOString(),
        },
        {
          key: 'createdAt_End',
          value: params?.createdAt_End?.toISOString(),
        },
        {
          key: 'createdAt_Start',
          value: params?.createdAt_Start?.toISOString(),
        },
        {
          key: 'type',
          value: params?.type,
        },
        {
          key: 'status',
          value: params?.status,
        },
        {
          key: 'public',
          value: params?.public,
        },
      ],
    })}`,
    component: TrickPage,
    roles: [Config.AuthRoles.Supervisor, Config.AuthRoles.Teacher],
  }),
  rankingPage: (params) => ({
    path: `/ranking${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'type',
          value: params?.type,
        },
      ],
    })}`,
    component: RankingPage,
    roles: [Config.AuthRoles.Supervisor],
  }),
  mobAdsPage: (): IPage => ({
    path: '/app-ads.txt',
    component: MobAdsPage,
    roles: Config.AuthRoles.All,
  }),
  systemSettingsPage: (): IPage => ({
    path: '/system-settings',
    component: SystemSettingsPage,
    roles: [Config.AuthRoles.Admin],
  }),
  uploadedFilesPage: (params): IPage => ({
    path: `/uploaded-files`,
    component: UploadedFilesPage,
    roles: [Config.AuthRoles.Supervisor],
  }),
  supportPage: () => ({
    path: '/support',
    component: SupportPage,
    roles: Config.AuthRoles.All,
  }),
  privacyPolicyPage: () => ({
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
    roles: Config.AuthRoles.All,
  }),
  termsOfUsePage: () => ({
    path: '/terms-of-use',
    component: TermsOfUsePagePage,
    roles: Config.AuthRoles.All,
  }),
  forceChangePasswordVerifyPage: ({ email = ':email' } = {}) => ({
    path: `/change-password/verify/${email}`,
    component: ForceChangePasswordVerifyPage,
    roles: [Config.AuthRoles.Unknown],
  }),
  forceChangePasswordResetPage: ({ token = ':token' } = {}) => ({
    path: `/change-password/reset/${token}`,
    component: ForceChangePasswordResetPage,
    roles: [Config.AuthRoles.Unknown],
    // state: (params: { email: string }) => params,
  }),
  reviewEntityPage: ({ entityType = ':entityType', ...params } = {}) => ({
    path: `/review-entity/${entityType}${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        // {
        //   key: 'entityType',
        //   value: params?.entityType,
        // },
        {
          key: 'name',
          value: params?.name,
        },
        {
          key: 'status',
          value: params?.status,
        },
        {
          key: 'createdAt',
          value: params?.createdAt,
        },
      ],
    })}`,
    component: ReviewEntityPage,
    roles: [Config.AuthRoles.Supervisor],
  }),
  tagsPage: ({ type = ':type', ...params } = {}) => ({
    path: `/tags/${type}${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
        // {
        //   key: 'type',
        //   value: params?.type,
        // },
        {
          key: 'status',
          value: params?.status,
        },
        {
          key: 'level',
          value: params?.level?.toString(),
        },
        {
          key: 'subType',
          value: params?.subType?.toString(),
        },
      ],
    })}`,
    component: TagsPage,
    roles: [Config.AuthRoles.Supervisor, Config.AuthRoles.Teacher],
  }),
  namespacesPage: (params) => ({
    path: `/namespaces${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'public',
          value: params?.public,
        },
        {
          key: 'status',
          value: params?.status,
        },
        {
          key: 'type',
          value: params?.type,
        },
      ],
    })}`,
    component: NamespacesPage,
    roles: [
      Config.AuthRoles.Admin,
      Config.AuthRoles.Teacher,
      Config.AuthRoles.Supervisor,
    ],
  }),
  notificationPage: (params?: any) => ({
    path: `/notification`,
    component: NotificationPage,
    roles: [Config.AuthRoles.Admin],
  }),
  ativitiesPage: (params) => ({
    path: `/activities${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'userId',
          value: params?.userId,
        },
        {
          key: 'requestId',
          value: params?.requestId,
        },
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'createdAt',
          value: params?.createdAt?.toISOString(),
        },
        {
          key: 'type',
          value: params?.type,
        },
        {
          key: 'success',
          value: params?.success,
        },
      ],
    })}`,
    component: ActivitiesPage,
    roles: [Config.AuthRoles.Admin],
  }),
  logsPage: (params) => ({
    path: `/logs${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'email',
          value: params?.email,
        },
        {
          key: 'statusCode',
          value: params?.statusCode?.toString(),
        },
        {
          key: 'httpMethod',
          value: params?.httpMethod,
        },
        {
          key: 'createdAt',
          value: params?.createdAt?.toISOString(),
        },
        {
          key: 'requestId',
          value: params?.requestId,
        },
        {
          key: 'path',
          value: params?.path,
        },
        {
          key: 'runTime',
          value: params?.runTime?.toString(),
        },
      ],
    })}`,
    component: LogsPage,
    roles: [Config.AuthRoles.Admin],
  }),
  usersPage: (params) => ({
    path: `/users${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
      ],
    })}`,
    component: UsersPage,
    roles: [Config.AuthRoles.Admin, Config.AuthRoles.Supervisor],
  }),
  studentsPage: (params) => ({
    path: `/students${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
        {
          key: 'level',
          value: params?.level,
        },
      ],
    })}`,
    component: StudentsPage,
    roles: [
      Config.AuthRoles.Admin,
      Config.AuthRoles.Supervisor,
      Config.AuthRoles.Teacher,
    ],
  }),
  confirmAccountPage: ({ email = ':email' } = {}) => ({
    path: `/confirm/${email}`,
    component: ConfirmAccountPage,
    roles: [Config.AuthRoles.Unknown],
  }),
  loginPage: () => ({
    path: '/login',
    component: LoginPage,
    roles: [Config.AuthRoles.Unknown],
  }),

  forgotPasswordPage: () => ({
    path: '/forgot',
    component: ForgotPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  resetPasswordPage: ({ token = ':token' } = {}) => ({
    path: `/reset-password/${token}`,
    component: ResetPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  profilePage: () => ({
    path: `/profile`,
    component: ProfilePage,
    roles: Config.AuthRoles.Registered,
  }),
  confirmSessionPage: () => ({
    path: `/session/confirm`,
    component: ConfirmSessionPage,
    roles: Config.AuthRoles.Registered,
  }),
  sessionPage: () => ({
    path: `/sessions`,
    component: SessionPage,
    roles: [Config.AuthRoles.Admin],
  }),
  trickDetailsPage: ({
    mode = ':mode',
    trickId,
    type,
    ...rest
  } = {}): IPage => ({
    path: `/items/${mode}${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'itemId',
          value: trickId,
        },
        {
          key: 'type',
          value: type,
        },
        {
          key: 'public',
          value: rest.public,
        },
      ],
    })}`,
    component: TrickDetailsPage,
    roles: [
      Config.AuthRoles.Admin,
      Config.AuthRoles.Supervisor,
      Config.AuthRoles.Teacher,
    ],
  }),
  routinesPage: (params): IPage => ({
    path: `/admin/collections${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
        {
          key: 'updatedAt_End',
          value: params?.updatedAt_End?.toISOString(),
        },
        {
          key: 'updatedAt_Start',
          value: params?.updatedAt_Start?.toISOString(),
        },
        {
          key: 'type',
          value: params?.type,
        },
        {
          key: 'status',
          value: params?.status,
        },
        {
          key: 'paymentType',
          value: params?.paymentType,
        },
        {
          key: 'public',
          value: params?.public,
        },
        {
          key: 'tag',
          value: params?.tag?.toString(),
        },
      ],
    })}`,
    component: RoutinesPage,
    roles: [
      Config.AuthRoles.Admin,
      Config.AuthRoles.Teacher,
      Config.AuthRoles.Supervisor,
    ],
  }),
  routineDetailsPage: ({
    mode = ':mode',
    routineId,
    type,
    ...rest
  } = {}): IPage => ({
    path: `/collections/${mode}${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'collectionId',
          value: routineId,
        },
        {
          key: 'type',
          value: type,
        },
        // {
        //   key: 'public',
        //   value: rest.public,
        // },
      ],
    })}`,
    component: RoutineDetailsPage,
    roles: [
      Config.AuthRoles.Admin,
      Config.AuthRoles.Teacher,
      Config.AuthRoles.Supervisor,
    ],
  }),
  paymentsPage: (params) => ({
    path: `/payments${PathUtils.varToStringParams({
      variablesArray: [
        {
          key: 'page',
          value: params?.page?.toString(),
        },
        {
          key: 'pageSize',
          value: params?.pageSize?.toString(),
        },
        {
          key: 'sortField',
          value: params?.sortField,
        },
        {
          key: 'orderType',
          value: params?.orderType,
        },
        {
          key: 'userId',
          value: params?.userId,
        },
        {
          key: 'globalSearch',
          value: params?.globalSearch,
        },
      ],
    })}`,
    component: PaymentsPage,
    roles: [Config.AuthRoles.Admin],
  }),

  deleteAccountPage: ({ app = ':app' } = {}): IPage => ({
    path: `/${app}/delete-account`,
    component: DeleteAccountPage,
    roles: Config.AuthRoles.All,
  }),

  // before last
  mainPage: (params?: any) => ({
    path: `/`,
    component: MainPage,
    roles: Config.AuthRoles.All,
  }),
  // always last
  rootPage: (params?: any): IPage => ({
    path: '*',
    component: RootPage,
    roles: Config.AuthRoles.All,
  }),
};

export const AppbarsConfig: IAppbarsConfig = {
  primaryNav: {
    component: AppbarMenu,
    include: null,
    exclude: [
      NavigationConfig.loginPage().path,
      // NavigationConfig.registerPage().path,
      NavigationConfig.forgotPasswordPage().path,
      NavigationConfig.resetPasswordPage().path,
      NavigationConfig.confirmAccountPage().path,
      NavigationConfig.confirmSessionPage().path,
      NavigationConfig.forceChangePasswordVerifyPage().path,
      NavigationConfig.forceChangePasswordResetPage().path,
      NavigationConfig.supportPage().path,
      NavigationConfig.privacyPolicyPage().path,
      NavigationConfig.termsOfUsePage().path,
      NavigationConfig.mobAdsPage().path,
      NavigationConfig.deleteAccountPage().path,
      NavigationConfig.appLoaderPage().path,
    ],
    path: '/',
    select: 'exclude',
  },
};

export const Appbars: Array<INav> = Object.values(AppbarsConfig);
export const RoutesConfig = Object.values(NavigationConfig).map(
  (navigation) => ({
    ...navigation(),
    exact: true,
  })
);

// console.log('Confiog::::::', RoutesConfig);
export default NavigationConfig;
