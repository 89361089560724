//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const SET_NAMESPACES_LIST = typeCreatorV2(
  'CommonReducer',
  'SetNamespacesList'
); // prpei na to alakso kai auto mallon

export const UPDATE_PORTAL_ACCESS_DETAILS = typeCreatorV2(
  'CommonReducer',
  'SetPortalAccessDetails'
);

export const SET_NAMESPACE_DETAILS = typeCreatorV2(
  'CommonReducer',
  'SetNamespaceDetails'
);

export const SHOW_LOADING_VIEW = typeCreatorV2(
  'CommonReducer',
  'ShowLoadingView'
);

export const RESET: string = typeCreatorV2('CommonReducer', 'Reset');
