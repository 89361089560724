import { FilePathTypes, FileType } from './filesConst';
import { LanguagesEnum } from './languagesConst';
import { SubTypeNameMapping } from './namespaceConst';
import { DurationNamingMap, DurationType } from './statistics';
import { TagLevel, TagSubTypes, TagTypes, TagTypesNameMap } from './tagsConst';
import {
  CollectionTypeEnum,
  ItemGroups,
  ItemTypeEnum,
  ItemTypeNameMapping,
  TrueFalseEnum,
} from './typesConst';

export const ItemTypeOptions = Object.values(ItemTypeEnum).map((el) => ({
  id: el,
  label: ItemTypeNameMapping[el].label,
}));

export const TagTypeOptions = Object.values(TagTypes).map((el) => ({
  id: el,
  label: TagTypesNameMap[el].name,
}));

export const TagSubTypeOptions = (tagType: TagTypes | null) =>
  Object.values(TagSubTypes)
    .map((el) => ({
      id: el,
      label: SubTypeNameMapping[el].label,
    }))
    .filter((el) =>
      tagType === TagTypes.Collection
        ? el.id.startsWith('Collection')
        : tagType === TagTypes.Item
        ? el.id.startsWith('Item')
        : true
    );

export const CollectionTypeOptions = Object.values(CollectionTypeEnum).map(
  (el) => ({
    id: el,
    label: el,
  })
);

export const LevelOptions = Object.values(TagLevel)
  .filter((el) => typeof el === 'number')
  .map((el) => ({
    id: el,
    label: TagLevel[el as unknown as TagLevel],
  }));

export const TrueFalseOptions = Object.values(TrueFalseEnum).map((el) => ({
  id: el,
  label: el,
}));

export const LanguageOptions = Object.values(LanguagesEnum).map((el) => ({
  id: el,
  label: el,
}));

export const FileTypeOptions = Object.values(FileType).map((el) => ({
  id: el as string,
  label: el as string,
}));

export const AttachmentTypeOptions = Object.values(FilePathTypes).map((el) => ({
  id: el as string,
  label: el as string,
}));

export const DurationTypeOptions = Object.values(DurationType).map((el) => ({
  id: el as string,
  label: DurationNamingMap[el],
}));

export const ItemVocabularyCategoriesOptions = Object.values(
  ItemGroups.Vocabulary
).map((el) => ({ id: el as string, label: ItemTypeEnum[el] }));
