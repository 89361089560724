import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from '../styles';
import { useSidebarIsVisible } from '../../../../redux/reducers/gl_navigation_reducer/selectors';
import { useDispatch } from 'react-redux';
import NavigationActions from '../../../../redux/reducers/gl_navigation_reducer/actions';
import LanguageMenu from '../../../../components/app_common/LanguageSelector';
import { Settings } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import {
  Badge,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Button,
  Box,
  Tooltip,
  Hidden,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ProfileConfiguration } from '../configuration';
import { useHistory, useLocation } from 'react-router';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import Environment from 'config/environmentConfig';
import SelectButton from 'components/molecules/SelectButton';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import * as NavigationSelectors from 'redux/reducers/gl_navigation_reducer/selectors';
import { getNavbarConfig, useSideBarConfig } from 'navigation/config';
import * as Paths from 'utils/path';
import Configuration from '../configuration';
import FormattedMenu from 'components/atoms/FormattedMenu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslatedText from 'components/atoms/TranslatedText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AuthActions from 'redux/reducers/gl_auth_reducer/actions';
import NavigationConfig from 'config/navigationConfig';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DarkmodeButton from 'components/app_common/DarkModeButton';
import { NavbarTypeEnum } from 'constants/navigationConst';
import ThemeAction from 'redux/reducers/gl_theme_reducer/actions';
import { useNamespaceDetails } from 'redux/reducers/gl_common_reducer/selectors';
import useTextSelector from 'hooks/useTextSelector';
import { AppTypes } from 'constants/namespaceConst';

const menuLanId = 'primary-lan-account-menu';
const menuId = 'primary-search-account-menu';
const mobileMenuId = 'primary-search-account-menu-mobile';

const AppbarMenu = (props: any) => {
  console.log('Render AppbarMenu');

  const classes = useStyles({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lanAnchorEl, setLanAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanMenuOpen = Boolean(lanAnchorEl);
  const navbarType = NavigationSelectors.useNavigationType();
  const namespaceDetails = useNamespaceDetails();
  const { textSelector } = useTextSelector();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLanAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLanMenuClose = () => {
    setLanAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderLanguageMenu = (
    <LanguageMenu
      handleLanMenuClose={handleLanMenuClose}
      isLanMenuOpen={isLanMenuOpen}
      menuLanId={menuLanId}
      lanAnchorEl={lanAnchorEl}
    />
  );

  const renderProfileMenu = (
    <FormattedMenu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      elevation={1}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {ProfileConfiguration.map((props, index: number) => (
        <MenuItem
          style={{ marginLeft: props.submenu ? 5 : 0 }}
          key={index}
          onClick={() => {
            props.action(dispatch, history);
            handleMenuClose();
          }}
        >
          {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
          <Typography variant="inherit">{props.label}</Typography>
        </MenuItem>
      ))}
    </FormattedMenu>
  );

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar>
        <OpenSideBarIconButton />
        <div
          onClick={() => history.push(NavigationConfig.mainPage().path)}
          className={classes.titleContainer}
        >
          <Typography className={classes.title} variant="h6" noWrap>
            {namespaceDetails.data?.name
              ? `${namespaceDetails.data?.name} ${textSelector('Workspace', {
                  [AppTypes.LanguageLearn]: 'Class',
                })}`
              : Environment.APP_NAME}
          </Typography>
        </div>

        {Configuration.search.enable && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        )}
        <div className={classes.grow}>
          {navbarType === NavbarTypeEnum.TOP_MENU && <ButtonsGroup />}
        </div>
        <div>
          <Button
            aria-controls={menuLanId}
            onClick={handleLanguageMenuOpen}
            color="inherit"
          >
            <LanguageIcon />
            <ExpandMoreIcon />
          </Button>
        </div>
        <div>
          <Tooltip title={<TranslatedText defaultText="Settings" />}>
            <IconButton
              color="inherit"
              onClick={() =>
                dispatch(ThemeAction.setThemeSettingsPanelOpenAction(true))
              }
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <Settings />
            </IconButton>
          </Tooltip>
        </div>
        {/* <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div> */}

        <div>
          <Tooltip title={<TranslatedText defaultText="Profile" />}>
            <IconButton
              color="inherit"
              onClick={() => history.push(NavigationConfig.profilePage().path)}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div>
          <Tooltip title={<TranslatedText defaultText="Logout" />}>
            <IconButton
              color="inherit"
              onClick={() => {
                dispatch(AuthActions.logoutAction(history));
              }}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
      {/* {renderMobileMenu} */}
      {renderLanguageMenu}
      {renderProfileMenu}
    </AppBar>
  );
};

/**
 * Mobile Devices: Visible always
 * Desktop: Visible only when sidebar is enable
 *
 */
const OpenSideBarIconButton = React.memo(() => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const navbarType = NavigationSelectors.useNavigationType();

  const ButtonIcon = React.useMemo(
    () => (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={() =>
          dispatch(
            NavigationActions.setSideMenuVisiblityAction(
              !NavigationSelectors.sideBarIsVisible()
            )
          )
        }
        edge="start"
        className={clsx(classes.menuButton)}
      >
        <MenuIcon />
      </IconButton>
    ),
    []
  );

  return (
    <React.Fragment>
      {/* visible in desktop */}
      {/* {Configuration.sidebar.enable && <Hidden smDown>{ButtonIcon}</Hidden>} */}
      {(navbarType === NavbarTypeEnum.SIDE_MENU ||
        navbarType === NavbarTypeEnum.MINI_SIDE_MENU) && (
        <Hidden smDown>{ButtonIcon}</Hidden>
      )}
      {/* visible in mobile device */}
      <Hidden mdUp>{ButtonIcon}</Hidden>
    </React.Fragment>
  );
});

const ButtonsGroup = React.memo(() => {
  // const roles = AuthSelectors.useSelectUserRoles();
  const config = useSideBarConfig();
  // getNavbarConfig(roles);
  const classes = useStyles({});
  const history = useHistory();

  return (
    <Box className={classes.sectionDesktop}>
      {config.map(({ title, subItems, Icon, path, id }) => (
        <SelectButton
          key={id}
          buttonProps={{
            color: 'inherit',
            style: {
              marginRight: 5,
              // fontSize: '14px',
              // fontStyle: 'normal',
              // fontWeight: 'normal',
              // fontFamily: 'Roboto',
              // lineHeight: '140.2%',
              // textTransform: 'capitalize',
            },
            variant: 'text',
          }}
          action={() => path && history.push(path)}
          children={
            <TextWrapper
              enableHidden
              Iconstart={Icon}
              text={title}
              path={path}
            />
          }
          items={(subItems || []).map(({ title, path }, index) => ({
            id: index.toString(),
            action: () => history.push(path),
            children: <TextWrapper key={index} text={title} path={path} />,
          }))}
        />
      ))}
    </Box>
  );
});

interface ITextWrapperProps {
  text: any;
  Iconstart?: React.FC | undefined;
  IconEnd?: React.FC | undefined;
  path: string | null | undefined;
  enableHidden?: boolean;
}

const TextWrapper = React.memo((props: ITextWrapperProps) => {
  const classes = useStyles({ enableHidden: props.enableHidden });
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = props.path
    ? Paths.pathsIsTheSame(currentPath, props.path)
    : false;
  let textWrapperClasses = clsx({
    [classes.menuItemSelected]: isSelected,
  });
  return (
    <Box
      className={textWrapperClasses}
      display="flex"
      // justifyContent="center"
      // alignItems="center"
    >
      {props.Iconstart && <props.Iconstart />}
      {/* {!props.Iconstart && (
        <span className={classes.buttonText}>{props.text}</span>
      )} */}
      <span className={classes.buttonText}>{props.text}</span>
      {props.IconEnd && <props.IconEnd />}
    </Box>
  );
});

// const BrightnessButton = React.memo(() => {
//   const themeMode = ThemeSelectors.useSelectTheme();
//   const dispatch = useDispatch();

//   return (
//     <IconButton
//       onClick={() =>
//         dispatch(
//           ThemeActions.changeThemeAction(
//             themeMode === ThemesModeEnum.Light
//               ? ThemesModeEnum.Dark
//               : ThemesModeEnum.Light
//           )
//         )
//       }
//       color="inherit"
//     >
//       {themeMode === ThemesModeEnum.Light ? (
//         <BrightnessMediumOutlinedIcon />
//       ) : (
//         <Brightness7Icon />
//       )}
//     </IconButton>
//   );
// });

export default AppbarMenu;
