import { colors } from '@material-ui/core';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { getFileDetails, openFileToNewTab } from 'services/api/file';
import Environment from 'config/environmentConfig';
import _ from 'lodash';
// import { useFormContext as useFormContextBasic } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

interface IImageContainerProps {
  fieldId: string;
  type: 'file' | 'img' | 'audio';
}

function FormFileWrapper({ fieldId, type }: IImageContainerProps) {
  const { getValues, control } = useFormContext<any>();

  return (
    <BaseImageWrapper
      control={control}
      fieldId={fieldId}
      getValues={getValues}
      type={type}
    />
  );
}

interface IBaseImageWrapperProps {
  control: any;
  getValues: () => any;
  fieldId: string;
  type: IImageContainerProps['type'];
}

export const BaseImageWrapper = ({
  control,
  getValues,
  fieldId,
  type,
}: IBaseImageWrapperProps) => {
  const imageId = useWatch({
    control,
    name: fieldId,
  }) as any;
  const [fileUrl, setFileUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    (async () => {
      if (imageId) {
        const details = await getFileDetails(imageId);
        setFileUrl(`${Environment.API_BASE_URL}${details.data.path}`);
      } else {
        setFileUrl(null);
      }
    })();
  }, [imageId]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: colors.grey['100'],
        cursor: 'pointer',
      }}
      onClick={async () => {
        fileUrl && window.open(fileUrl, '_blank');
      }}
    >
      {fileUrl && type === 'img' ? (
        <img
          src={fileUrl}
          style={{ width: 'auto', height: 'auto', maxHeight: 200, padding: 10 }}
        />
      ) : fileUrl && type === 'file' ? (
        <img
          src={require('assets/images/icons/uploaded-file.png')}
          style={{ width: 'auto', height: 'auto', maxHeight: 150, padding: 20 }}
        />
      ) : fileUrl && type === 'audio' ? (
        <img
          src={require('assets/images/icons/audio-file.png')}
          style={{ width: 'auto', height: 'auto', maxHeight: 150, padding: 20 }}
        />
      ) : undefined}
    </div>
  );
};

export default FormFileWrapper;
