import { Box, Button, Checkbox, IconButton, useTheme } from '@material-ui/core';
import TranslatedText from 'components/atoms/TranslatedText';
import BaseModal from 'components/molecules/BaseModal';
import React from 'react';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import TrickList from './TricksTabsList';
import * as RoutineDetailsSelectors from '../../store/selectors';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useFieldArray, useForm } from 'react-hook-form';
import { IFormData } from '../../configuration';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import { IState } from '../../store/reducer';
import FilterListIcon from '@material-ui/icons/FilterList';
import OptionsMenu from 'components/atoms/OptionsMenu';
import RoutineDetailsActions from '../../store/actions';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';
import { CollectionTypeEnum, ItemTypeEnum } from 'constants/typesConst';

interface ITricksListModalProps {
  setValue: ReturnType<typeof useForm>['setValue'];
}

export interface TricksListModalParams {
  append: ReturnType<typeof useFieldArray>['append'];
  tricks: IFormData['items'];
  onItemAdded?: () => void;
  collectionType: CollectionTypeEnum;
}

const TricksListModal = (props: ITricksListModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_TRICKS_LIST}
      showExitIcon={true}
      contentProps={{
        style: { padding: 0, margin: 0 },
      }}
      // classes={{ paper: classes.dialogPaper }}
      modalProps={{
        fullWidth: true,
        classes: { paper: !matches ? classes.dialogPaper : undefined },
        fullScreen: matches,
      }}
      renderHeader={(params) => <Header />}
      renderContent={(params: TricksListModalParams) => {
        return <TrickList collectionType={params.collectionType} />;
      }}
      renderFooter={(params: TricksListModalParams) => (
        <SubmitForm {...params} />
      )}
    />
  );
};

const Header = () => {
  const tagsData = RoutineDetailsSelectors.useItemTags();
  const dispatch = useDispatch();
  const tagsArray = RoutineDetailsSelectors.useSelectedTags();
  const tagsSet = React.useMemo(() => {
    const set = {};
    (tagsArray || []).forEach((el) => _.set(set, el, null));
    return set;
  }, [tagsArray]);

  return (
    <Box alignItems="center" display="flex">
      <OptionsMenu
        id="filteringOptions"
        closeOnClick={false}
        options={
          tagsData.data?.items?.map((el) => ({
            id: el.name,
            onClick: () => {
              const currentTags = tagsArray ?? [];

              const index = currentTags.indexOf(el.name);
              if (index > -1) {
                // remove
                currentTags.splice(index, 1);
              } else {
                currentTags.push(el.name);
              }

              dispatch(
                RoutineDetailsActions.setSelectedTagsAction([...currentTags])
              );
            },
            label: (
              <Box display={'flex'} alignItems="center">
                <Checkbox
                  checked={el.name in tagsSet}
                  disableRipple
                  style={{ marginRight: '1rem' }}
                />
                {el.description}
              </Box>
            ),
          })) ?? []
        }
      >
        <IconButton style={{ marginRight: '1rem' }}>
          <FilterListIcon />
        </IconButton>
      </OptionsMenu>
      <TranslatedText defaultText={`Items`} />
    </Box>
  );
};

const SubmitForm = (params: TricksListModalParams) => {
  const dispatch = useDispatch();

  return (
    <>
      <Button
        onClick={() => {
          dispatch(
            ModalActions.closeModalAction({
              modalID: ModalIDs.MODAL_TRICKS_LIST,
            })
          );
          const itemsMap =
            ((getState() as any)[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState)
              .selectedItemsMap ?? {};

          const elements = Object.values(itemsMap);
          params.append(elements);
          params.onItemAdded && params.onItemAdded();
        }}
        color="primary"
        variant="contained"
      >
        <TranslatedText defaultText="Add" />
      </Button>
    </>
  );
};

export default React.memo(TricksListModal);
