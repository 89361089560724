import { Dispatch } from 'react';
import { combineReducers } from 'redux';

export interface IReduxType {
  Pending: string;
  Success: string;
  Failed: string;
}

export enum StateStatus {
  Success = 'Success',
  Pending = 'Pending',
  Failed = 'Failed',
}

export interface IAction {
  type: string;
  status?: StateStatus;
  data: any;
}

export const typeCreator = (
  reducerName: string,
  label: string
): IReduxType => ({
  Pending: `[${reducerName}][${label}][Pending]`,
  Success: `[${reducerName}][${label}][Success]`,
  Failed: `[${reducerName}][${label}][Failed]`,
});

export const typeCreatorV2 = (reducerName: string, label: string): string =>
  `[${reducerName}][${label}]`;

export interface IActionMethods {
  onSuccess(result?: any): IAction;
  onFailed(result?: any): IAction;
  onPending(result?: any): IAction;
  action(
    data?: any
  ): ((dispatch: Dispatch<any>, getState?: () => any) => void) | IAction;
}

export const KEEP_THE_SAME = '__KEEP_THE_SAME__';

// export const combineLazyReducers = (reducers: any, initialState: any) => {
//   initialState = initialState || {};
//   let handler = {
//     ownKeys(target: any) {
//       return Array.from(
//         new Set([...Reflect.ownKeys(target), ...Reflect.ownKeys(initialState)])
//       );
//     },
//     get(target: any, key: any) {
//       return (
//         target[key] || ((state: any) => (state === undefined ? null : state))
//       );
//     },
//     getOwnPropertyDescriptor(target: any, key: any) {
//       return (
//         Reflect.getOwnPropertyDescriptor(target, key) ||
//         Reflect.getOwnPropertyDescriptor(initialState, key)
//       );
//     },
//   };

//   return combineReducers(new Proxy(reducers, handler));
// };

// export const combineLazyReducers = (reducers: any, initialState: any) => {
//   let reducer: any = combineReducers(reducers);
//   return (state: any, _action: any) => ({
//     ...initialState,
//     ...reducer(state, _action),
//   });
// };
