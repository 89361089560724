import withAuth from 'middleware/withAuth';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import CommonActions from 'redux/reducers/gl_common_reducer/actions';

const CommonProvider = () => {
  const dispatch = useDispatch();
  const userData = useSelectUserData();

  React.useEffect(() => {
    dispatch(CommonActions.fetchNamespacesListAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (userData.accessPortal?.selectedNamespace) {
      dispatch(
        CommonActions.fetchNamespaceDetailsAction({
          id: userData.accessPortal?.selectedNamespace,
        })
      );
    }
  }, [dispatch, userData]);

  return <></>;
};

export default withAuth(CommonProvider);
