import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import { useDispatch } from 'react-redux';
import ThemeActions from 'redux/reducers/gl_theme_reducer/actions';
import {
  Box,
  Card,
  colors,
  Divider,
  Typography,
  useTheme,
} from '@material-ui/core';
import ThemeSelector from './components/themeSelector';
import ThemeMode from './components/themeMode';
import NavType from './components/navType';
import CardLabelTitle from '../CardLabelTitle';
import TranslatedText from 'components/atoms/TranslatedText';
import { alpha } from '@material-ui/core/styles';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const anchor: Anchor = 'right';

const ThemeSettings = () => {
  const open = ThemeSelectors.useThemeSettingsPanelOpen();
  const dispatch = useDispatch();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      dispatch(ThemeActions.setThemeSettingsPanelOpenAction(open));
    };

  return (
    <Drawer
      PaperProps={{}}
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <Box boxShadow={1}>
        <Typography
          style={{
            fontWeight: 'bold',
            padding: '1rem .75rem',
          }}
          variant="h5"
        >
          <TranslatedText defaultText="Theme Settings" />
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: 350,
          maxWidth: '100%',
          padding: '0 .75rem',
        }}
      >
        <RowContent>
          <Header title="Theme" />
          <ThemeMode />
        </RowContent>

        <RowContent>
          <Header title="Palette" />
          <ThemeSelector />
        </RowContent>

        <RowContent>
          <Header title="Navigation" />
          <NavType />
        </RowContent>
      </Box>
    </Drawer>
  );
};

interface HeaderProps {
  title: React.ReactNode;
}

interface RowContentProps {
  children: React.ReactNode;
}

// https://material-ui.com/customization/components/

const RowContent = ({ children }: RowContentProps) => {
  return (
    <Card
      raised
      elevation={3}
      style={{ marginTop: '1.2rem', padding: '.75rem 1rem' }}
    >
      {children}
    </Card>
  );
};

const Header = (props: HeaderProps) => {
  const theme = useTheme();

  return (
    <Box width="100%">
      <Typography
        style={{
          fontSize: '1.1rem',
          fontWeight: 600,
          paddingBottom: '.5rem',
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

export default React.memo(ThemeSettings);
