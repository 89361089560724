import React from 'react';
import {
  Button,
  ButtonProps,
  Box,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import {
  AppGroupText,
  AppMultipleSelect,
  AppSelect,
  AppDatePicker,
  AppTextField,
  AppRadioGroup,
  AppCommon,
} from 'components/molecules/FormControls';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import GridLayout from 'components/molecules/GridLayout';
import _ from 'lodash';
import TranslatedText from 'components/atoms/TranslatedText';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { IFormData } from '../../types';
import DataList from 'components/molecules/DataList';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { ItemTypeEnum } from 'constants/typesConst';

interface DefaultDataWrapperProps {
  control: any;
  errors: ReturnType<typeof useForm>['formState']['errors'];
  fieldName: string;
  setValue: ReturnType<typeof useForm>['setValue'];
  removeButtonEnabled: boolean;
  addButtonEnabled: boolean;
  itemType: ItemTypeEnum;
  lineNumbers?: number;
}

export const DefaultData = { example: '' };

const SortableListContainer = SortableContainer(
  ({
    errors,
    control,
    fieldName,
    arrayFields,
    removeButtonEnabled,
    addButtonEnabled,
    itemType,
    lineNumbers,
  }: DefaultDataWrapperProps & {
    arrayFields: ReturnType<typeof useFieldArray>;
  }) => {
    const { fields, append, prepend, remove, swap, move, insert } = arrayFields;

    return (
      <React.Fragment>
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          {fields.map((element, index) => (
            <SortableItem
              remove={remove}
              lineNumbers={lineNumbers}
              key={element.id ?? index}
              control={control}
              element={element as any}
              _index={index}
              index={index}
              errors={errors}
              fieldName={fieldName}
              removeButtonEnabled={removeButtonEnabled}
              addButtonEnabled={addButtonEnabled}
              itemType={itemType}
            />
          ))}
        </div>
        {/* <DataList
          data={fields ?? []}
          renderElement={(element, index) => {
            return (
              <SortableItem
                remove={remove}
                key={element.id ?? index}
                control={control}
                element={element as any}
                _index={index}
                index={index}
                errors={errors}
                fieldName={fieldName}
                removeButtonEnabled={removeButtonEnabled}
                addButtonEnabled={addButtonEnabled}
                itemType={itemType}
              />
            );
          }}
        /> */}
        {addButtonEnabled && (
          <Button
            onClick={() => {
              append({
                ...DefaultData,
              });
            }}
            style={{ textTransform: 'none', marginTop: '1rem' }}
            variant="text"
            color="secondary"
          >
            <TranslatedText defaultText="+ Add new" />
          </Button>
        )}
      </React.Fragment>
    );
  }
);

interface ISortableItemProps {
  errors: ReturnType<typeof useForm>['errors'];
  control: Control<any>;
  element: IFormData['examples'][0];
  remove: ReturnType<typeof useFieldArray>['remove'];
  _index: number;
  fieldName: string;
  removeButtonEnabled: boolean;
  addButtonEnabled: boolean;
  itemType: ItemTypeEnum;
  index: number;
  lineNumbers?: number;
}

const SortableItem = SortableElement(
  ({
    element,
    errors,
    remove,
    _index,
    fieldName,
    control,
    removeButtonEnabled,
    itemType,
    index,
    lineNumbers,
  }: ISortableItemProps) => {
    const dispatch = useDispatch();

    return (
      // key is important
      <Box
        key={index}
        // display="flex"
        // alignItems="center"
        style={{ marginTop: '1rem' }}
        // flex={1}
      >
        <AppTextField
          variant="outlined"
          rows={lineNumbers}
          multiline={(lineNumbers ?? 0) > 1}
          margin="none"
          placeholder={
            itemType === ItemTypeEnum.Word ? 'Add example..' : 'Add Choice..'
          }
          fullWidth
          id={`${fieldName}[${_index}].example`}
          name={`${fieldName}[${_index}].example`}
          autoComplete={`${fieldName}[${_index}].example`}
          control={control}
          error={Boolean(_.get(errors, `${fieldName}[${_index}].example`))}
          helperText={_.get(errors, `${fieldName}[${_index}].example.message`)}
          rules={{
            required: <AppCommon.RequiredField />,
          }}
          controllerExtras={{
            defaultValue: (element as any).example,
          }}
          icon={{
            ComponentRight: (
              <>
                {removeButtonEnabled && (
                  <IconButton
                    style={{
                      color: 'red',
                      padding: 0,
                      margin: 0,
                    }}
                    onClick={() => remove(_index)}
                  >
                    <RemoveCircleOutlineOutlinedIcon
                      style={{ cursor: 'pointer', zIndex: 400 }}
                    />
                  </IconButton>
                )}
              </>
            ),
            ComponentLeft: (
              <div style={{ cursor: 'move' }}>
                <DragIndicatorIcon />
              </div>
            ),
          }}
        />
        {/* <GridLayout
          spacing={0}
          elements={[
            {
              element: (
                <AppTextField
                  variant="outlined"
                  margin="none"
                  placeholder={
                    itemType === ItemTypeEnum.Word
                      ? 'Add example..'
                      : 'Add Choice..'
                  }
                  fullWidth
                  id={`${fieldName}[${_index}].example`}
                  name={`${fieldName}[${_index}].example`}
                  autoComplete={`${fieldName}[${_index}].example`}
                  control={control}
                  error={Boolean(
                    _.get(errors, `${fieldName}[${_index}].example`)
                  )}
                  helperText={_.get(
                    errors,
                    `${fieldName}[${_index}].example.message`
                  )}
                  rules={{
                    required: <AppCommon.RequiredField />,
                  }}
                  controllerExtras={{
                    defaultValue: (element as any).example,
                  }}
                  icon={{
                    ComponentRight: (
                      <>
                        {removeButtonEnabled && (
                          <IconButton
                            style={{
                              color: 'red',
                              padding: 0,
                              margin: 0,
                            }}
                            onClick={() => remove(_index)}
                          >
                            <RemoveCircleOutlineOutlinedIcon
                              style={{ cursor: 'pointer', zIndex: 400 }}
                            />
                          </IconButton>
                        )}
                      </>
                    ),
                    ComponentLeft: (
                      <IconButton size="small">
                        <DragIndicatorIcon />
                      </IconButton>
                    ),
                  }}
                />
              ),
              id: 'example',
              size: 12,
            },
          ]}
        /> */}
      </Box>
    );
  }
);

const FieldWrapper = (props: DefaultDataWrapperProps) => {
  const arrayFields = useFieldArray({
    control: props.control, // control props comes from useForm (optional: if you are using FormContext)
    name: props.fieldName, // unique name for your Field Array
    // keyName: 'arrayId', //default to "id", you can change the key name
  });

  return (
    <SortableListContainer
      {...props}
      distance={5}
      arrayFields={arrayFields}
      onSortEnd={({ oldIndex, newIndex }) => {
        props.setValue(
          props.fieldName,
          arrayMove(arrayFields.fields, oldIndex, newIndex)
        );
      }}
    />
  );
};

export default FieldWrapper;
