import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { showToastAction } from '../ToastMessage';
import { copyToClipboard } from 'utils/window';

interface ButtonCopyProps extends ButtonProps {
  text: string;
}

const ButtonCopy: React.FC<ButtonCopyProps> = ({ text, ...props }) => {
  const handleClick = async () => {
    showToastAction('Copy text successfully', 'info', {
      position: 'bottom-left',
    });
    // navigator.clipboard.writeText(text);
    copyToClipboard(text);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<FileCopyIcon />}
      onClick={handleClick}
      {...props}
    >
      {props.children ?? 'Copy'}
    </Button>
  );
};

export default ButtonCopy;
