import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  element: {
    padding: '.75rem',
    marginTop: '.25rem',
    marginBottom: '.25rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  selected: {
    background: theme.palette.action.selected,
  },
}));

export default useStyles;
