import React from 'react';
import Datatable from 'components/molecules/Datatable';
import { useDispatch } from 'react-redux';
import { Box, useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import * as DiscoverImagesSelectors from '../../store/selectors';
import DiscoverImagesActions from '../../store/actions';
import SimpleListElement from 'components/atoms/SimpleListElement';
import TableActions, { OptionsButton } from '../TableAction';
import * as Configuration from '../../configuration';
import { IModalParams } from '../../types';
import { viewFileUrl } from 'services/api/file';
import { formatDate } from 'utils/dates';
import ColumnName from '../ColumnName';
import { formatBytes } from 'utils/sizes';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import { IState } from '../../store/reducer';
import { StateStatus } from 'redux/utils/common';
import { FileType } from 'constants/filesConst';
import { cleanObject } from 'utils/objects';
import { openUrlToNewTab } from 'utils/window';

const Content = (props: IModalParams) => {
  const result = DiscoverImagesSelectors.useImagesList();
  const dispatch = useDispatch();

  const paging = {
    page: result.data?.paging?.page || 0,
    pageSize: result.data?.paging?.pageSize || 25,
    total: result.data?.paging?.total || 0,
  };

  return (
    <Datatable
      paging={paging}
      data={result.data?.items || []}
      disablePaper
      filtering={{
        searchFields: true,
        globalSearchPlaceholder: 'Search by name..',
        globalSearchDefaultValue: props.initialSearch,
      }}
      renderItemInSmallDevices={(data, index) => {
        return (
          <SimpleListElement
            key={index}
            title={<ColumnName name={data.name ?? null} />}
            actionLeftButton={
              <img
                style={{ marginRight: '1rem', cursor: 'pointer' }}
                height={80}
                width={80}
                src={data.imageUrl}
                onClick={() => {
                  openUrlToNewTab(data.imageUrl);
                }}
              />
            }
            actionRightButton={<TableActions small {...props} params={data} />}
            // subTitle={
            //   <div
            //     style={{
            //       display: 'flex',
            //       flex: 1,
            //       justifyContent: 'space-between',
            //       marginRight: '0.5rem',
            //       marginTop: '0.8rem',
            //       alignItems: 'center',
            //     }}
            //   >
            //     <Chip
            //       size="small"
            //       variant="outlined"
            //       label={<div>{formatBytes(data.size ?? 0)}</div>}
            //     />

            //     <div>{formatDate(data.createdAt)}</div>
            //   </div>
            // }
          />
        );
      }}
      // minHeight={'100vh'}
      // height={'100vh'}
      paperProps={{
        style: {
          borderRadius: 2,
          //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
          overflow: 'hidden',
        },
        // square: false,
        variant: 'outlined',
      }}
      tableCellProps={() => ({
        style: {
          //   paddingTop: 0,
          //   paddingBottom: 0,
          borderLeft: 'none',
          borderRight: 'none',
        },
      })}
      tableCellMainRowProps={() => ({
        style: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
        },
      })}
      loading={
        result.status === StateStatus.Pending &&
        (result.data?.items.length ?? 0) === 0
      }
      columns={Configuration.TableColumns(props)}
      handleDataChange={(
        selectedPage,
        pageSize,
        previousOrder,
        _sortField,
        newOrder,
        filteringData
      ) => {
        // console.log('INNNNNNNNNNNNNN2', filteringData);
        const currentState = (getState() as any)[
          ReducerKeys.DISCOVER_IMAGES_REDUCER
        ] as IState;
        if (currentState.imagesList.status !== StateStatus.Pending) {
          dispatch(
            DiscoverImagesActions.fetchImagesFromExternalAction({
              data: cleanObject({
                page: selectedPage,
                pageSize,
                type: filteringData.type ?? 'pexels',
                topic: filteringData.__globalValue,
              }) as any,
              onSuccess: () => {},
            })
          );
        }
      }}
    />
  );
};

export default React.memo(Content);
