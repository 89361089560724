import React from 'react';
import Datatable from 'components/molecules/Datatable';
import { useDispatch } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import * as CollectionSelectors from '../../store/selectors';
import * as Common from '../../common';
import SimpleListElement from 'components/atoms/SimpleListElement';
import { OptionsButton } from '../TableAction';
import NavigationConfig from 'config/navigationConfig';
import { cleanObject } from 'utils/objects';
import { useHistory } from 'react-router';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'constants/authRolesConst';
import * as DateUtils from 'utils/dates';
import LaunchIcon from '@material-ui/icons/Launch';
import TableActions from '../TableAction';
import Vector from 'components/atoms/Vector';
import { viewFileUrl } from 'services/api/file';
import { colors } from '@material-ui/core';
import useTextSelector from 'hooks/useTextSelector';
import { AppTypes } from 'constants/namespaceConst';
import { useContextProps } from '../../provider';
import { useNamespaceDetails } from 'redux/reducers/gl_common_reducer/selectors';

const CollectionsDatable = () => {
  const result = CollectionSelectors.useCollectionsList();
  const dispatch = useDispatch();
  const params = Common.useParams();
  const sortField = React.useRef<string>(params.sortField);
  const props = useContextProps();
  const sortType = React.useRef<'asc' | 'desc'>(params.orderType);
  const updateState = Common.useUpdateState();
  const columns = Common.useTableColumns();
  const { textSelector } = useTextSelector();
  const namespaceDetails = useNamespaceDetails();

  const paging = {
    page: params.page ?? Common.DefaultPaging.page,
    pageSize: params.pageSize ?? Common.DefaultPaging.pageSize,
    total: result.data?.paging.total || 0,
  };

  return (
    <Datatable
      paging={paging}
      data={result.data?.items || []}
      sorting={{
        sortField: sortField.current,
        order: sortType.current,
      }}
      // filtering={{
      //   globalSearchPlaceholder: textSelector('Search collections..', {
      //     [AppTypes.LanguageLearn]: 'Search students...',
      //   }),
      //   // globalSearchDefaultValue: params.globalSearch,
      //   searchFields: true,
      // }}
      title={textSelector('Manage Ratings', {
        [AppTypes.LanguageLearn]: 'Manage Ratings',
      })}
      disablePaper
      renderItemInSmallDevices={(data, index) => {
        return (
          <SimpleListElement
            title={`${data.user.email} (${data.user.firstName} ${data.user.lastName})`}
            subTitle={
              <Box
                display={'flex'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: 10,
                  paddingRight: '0.7rem',
                }}
                alignItems="center"
              >
                <span>
                  <Chip
                    style={{
                      marginRight: '0.2rem',
                      background:
                        (data.process ?? 0) < 50
                          ? colors['red']['600']
                          : (data.process ?? 0) < 70
                          ? colors['orange']['600']
                          : colors['green']['600'],
                      color: 'white',
                    }}
                    label={`${data.process ?? 0}%`}
                    size="small"
                  />
                </span>
              </Box>
            }
            actionRightButton={<TableActions params={data} />}
            actionLeftButton={
              <div
                style={{
                  marginRight: '0.8rem',
                }}
              >
                {namespaceDetails.data?._id &&
                data?.user.settings.namespaceSettings?.[
                  namespaceDetails.data._id as string
                ]?.image ? (
                  <img
                    style={{ width: 60, height: 60, borderRadius: 10 }}
                    src={viewFileUrl(
                      data?.user.settings.namespaceSettings?.[
                        namespaceDetails.data._id as string
                      ]?.image!
                    )}
                  />
                ) : (
                  <Vector
                    style={{
                      fontWeight: 400,
                      color: colors.grey['800'],
                    }}
                    fontSize={55}
                    code={59555}
                  />
                )}
              </div>
            }
          />
        );
      }}
      minHeight={'50vh'}
      paperProps={{
        style: {
          borderRadius: 2,
          //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
          overflow: 'hidden',
        },
        // square: false,
        variant: 'outlined',
      }}
      tableCellProps={() => ({
        style: {
          //   paddingTop: 0,
          //   paddingBottom: 0,
          borderLeft: 'none',
          borderRight: 'none',
        },
      })}
      tableCellMainRowProps={() => ({
        style: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
          //   backgroundColor: Colors.APP_COLOR_DARK({
          //     darkMode: theme.palette.type === 'dark',
          //   }),
          //   color: 'white',
        },
      })}
      loading={false}
      columns={columns}
      resetFilteringAction={() => {
        updateState &&
          updateState({
            collectionId: params.collectionId,
            title: params.title,
          });
      }}
      handleDataChange={(
        selectedPage,
        pageSize,
        previousOrder,
        _sortField,
        newOrder,
        filteringData
      ) => {
        // 'asc' | 'desc'
        sortType.current = newOrder;
        sortField.current = _sortField;

        updateState &&
          updateState({
            ...props,
            ...cleanObject({
              page: selectedPage,
              pageSize,
              sortField: _sortField,
              orderType: previousOrder,
            }),
          });
      }}
    />
  );
};

export default React.memo(CollectionsDatable);
