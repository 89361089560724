import {
  Box,
  Button,
  ButtonGroup,
  colors,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { IState } from '../../store/reducer';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { withVerification } from 'components/molecules/ModalVerifyAction';
import UploadedFileActions from '../../store/actions';
import { DefaultPaging } from '../../configuration';
import { IModalParams } from '../../types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'constants/authRolesConst';
import { useContextProps } from '../../provider';

interface ITableActionsProps extends IModalParams {
  params: NonNullable<IState['filesList']['data']>['items'][0];
  small?: boolean;
}

const TableActions = React.memo((props: ITableActionsProps) => {
  return (
    <ButtonGroup size="small">
      <OptionsButton {...props} />
    </ButtonGroup>
  );
});

export const OptionsButton = ({
  params,
  selectItem,
  handleSelectedFile,
  small,
}: ITableActionsProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const roles = useSelectUserRoles();
  const { refetch } = useContextProps();

  const isAdmin = React.useMemo(() => roles.includes(Roles.Admin), [roles]);

  return (
    <>
      {selectItem ? (
        <Tooltip title="Add File">
          <IconButton
            size={small ? 'small' : 'medium'}
            color="primary"
            onClick={() => {
              handleSelectedFile(params._id);
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {isAdmin ? (
            <Tooltip title="Delete File">
              <IconButton
                size={small ? 'small' : 'medium'}
                style={{ color: colors.red['400'] }}
                onClick={() => {
                  withVerification({
                    action1Event: () => {
                      dispatch(
                        UploadedFileActions.removeFileAction({
                          fileId: params._id,
                          onSuccess: () => {
                            // dispatch(
                            //   UploadedFileActions.fetchFilesAction({
                            //     payload: contextParams,
                            //     onSuccess: () => {},
                            //   })
                            // );
                            refetch && refetch();
                          },
                        })
                      );
                    },
                  });
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default TableActions;
