import Environment from 'config/environmentConfig';
import request from '../../../utils/request';
import axios from 'axios';
import * as Types from '../types';
import store from '../../../redux/store';
import { showToastAction } from '../../../components/atoms/ToastMessage';
import _ from 'lodash';
import * as ClientConfig from 'constants/clientConst';
import { Roles as RolesEnum } from 'constants/authRolesConst';
import Configuration from 'utils/request/configuration';
import { loadFromLocalStorage } from 'redux/utils/storage';

// https://react-redux.js.org/using-react-redux/usage-with-typescript redux and store

/** authenticateUser Endpoint */
interface IAuthenticateInput {
  username: string;
  password: string;
}

export interface IAuthData {
  roles: Array<RolesEnum>;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  userid: string;
  twoStepVerification: boolean;
  sessionIsVerified: boolean;
  hasPassword: boolean;
  settings?: Types.IUserSettings;
  accessPortal: {
    // accessPortalSelectedNamespace?: string;
    selectedNamespace: string | null;
    assignNamespaces: string[];
  } | null;
}

export interface IAuthenticateData extends IAuthData {
  accessToken: string;
  refreshToken: string;
}

interface IAuthenticateOutput extends Types.ResponseOutput {
  data: IAuthenticateData;
}

export const authenticateUser = async (
  data: IAuthenticateInput
): Promise<IAuthenticateOutput> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/login/${Environment.CLIENT_TYPE}`,
    {
      body: {
        username: data.username,
        password: data.password,
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return result.data;
};

/** registerUser Endpoint */
export interface IForgotPasswordInput {
  email: string;
}
interface IForgotPasswordOutput extends Types.ResponseOutput {
  data: null;
}

export const forgotPassword = async (
  data: IForgotPasswordInput
): Promise<IForgotPasswordOutput> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/forgot-password`,
    {
      body: {
        email: data.email,
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return result.data;
};

/** Reset Password after forgot */
interface IResetPasswordInput {
  newPassword: string;
  token: string;
}
export const resetPassword = async (
  input: IResetPasswordInput
): Promise<Types.ResponseOutput> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/forgot-password/reset`,
    {
      body: {
        newPassword: input.newPassword,
        token: input.token,
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

export interface IChangePasswordInput {
  password: string;
  confirmPassword: string;
  oldPassword: string;
}

interface IChangePasswordOutput extends Types.ResponseOutput {
  data: null;
}

export const changePassword = async (
  input: IChangePasswordInput
): Promise<IChangePasswordOutput> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/change-password`,
    {
      body: {
        password: input.password,
        confirmPassword: input.confirmPassword,
        oldPassword: input.oldPassword,
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return result;
};

/** reloadAuthData Endpoint */
interface IGetUserDataInput {}
interface IGetUserDataOutput extends Types.ResponseOutput {
  data: IAuthData;
}

export const getUserData = async (
  data: IGetUserDataInput
): Promise<IGetUserDataOutput> => {
  const result: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/auth`
  );
  return result.data;
};

/** logout Endpoint */
interface ILogoutOutput extends Types.ResponseOutput {
  data: null;
}

export const logout = async (): Promise<ILogoutOutput> => {
  // get auth token
  const data = await loadFromLocalStorage();

  const result = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/logout`,
    {
      useMiddleware: false,
      headers: {
        [Configuration.AUTH_HEADER_KEY]: `Bearer ${data.token}`,
      },
    }
  );
  return result.data;
};

/** registerUser  */
export interface IRegisterUserInput {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone?: string;
  username: string;
}

export type RegisterUserData = {
  email: string;
};

interface IRegisterUserOutput extends Types.ResponseOutput {
  data: RegisterUserData;
}

export const registerUser = async (
  payload: IRegisterUserInput
): Promise<IRegisterUserOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/register`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return data;
};

/** forgotPasswordVerify  */
export interface IForgotPasswordVerifyInput {
  verificationCode: string;
  email: string;
}

export type ForgotPasswordVerifyData = {
  token: string;
};

interface IForgotPasswordVerifyOutput extends Types.ResponseOutput {
  data: ForgotPasswordVerifyData;
}

export const forgotPasswordVerify = async (
  payload: IForgotPasswordVerifyInput
): Promise<IForgotPasswordVerifyOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/forgot-password/verify`,
    {
      body: {
        email: payload.email,
        code: payload.verificationCode,
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return data;
};

/** resendConfirmationEmail  */
export interface IResendConfirmationEmailInput {
  type: 'VerifyAccount' | 'ResetPassword' | 'VerifySession';
  email: string;
  verifySessionOptions?: {
    token: string;
    refreshToken: string;
  };
}

interface IResendEmailOutput extends Types.ResponseOutput {
  data: null;
}

export const resendVerificationEmail = async (
  payload: IResendConfirmationEmailInput
): Promise<IResendEmailOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/email-resend`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return data;
};

/** verifyAccount  */
export interface IVerifyAccountData {
  code: string;
  email: string;
}

interface IVerifyAccountOutput extends Types.ResponseOutput {
  data: null;
}

export const verifyAccount = async (
  payload: IVerifyAccountData
): Promise<IVerifyAccountOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/verify-account`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return data;
};

/** verifySession  */
export interface IVerifySessionInput {
  code: string;
  token: string;
  refreshToken: string;
}

interface IVerifySessionOutput extends Types.ResponseOutput {
  data: null;
}

export const verifySession = async (
  payload: IVerifySessionInput
): Promise<IVerifySessionOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/verify-session`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return data;
};

/** forceChangePasswordVerify  */
export interface IForceChangePasswordVerifyInput {
  verificationCode: string;
  email: string;
}

export type ForceChangePasswordVerifyData = {
  token: string;
};

interface IForceChangePasswordVerifyOutput extends Types.ResponseOutput {
  data: ForceChangePasswordVerifyData;
}

export const forceChangePasswordVerify = async (
  payload: IForceChangePasswordVerifyInput
): Promise<IForceChangePasswordVerifyOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/force-change-password/verify`,
    {
      body: {
        email: payload.email,
        code: payload.verificationCode,
      },
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );
  return data;
};

/** Reset Password after force change password */
export interface IForceChangeResetPasswordInput {
  newPassword: string;
  token: string;
  oldPassword: string;
}
export const resetPasswordAfterForceChange = async (
  input: IForceChangeResetPasswordInput
): Promise<Types.ResponseOutput> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/auth/force-change-password/reset`,
    {
      body: input,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};

export const socialLogin = (
  providerType: 'google' | 'instagram' | 'facebook'
) => {
  window.location =
    `${Environment.API_BASE_URL}/api/auth/login/${providerType}/${Environment.CLIENT_TYPE}` as any;
};
