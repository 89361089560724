import React from 'react';

interface IContextBuilderProps<T> {
  children: React.ReactNode;
  props: T;
}

export default function <T>(defaultValue: T) {
  const ScreenPropsContext = React.createContext<T>(defaultValue);

  const ContextProvider = ({ children, props }: IContextBuilderProps<T>) => {
    return (
      <ScreenPropsContext.Provider value={props}>
        {children}
      </ScreenPropsContext.Provider>
    );
  };

  const useContextProps = () => {
    const props = React.useContext(ScreenPropsContext);
    return props;
  };

  return { ContextProvider, useContextProps };
}
