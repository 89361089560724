import React from 'react';
import { useContextProps } from '../../provider';
import { arraysHasCommonElements } from 'utils/arrays';
import TranslatedText from 'components/atoms/TranslatedText';
import { Button } from '@material-ui/core';
import useStyles from './styles';
import * as OpenAIService from 'services/api/openai';
import { NoNullFields, OverrideValues } from 'utils/generics';
import { Roles } from 'constants/authRolesConst';
import RoutineDetailsActions from '../../store/actions';
import { ItemTypeEnum } from 'constants/typesConst';
import {
  closePromptsModal,
  openPromptsModal,
} from 'components/app_common/Prompts';
import { formatCamelOrUnderscoreCaseToSentenceCase } from 'utils/strings';
import {
  addItemsDataByPrompt,
  ICollectionPromptData,
} from 'services/api/openai';
import { openPromptInputModalModal } from 'components/app_common/PromptInputModal';
import { useDispatch } from 'react-redux';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import _ from 'lodash';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import {
  hideLoadingView,
  showLoadingView,
} from 'components/compounds/FullScreenLoading';

const AIPromptsButton = () => {
  const params = useContextProps();
  const routineId = params.collectionId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const roles = useSelectUserRoles();

  return (
    <>
      {params?.mode !== 'add' &&
        routineId &&
        arraysHasCommonElements(roles, [
          Roles.Admin,
          Roles.Teacher,
          Roles.Supervisor,
        ]) && (
          <Button
            className={classes.submit}
            color="primary"
            variant="contained"
            onClick={async () => {
              const response = await OpenAIService.fetchCollectionPrompt(
                routineId
              );

              const mapData: Required<
                OverrideValues<ICollectionPromptData, ItemTypeEnum | null>
              > = {
                description: null,
                titleTranslations: null,
                itemsMultipleChoice: ItemTypeEnum.MultipleChoice,
                itemsTrueFalse: ItemTypeEnum.TrueOrFalse,
                itemsRearrangementQuestionAnswer:
                  ItemTypeEnum.RearrangementAnswer,
                itemsQuestionAnswer: ItemTypeEnum.QuestionAnswer,
                itemsRearrangement: ItemTypeEnum.Rearrangement,
                itemsPhrasesByText: ItemTypeEnum.Phrase,
                itemsPhrasesByTopic: ItemTypeEnum.Phrase,
                itemsWordsByText: ItemTypeEnum.Word,
                itemsWordsByTopic: ItemTypeEnum.Word,
                itemsMultipleGap: ItemTypeEnum.FillTheGapMultiple,
                descriptionByItems: null,
                descriptionByTopic: null,
              };

              openPromptsModal({
                prompts: Object.keys(response.data ?? {}).map((el) => ({
                  title: formatCamelOrUnderscoreCaseToSentenceCase(el),
                  content: _.get(response.data, el),
                  handleAddData: _.get(mapData, el)
                    ? () => {
                        openPromptInputModalModal({
                          title: `${el} Prompt`,
                          handleResult: async (data) => {
                            try {
                              showLoadingView();
                              await addItemsDataByPrompt({
                                collectionId: routineId,
                                promptResult: data,
                                itemType: _.get(mapData, el),
                              });
                            } catch (error) {
                              console.log(
                                'openPromptInputModalModal:',
                                error.message
                              );
                            } finally {
                              hideLoadingView();
                            }

                            // refetch data
                            dispatch(
                              RoutineDetailsActions.fetchRoutineDetailsAction(
                                routineId as string
                              )
                            );
                            closePromptsModal();
                          },
                        });
                      }
                    : undefined,
                })),
              });
            }}
            startIcon={<SystemUpdateAltOutlinedIcon />}
          >
            <TranslatedText defaultText={'AI PROMPTS'} />
          </Button>
        )}
    </>
  );
};

export default React.memo(AIPromptsButton);
