import { Container, ContainerProps } from '@material-ui/core';
import React, { ReactElement } from 'react';
import useStyles from './styles';
import clsx from 'clsx';

interface IProps extends Omit<ContainerProps, 'className' | 'maxWidth'> {
  children: ReactElement[] | ReactElement;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  className?: string;
}

export default function PageLayout(props: IProps) {
  const { paddingTop = true, paddingBottom = true, children, ...rest } = props;
  const classes = useStyles({ paddingTop, paddingBottom });

  return (
    <Container
      {...rest}
      className={clsx(classes.root, props.className)}
      maxWidth="xl"
    >
      {children}
    </Container>
  );
}
