// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import Environment from 'config/environmentConfig';
import request from 'utils/request';
import { CollectionTypeEnum, ItemTypeEnum } from 'constants/typesConst';
import { TagLevel, TagTypes } from 'constants/tagsConst';
import * as PathUtils from 'utils/path';

/** fetchCollectionPrompt  */
export type ICollectionPromptData = {
  // description?: string;
  // titleTranslations?: string;
  // itemsMultipleChoice?: string;
  // itemsTrueFalse?: string;
  // itemsQuestionAnswer?: string;
  // itemsRearrangementQuestionAnswer?: string;
  // itemsRearrangement?: string;
  // items?: string;
  description?: string;
  titleTranslations?: string;
  itemsMultipleChoice?: string;
  itemsTrueFalse?: string;
  itemsQuestionAnswer?: string;
  itemsRearrangementQuestionAnswer?: string;
  itemsRearrangement?: string;
  itemsWordsByTopic?: string;
  itemsWordsByText?: string;
  itemsPhrasesByTopic?: string;
  itemsPhrasesByText?: string;
  itemsMultipleGap?: string;
  descriptionByItems?: string;
  descriptionByTopic?: string;
};

export const fetchCollectionPrompt = async (
  id: string
): Promise<Types.ResponseOutputV2<ICollectionPromptData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/openai/prompts/collection/${id}`
  );

  return data;
};

/** fetchItemPrompt  */
export type IItemPromptData = {
  description?: string;
  explanationTranslation?: string;
  sentence?: string;
  translation?: string;
  etymologyEvolution?: string;
};

export const fetchItemPrompt = async (
  id: string
): Promise<Types.ResponseOutputV2<IItemPromptData>> => {
  const { data } = await request(
    'get',
    `${Environment.API_BASE_URL}/api/openai/prompts/item/${id}`
  );

  return data;
};

/** addItemsDataByPrompt  */
export type IAddItemsDataByPromptData = {};

export type IAddItemsDataByPromptPayload = {
  collectionId: string;
  promptResult: string;
  itemType: ItemTypeEnum;
};

export const addItemsDataByPrompt = async (
  payload: IAddItemsDataByPromptPayload
): Promise<Types.ResponseOutputV2<IAddItemsDataByPromptData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/openai/prompts/add-collection-items`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** addItemsDataByPrompt  */
export type IEtymologyEvolutionDataByPromptData = {};

export type IEtymologyEvolutionDataByPromptPayload = {
  itemId: string;
  promptResult: string;
};

export const addItemEtymologyEvolutionDataByPrompt = async (
  payload: IEtymologyEvolutionDataByPromptPayload
): Promise<Types.ResponseOutputV2<IAddItemsDataByPromptData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/openai/prompts/add-etymology-evolution`,
    {
      body: payload,
      toastMessageVisibility: {
        error: true,
        success: true,
      },
    }
  );

  return data;
};

/** fetchTagPrompt  */
export type ITagPromptPayload = {
  type: TagTypes;
};
export type ITagPromptData = Array<{
  content: string;
  level: number;
}>;

export const fetchTagPrompt = async (
  payload: ITagPromptPayload
): Promise<Types.ResponseOutputV2<ITagPromptData>> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/openai/prompts/tag`,
    {
      body: payload,
      toastMessageVisibility: {
        success: true,
        error: true,
      },
    }
  );

  return data;
};
