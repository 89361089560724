// Function to split text into paragraphs and pair them
export function pairParagraphs(
  text1: string,
  text2: string,
  getLength?: (text1Length: number, text2Length: number) => number
): {
  pairedParagraphs: Array<{ text1: string; text2: string }>;
  sameNumberOfParagraphs: boolean;
} {
  // Helper function to split text into paragraphs
  const splitIntoParagraphs = (text: string): string[] => {
    return text
      .split(/\n{2,}/)
      .map((paragraph) => paragraph.trim())
      .filter((paragraph) => paragraph.length > 0);
  };

  // Split texts into paragraphs
  const paragraphs1 = splitIntoParagraphs(text1);
  const paragraphs2 = splitIntoParagraphs(text2);

  // Create the array of objects with paired paragraphs
  const pairedParagraphs: Array<{ text1: string; text2: string }> = [];
  const length = getLength
    ? getLength(paragraphs1.length, paragraphs2.length)
    : Math.max(paragraphs1.length, paragraphs2.length);

  for (let i = 0; i < length; i++) {
    pairedParagraphs.push({
      text1: paragraphs1[i] ?? '---',
      text2: paragraphs2[i] ?? '---',
    });
  }

  // Check if the texts have the same number of paragraphs
  const sameNumberOfParagraphs = paragraphs1.length === paragraphs2.length;

  return { pairedParagraphs, sameNumberOfParagraphs };
}

// function chunkPairedParagraphs(
//   pairedParagraphs: Array<{ text1: string; text2: string }>,
//   wordLimit: number,
//   joinWidth = '\n\n'
// ): Array<{ text1: string; text2: string }> {
//   const chunkedParagraphs: Array<{ text1: string; text2: string }> = [];

//   let currentChunkText1 = '';
//   let currentChunkText2 = '';

//   const countWords = (text: string) => text.split(/\s+/).length;

//   for (const pair of pairedParagraphs) {
//     const wordsInText1 = countWords(pair.text1);
//     const wordsInText2 = countWords(pair.text2);

//     const currentChunkWords1 = countWords(currentChunkText1);
//     const currentChunkWords2 = countWords(currentChunkText2);

//     const currentChunkWords =
//       currentChunkWords1 > currentChunkWords2
//         ? currentChunkWords1
//         : currentChunkWords2;

//     const wordsInText =
//       wordsInText1 > wordsInText2 ? wordsInText1 : wordsInText2;

//     if (wordsInText >= wordLimit * 0.8) {
//       // add previous
//       chunkedParagraphs.push({
//         text1: currentChunkText1,
//         text2: currentChunkText2,
//       });

//       currentChunkText1 = '';
//       currentChunkText2 = '';

//       // add new
//       chunkedParagraphs.push({
//         text1: pair.text1,
//         text2: pair.text2,
//       });
//     } else if (currentChunkWords + wordsInText <= wordLimit) {
//       currentChunkText1 += (currentChunkText1 ? joinWidth : '') + pair.text1;
//       currentChunkText2 += (currentChunkText2 ? joinWidth : '') + pair.text2;
//     } else {
//       if (currentChunkText1.length > 0 || currentChunkText2.length > 0) {
//         chunkedParagraphs.push({
//           text1: currentChunkText1,
//           text2: currentChunkText2,
//         });
//       }

//       currentChunkText1 = '';
//       currentChunkText2 = '';

//       // add new
//       chunkedParagraphs.push({
//         text1: pair.text1,
//         text2: pair.text2,
//       });
//     }
//   }

//   if (currentChunkText1.length > 0 || currentChunkText2.length > 0) {
//     chunkedParagraphs.push({
//       text1: currentChunkText1,
//       text2: currentChunkText2,
//     });

//     currentChunkText1 = '';
//     currentChunkText2 = '';
//   }

//   return chunkedParagraphs.filter(
//     (el) => el.text1.length > 0 || el.text2.length > 0
//   );
// }

function chunkPairedParagraphs(
  pairedParagraphs: Array<{ text1: string; text2: string }>,
  wordLimit: number,
  joinWidth = '\n\n'
): Array<{ text1: string; text2: string }> {
  const chunkedParagraphs: Array<{ text1: string; text2: string }> = [];

  let currentChunkText1 = '';
  let currentChunkText2 = '';

  const countWords = (text: string) => text.trim().split(/\s+/).length;

  for (const pair of pairedParagraphs) {
    const wordsInText1 = countWords(pair.text1);
    const wordsInText2 = countWords(pair.text2);

    const currentChunkWords1 = countWords(currentChunkText1);
    const currentChunkWords2 = countWords(currentChunkText2);

    // Calculate the total words in current chunks and new pair to decide if they can fit
    const maxCurrentWords = Math.max(currentChunkWords1, currentChunkWords2);
    const maxNewWords = Math.max(wordsInText1, wordsInText2);

    // Check if adding this pair would exceed the word limit
    if (maxCurrentWords + maxNewWords <= wordLimit) {
      currentChunkText1 += (currentChunkText1 ? joinWidth : '') + pair.text1;
      currentChunkText2 += (currentChunkText2 ? joinWidth : '') + pair.text2;
    } else {
      // If current chunk is not empty, push it to the result
      if (currentChunkText1 || currentChunkText2) {
        chunkedParagraphs.push({
          text1: currentChunkText1,
          text2: currentChunkText2,
        });
      }

      // Start a new chunk with the current pair
      currentChunkText1 = pair.text1;
      currentChunkText2 = pair.text2;
    }
  }

  // Push any remaining chunk to the result
  if (currentChunkText1 || currentChunkText2) {
    chunkedParagraphs.push({
      text1: currentChunkText1,
      text2: currentChunkText2,
    });
  }

  return chunkedParagraphs;
}

export function splitTextIntoChunks(
  text1: string,
  text2: string,
  wordLimit = 50
) {
  const result = pairParagraphs(text1, text2);

  if (result.sameNumberOfParagraphs) {
    const chunkedParagraphs = chunkPairedParagraphs(
      result.pairedParagraphs,
      wordLimit
    );

    return chunkedParagraphs;
  }

  return null;
}

export function magicFormat(text: string) {
  // Split the text into paragraphs based on the pattern [...]
  const paragraphs = text.split(/\[(\d+)\]/).slice(1); // Skip the empty element from split if it starts with [

  // Combine the numbers and the paragraphs back together
  const formattedParagraphs = [];
  for (let i = 0; i < paragraphs.length; i += 2) {
    const index = paragraphs[i];
    let content = paragraphs[i + 1].trim(); // Trim the paragraph text
    content = content.replace(/\n+/g, ' '); // Remove new lines within the paragraph
    formattedParagraphs.push(`[${index}] ${content}`);
  }

  // Join paragraphs with two newline characters
  const outputText = formattedParagraphs.join('\n\n');
  return outputText;
}
