import React from 'react';
import Menu from '@material-ui/core/Menu';
import { LanguageConfiguration } from './configuration';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { LanguagesEnum } from '../../../constants/languagesConst';
import * as LanguageSelectors from '../../../redux/reducers/gl_translations_reducer/selectors';
import FormattedMenu from 'components/atoms/FormattedMenu';
import { ListItemText, MenuItem, useTheme } from '@material-ui/core';

interface IProps {
  isLanMenuOpen: boolean;
  menuLanId: string;
  lanAnchorEl: HTMLElement | null;
  handleLanMenuClose: () => void;
}

export default function LanguageMenu({
  isLanMenuOpen,
  menuLanId,
  lanAnchorEl,
  handleLanMenuClose,
}: IProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const languageID: LanguagesEnum = LanguageSelectors.useSelectLanguageID();

  return (
    <FormattedMenu
      anchorEl={lanAnchorEl}
      id={menuLanId}
      open={isLanMenuOpen}
      onClose={handleLanMenuClose}
    >
      {LanguageConfiguration.map((props, index: number) => (
        <MenuItem
          style={{
            fontSize: 16,
            ...(languageID === props.id
              ? {
                  backgroundColor: theme.palette.primary.main,
                  color:
                    theme.palette.type === 'dark'
                      ? theme.palette.primary.dark
                      : theme.palette.common.white,
                }
              : undefined),
          }}
          key={index}
          onClick={() => {
            props.action(dispatch);
            handleLanMenuClose();
          }}
        >
          {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
          <ListItemText
            style={{
              color:
                theme.palette.type === 'dark'
                  ? theme.palette.common.white
                  : undefined,
            }}
            color="inherit"
            primary={props.label}
          />
        </MenuItem>
      ))}
    </FormattedMenu>
  );
}
