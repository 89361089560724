import React, { ReactElement } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  // TextField as MuTextField,
  StandardTextFieldProps,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

interface IIcon {
  // position: 'left' | 'right';
  // Component: ReactElement;
  ComponentLeft?: ReactElement;
  ComponentRight?: ReactElement;
}

interface Iprops extends Omit<StandardTextFieldProps, 'onChange' | 'variant'> {
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  name: string;
  onFocus?: any;
  controllerExtras?: any;
}

export default function TTextField(props: Iprops) {
  const { control, icon, rules, controllerExtras = {}, ...other } = props;

  return (
    <Controller
      render={({ value, onChange, ...rest }) => (
        <TextField
          value={value || ''}
          // onChange={(e) => field.onChange(parseInt(e.target.value, 10))}

          onChange={
            props.type === 'number'
              ? (e) => onChange(parseInt(e.target.value, 10))
              : onChange
          }
          {...rest}
          {...other}
          InputProps={{
            endAdornment: icon && icon.ComponentRight && (
              <React.Fragment>
                {
                  <InputAdornment position="start">
                    {icon.ComponentRight}
                  </InputAdornment>
                }
              </React.Fragment>
            ),
            startAdornment: icon && icon.ComponentLeft && (
              <React.Fragment>
                {
                  <InputAdornment position="end">
                    {icon.ComponentLeft}
                  </InputAdornment>
                }
              </React.Fragment>
            ),
            ...other.InputProps,
          }}
        />
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
