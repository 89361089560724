import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from 'redux/utils/common';
import * as UsersService from 'services/api/user';
import reactLogger from 'utils/logger';
import { IState } from './reducer';
import * as NamespaceServices from 'services/api/namespaces';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import _ from 'lodash';
import * as CollectionsServices from 'services/api/collections';
import { CollectionStatusEnum } from 'constants/statusConst';
import * as CollectionLibraryServices from 'services/api/collectionsLibrary';

/** Fetch Cimport * as CollectionsServices from 'services/api/collections';
ollections  */
interface IFetchLibraryCollectionByCollectionIdInput {
  filter: CollectionLibraryServices.ICollectionLibraryPerCollectionInput;
  onSuccess?: () => void;
}

class FetchLibraryCollectionsByCollectionId implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COLLECTIONS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: IState['collectionsList']['data']): IAction {
    return {
      type: Types.SET_COLLECTIONS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COLLECTIONS_LIST,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(payload: IFetchLibraryCollectionByCollectionIdInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());

        const response =
          await CollectionLibraryServices.fetchCollectionLibrariesPerCollection(
            payload.filter
          );
        payload.onSuccess && payload.onSuccess();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchLibraryCollectionsByCollectionId:',
          error.message
        ); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// /** Set selected collections  */
// interface ISetSelectedCollectionsInput {
//   action: 'add' | 'remove';
//   collectionId: string | string[];
//   multiple: boolean;
//   handleNewData?: (payload: IState['selectedCollectionsMapping']) => void;
// }

// class SetSelectedCollections implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_SELECTED_COLLECTIONS,
//       status: StateStatus.Pending,
//       data: null,
//     };
//   }
//   onSuccess(data: IState['selectedCollectionsMapping']): IAction {
//     return {
//       type: Types.SET_SELECTED_COLLECTIONS,
//       status: StateStatus.Success,
//       data: data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_SELECTED_COLLECTIONS,
//       status: StateStatus.Failed,
//       data: null,
//     };
//   }

//   action(payload: ISetSelectedCollectionsInput): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         let currentMapping =
//           (
//             (getState() as any)?.[
//               ReducerKeys.COLLECTION_SELECTOR_REDUCER
//             ] as IState
//           )?.selectedCollectionsMapping ?? {};

//         let collections: string[] = [];
//         if (Array.isArray(payload.collectionId)) {
//           collections = payload.collectionId;
//         } else {
//           collections = [payload.collectionId];
//         }

//         if (payload.multiple) {
//           for (const student of collections) {
//             if (payload.action === 'add') {
//               currentMapping[student] = { id: student };
//             } else {
//               _.unset(currentMapping, student);
//             }
//           }
//         } else {
//           currentMapping = {
//             [collections?.[0]]: { id: collections?.[0] },
//           };
//         }

//         dispatch(this.onSuccess(currentMapping));
//         payload.handleNewData && payload.handleNewData(currentMapping);
//       } catch (error) {
//         reactLogger.error('SetSelectedCollections:', error.message); // '<ClassName> Error: <error>'
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

class Reset implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method Not Implemented');
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    throw new Error('Method Not Implemented');
  }

  action(): IAction {
    return this.onSuccess();
  }
}

export default {
  // setSelectedCollectionsAction: (payload: ISetSelectedCollectionsInput) =>
  //   new SetSelectedCollections().action(payload),
  fetchLibraryCollectionsByCollectionIdAction: (
    payload: IFetchLibraryCollectionByCollectionIdInput
  ) => new FetchLibraryCollectionsByCollectionId().action(payload),
  resetAction: () => new Reset().action(),
};
