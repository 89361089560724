import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { openUpdateFileDataModal } from '../EditFileDataModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

interface IColumnNameProps {
  name: string | null;
  fileId: string;
}

const ColumnName = ({ name, fileId }: IColumnNameProps) => {
  return (
    <React.Fragment>
      <Tooltip title="Edit File name">
        <IconButton
          onClick={() => {
            openUpdateFileDataModal({
              name,
              fileId,
            });
          }}
          size="small"
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>

      {name}
    </React.Fragment>
  );
};

export default ColumnName;
