import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_LOGS_LIST: string = typeCreatorV2(
  'LogsReducer',
  'SetLogsList'
);

export const SET_REQUEST_ACTIVITIES_LIST: string = typeCreatorV2(
  'LogsReducer',
  'SetRequestActivitiesList'
);
