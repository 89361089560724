import {
  NamespaceType,
  WordReferenceNamespaceTypeMap,
} from 'constants/namespaceConst';
import { getState } from 'redux/store';
import { openNestedWindow } from 'utils/window';

export const openWordReference = (text: string) => {
  const namespaceLan =
    getState().CommonReducer.namespacesDetails.data?.type ??
    NamespaceType.English;
  openNestedWindow(
    `https://www.wordreference.com/${WordReferenceNamespaceTypeMap[namespaceLan]}/${text}`
  );
};
