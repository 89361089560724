import useQuery from 'hooks/useQuery';
import React from 'react';
import { CollectionTypeEnum } from 'constants/typesConst';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import { IState } from './store/reducer';
import { QuizzesByLevelMap, QuizzesByTypeMap } from 'constants/collectionConst';

// export const useQueryParams = () => {
//   const query = useQuery();
//   // const _public = query.get('public') as 'true' | 'false';
//   const collectionId = query.get('collectionId') as any;
//   const type = query.get('type') as CollectionTypeEnum | undefined;

//   return React.useMemo(
//     () => ({
//       // public: _public !== 'false',
//       collectionId,
//       type,
//     }),
//     [collectionId, type] //_public,
//   );
// };

// export const getQuizzesByChapterLevel = (tagName: string) => {
//   const result = (
//     (getState() as any)?.[ReducerKeys.ROUTINE_DETAILS_REDUCER] as IState
//   ).tagsList?.data?.items.find((el) => el.name === tagName);

//   return result?.level ? QuizzesByLevelMap[result.level] : undefined;
// };

export const getQuizzesByChapterType = (type: CollectionTypeEnum) => {
  return QuizzesByTypeMap?.[type];
};
