import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import * as Configuration from './configuration';
import reactLogger from 'utils/logger';
import { createTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { ThemesModeEnum } from 'constants/themeConst';
import Environment from 'config/environmentConfig';

interface IProps {
  children: React.ReactElement[];
}

export default function ThemeProvider(props: IProps) {
  reactLogger.renderComponent('ThemeProvider');
  const theme = ThemeSelectors.useSelectTheme();
  const palleteId = ThemeSelectors.usePalleteId();

  return (
    <MuiThemeProvider
      theme={createTheme(
        Configuration.Palletes[palleteId || Environment.DEFAULT_PALLET][
          theme || ThemesModeEnum.Light
        ]
      )}
    >
      {props.children}
    </MuiThemeProvider>
  );
}
