import ContextBuilder from 'providers/ContextBuilder';
import type { IModalParams } from './types';
import { FilePathTypes, FileType } from 'constants/filesConst';

export const { ContextProvider, useContextProps } = ContextBuilder<
  IParams & {
    setParams?: (params: IParams) => void;
    refetch?: (initialSearch?: boolean) => void;
  }
>({});

export type IParams = {
  page?: number;
  pageSize?: number;
  orderType?: 'asc' | 'desc';
  globalSearch?: string;
  attachmentType?: FilePathTypes;
  fileType?: FileType;
  sortField?: string;
  isOrphanCheck?: 'true' | 'false';
};
