import React from 'react';
import { AppTypes } from 'constants/namespaceConst';
import { useAppType } from 'redux/reducers/gl_common_reducer/selectors';

export default function useTextSelector() {
  const appType = useAppType();

  const textSelector = <T>(
    default_: T,
    namesMapping?: { [key in AppTypes]?: T }
  ) => {
    return appType ? namesMapping?.[appType] ?? default_ : default_;
  };

  return { textSelector };
}
