import React from 'react';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import AuthActions from 'redux/reducers/gl_auth_reducer/actions';
import { useDispatch } from 'react-redux';
import useQuery from 'hooks/useQuery';
import { Roles } from 'constants/authRolesConst';
import ThemeActions from 'redux/reducers/gl_theme_reducer/actions';
import TranslationActions from 'redux/reducers/gl_translations_reducer/actions';
import usePrevious from 'hooks/usePrevious';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import { getState } from 'redux/store';
import _ from 'lodash';
import {
  useHasModals,
  useModalData,
} from 'redux/reducers/gl_modal_reducer/selectors';

export interface IWebviewProviderProps {
  children: React.ReactNode;
}

const WebviewProvider = ({ children }: IWebviewProviderProps) => {
  return (
    <>
      <WebViewHandlder />
      {children}
    </>
  );
};

const useWebview = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const hasModals = useHasModals();
  //   const mobileAppAccess = ThemeSelectors.useIsMobileAppAccess();
  const webViewAccess =
    query.get('webViewAccess') === 'true' ||
    (getState().ThemeReducer.accessFromMobileApp &&
      !query.get('webViewAccess'));

  React.useEffect(() => {
    if (webViewAccess) {
      window.addEventListener('message', (message) => {
        console.log('Get messages from webview...:', message);
        // alert('Get messages from webview...:');

        try {
          const data = message.data;
          if (_.has(data, 'themeMode')) {
            dispatch(ThemeActions.changeThemeAction(data.themeMode as any));
          }

          if (_.has(data, 'lan')) {
            dispatch(TranslationActions.changeLanguageAction(data.lan as any));
          }

          if (_.has(data, 'logout')) {
            alert('Logout!!..');
            dispatch(AuthActions.logoutAction());
          }
          // ... add more events..
        } catch (ex) {
          alert('Error..' + ex.message);
        }
      });
      // dome is ready
      (window as any)?.ReactNativeWebView &&
        (window as any).ReactNativeWebView.postMessage('READY');
    }

    // return () => {
    //   if (webViewAccess) {
    //     dispatch(AuthActions.logoutAction());
    //   }
    // };
  }, [dispatch, webViewAccess]);

  React.useLayoutEffect(() => {
    dispatch(ThemeActions.setAccessAction(webViewAccess));
  }, [dispatch, webViewAccess]);

  React.useEffect(() => {
    if (webViewAccess) {
      if (hasModals) {
        (window as any)?.ReactNativeWebView &&
          (window as any).ReactNativeWebView.postMessage('HAS_MODALS');
      } else {
        (window as any)?.ReactNativeWebView &&
          (window as any).ReactNativeWebView.postMessage('NO_MODALS');
      }
    }
  }, [hasModals, webViewAccess]);
};

const WebViewHandlder = React.memo(() => {
  useWebview();

  return <></>;
});

export default React.memo(WebviewProvider);
