import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as SessionService from 'services/api/session';
import * as UsersService from 'services/api/user';

export interface IState {
  sessionList: {
    status: StateStatus | null;
    data: SessionService.FetchSessionData | null;
  };
  sessionsSelection: {
    enabled: boolean;
    data: Array<string> | null;
  };
  forceLogout: StateStatus | null;
}

const initialState: IState = {
  sessionList: {
    status: null,
    data: null,
  },
  sessionsSelection: {
    enabled: false,
    data: null,
  },
  forceLogout: null,
};

const SessionReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_SESSION_LIST:
      return {
        ...state,
        sessionList: {
          ...state.sessionList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.sessionList.data
              : action.data,
        },
      };
    case Types.TOGGLE_SESSIONS_SELECTION:
      return {
        ...state,
        sessionsSelection: {
          enabled: action.data,
          data: action.data === true ? [] : null,
        },
      };
    case Types.SET_SELECTED_SESSIONS:
      return {
        ...state,
        sessionsSelection: {
          ...state.sessionsSelection,
          data: action.data,
        },
      };
    case Types.FORCE_LOGOUT:
      return {
        ...state,
        forceLogout: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default SessionReducer;
