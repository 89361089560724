import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ActivitiesService from 'services/api/activities';

export interface IState {
  activitiesList: {
    status: StateStatus | null;
    data: ActivitiesService.FetchActivitiesData | null;
  };
}

const initialState: IState = {
  activitiesList: {
    status: null,
    data: null,
  },
};

const ActivitiesReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_ACTIVITIES_LIST:
      return {
        ...state,
        activitiesList: {
          ...state.activitiesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.activitiesList.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default ActivitiesReducer;
