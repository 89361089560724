import { makeStyles, PaletteColorOptions } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  element: {
    padding: '.75rem',
    marginTop: '.25rem',
    marginBottom: '.25rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  selected: {
    background: theme.palette.action.selected,
  },
  palette: ({
    primary,
    secondary,
  }: {
    primary?: string;
    secondary?: string;
  }) => ({
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    marginLeft: '.25rem',
    background: `linear-gradient(90deg, ${primary} 50%, ${secondary} 50% )`,
  }),
}));

export default useStyles;
