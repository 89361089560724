import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const UPLOAD_FILE = typeCreatorV2('UploadedFilesReducer', 'UploadFile');

export const REMOVE_FILE = typeCreatorV2('UploadedFilesReducer', 'RemoveFile');

export const SET_FILES = typeCreatorV2('UploadedFilesReducer', 'SetFiles');

export const EDIT_FILE = typeCreatorV2('UploadedFilesReducer', 'EditFile');

export const UPLOAD_FILE_FROM_URL = typeCreatorV2(
  'UploadedFilesReducer',
  'UploadFileFromURL'
);
