import { alpha, colors, ThemeOptions } from '@material-ui/core';
import { ThemesModeEnum } from 'constants/themeConst';
import { PalletesEnum } from 'constants/palletesConst';

interface IThemesConfig {
  [ThemesModeEnum.Dark]?: ThemeOptions;
  [ThemesModeEnum.Light]?: ThemeOptions;
}

interface IPalletesConfig {
  [key: string]: IThemesConfig;
}

// https://bareynol.github.io/mui-theme-creator/ official generator
// https://in-your-saas.github.io/material-ui-theme-editor/
// https://material-ui.com/customization/default-theme/ --> DEFAULT THEME
export const Palletes: IPalletesConfig = {
  [PalletesEnum.DefaultPallete]: {
    [ThemesModeEnum.Dark]: {
      palette: {
        primary: {
          main: colors.common.black,
        },
        type: 'dark',
      },
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: colors.grey['900'],
          },
        },
      },
    },
    [ThemesModeEnum.Light]: {
      palette: {
        primary: {
          main: '#116eee', //'#040b91',
        },
        secondary: {
          main: '#050A30',
        },
        type: 'light',
      },
    },
  },
  [PalletesEnum.Pallete2]: {
    [ThemesModeEnum.Dark]: {
      palette: {
        primary: {
          main: colors.common['black'],
        },
        secondary: {
          main: colors.grey['700'],
        },
        background: {
          // default: '#121212',
          default: '#121212',
        },
        text: {
          // primary: 'white',
        },
        type: 'dark',
      },
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: colors.grey['900'],
          },
        },
      },
    },
    [ThemesModeEnum.Light]: {
      palette: {
        primary: {
          main: colors.red['500'],
        },
        secondary: {
          main: colors.blue['700'],
        },
        background: {
          default: '#f2f4f7',
        },
        // text: {
        //   // primary: TEXT_COLOR_PRIMARY({}),
        //   // secondary: colors.common['white'],
        // },
        type: 'light',
      },
    },
  },
  [PalletesEnum.Pallete1]: {
    [ThemesModeEnum.Dark]: {
      palette: {
        primary: {
          main: colors.common.black,
        },
        type: 'dark',
      },
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: colors.grey['900'],
          },
        },
      },
    },
    [ThemesModeEnum.Light]: {
      palette: {
        type: 'light',
        // danger: {
        //   main: colors.red['700'],
        // },
      },
      overrides: {},
    },
  },
  [PalletesEnum.Pallete3]: {
    [ThemesModeEnum.Dark]: {
      palette: {
        primary: {
          main: colors.common.black,
        },
        type: 'dark',
      },
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: colors.grey['900'],
          },
        },
      },
    },
    [ThemesModeEnum.Light]: {
      palette: {
        common: { black: '#000', white: '#fff' },
        background: {
          paper: 'rgba(255, 255, 255, 1)',
          default: 'rgba(234, 234, 234, 1)',
        },
        primary: {
          light: 'rgba(14, 26, 87, 1)',
          main: 'rgba(16, 13, 121, 1)',
          dark: 'rgba(0, 1, 4, 1)',
          contrastText: '#fff',
        },
        secondary: {
          light: 'rgba(16, 165, 150, 1)',
          main: 'rgba(15, 118, 133, 1)',
          dark: 'rgba(3, 56, 54, 1)',
          contrastText: '#fff',
        },
        error: {
          light: '#e57373',
          main: '#f44336',
          dark: '#d32f2f',
          contrastText: '#fff',
        },
        text: {
          primary: 'rgba(0, 0, 0, 0.87)',
          secondary: 'rgba(0, 0, 0, 0.54)',
          disabled: 'rgba(0, 0, 0, 0.38)',
          hint: 'rgba(6, 7, 53, 0.38)',
        },
      },
    },
  },
};
