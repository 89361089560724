import React from 'react';
import BasicTemplate from '../../BasicTemplate';
import {
  AnswerSplitItem,
  IItemDetailsProps,
  BlankSplitItem,
  getDefaultValues,
} from '../../../configuration';

const WordItemForm = (props: IItemDetailsProps) => {
  return (
    <BasicTemplate
      {...props}
      config={{
        sectionTitle: {
          visibility: {
            includeTitle: true,
          },
        },
        sectionExamples: {
          visibility: {
            includeExamples: true,
          },
        },
        senctionCategories: {
          visibility: { includeCategories: true },
        },
        senctionBasicInfo: {
          visibility: {
            includeStatus: true,
            includePublic: true,
            includeType: true,
            includeDifficulty: true,
            includeNamespaces: true,
          },
        },
        senctionImage: {
          visibility: {},
        },
        senctionExplanation: {
          visibility: {
            includeDescription: true,
          },
        },
        senctionAdditionalInfo: {
          visibility: {
            includeAdditionalInfoText: true,
          },
          renameLabel: 'Additional Info',
          optional: true,
        },
        senctionHiddenInfo: {
          visibility: { includeHiddenInfo: true },
        },
      }}
    />
  );
};

export default WordItemForm;
