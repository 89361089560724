import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: 0,
    },
  },
  subContainer: {
    maxWidth: 1500,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
  },
  header: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  actionsMenuContainer: {
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
}));

export default useStyles;
