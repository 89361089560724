import React from 'react';
import { Box, colors, Paper, Switch, Typography } from '@material-ui/core';
import ThemeActions from 'redux/reducers/gl_theme_reducer/actions';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import { useDispatch } from 'react-redux';
import { ThemesModeEnum } from 'constants/themeConst';
import TranslatedText from 'components/atoms/TranslatedText';
import useStyles from './styles';

const ThemeSelector = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = ThemeSelectors.useSelectTheme();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography>
        <TranslatedText defaultText={'Dark mode'} />
      </Typography>
      <Switch
        checked={theme && theme === ThemesModeEnum.Dark}
        onChange={() => {
          if (theme === ThemesModeEnum.Dark)
            dispatch(ThemeActions.changeThemeAction(ThemesModeEnum.Light));
          else dispatch(ThemeActions.changeThemeAction(ThemesModeEnum.Dark));
        }}
        name="theme"
      />
    </Box>
  );
};

export default React.memo(ThemeSelector);
