import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      marginTop: '1rem',
      maxHeight: '70vh',
    },
  },
  chipContainerMd: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  chipContainerSm: {
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  buttonsBarAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
    marginTop: '1rem',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.8rem',
  },
  buttonsNestedAction: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  listItemContainer: {
    paddingRight: '0.4rem',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
}));

export default useStyles;
